import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import LoginImage from "../../../components/glyphs/elements/login-image";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import { AppContext } from "../../../utils/context";
import Footer from "../footer";
import Button from "../button";
import Trademark from "../trademark";
import Error from "../../../components/glyphs/elements/error";
import Alert from "../../../components/alert";
import { useIntercom } from "react-use-intercom";

/** store alert messages */
const alertData = [
  {
    text: "Link not valid or expired. Try generating a new link.",
    backgroundColor: colors["background-dull-red"],
    borderColor: colors.error[1],
    color: colors.error[1],
    glyph: <Error fill={colors.error[1]} />
  },
  {
    text: "Password too weak. Try a different password.",
    backgroundColor: colors["background-dull-red"],
    borderColor: colors.error[1],
    color: colors.error[1],
    glyph: <Error fill={colors.error[1]} />
  }
];

const ResetPassword = () => {
  let history = useHistory();
  const { host } = useContext(AppContext);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordValidation, togglePasswordValidation] = useState([false, ""]);
  const [isLoaded, toggleLoading] = useState(false);
  const [alert, toggleAlert] = useState([false, 0]);
  const token = useParams().token;
  const email = useParams().email;

  const handleSubmit = async event => {
    event.preventDefault();
    if (password !== confirmPassword) {
      togglePasswordValidation([true, "Passwords do not match"]);
    } else if (password === "" || confirmPassword === "") {
      togglePasswordValidation([true, "Password cannot be empty"]);
    } else {
      toggleLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Cookie",
        "_aknamed_admin_session=KNT91jPIl2iY0q52%2BbgDAdmTo9SEP3CP92q3Sh3iM1r7A%2F6VcESDgdk5IFBfSbjuzg%3D%3D--kK9wtn8Cf%2FFV0BVy--RLvNl2gaR7OL9A6dvntJ9g%3D%3D"
      );

      const raw = JSON.stringify({
        password: password
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      try {
        const response = await fetch(
          `${host}/smartchain/reset_password?token=${token}&email=${email}`,
          requestOptions
        );
        if (response.status === 422) {
          toggleAlert([true, 1]);
        } else if (response.status === 404) {
          toggleAlert([true, 0]);
        }
        const result = await response.json();
        toggleLoading(false);
        if (result.alert) {
          history.push("/");
        }
      } catch (err) {
        console.log("Error ", err);
      }
    }
  };

  useEffect(() => {
    if (passwordValidation[0]) {
      if (password === confirmPassword) {
        togglePasswordValidation([false, ""]);
      }
    }
  }, [password, confirmPassword, passwordValidation]);

  const { shutdown } = useIntercom();

  useEffect(() => {
    shutdown();
  }, []);
  /** stores common props of two input element */
  const commonInputProps = {
    border: 1,
    borderColor: colors.text[2],
    borderRadius: 4,
    borderStyle: "solid",
    height: 33,
    pl: 10
  };

  return (
    <Primitives.Flex
      flexDirection="column"
      mb={[4, 1]}
      ml={4}
      mr={4}
      mt={[4, 1]}
    >
      <Primitives.Flex mx="auto">
        <Primitives.Flex display={["flex", "none"]}>
          <LoginImage />
        </Primitives.Flex>
        <Primitives.Flex display={["none", "flex"]}>
          <LoginImage height={275} width={400} />
        </Primitives.Flex>
      </Primitives.Flex>

      <Primitives.Flex flexDirection="column" mx="auto">
        <Trademark />
        <Primitives.Flex flexDirection="column" pt={2}>
          <Primitives.Text color={colors.text[0]} fontSize={6} fontWeight={0}>
            Enter New Password
          </Primitives.Text>
        </Primitives.Flex>
        {alert[0] && (
          <Primitives.Flex mt={2}>
            <Alert
              backgroundColor={alertData[alert[1]].backgroundColor}
              borderColor={alertData[alert[1]].borderColor}
              color={alertData[alert[1]].color}
              fontSize={1}
              glyph={alertData[alert[1]].glyph}
              label={
                <Primitives.Text alignItems="center" display="inline-flex">
                  {alertData[alert[1]].text}
                </Primitives.Text>
              }
              lineHeight={3}
              mx={2}
              pl={0}
              pr={[0, 1]}
              py="5px"
            />
          </Primitives.Flex>
        )}
        <form style={{ display: "flex", flexDirection: "column" }}>
          <Primitives.Input
            {...commonInputProps}
            mt={2}
            onChange={e => {
              setPassword(e.target.value);
            }}
            placeholder="Enter New Password"
            type="password"
            autoComplete="off"
          ></Primitives.Input>

          {passwordValidation[0] && (
            <Primitives.Flex mb={-21} px="3px" py="3px">
              <Primitives.Text color={colors.error[0]} fontSize={1}>
                {passwordValidation[1]}
              </Primitives.Text>
            </Primitives.Flex>
          )}
          <Primitives.Input
            {...commonInputProps}
            my={2}
            onChange={e => {
              setConfirmPassword(e.target.value);
            }}
            placeholder="Retype New Password"
            type="password"
            autoComplete="new-password"
          ></Primitives.Input>
          <Button isLoaded={isLoaded} handleSubmit={handleSubmit}>
            Change Password
          </Button>
        </form>
        <Footer />
      </Primitives.Flex>
    </Primitives.Flex>
  );
};
export default ResetPassword;
