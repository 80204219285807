import React, { useContext, useState, useEffect } from "react";
import Modal from "../../../components/modal";
import Primitives from "../../../components/primitives";
import { indianNumberFormat } from "../../../components/utils";
import { breakPoint, colors } from "../../../components/utils/theme";
import TooltipOnClick from "../../../lib/tooltip-onclick";
import { number, oneOfType, string, array, func } from "prop-types";
import { AppContext } from "../../../utils/context";
import useApiRequest from "../../../hooks/useApiRequest";
import { FilterContext } from "../../hospital-sales-report/filterContext";
import Loading from "../../../components/loading";
import Table from "../../../components/table-two";
import isNewSCApiUser from "../../../lib/is-new-sc-api-user";

const propTypes = {
  branchId: oneOfType([string, number]),
  filters: oneOfType([string, array]),
  skuId: oneOfType([string, number]),
  value: oneOfType([string, number]),
  column: array,
  endPoint: string,
  headingText: string,
  modifyData: func
};
const defaultProps = {
  modifyData: data => data
};

const Loader = () => (
  <Primitives.Flex
    alignItems="center"
    display="flex"
    justifyContent="center"
    mx="auto"
  >
    <Loading onlySpinner={true} />
  </Primitives.Flex>
);

const ToolTipContent = (isLoaded, column, data, headingText, otherProps) => (
  <Primitives.Flex flexDirection="column" width={1} {...otherProps}>
    <Primitives.Text
      color={colors.text[0]}
      fontSize="14px"
      fontWeight={2}
      mb={1}
      textAlign="left"
    >
      {headingText}
    </Primitives.Text>
    {isLoaded ? (
      <Table
        alternateRowColor="whitesmoke"
        backgroundColor={{ head: colors["background-light-blue"] }}
        border={`1px solid ${colors.text[3]}`}
        column={column}
        data={data}
        striped={true}
      />
    ) : (
      <Loader />
    )}
  </Primitives.Flex>
);

const toolTipPosition = { "-5px": "10px", "-100px": "102px" };

const PopUp = ({
  branchId,
  skuId,
  value,
  column,
  endPoint,
  headingText,
  modifyData
}) => {
  const { host } = useContext(AppContext);
  const filterContext = useContext(FilterContext);
  const [request, updateRequest] = useState(null);
  const { data, isLoaded } = useApiRequest(host, request);
  const [modalActive, setModalActive] = useState(false);
  const [position, setPosition] = useState("-5px");

  useEffect(() => {
    modalActive &&
      updateRequest(
        `${host}/smartchain${
          isNewSCApiUser() ? "/" : "/v4/"
        }product_reports/${endPoint}?q[universal_sku_id_eq]=${skuId ||
          ""}&${filterContext?.filterState?.datePayload ||
          ""}&branch_id=${branchId || ""}`
      );
  }, [host, filterContext, branchId, modalActive, endPoint]);

  const view = {
    mobile: (
      <>
        <Primitives.Text
          color="#4C49D6"
          cursor="pointer"
          onClick={() => setModalActive(true)}
        >
          {indianNumberFormat(value)}
        </Primitives.Text>
        {modalActive && (
          <Modal
            border={`1px solid ${colors.text[3]}`}
            borderRadius={8}
            closeHandler={() => {
              setModalActive(false);
            }}
            closeHeight="11px"
            content={ToolTipContent(
              isLoaded,
              column,
              modifyData(data),
              headingText,
              {
                maxHeight: "50vh"
              }
            )}
            open={true}
            pb={1}
            pt={1}
            px={2}
            width="85vw"
          />
        )}
      </>
    ),
    desktop: (
      <TooltipOnClick
        backgroundColor="white"
        boxShadow={9}
        contentTextAlign="start"
        content={ToolTipContent(
          isLoaded,
          column,
          modifyData(data),
          headingText,
          {
            p: 1,
            overflow: "auto",
            maxHeight: "35vh"
          }
        )}
        contentWidth="400px"
        getPosition={xAxis => xAxis > 580 && setPosition("-100px")}
        left="-436px"
        top={position}
        toolTipTriangle={{
          right: "-3%",
          top: toolTipPosition[position],
          transform: "rotate(90deg)"
        }}
        value={
          <Primitives.Text color="#4C49D6" cursor="pointer" zIndex={2}>
            {indianNumberFormat(value)}
          </Primitives.Text>
        }
        active={modalActive}
        setActive={setModalActive}
      />
    )
  };
  return window.innerWidth < breakPoint ? view["mobile"] : view["desktop"];
};

PopUp.propTypes = propTypes;
PopUp.defaultProps = defaultProps;
export default PopUp;
