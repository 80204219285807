import React, { useContext, useEffect, useState } from "react";
import Primitives from "../../../../components/primitives";
import Card from "../../../../lib/card";
import Table from "../../../../components/table-two";
import Months from "../../../../lib/months-list";
import { AppContext } from "../../../../utils/context";
import useApiRequest from "../../../../hooks/useApiRequest";
import Loading from "../../../../components/loading";
import ProgressIcon from "../../../../components/progress-icon";
import roundOffTwoDecimal from "../../../../components/utils/round-off-two-decimal";
import NoResults from "../../../../components/no-results";
import { colors } from "../../../../components/utils/theme";

const filters = {
  "By Quantity": {
    requiredValue: "qty",
    filterValue: ""
  },
  "By Value": {
    requiredValue: "value",
    filterValue: "by_value=true"
  }
};

const showProgress = (value1, value2) => {
  if (!value1 || !value2) {
    return;
  } else if (value1 > value2) {
    return "+" + (value1 - value2);
  } else if (value1 === value2) {
    return;
  } else {
    return "-" + (value2 - value1);
  }
};

const modifyCurrentMonthSqu = (data, dropdown) =>
  data &&
  data.map(item => ({
    rank: (
      <Primitives.Flex>
        <Primitives.Text mr={2}>{item["rank"]}</Primitives.Text>
        <ProgressIcon input1={item.last_rank} input2={item.rank} />
        <Primitives.Text ml={2}>
          {showProgress(item.last_rank, item.rank)}
        </Primitives.Text>
      </Primitives.Flex>
    ),
    product: item["sku_name"],
    qty:
      item[filters[dropdown]["requiredValue"]] &&
      roundOffTwoDecimal(item[filters[dropdown]["requiredValue"]])
  }));

const modifyLastMonthSqu = (data, dropdown) =>
  data &&
  data.map(item => ({
    rank: item["rank"],
    product: item["sku_name"],
    qty:
      item[filters[dropdown]["requiredValue"]] &&
      roundOffTwoDecimal(item[filters[dropdown]["requiredValue"]])
  }));

const column = dropdown => [
  {
    heading: "RANK",
    type: "number",
    width: 3
  },
  {
    heading: "PRODUCT",
    type: "string",
    width: 7
  },
  {
    align: "flex-end",
    heading: dropdown === "By Quantity" ? "QTY" : "VALUE",
    type: "number",
    width: 2
  }
];

const TopTenProducts = () => {
  const { host } = useContext(AppContext);
  const [dropdown, setDropdown] = useState("By Quantity");
  const [request, updateRequest] = useState(
    `${host}/smartchain/wallet_analytics/top_10_products?`
  );
  const { data, isLoaded } = useApiRequest(host, encodeURI(request));

  const results =
    data &&
    data["last_month_top_skus"] &&
    data["last_month_top_skus"].filter(
      ({ master_sku_id: id1 }) =>
        !data["curr_month_top_skus"].some(
          ({ master_sku_id: id2 }) => id2 === id1
        )
    );

  const getFilterValues = text => {
    setDropdown(text);
  };

  useEffect(() => {
    updateRequest(
      `${host}/smartchain/wallet_analytics/top_10_products?${filters[dropdown]["filterValue"]}`
    );
  }, [dropdown, host]);
  return (
    <Card
      details={
        /** Table Section */
        <>
          <Primitives.Flex mt={1}>
            <Primitives.Flex fontSize={[1, 2]} fontWeight={2} width="48%">
              {Months()[1].text && Months()[1].text}
            </Primitives.Flex>
            <Primitives.Flex m="20px"></Primitives.Flex>
            <Primitives.Flex fontSize={[1, 2]} fontWeight={2} width="48%">
              {Months()[0].text && Months()[0].text}
            </Primitives.Flex>
          </Primitives.Flex>

          <Primitives.Flex mt={2} minHeight="22vh">
            {/* Table 1  */}
            <Primitives.Flex
              overflowX={["scroll", "scroll", "unset"]}
              width="48%"
              p={2}
            >
              {isLoaded ? (
                <Primitives.Flex minWidth={[350, "unset"]} width={1}>
                  {data &&
                  Array.isArray(data["last_month_top_skus"]) &&
                  data["last_month_top_skus"].length > 0 ? (
                    <Table
                      column={column("By Quantity")}
                      data={modifyLastMonthSqu(
                        data["last_month_top_skus"],
                        dropdown
                      )}
                      fontSize={{ head: 1, row: 1 }}
                      justify="flex-start"
                    />
                  ) : (
                    <Primitives.Flex m="auto">
                      <NoResults small />
                    </Primitives.Flex>
                  )}
                </Primitives.Flex>
              ) : (
                <Primitives.Flex m="auto">
                  <Loading onlySpinner />
                </Primitives.Flex>
              )}
            </Primitives.Flex>
            <Primitives.Flex
              borderRight={`1px solid ${colors.text[0]}`}
              m={1}
            />
            {/* Table 2 */}
            <Primitives.Flex
              flexDirection="column"
              ml={["unset", 3]}
              width="48%"
            >
              {isLoaded ? (
                <>
                  {data &&
                  Array.isArray(data["curr_month_top_skus"]) &&
                  data["curr_month_top_skus"].length > 0 ? (
                    <Primitives.Flex
                      overflowX={["scroll", "scroll", "unset"]}
                      pb={2}
                    >
                      <Primitives.Box minWidth={[350, "unset"]} width={1}>
                        <Table
                          column={column(dropdown)}
                          data={modifyCurrentMonthSqu(
                            data["curr_month_top_skus"],
                            dropdown
                          )}
                          fontSize={{ head: 1, row: 1 }}
                          // header={filters[dropdown]["headers"]}
                          justify="flex-start"
                        />
                      </Primitives.Box>
                    </Primitives.Flex>
                  ) : (
                    <Primitives.Flex
                      height={1}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <NoResults small />
                    </Primitives.Flex>
                  )}
                  {data &&
                    Array.isArray(data["curr_month_top_skus"]) &&
                    data["curr_month_top_skus"].length > 0 && (
                      <Primitives.Box
                        boxShadow="whitesmoke 0px 3px 8px"
                        backgroundColor="athens-gray"
                        borderRadius="5px"
                        fontSize={["10px", 1]}
                        fontWeight={2}
                        mt={2}
                        mr={["unset", 4]}
                        overflow="auto"
                        p={1}
                        top={10}
                      >
                        {results &&
                          results[0] &&
                          results[0].sku_name &&
                          results[0].sku_name}{" "}
                        & {results && results.length > 0 && results.length - 1}{" "}
                        More are not in the top 10 selling product this month
                      </Primitives.Box>
                    )}
                </>
              ) : (
                <Primitives.Flex m="auto">
                  <Loading onlySpinner />
                </Primitives.Flex>
              )}
            </Primitives.Flex>
          </Primitives.Flex>
        </>
      }
      downloadApi={request}
      dropdownDefaultValue={dropdown}
      filters={Object.keys(filters)}
      getFilterValues={getFilterValues}
      title={
        <Primitives.Text>
          Top 10 Products Last Month{" "}
          <Primitives.Text fontWeight={1}>vs</Primitives.Text> Total Aknamed
          This Month
        </Primitives.Text>
      }
      width="auto"
    />
  );
};

export default TopTenProducts;
