import React, { useEffect, useRef, useState } from "react";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import PropTypes, {
  bool,
  func,
  number,
  object,
  oneOfType,
  string
} from "prop-types";
import SingleDatePick from "../../single-date-pick";

const propTypes = {
  dateRangeCollapse: bool,
  changeHandler: func,
  date: PropTypes.instanceOf(Date),
  defaultValue: oneOfType([object, string]),
  marginLeft: oneOfType([bool, number])
};

const SingleDate = ({ dateRangeCollapse, changeHandler, date, marginLeft }) => {
  const node = useRef();
  const [collapse, setCollpase] = useState(false);

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    if (
      !e.target.closest(".dropdown-filter") &&
      !e.target.closest(".rdrDateRangeWrapper") &&
      !e.target.closest(".rdrDefinedRangesWrapper")
    ) {
      setCollpase(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    setCollpase(!collapse);
  }, [dateRangeCollapse]);

  return (
    <Primitives.Flex flex={1} ml={marginLeft} ref={node} flexDirection="column">
      <SingleDatePick
        backgroundColor="white"
        border="1px solid"
        borderRadius={4}
        cancelHandler={() => setCollpase(!collapse)}
        color={colors.text[1]}
        changeHandler={changeHandler}
        collapsed={collapse}
        collapseHandler={() => setCollpase(!collapse)}
        date={date}
      />
    </Primitives.Flex>
  );
};

SingleDate.propTypes = propTypes;
export default SingleDate;
