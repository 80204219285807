import React, { useState } from "react";
import Modal from "../../../components/modal";
import Primitives from "../../../components/primitives";
import Table from "../../../components/table-two";
import { indianNumberFormat } from "../../../components/utils";
import { breakPoint, colors } from "../../../components/utils/theme";
import TooltipOnClick from "../../../lib/tooltip-onclick";
import { number, object, oneOfType, string } from "prop-types";

const propTypes = {
  data: object,
  value: oneOfType([string, number])
};

const toolTipPosition = { "-5px": "10px", "-100px": "102px" };

const Header = totalQuantity => (
  <Primitives.Flex justifyContent="space-between">
    <Primitives.Text
      color={colors.text[0]}
      fontSize="14px"
      fontWeight={2}
      mb={1}
    >
      Hospital Details
    </Primitives.Text>
    <Primitives.Text color={colors.text[0]} fontSize="14px" mb={1}>
      Total Quantity: {totalQuantity}
    </Primitives.Text>
  </Primitives.Flex>
);

const column = [
  { heading: "Hospital Name", type: "string", width: 1.5 },
  { heading: "Qty", type: "string", width: 1, align: "flex-end" }
];

const HospitalDetails = ({ data, value }) => {
  const [modalActive, setModalActive] = useState(false);
  const [position, setPosition] = useState("-5px");
  const { hospitalData, totalQuantity } = data?.reduce(
    ({ hospitalData, totalQuantity }, item) => ({
      hospitalData: [
        ...hospitalData,
        { hospitalName: item.display_name ?? 0, quantity: item.quantity }
      ],
      totalQuantity: totalQuantity + item.quantity
    }),
    { hospitalData: [], totalQuantity: 0 }
  );

  const view = {
    mobile: (
      <>
        <Primitives.Text
          color="#4C49D6"
          cursor="pointer"
          onClick={() => setModalActive(true)}
        >
          {indianNumberFormat(value)}
        </Primitives.Text>
        {modalActive && (
          <Modal
            border={`1px solid ${colors.text[3]}`}
            borderRadius={8}
            closeHandler={() => {
              setModalActive(false);
            }}
            closeHeight="11px"
            content={
              <Primitives.Flex
                flexDirection="column"
                maxHeight="50vh"
                width={1}
              >
                {Header(totalQuantity)}
                <Table
                  alternateRowColor="whitesmoke"
                  backgroundColor={{ head: colors["background-light-blue"] }}
                  border={`1px solid ${colors.text[3]}`}
                  column={column}
                  data={hospitalData}
                  striped={true}
                />
              </Primitives.Flex>
            }
            open={true}
            pb={1}
            pt={1}
            px={2}
            width="85vw"
          />
        )}
      </>
    ),
    desktop: (
      <TooltipOnClick
        backgroundColor="white"
        boxShadow={9}
        contentTextAlign="start"
        content={
          <Primitives.Flex
            flexDirection="column"
            p={1}
            maxHeight="35vh"
            overflow="auto"
            width={1}
          >
            {Header(totalQuantity)}
            <Table
              alternateRowColor="whitesmoke"
              backgroundColor={{ head: colors["background-light-blue"] }}
              border={`1px solid ${colors.text[3]}`}
              column={column}
              data={hospitalData}
            />
          </Primitives.Flex>
        }
        contentWidth="400px"
        getPosition={xAxis => xAxis > 580 && setPosition("-100px")}
        left="-436px"
        top={position}
        toolTipTriangle={{
          right: "-3%",
          top: toolTipPosition[position],
          transform: "rotate(90deg)"
        }}
        value={
          <Primitives.Text color="#4C49D6" cursor="pointer" zIndex={2}>
            {indianNumberFormat(value)}
          </Primitives.Text>
        }
        active={modalActive}
        setActive={setModalActive}
      />
    )
  };
  return window.innerWidth < breakPoint ? view["mobile"] : view["desktop"];
};

HospitalDetails.propTypes = propTypes;
export default HospitalDetails;
