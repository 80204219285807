import React from "react";
import DecreasingZigZagArrow from "../glyphs/elements/decreasing-zigzag-arrow";
import DownwardArrow from "../glyphs/elements/downwardArrow";
import IncreasingZigZagArrow from "../glyphs/elements/increasing-zigzag-arrow";
import UpwardArrow from "../glyphs/elements/upwardArrow";
import { colors } from "../utils/theme";
import { bool, number } from "prop-types";

const propTypes = {
  /** input value one */
  input1: number,
  /** input value two */
  input2: number,
  /** bool to indicate if zigzag arrow required */
  zigzag: bool
};

const defaultProps = {
  zigzag: true
};

const arrow = {
  inc: <UpwardArrow fill="green" />,
  dec: <DownwardArrow fill="red" />
};

const zigzagArrow = {
  inc: <IncreasingZigZagArrow fill={colors["background-dark-green"]} />,
  dec: <DecreasingZigZagArrow fill={colors.error[1]} />
};

const ProgressIcon = ({ input1, input2, zigzag }) => {
  if (input1 < input2) {
    return zigzag ? zigzagArrow["dec"] : arrow["dec"];
  } else if (input1 === input2) {
    return null;
  } else {
    return zigzag ? zigzagArrow["inc"] : arrow["inc"];
  }
};
ProgressIcon.propTypes = propTypes;
ProgressIcon.defaultProps = defaultProps;
export default ProgressIcon;
