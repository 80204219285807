import React, { useContext, useEffect, useState } from "react";
import Primitives from "../../../components/primitives";
import { AppContext } from "../../../utils/context";
import { useHistory, useParams } from "react-router-dom";
import Reports from "../../reports";
import { breakPoint } from "../../../components/utils/theme";
import ConvertMoneyToLakhsCrores from "../../../components/utils/convert-money-to-lakh-crores";
import ParamsToHash from "../../../lib/params-to-hash/index";
import DropdownView from "../../../lib/dropdown-view";
import ToolTipView from "../../../lib/tooltip-view";
import Title from "../../../lib/title";
import checkSortingIndex from "../../../lib/check-sorting-index/index";
import roundOffTwoDecimal from "../../../components/utils/round-off-two-decimal";
import BackButton from "../../../lib/back-button";
import HospitalDetails from "../hospital-details";
import { FilterContext } from "../../hospital-sales-report/filterContext";
import PopUp from "../pop-ups";
import isNewSCApiUser from "../../../lib/is-new-sc-api-user";

/** dropdown index */
const dropdownIndex = {
  //mtd: 0,
  ssr: 0,
  istr: 1
};

/** no sorting columns */
const noSortingColumns = [0];

// const mtdSsrFilters = ["Search", "Date Range", "Division", "Tenant"];
const mtdSsrFilters = {
  skuSearch: true,
  "Date Range": true,
  Division: true,
  Tenant: true,
  mobileFilters: [
    { text: "Date Range", filterText: "Date Range" },
    { text: "Tenant", filterText: "Tenant" },
    { text: "Division", filterText: "Division" }
  ],
  defaultFilter: "Date Range",
  defaultDate: {
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date()
  }
};

const dropdownData = (companyName = "") => [
  // {
  //   col: [3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  //   desc:
  //     "Live stock and sales statement for products which have been sold this month",
  //   filters: mtdSsrFilters,
  //   id: "mtd",
  //   text: `${companyName} MTD Sales Report`,
  //   toolTipHeight: 50,
  //   toolTipText: {
  //     4: `Quantity purchased this month`,
  //     5: "Secondary sales this month"
  //   }
  // },
  {
    col: [3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    desc: "Live stock and sales statement displaying all products",
    filters: mtdSsrFilters,
    id: "ssr",
    text: `${companyName} Stock & Sales Report`,
    toolTipHeight: 40,
    toolTipText: {
      4: "Quantity purchased",
      5: "Scheme Qty"
    }
  },
  {
    col: [1, 1, 1, 1, 1],
    desc:
      "Report showing products transferred internally between two warehouses",
    filters: {
      "Date Range": true,
      masterSku: true,
      originBranch: true,
      currentBranch: true,
      mobileFilters: [
        { text: "Date Range", filterText: "Date Range" },
        { text: "Master SKU", filterText: "masterSku" },
        { text: "Origin Branch", filterText: "originBranch" },
        { text: "Current Branch", filterText: "currentBranch" }
      ],
      defaultFilter: "Date Range",
      defaultDate: {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date()
      }
    },
    id: "istr",
    text: "Internal Stock Transfer Report",
    toolTipHeight: 50
  }
];

const glyphHeader = (title, key, sort = false) => [
  {
    footerData: "Total",
    heading: (
      <Primitives.Flex key={key} width={1}>
        <Primitives.Text fontWeight={700}>{title}</Primitives.Text>
      </Primitives.Flex>
    ),
    type: "string",
    width: 2,
    sort: sort
  }
];

const commonColumn = (columnMonth, columnSum) => [
  {
    heading: "Division",
    type: "string",
    width: 2
  },
  {
    align: "flex-end",
    footerData: ConvertMoneyToLakhsCrores(columnSum["last_month_sales"]),
    heading: `Last Month Sales Qty`,
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    footerData: ConvertMoneyToLakhsCrores(columnSum["opening_stock"]),
    heading: `Opening Qty`,
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    background: "#E2E7FE !important",
    footerData: ConvertMoneyToLakhsCrores(columnSum["stock_in_grn_quantity"]),
    heading: "GRN Qty.",
    mainHeading: " ",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    background: "#E2E7FE !important",
    footerData: ConvertMoneyToLakhsCrores(
      columnSum["stock_in_grn_free_quantity"]
    ),
    heading: "Free Qty.",
    mainHeading: (
      <Primitives.Flex fontWeight={3} position="absolute" ml={3}>
        Stock
      </Primitives.Flex>
    ),
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    background: "#E2E7FE !important",
    footerData: ConvertMoneyToLakhsCrores(columnSum["stock_in_ist_quantity"]),
    heading: "Int Trns",
    mainHeading: (
      <Primitives.Flex fontWeight={3} position="absolute" ml={-55}>
        In
        <Primitives.Text fontSize="12px" ml="4px">
          ⬇️
        </Primitives.Text>
      </Primitives.Flex>
    ),
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    background: "#E2E7FE !important",
    footerData: ConvertMoneyToLakhsCrores(
      columnSum["stock_in_manual_credit_quantity"]
    ),
    heading: "Manual Cr",
    mainHeading: " ",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    background: "#CDD5FF !important",
    footerData: ConvertMoneyToLakhsCrores(
      columnSum["stock_out_sales_quantity"]
    ),
    heading: "MTD Sales",
    mainHeading: " ",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    background: "#CDD5FF !important",
    footerData: ConvertMoneyToLakhsCrores(
      columnSum["stock_out_sales_free_quantity"]
    ),
    heading: "Free Qty",
    type: "number",
    mainHeading: (
      <Primitives.Flex fontWeight={3} position="absolute" ml={3}>
        Stock
      </Primitives.Flex>
    ),
    width: 1
  },
  {
    align: "flex-end",
    background: "#CDD5FF !important",
    footerData: `${ConvertMoneyToLakhsCrores(
      columnSum["stock_out_ist_quantity"]
    )}`,
    heading: "Int Trns",
    mainHeading: (
      <Primitives.Flex fontWeight={3} position="absolute" ml={-55}>
        Out
        <Primitives.Text fontSize="12px" ml="4px">
          ⬆️
        </Primitives.Text>
      </Primitives.Flex>
    ),
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    background: "#CDD5FF !important",
    footerData: ConvertMoneyToLakhsCrores(
      columnSum["stock_out_manual_debit_quantity"]
    ),
    heading: "Manual Db",
    mainHeading: " ",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(columnSum["sales_value"])}`,
    heading: "Sales Value",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    footerData: ConvertMoneyToLakhsCrores(columnSum["sales_return_quantity"]),
    heading: "Sales Return",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    footerData: ConvertMoneyToLakhsCrores(
      columnSum["purchase_return_quantity"]
    ),
    heading: "Purchase Return",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    footerData: ConvertMoneyToLakhsCrores(columnSum["closing_stock"]),
    heading: "Closing Qty.",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      columnSum["closing_stock_value"]
    )}`,
    heading: "Closing Value",
    type: "number",
    width: 1
  }
];

const purchaseDetailsPopUpColumn = [
  { heading: "PI Number", type: "string", width: 1.5 },
  { heading: "GRN Date", type: "string", width: 1 },
  { heading: "Quantity", type: "number", width: 1 }
];
const salesReturnPopUpColumns = [
  { heading: "Hospital", type: "string", width: 3 },
  { heading: "Sales Date", type: "string", width: 1.5 },
  { heading: "Return Quantity", type: "number", width: 1.5 }
];
const closingStockPopUpColumns = [
  { heading: "Near Expiry", type: "string", width: 1 },
  { heading: "Expired", type: "string", width: 1 }
];

const istrHeader = columnSum => [
  {
    heading: "Origin Location",
    type: "string",
    width: 1
  },
  {
    heading: "Current Location",
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    heading: "No. of Hospitals Delivered to",
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    footerData: Math.round(columnSum["sales_quantity"]).toLocaleString(),
    heading: "Total Qty",
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(columnSum["sales_value"])}`,
    heading: "Total Value",
    type: "string",
    width: 1
  }
];

const ProductReportSku = () => {
  const { branches, loggedInUser } = useContext(AppContext);
  const filterContext = useContext(FilterContext);
  const history = useHistory();
  const [columnMonth, setColumnMonth] = useState("");
  const type = useParams().type || "istr";
  const branchId = useParams().id || "";
  const [columnSum, setColumnSum] = useState({});
  const [branch, setBranch] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownId, setdropdownId] = useState(
    dropdownData("")[dropdownIndex[type]]
  );
  const [sort, updateSort] = useState(
    type === "istr" ? "sales_quantity+desc" : "current_month_sales+desc"
  );
  const [column, setColumn] = useState([]);
  const [filters, setFilters] = useState([]);

  /** Hide the report to unauthorised user */
  type === "istr" &&
    loggedInUser &&
    loggedInUser.user &&
    !loggedInUser.user.istr_report_access &&
    history.push("/company/product_report/ssr");

  const getFilterMonth = str => {
    setFilters(str);
    const data = ParamsToHash(str);
    /** two distinguish between two same months of different years we are appending 
    corresponding year as well */
    setColumnMonth(
      data["sales_invoices_invoice_date_in"] &&
        data["sales_invoices_invoice_date_in"]
    );
  };
  /** return pop up props */
  const getPopUpProps = (skuId, value) => ({
    branchId,
    filters,
    skuId,
    value: roundOffTwoDecimal(value)
  });

  const modifyReports = (data, api) => {
    if (data && data["items"]) {
      if (api.includes(`/product_reports?branch_id=${branchId}&type=istr`)) {
        return data["items"].map(item => ({
          productName: item.sku_name,
          originalWarehouse: item.orignal_warehouse,
          currentWarehouse: item.current_warehouse,
          secondarySiis:
            item.secondary_siis?.length > 0 && item.secondary_siis_count ? (
              <HospitalDetails
                data={item.secondary_siis}
                value={item.secondary_siis_count}
              />
            ) : (
              item.secondary_siis_count
            ),
          quantity: item.sales_quantity,
          value: `₹ ${roundOffTwoDecimal(item.sales_value)}`
        }));
      } else {
        return data["items"].map(item => ({
          productName: item.sku_name,
          companyDivision: item.company_division,
          lastMonthSales: roundOffTwoDecimal(item.last_month_sales),
          openingStock: roundOffTwoDecimal(item.opening_stock),
          quantityOrdered:
            item.stock_in_grn_quantity > 0 ? (
              <PopUp
                {...getPopUpProps(item.sku_id, item.stock_in_grn_quantity)}
                column={purchaseDetailsPopUpColumn}
                endPoint="quantity_ordered_details"
                headingText="Purchase Details"
              />
            ) : (
              item.stock_in_grn_quantity
            ),
          stock_in_grn_free_quantity: roundOffTwoDecimal(
            item.stock_in_grn_free_quantity
          ),
          stock_in_ist_quantity: roundOffTwoDecimal(item.stock_in_ist_quantity),
          stock_in_manual_credit_quantity: roundOffTwoDecimal(
            item.stock_in_manual_credit_quantity
          ),
          stock_out_sales_quantity: roundOffTwoDecimal(
            item.stock_out_sales_quantity
          ),
          stock_out_sales_free_quantity: item.stock_out_sales_free_quantity,
          stock_out_ist_quantity: roundOffTwoDecimal(
            item.stock_out_ist_quantity
          ),
          stock_out_manual_debit_quantity: roundOffTwoDecimal(
            item.stock_out_manual_debit_quantity
          ),
          sales_value: `₹ ${roundOffTwoDecimal(item.sales_value)}`,
          sales_return_quantity:
            item.sales_return_quantity > 0 ? (
              <PopUp
                {...getPopUpProps(item.sku_id, item.sales_return_quantity)}
                column={salesReturnPopUpColumns}
                endPoint="sales_return_details"
                headingText="Return Details"
              />
            ) : (
              item.sales_return_quantity
            ),
          purchase_return_quantity: roundOffTwoDecimal(
            item.purchase_return_quantity
          ),
          closing_stock:
            item.closing_stock > 0 ? (
              <PopUp
                {...getPopUpProps(item.sku_id, item.closing_stock)}
                column={closingStockPopUpColumns}
                headingText="SOH Details"
                endPoint="closing_stock_breakdown_details"
                modifyData={data =>
                  data && data[0]
                    ? [
                        {
                          near_expiry_stock: data[0].near_expiry_stock,
                          expired_stock: data[0].expired_stock
                        }
                      ]
                    : []
                }
              />
            ) : (
              item.closing_stock
            ),
          closing_stock_value: `₹ ${roundOffTwoDecimal(
            item.closing_stock_value
          )}`
        }));
      }
    }
  };

  const headerMap = {
    mtd: [
      ...glyphHeader("Product Name", 1),
      ...commonColumn(columnMonth, columnSum)
    ],
    ssr: [
      ...glyphHeader("Product Name", 1),
      ...commonColumn(columnMonth, columnSum)
    ],
    istr: [...glyphHeader("Product Name", 1, true), ...istrHeader(columnSum)]
  };

  const getColumnSum = data => {
    setColumnSum(data);
  };

  const commonIndex = {
    1: "company_division+",
    2: "last_month_sales+",
    3: "opening_stock+",
    4: "stock_in_grn_quantity+",
    5: "stock_in_grn_free_quantity+",
    6: "stock_in_ist_quantity+",
    7: "stock_in_manual_credit_quantity+",
    8: "stock_out_sales_quantity+",
    9: "stock_out_sales_free_quantity+",
    10: "stock_out_ist_quantity+",
    11: "stock_out_manual_debit_quantity+",
    12: "sales_value+",
    13: "sales_return_quantity+",
    14: "purchase_return_quantity+",
    15: "closing_stock+",
    16: "closing_stock_value+"
  };

  // for sorting to all columns
  const istrIndex = {
    0: "sku_name+",
    1: "orignal_warehouse+",
    2: "current_warehouse+",
    3: "secondary_siis_count+",
    4: "sales_quantity+",
    5: "sales_value+"
  };

  const getHeaderId = {
    mtd: (id, order) => checkSortingIndex(commonIndex, id, order, updateSort),
    ssr: (id, order) => checkSortingIndex(commonIndex, id, order, updateSort),
    istr: (id, order) => checkSortingIndex(istrIndex, id, order, updateSort)
  };

  const api = `smartchain${
    isNewSCApiUser() ? "/" : "/v4/"
  }product_reports?branch_id=${branchId}&type=${dropdownId?.id}`;

  useEffect(() => {
    setColumn(headerMap[dropdownId?.id]);
  }, [dropdownId, columnSum]);

  const dropDownSelectHandler = item => {
    setdropdownId(dropdownData("")[dropdownIndex[item.id]]);
    item.id === "istr"
      ? history.push("/company")
      : history.push(
          `/company/product_report/${
            dropdownData("")[dropdownIndex[item.id]].id
          }`
        );
    setShowDropdown(!showDropdown);
  };
  const [request, updateRequest] = useState(
    `${api}&${filterContext?.filterState?.datePayload}&${filterContext?.filterState?.divisionPayload}&${filterContext?.filterState?.tenantPayload}`
  );
  const filterDateFormat = date =>
    date
      .split("=")[1]
      .split("-")
      .reverse()
      .join("-");

  const filterUpdate = ({
    date,
    skuSearch,
    Tenant,
    Division,
    originBranch,
    currentBranch,
    masterSku
  }) => {
    filterContext.updateFilterState({
      ...filterContext.filterState,
      date: {
        startDate: new Date(filterDateFormat(date?.split("&")[0])),
        endDate: new Date(filterDateFormat(date?.split("&")[1]))
      },
      tenant: Tenant?.split("&[tenant_id][]=")
        ?.filter(n => n)
        .join(","),
      division: Division?.split("&q[company_division_eq]=")
        ?.filter(n => n)
        .join(","),
      datePayload: date,
      divisionPayload: Division,
      tenantPayload: Tenant,
      skuSearch: skuSearch
    });
    updateRequest(
      `${api}&${date || ""}${
        skuSearch ? `&q[name_cont]=${skuSearch}` : ""
      }&${Division || ""}&${Tenant !== "&[tenant_id][]=" ? Tenant : ""}&${
        originBranch !== "&original_warehouse_id[]=" ? originBranch : ""
      }&${currentBranch !== "&current_warehouse_id[]=" ? currentBranch : ""}&${
        masterSku !== "&q[master_sku_id_in][]=" ? masterSku : ""
      }`
    );
  };

  return (
    <>
      <Reports
        api={request}
        column={column}
        displayLockIcon={["block"]}
        filterConfig={
          dropdownData().find(item => item.id === dropdownId?.id)?.filters
        }
        filterUpdate={filterUpdate}
        getColumnSum={getColumnSum}
        getCustomerData={data => setBranch(data)}
        getFilterMonth={getFilterMonth}
        getHeaderId={getHeaderId[dropdownId?.id]}
        modifyReports={modifyReports}
        noSortingColumns={noSortingColumns}
        placeholder="Search by Master SKU"
        masterskuPlaceholder="Product"
        pageId="ProductReportSku"
        responsiveHeight={["40vh", "55vh"]}
        responsiveWidth={
          type === "istr"
            ? [750, 1000, 1100, "unset"]
            : [2023, 2200, 2120, 2180]
        }
        subNavIndex={[0, dropdownIndex[type]]}
        sort={sort}
        selected={0}
        title={
          <>
            <Primitives.Flex>
              <Primitives.Flex flexDirection="column">
                {type !== "istr" &&
                branches &&
                branches["Branches"] &&
                branches["Branches"].length > 1 ? (
                  <Primitives.Flex mb={1}>
                    <BackButton
                      clickHandler={() => {
                        history.goBack();
                        filterContext.updateFilterState("");
                      }}
                    />
                    <Title>
                      <Primitives.Text>
                        {columnSum.name &&
                          columnSum.name.charAt(0).toUpperCase() +
                            columnSum.name.slice(1).toLowerCase()}{" "}
                        {
                          dropdownData(
                            branch &&
                              branch.branch_details &&
                              branch.branch_details["branch_name"]
                          )[dropdownIndex[type]]?.text
                        }
                      </Primitives.Text>
                    </Title>
                  </Primitives.Flex>
                ) : (
                  <Primitives.Flex flexDirection={["row", "column", "column"]}>
                    <Title>
                      <Primitives.Text mb={1}>
                        {window.innerWidth < breakPoint
                          ? "Product Report"
                          : dropdownData("")[dropdownIndex[type]]?.text}
                      </Primitives.Text>
                    </Title>
                    <ToolTipView
                      desc={dropdownData("")[dropdownIndex[type]]?.desc}
                    />
                  </Primitives.Flex>
                )}
                <Primitives.Flex ml={type !== "istr" && 27}>
                  <DropdownView
                    dropdownData={dropdownData()}
                    dropdownId={dropdownId}
                    dropDownSelectHandler={dropDownSelectHandler}
                    id="ProductReportBranch"
                    setShowDropdown={setShowDropdown}
                    showDropdown={showDropdown}
                    showTabsOnMediumScreen={true}
                    tabSelectHandler={dropDownSelectHandler}
                  />
                </Primitives.Flex>
              </Primitives.Flex>
            </Primitives.Flex>
          </>
        }
        reportTitle={`${dropdownData(branch?.branch_details?.branch_name)[
          dropdownIndex[type]
        ]?.text?.replace("Report", "")}`}
        toolTipText={dropdownId?.toolTipText}
      />
    </>
  );
};

export default ProductReportSku;
