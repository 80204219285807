import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../utils/context";
import GenericReport from "../generic-report";

const inputFields = {
  password: "Create Password",
  confirm_password: "Confirm Password"
};

const alertData = [
  "Link not valid or expired. Try generating a new link.",
  "Password too weak. Try a different password.",
  "Password Mismatch, Please enter correct Password"
];

const CreatePassword = () => {
  const history = useHistory();
  const { host } = useContext(AppContext);
  const [userPassword, setUserPassword] = useState({});
  const [passwordToken, setPasswordToken] = useState("");
  const [email, setEmail] = useState("");
  const [alert, toggleAlert] = useState([]);
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const formValidation = e => {
    e.preventDefault();
    setLoading(true);
    if (userPassword["password"] === userPassword["confirm_password"]) {
      toggleAlert([]);
      submitHandler(e);
      setLoading(false);
    } else {
      toggleAlert([2]);
      setLoading(false);
    }
  };

  const submitHandler = event => {
    event.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
      token: passwordToken,
      send_email_verification_otp: "",
      password: userPassword["password"]
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${host}/smartchain/reset_password`, requestOptions)
      .then(response => {
        if (response.status === 422) {
          toggleAlert([1]);
        } else if (response.status === 404) {
          toggleAlert([0]);
        }
        return response.json();
      })
      .then(result => {
        if (result.alert) {
          setLoading(false);
          history.push("/");
        }
      })
      .catch(error => console.log("error", error));
  };

  useEffect(() => {
    setEmail(localStorage.getItem("email"));
    setPasswordToken(localStorage.getItem("password_token"));
  }, [location]);

  return (
    <GenericReport
      alertData={alertData[alert]}
      buttonText="Confirm Password"
      formValidation={formValidation}
      heading="Create Password"
      inputFields={inputFields}
      loading={loading}
      setUserData={setUserPassword}
      type="password"
      userData={userPassword}
    />
  );
};

export default CreatePassword;
