import React, { useState } from "react";
import Primitives from "../../components/primitives";
import Reports from "../reports";
import ConvertMoneyToLakhsCrores from "../../components/utils/convert-money-to-lakh-crores";
import { breakPoint } from "../../components/utils/theme";
import ToolTipView from "../../lib/tooltip-view";
import Title from "../../lib/title";
import checkSortingIndex from "../../lib/check-sorting-index";
import ProgressIcon from "../../components/progress-icon";
import roundOffTwoDecimal from "../../components/utils/round-off-two-decimal";
const desc = "Insights report showing product specific sales trend";

const headerMetric = data => `Sales (last ${data} days)`;

const column = (columnSum, performanceData) => [
  {
    footerData: "Total",
    heading: (
      <Primitives.Flex
        key="1"
        width={1}
        alignItems="space-between"
        justifyContent="space-between"
      >
        <Primitives.Text fontWeight={700}>Product Name</Primitives.Text>
      </Primitives.Flex>
    ),
    type: "string",
    width: 3,
    sort: false
  },
  {
    align: "flex-end",
    heading: "No. of Centres",
    type: "number",
    width: 2
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      columnSum["avg_sales_90_days_sum"]
    )}`,
    heading: `Daily avg. ${headerMetric("90", performanceData)}`,
    type: "string",
    width: 2
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      columnSum["avg_sales_30_days_sum"]
    )}`,
    heading: `Daily avg. ${headerMetric("30", performanceData)}`,
    type: "string",
    width: 2
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      columnSum["avg_sales_7_days_sum"]
    )}`,
    heading: `Daily avg. ${headerMetric("7", performanceData)}`,
    type: "string",
    width: 2
  },
  {
    align: "flex-end",
    heading: "Performance",
    type: "number",
    width: 2
  }
];

const toolTip = () => <ToolTipView desc={desc} />;

const metricMapper = data => (data["metrics"] === "qty" ? "" : "₹ ");
const modifyReports = data =>
  data &&
  data["items"] &&
  data["items"].map(item => ({
    productName: item.name,
    noOfCentres: item.centres_purchasing,
    last90DaysAvg:
      metricMapper(data) + `${roundOffTwoDecimal(item.avg_sales_90_days)}`,
    last30DaysAvg:
      metricMapper(data) + `${roundOffTwoDecimal(item.avg_sales_30_days)}`,
    last7DaysAvg:
      metricMapper(data) + `${roundOffTwoDecimal(item.avg_sales_7_days)}`,
    performance: (
      <Primitives.Flex>
        <ProgressIcon input1={item.performance} input2={0} />
        <Primitives.Text ml={1}>{item.performance}</Primitives.Text>
      </Primitives.Flex>
    )
  }));

const ProductPerformance = () => {
  const [columnSum, setColumnSum] = useState({});
  const [sort, updateSort] = useState(null);
  const [performanceData, setperformanceData] = useState([]);
  const api = "smartchain/insights/product_performance?";
  const [request, updateRequest] = useState(api);

  const getCustomerData = data => {
    setperformanceData(data);
  };
  const index = {
    1: "centres_purchasing+",
    2: "avg_sales_90_days+",
    3: "avg_sales_30_days+",
    4: "avg_sales_7_days+",
    5: "performance+"
  };

  const getHeaderId = (id, order) =>
    checkSortingIndex(index, id, order, updateSort);

  const getColumnSum = data => {
    setColumnSum(data);
  };

  const filterUpdate = ({ singleDate, productSearch }) => {
    updateRequest(
      `${api}${
        singleDate ? `&q[sales_invoices_invoice_date_in]=${singleDate}` : ""
      }${productSearch ? `&q[name_cont]=${productSearch}` : ""}`
    );
  };
  return (
    <Reports
      api={request}
      column={column(columnSum, performanceData)}
      filterConfig={{
        productSearch: true,
        Date: true,
        search: "productSearch",
        mobileFilters: [{ text: "Date", filterText: "Date" }],
        defaultFilter: "Date"
      }}
      filterUpdate={filterUpdate}
      getColumnSum={getColumnSum}
      getCustomerData={getCustomerData}
      getHeaderId={getHeaderId}
      modifyReports={modifyReports}
      placeholder="Search by Product Name"
      pageId="productPerformance"
      responsiveWidth={[850, 1100, 1100, "unset"]}
      responsiveHeight={["30vh", "55vh"]}
      sort={sort}
      selected={4}
      title={
        <>
          <Primitives.Flex alignItems="center">
            <Title>
              <Primitives.Text mb={["unset", 1]}>
                Product Performance
              </Primitives.Text>
            </Title>
            {window.innerWidth < breakPoint && toolTip()}
          </Primitives.Flex>
          {window.innerWidth > breakPoint && toolTip()}
        </>
      }
      reportTitle="Product Performance"
    />
  );
};

export default ProductPerformance;
