import React, { useState } from "react";
import Primitives from "../../components/primitives";
import NearExpiry from "./near-expiry";
import InventoryBreakup from "./inventory-breakup";
import Reports from "../wallet-share-analytics/reports/";
import Title from "../../lib/title";
import InventoryHeader from "./inventory-header";
import { colors } from "../../components/utils/theme";

const CustomerPerformance = () => {
  const [tabKey, setTabKey] = useState("");
  const [branchObj, setBranchObj] = useState({});

  return (
    <Reports
      backgroundColor={colors["background-blue"]}
      components={
        <Primitives.Box pb={8}>
          <Primitives.Flex
            backgroundColor={colors["background-blue"]}
            flexDirection="column"
            pb={2}
            pt={1}
            px={[8, 8, 45]}
          >
            <Title>
              <Primitives.Text pb={19}>
                Inventory Analysis Report
              </Primitives.Text>
            </Title>
            <InventoryHeader />
          </Primitives.Flex>
          <Primitives.Flex flexDirection="column" px={[8, 8, 45]}>
            <NearExpiry
              getKey={key => setTabKey(key)}
              setBranchFilter={data => setBranchObj(data)}
            />
            <InventoryBreakup tabKey={tabKey} branch={branchObj} />
          </Primitives.Flex>
        </Primitives.Box>
      }
      reportTitle="Inventory Analysis Report"
      selected={2}
    />
  );
};

export default CustomerPerformance;
