import React from "react";

const Envelope = ({ ...props }) => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5667 0H1.5C0.671233 0 0 0.6754 0 1.5V10.5C0 11.3295 0.676167 12 1.5 12H15.5667C16.3885 12 17.0667 11.3323 17.0667 10.5V1.5C17.0667 0.676867 16.3981 0 15.5667 0ZM15.3566 1C15.0501 1.30483 9.7761 6.5511 9.594 6.73223C9.31067 7.01557 8.934 7.17157 8.53333 7.17157C8.13267 7.17157 7.756 7.01553 7.47173 6.7313C7.34927 6.60947 2.13343 1.42113 1.71007 1H15.3566ZM1 10.2965V1.70417L5.32127 6.00267L1 10.2965ZM1.7107 11L6.03027 6.7079L6.76557 7.43933C7.23777 7.91153 7.86557 8.17157 8.53333 8.17157C9.2011 8.17157 9.8289 7.91153 10.3002 7.44027L11.0364 6.7079L15.356 11H1.7107ZM16.0667 10.2965L11.7454 6.00267L16.0667 1.70417V10.2965Z"
      fill="inherit"
    />
  </svg>
);

export default Envelope;
