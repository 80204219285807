import React from "react";

const LoginImage = ({ ...props }) => (
  <svg
    width="275"
    height="136"
    viewBox="0 0 275 136"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M274.97 99.0667C274.787 108.33 272.741 117.427 269.523 126.08C269.059 127.307 266.488 135.149 265.317 135.149C263.49 135.149 261.661 135.15 259.833 135.15C239.554 135.154 219.275 135.156 198.997 135.159C159.036 135.165 119.075 135.171 79.1134 135.175C55.4485 135.178 31.7826 135.193 8.11767 135.179C6.56561 135.178 5.2727 133.16 4.381 132.095C2.73967 130.133 1.59938 127.904 0.906372 125.539C-0.0371536 122.316 -0.627457 117.552 1.12522 111.491C2.07162 108.218 4.10073 104.918 5.63552 102.44C8.93162 97.119 13.2221 92.5487 17.9599 88.5075C23.4061 83.8618 30.4129 79.5831 33.0947 72.5774C37.0617 62.2171 30.2545 52.0518 31.1837 41.6123C32.7328 24.2077 49.9227 11.1996 65.0239 5.32756C76.7244 0.777485 89.6938 -0.32334 102.142 0.0754684C108.233 0.270527 114.316 0.875015 120.323 1.90342C125.811 2.84202 132.274 3.80186 137.307 6.33279C148.101 10.3518 157.445 16.8843 165.364 25.2361C169.211 29.2937 172.623 33.8486 176.822 37.5538C181.832 41.9744 186.96 41.8151 193.198 40.5984C205.067 38.2558 216.664 35.7026 228.695 38.6469C240.347 41.4974 250.364 48.619 258.635 57.1504C269.569 68.4329 275.272 83.3269 274.97 99.0667C274.959 99.6683 275.042 95.3336 274.97 99.0667Z"
      fill="#EDF4FF"
    />
    <path
      d="M272.962 135.265H152.931H151.068H4.85993C4.34679 135.265 3.92639 135.429 3.92639 135.63V135.634C3.92639 135.835 4.34679 136 4.85993 136H151.068H152.931H272.962C273.466 136 273.878 135.839 273.878 135.641V135.624C273.878 135.426 273.466 135.265 272.962 135.265Z"
      fill="#BBBEDB"
    />
    <path
      d="M198.639 50.9502L193.744 54.6237C193.744 54.6237 200.007 93.5411 200.97 103.767L226.279 102.984C226.279 102.984 216.261 49.7943 198.639 50.9502Z"
      fill="white"
    />
    <path
      d="M197.258 50.4658L196.998 58.7518C196.998 58.7518 196.178 74.1211 195.174 75.5899C194.696 76.2888 192.711 77.8655 190.76 79.3262C188.613 80.9344 186.508 82.4031 186.508 82.4031H182.502L187.91 75.5931L189.88 73.1127C189.88 73.1127 190.764 58.3036 190.921 56.5044C191.206 53.2461 197.258 50.4658 197.258 50.4658Z"
      fill="white"
    />
    <path
      d="M190.76 79.3249C188.613 80.9331 186.508 82.4018 186.508 82.4018H182.502L187.91 75.5918C188.515 76.2343 190.226 78.1109 190.76 79.3249Z"
      fill="#E7B2B6"
    />
    <path
      d="M210.314 132.235L209.394 135.212H200.266C200.266 135.212 202.601 131.197 210.314 132.235Z"
      fill="#221E50"
    />
    <path
      d="M226.529 132.3L225.714 135.277H217.632C217.632 135.277 219.699 131.262 226.529 132.3Z"
      fill="#221E50"
    />
    <path
      d="M205.663 76.9233H223.212C223.212 76.9233 217.311 106.394 210.462 132.485L202.199 132.727L205.663 76.9233Z"
      fill="#2B295E"
    />
    <path
      d="M225.897 76.6387C225.897 76.6387 229.586 98.789 226.979 132.728H219.904L215.472 87.9903C215.343 86.6852 213.342 85.5406 213.342 85.5406V77.8769L225.897 76.6387Z"
      fill="#333383"
    />
    <path
      d="M225.315 126.646C224.674 122.389 224.143 117.337 223.851 112.066C223.807 111.261 223.766 110.431 223.73 109.602C223.69 108.687 223.655 107.761 223.626 106.848C223.616 106.535 223.701 106.173 223.784 105.822C223.861 105.496 223.94 105.16 223.934 104.889C223.848 101.419 223.919 98.2423 223.981 95.4394C223.997 94.7043 224.013 93.9844 224.026 93.2791C224.143 86.9051 224.187 83.2938 224.186 83.2583C224.187 83.2938 224.345 86.8874 224.228 93.2702C224.215 93.9764 224.199 94.6962 224.182 95.4314C224.12 98.2318 224.049 101.406 224.135 104.869C224.142 105.172 224.059 105.525 223.979 105.866C223.9 106.2 223.818 106.546 223.827 106.827C223.855 107.739 223.891 108.664 223.93 109.578C223.966 110.406 224.008 111.234 224.052 112.039C224.343 117.302 224.732 122.399 225.372 126.648L225.315 126.646Z"
      fill="#2B295E"
    />
    <path
      d="M197.258 50.4674C200.312 47.7242 205.252 46.083 209.904 45.5397C215.218 44.919 228.309 72.33 223.211 75.7745C217.397 79.7026 205.662 76.9264 205.662 76.9264C205.662 76.9264 199.753 69.3715 198.145 65.9431C196.972 63.4402 197.258 50.4674 197.258 50.4674Z"
      fill="#333383"
    />
    <path
      d="M188.79 45.5381L181.801 49.3977L183.42 52.4416L190.41 48.4538L188.79 45.5381Z"
      fill="#333383"
    />
    <path
      d="M190.921 50.6413L189.301 47.7256L183.116 51.1411L180.893 46.8252L172.905 50.7961L176.222 58.4163C179.527 58.7129 185.082 54.9572 185.082 54.9572L184.69 54.1963L190.921 50.6413Z"
      fill="#555CA5"
    />
    <path
      d="M176.826 59.8044L176.8 59.7431L176.221 58.4154L172.904 50.7945L172.631 50.1665L162.793 54.501L162.812 54.5461L164.739 58.9725C164.765 59.0329 164.793 59.0934 164.821 59.1522C164.597 59.3465 164.284 59.6278 163.925 59.9535C162.486 61.2618 160.319 63.2884 160.319 63.2884L160.339 63.3109L161.946 65.1118L166.196 61.003C166.212 61.0184 166.228 61.0321 166.244 61.0466C165.478 62.795 164.16 65.9542 164.16 65.9542L166.447 66.795L168.269 62.2162C168.374 62.2517 168.482 62.2848 168.59 62.3146C168.682 64.2049 168.821 66.5499 168.821 66.5499H171.261L170.952 62.4629C171.471 62.3896 171.989 62.2445 172.492 62.0228L174.009 61.3545C175.766 62.3976 178.312 64.4935 179.052 68.1347C179.099 68.3628 179.138 68.5966 179.169 68.8368H157.466C156.95 68.8368 156.529 69.2616 156.529 69.78C156.529 70.2991 156.951 70.7231 157.466 70.7231H179.252C179.238 71.1052 179.206 71.4994 179.152 71.9073C178.821 74.4732 177.689 77.5606 175.32 81.2542H184.27C184.27 81.2542 184.779 79.1526 184.977 76.249C185.36 70.6562 184.591 62.0848 176.826 59.8044Z"
      fill="#D0D6EC"
    />
    <path d="M187.201 81.251H171.426V82.0095H187.201V81.251Z" fill="#333383" />
    <path
      d="M195.947 46.8181C195.947 46.8181 196.811 49.2349 197.452 51.0083C197.807 51.9885 198.868 52.5093 199.855 52.1876L200.791 51.8821C201.785 51.5573 202.593 50.8205 203.012 49.858L203.866 47.8935C203.866 47.8935 201.374 46.1209 199.829 42.395L195.947 46.8181Z"
      fill="#D98789"
    />
    <path
      d="M195.647 47.4488C195.647 47.4488 201.439 44.837 199.767 41.2337C198.094 37.6304 197.716 34.8654 193.947 36.3809C190.178 37.8956 190.102 39.6062 190.37 40.9459C190.638 42.2857 194.097 48.0349 195.647 47.4488Z"
      fill="#E7B2B6"
    />
    <path
      d="M199.767 41.2329C199.767 41.2329 196.969 36.7107 192.011 39.4394C187.053 42.1672 186.829 36.2617 190.808 33.3564C190.808 33.3564 191.203 35.2766 194.064 34.1609C200.068 31.8192 199.899 40.2237 199.767 41.2329Z"
      fill="#555CA5"
    />
    <path
      d="M235.446 104.826L220.626 107.94C220.626 107.94 215.771 91.7281 211.486 77.6333C210.118 73.1335 208.808 68.8499 207.734 65.3788C206.566 61.6062 205.676 58.7928 205.289 57.7038C203.432 52.4737 202.92 47.0559 202.92 47.0559C202.92 47.0559 210.205 44.5577 211.422 44.9124C213.556 45.5339 219.182 54.6236 223.083 65.494C226.4 74.7361 235.446 104.826 235.446 104.826Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M197.258 50.4658L197.88 69.8391L195.174 57.238L195.947 55.9071L194.535 54.1634L197.258 50.4658Z"
      fill="#555CA5"
    />
    <path
      opacity="0.4"
      d="M202.92 47.0566C202.92 47.0566 204.981 65.1272 208.87 68.6765C208.87 68.6765 206.916 54.5124 207.319 53.433L205.784 52.7591L206.635 50.9316L202.92 47.0566Z"
      fill="#555CA5"
    />
    <path
      d="M211.486 77.7081C210.118 73.2084 208.808 68.9247 207.735 65.4536C209.175 63.2045 215.771 55.7778 215.771 55.7778C215.771 55.7778 214.485 68.0944 211.486 77.7081Z"
      fill="#D0D6EC"
    />
    <path
      d="M194.848 62.4416L192.796 64.9688L187.314 59.7016L186.25 58.6787C185.289 58.0394 184.769 56.4345 184.948 54.8263C185.126 53.2197 184.935 53.6308 185.039 52.4861C185.142 51.3423 186.473 52.1089 186.473 52.1089C189.57 54.2104 188.298 57.3889 188.298 57.3889L189.052 57.9701L194.848 62.4416Z"
      fill="#E7B2B6"
    />
    <path
      d="M209.557 47.359C209.557 47.359 204.835 64.4179 203.352 66.5468L193.805 60.7719L190.92 64.1648C190.92 64.1648 199.807 74.5797 204.688 74.1952C207.319 73.9881 216.061 56.5035 216.061 56.5035C216.061 56.5035 215.247 46.8278 209.557 47.359Z"
      fill="white"
    />
    <path
      d="M189.051 57.9701C188.464 58.5698 187.803 59.2325 187.314 59.7016L186.25 58.6787C185.289 58.0394 184.769 56.4345 184.948 54.8263C185.126 53.2197 184.935 53.6308 185.039 52.4861C185.142 51.3423 186.473 52.1089 186.473 52.1089C189.57 54.2104 188.298 57.3889 188.298 57.3889L189.051 57.9701Z"
      fill="#DFE5F4"
    />
    <path
      d="M184.979 76.2453C184.936 76.3518 184.912 76.4098 184.912 76.4098C184.897 72.0616 179.09 62.4673 177.388 61.1211C176.892 60.7293 176.684 60.4254 176.64 60.1925C176.608 60.0191 176.664 59.8853 176.759 59.7813C176.771 59.7668 176.785 59.7531 176.799 59.7402L176.826 59.8015C184.59 62.082 185.359 70.6534 184.979 76.2453Z"
      fill="#DFE5F4"
    />
    <path
      d="M172.63 50.1646C172.63 50.1646 172.28 53.2915 169.27 54.6256C167.15 55.5639 164.308 54.9746 162.811 54.5441L162.792 54.499L172.63 50.1646Z"
      fill="#DFE5F4"
    />
    <path
      d="M163.925 59.9526C163.872 60.2928 163.741 60.6733 163.495 61.0925C162.607 62.6064 161.119 63.1336 160.34 63.3101L160.32 63.2875C160.319 63.2867 162.486 61.261 163.925 59.9526Z"
      fill="#DFE5F4"
    />
    <path
      d="M195.947 85.561L193.895 95.9333L190.372 113.727L186.105 135.276H89.8248L85.3456 114.458L81.4346 96.2767L79.1293 85.561H195.947Z"
      fill="#D0D6EC"
    />
    <path
      opacity="0.2"
      d="M195.947 85.561L193.895 95.9333L190.372 113.727C179.266 107.956 159.156 105.22 141.181 103.768C122.062 102.222 97.5564 107.927 85.3457 114.458L81.4346 96.2767L79.1301 85.561H195.947Z"
      fill="#555CA5"
    />
    <path
      d="M200.44 85.5598H73.2499C72.2787 85.5598 71.4911 84.7674 71.4911 83.7904C71.4911 82.8134 72.2787 82.021 73.2499 82.021H200.44C201.411 82.021 202.199 82.8134 202.199 83.7904C202.199 84.7674 201.411 85.5598 200.44 85.5598Z"
      fill="#555CA5"
    />
    <path
      d="M133.164 53.3689C131.256 51.464 131.501 36.6606 131.479 35.7554C131.416 33.1412 130.434 32.0489 130.434 32.0489C129.93 30.664 121.724 29.9594 119.666 35.2427C118.346 38.6324 114.968 45.2231 114.968 45.2231C101.165 64.4916 106.65 73.9972 106.65 73.9972L107.24 73.932C107.037 73.3008 106.877 72.41 107.084 72.0311L107.646 73.9183L117.843 72.5986C117.921 70.6269 118.708 67.5532 118.708 67.5532L118.756 72.7712L120.124 72.8437C119.583 61.8419 121.906 56.4394 122.594 55.1004C122.004 61.2172 124.947 71.1912 124.947 71.1912L125.368 71.0961L124.413 66.1095L125.636 71.0356L126.821 70.7647C126.632 70.1013 126.459 69.454 126.301 68.822C126.627 70.0401 126.821 70.7647 126.821 70.7647C129.361 67.9845 134.015 68.2303 134.015 68.2303C136.543 64.6544 133.164 53.3689 133.164 53.3689Z"
      fill="#D35C2B"
    />
    <path
      d="M134.28 127.618L137.255 133.59L139.677 132.651L139.12 126.555L134.28 127.618Z"
      fill="#E7B2B6"
    />
    <path
      d="M136.205 132.577L137.016 135.275H145.061C145.061 135.274 143.003 131.635 136.205 132.577Z"
      fill="#ED823A"
    />
    <path
      d="M134.013 75.636L140.368 128.006H133.629C133.629 128.006 124.836 105.357 123.575 81.7552C123.092 72.7381 134.013 75.636 134.013 75.636Z"
      fill="#333383"
    />
    <path
      d="M117.782 128.088L119.161 132.959L121.739 132.646L122.309 125.201L117.782 128.088Z"
      fill="#E7B2B6"
    />
    <path
      d="M118.921 132.577L119.732 135.275H127.776C127.776 135.274 125.719 131.635 118.921 132.577Z"
      fill="#ED823A"
    />
    <path
      d="M125.317 76.347H120.326L114.971 75.5933C114.971 75.5933 111.544 83.1223 113.309 105.436C114.205 116.776 117.54 128.837 117.54 128.837L124.448 128.694L127.431 87.0779C127.52 85.9381 128.839 85.5278 128.839 85.5278L128.647 76.218L125.317 76.347Z"
      fill="#555CA5"
    />
    <path
      d="M120.047 127.844C119.116 122.812 118.3 116.843 117.773 110.617C117.693 109.665 117.616 108.686 117.544 107.707C117.466 106.627 117.394 105.532 117.33 104.455C117.307 104.085 117.403 103.658 117.495 103.245C117.58 102.861 117.669 102.464 117.652 102.145C117.438 98.0481 117.427 94.298 117.416 90.9889C117.414 90.1208 117.411 89.2719 117.405 88.4392C117.351 80.9158 117.294 76.6523 117.291 76.6104C117.294 76.6523 117.602 80.8964 117.656 88.4304C117.661 89.2639 117.664 90.1135 117.667 90.9817C117.677 94.2876 117.688 98.0335 117.902 102.124C117.92 102.48 117.827 102.897 117.738 103.298C117.65 103.692 117.56 104.1 117.58 104.432C117.644 105.508 117.716 106.601 117.794 107.68C117.865 108.659 117.942 109.637 118.023 110.587C118.549 116.804 119.191 122.825 120.119 127.849L120.047 127.844Z"
      fill="#333383"
    />
    <path
      d="M127.309 42.0196C127.309 42.0196 126.519 46.6434 129.38 48.8255C129.38 48.8255 126.719 52.6409 125.362 52.5248C123.853 52.3958 121.644 47.7115 121.644 47.7115C121.644 47.7115 122.27 44.8337 122.14 40.4355L127.309 42.0196Z"
      fill="#D98789"
    />
    <path
      d="M128.315 43.342C128.315 43.342 122.01 43.6322 121.89 39.679C121.772 35.7258 120.878 33.1012 124.898 32.7489C128.919 32.3966 129.747 33.8815 130.106 35.1914C130.465 36.5005 129.952 43.1646 128.315 43.342Z"
      fill="#E7B2B6"
    />
    <path
      d="M134.013 75.6367C134.013 75.6367 133.176 76.3307 124.824 76.7475C124.28 76.7741 123.764 76.7926 123.274 76.8047C120.278 76.874 118.261 76.6644 116.933 76.4017C115.143 76.047 114.605 75.5947 114.605 75.5947C114.605 75.5947 117.212 69.266 117.212 66.2584C117.212 64.1802 121.875 47.3373 121.875 47.3373C122.421 48.8504 123.894 50.1466 125.511 50.1587C129.743 50.1902 128.092 47.3301 128.092 47.3301L132.86 49.2575C132.86 49.2575 132.268 51.0366 132.489 52.7608C132.547 53.2074 132.659 53.6492 132.851 54.0563C132.893 54.1449 132.938 54.2328 132.988 54.3183C134.141 56.2997 133.351 59.7353 132.508 63.7159C131.665 67.6948 134.013 75.6367 134.013 75.6367Z"
      fill="#555CA5"
    />
    <path
      d="M155.59 57.2552C155.252 58.0831 155.266 57.7292 154.814 58.9037C154.364 60.079 153.43 61.0004 152.537 61.0939C152.537 61.0939 152.147 61.3978 151.504 61.8823L151.503 61.8831C151.311 62.0274 151.096 62.1878 150.863 62.3611C147.972 64.5062 142.178 68.6053 140.368 68.4013C137.922 68.1256 130.991 58.1242 130.991 58.1242L132.86 49.2554C132.86 49.2554 137.888 57.7566 140.367 64.1797C140.367 64.1797 146.443 61.6413 149.918 60.1701C150.103 60.0919 150.281 60.0161 150.451 59.9444L151.583 59.4607C151.583 59.4607 151.842 56.8046 154.733 56.4733C154.733 56.4749 155.927 56.4273 155.59 57.2552Z"
      fill="#E7B2B6"
    />
    <path
      d="M155.59 57.2536C155.252 58.0815 155.266 57.7276 154.814 58.9021C154.364 60.0774 153.43 60.9988 152.537 61.0923C152.537 61.0923 152.147 61.3962 151.504 61.8806L151.503 61.8815C151.311 62.0257 151.096 62.1862 150.863 62.3595C150.822 61.426 149.918 60.1701 149.918 60.1701V60.1693C150.104 60.0911 150.281 60.0153 150.451 59.9436L151.584 59.4599C151.584 59.4599 151.842 56.8038 154.734 56.4725C154.733 56.4733 155.927 56.4257 155.59 57.2536Z"
      fill="#555CA5"
    />
    <path
      d="M155.031 55.0024V64.9192C155.031 65.3884 154.654 65.768 154.188 65.768C153.721 65.768 153.344 65.3884 153.344 64.9192V55.0024H155.031Z"
      fill="#DFE5F4"
    />
    <path
      d="M155.031 62.0298V64.9189C155.031 65.388 154.654 65.7677 154.188 65.7677C153.721 65.7677 153.344 65.388 153.344 64.9189V62.0298H155.031Z"
      fill="#555CA5"
    />
    <path
      d="M144.611 66.8726C144.611 66.8726 141.313 68.5388 140.659 68.5388C140.006 68.5388 138.227 67.6715 136.357 65.4289C135.542 64.451 134.348 62.9202 133.264 61.4958C131.86 59.6539 130.639 57.9917 130.657 57.9401C130.687 57.8482 131.341 48.6416 131.341 48.6416C131.341 48.6416 132.906 49.0713 133.014 49.1777C133.12 49.2849 139.261 60.3576 140.356 63.6296L144.012 62.5244L144.611 66.8726Z"
      fill="white"
    />
    <path
      d="M127.142 61.9778L127.927 97.3959H136.916C136.916 97.3959 134.952 77.3004 134.012 71.016C132.935 63.815 133.011 49.1768 133.011 49.1768C133.011 49.1768 129.321 47.6646 129.04 47.7105C128.759 47.7564 127.142 61.9778 127.142 61.9778Z"
      fill="white"
    />
    <path
      d="M128.093 47.3301C128.093 47.3301 126.595 59.0751 127.142 61.9803L129.498 54.0555L128.966 53.1091L129.938 52.8036L129.331 47.8178C129.332 47.8186 128.934 47.3301 128.093 47.3301Z"
      fill="#D0D6EC"
    />
    <path
      d="M126.278 62.3443V97.3964C126.278 97.3964 120.562 97.2924 116.147 97.1489C113.37 97.0586 111.107 96.9522 111.107 96.8458C111.107 96.5975 112.675 82.256 115.539 71.883C116.177 69.5751 116.4 67.1721 116.191 64.786C115.985 62.4458 115.683 59.7026 115.32 58.4314C115.316 58.4201 115.313 58.4096 115.31 58.3984C115.31 58.3984 109.907 62.7796 110.043 62.9859C110.18 63.1923 111.516 66.8399 111.516 66.8399C111.516 66.8399 108.979 68.6908 108.462 68.9503C108.462 68.9503 103.75 62.5587 104.403 61.5406C104.403 61.5406 112.616 47.4925 114.482 48.0817C115.011 48.2486 120.532 47.7109 120.532 47.7109L126.278 62.3443Z"
      fill="white"
    />
    <path
      d="M121.644 47.713C121.874 47.9452 126.278 62.3464 126.278 62.3464C126.278 62.3464 122.235 55.4033 121.93 55.2502L122.382 54.3941L121.414 53.9048L120.532 47.713C120.531 47.713 121.265 47.3326 121.644 47.713Z"
      fill="#D0D6EC"
    />
    <path
      d="M119.427 74.0989C118.618 74.4705 118.869 74.2222 117.741 74.7688C116.612 75.3145 115.305 75.3443 114.59 74.7978L113.798 74.186C113.662 74.0804 113.415 73.8563 113.099 73.5572C111.578 72.1199 108.462 68.9527 108.462 68.9527L111.516 66.8423C111.598 67.2993 114.04 71.6677 114.498 72.4835C114.544 72.5681 114.571 72.614 114.571 72.614L115.004 72.951C115.004 72.951 116.999 71.1921 119.333 72.9397C119.333 72.9397 120.237 73.7273 119.427 74.0989Z"
      fill="#E7B2B6"
    />
    <path
      d="M111.441 66.6372L107.937 68.8371C107.937 68.8371 108.394 69.3425 108.409 69.3119C108.424 69.2812 111.571 67.0008 111.571 67.0008L111.441 66.6372Z"
      fill="#AFB8DA"
    />
    <path
      d="M130.048 33.6938C130.048 33.6938 122.314 39.6897 122.997 44.1184C123.638 48.2788 122.421 48.6496 121.517 49.0462C119.182 50.07 115.372 48.0386 114.159 48.099C113.296 48.1417 116.263 47.2566 117.673 45.3615C121.425 40.3201 121.059 33.5084 121.808 33.0175C126.911 29.677 130.048 33.6938 130.048 33.6938Z"
      fill="#D35C2B"
    />
    <path
      d="M144.702 62.0259C144.702 62.0259 145.356 66.2443 145.675 66.9327L144.535 67.5187C144.535 67.5187 143.851 63.156 144.033 62.3604L144.702 62.0259Z"
      fill="#D0D6EC"
    />
    <path d="M154.187 54.999H153.696V62.0259H154.187V54.999Z" fill="#D0D6EC" />
    <path
      d="M118.705 77.0479C118.103 79.3462 116.729 91.7304 116.147 97.1483C113.369 97.058 111.107 96.9516 111.107 96.8452C111.107 96.5969 112.675 82.2554 115.539 71.8824C116.177 69.5745 116.4 67.1715 116.191 64.7854C115.985 62.4452 115.683 59.702 115.32 58.4308C115.716 58.0834 116.171 57.8786 116.433 58.0995C117.007 58.5904 119.48 74.0871 118.705 77.0479Z"
      fill="#D0D6EC"
    />
    <path
      d="M119.427 74.099C118.618 74.4706 118.869 74.2224 117.741 74.7689C116.612 75.3146 115.305 75.3445 114.59 74.7979L113.798 74.1861C113.662 74.0805 113.415 73.8564 113.099 73.5573C113.582 73.2873 114.148 72.9132 114.498 72.4836C114.544 72.5682 114.571 72.6142 114.571 72.6142L115.004 72.9511C115.004 72.9511 116.999 71.1922 119.333 72.9398C119.333 72.9398 120.237 73.7274 119.427 74.099Z"
      fill="#555CA5"
    />
    <path
      d="M144.612 66.8719C144.612 66.8719 141.313 68.5381 140.659 68.5381C140.006 68.5381 138.227 67.6708 136.358 65.4282C135.542 64.4503 134.348 62.9195 133.264 61.4951C133.112 60.743 133.055 59.9103 133.563 59.8587C134.475 59.7668 139.4 67.2911 140.632 67.2451C141.862 67.2 144.612 66.8719 144.612 66.8719Z"
      fill="#D0D6EC"
    />
    <path
      d="M62.2947 30.7475C62.2947 30.7475 61.8708 29.0385 60.0014 29.7269C58.132 30.4153 59.773 31.88 59.773 31.88L62.2947 30.7475Z"
      fill="#555CA5"
    />
    <path
      d="M63.8191 127.603L66.7943 133.576L69.2173 132.636L68.6604 126.54L63.8191 127.603Z"
      fill="#E7B2B6"
    />
    <path
      d="M84.2472 78.7174C84.2472 78.8439 84.24 78.9689 84.2264 79.0906C84.0421 80.7399 82.6519 82.0208 80.9636 82.0208C79.3202 82.0208 77.9588 80.806 77.7176 79.2204C77.6927 79.0559 77.6799 78.8883 77.6799 78.7174C77.6799 77.3196 78.5429 76.1257 79.7617 75.6437V71.4575H82.1671V75.6437C83.3859 76.1257 84.2472 77.3196 84.2472 78.7174Z"
      fill="#DFE5F4"
    />
    <path
      d="M84.2262 79.0918C84.0419 80.7411 82.6517 82.022 80.9634 82.022C79.32 82.022 77.9586 80.8072 77.7174 79.2216C78.5804 79.2119 80.0475 79.2216 81.3737 79.361C82.5075 79.4795 83.5363 79.2861 84.2262 79.0918Z"
      fill="#D35C2B"
    />
    <path
      d="M80.6834 71.4561H80.2194L80.2266 75.6422C80.2266 75.6422 79.1321 76.4451 79.1321 76.8812L80.5688 77.3399C80.5688 77.3399 80.3404 76.0323 80.6826 75.6422V71.4561H80.6834Z"
      fill="#D0D6EC"
    />
    <path
      d="M65.7451 132.563L66.556 135.261H74.6009C74.6009 135.261 72.5424 131.622 65.7451 132.563Z"
      fill="#333381"
    />
    <path
      d="M69.9069 127.992H63.1681C63.1681 127.992 54.3756 105.344 53.1136 81.7423C52.632 72.7243 63.1344 75.5804 63.1344 75.5804L65.6248 92.6997L69.9069 127.992Z"
      fill="#555CA5"
    />
    <path
      d="M64.1769 127.992H63.1681C63.1681 127.992 54.3756 105.344 53.1136 81.7423C52.632 72.7243 63.1344 75.5804 63.1344 75.5804L65.6248 92.6997C64.7538 103.595 63.6296 120.259 64.1769 127.992Z"
      fill="#333383"
    />
    <path
      d="M47.3218 128.074L48.7008 132.945L51.2785 132.631L51.849 125.187L47.3218 128.074Z"
      fill="#E7B2B6"
    />
    <path
      d="M48.4597 132.563L49.2706 135.261H57.3155C57.3155 135.261 55.2578 131.622 48.4597 132.563Z"
      fill="#333381"
    />
    <path
      d="M58.0134 85.5164C58.0134 85.5164 56.6937 85.9267 56.6047 87.0665L53.6224 128.683L49.3539 128.771L46.7145 128.825C46.7145 128.825 43.3796 116.764 42.4829 105.424C42.3531 103.784 42.2514 102.225 42.1744 100.741C41.2033 82.0436 44.1448 75.5811 44.1448 75.5811L49.4998 76.334H54.4909L57.8211 76.2058L58.0134 85.5164Z"
      fill="#555CA5"
    />
    <path
      d="M49.3542 128.769L46.7147 128.824C46.7147 128.824 43.3798 116.762 42.4832 105.423C42.3534 103.783 42.2516 102.223 42.1747 100.74L49.5857 99.9033L50.6731 104.583C50.6731 104.584 48.7492 122.859 49.3542 128.769Z"
      fill="#333383"
    />
    <path
      d="M87.5912 69.876C86.6505 70.1469 86.9638 69.918 85.6497 70.3267C84.39 70.7184 83.0463 70.4645 82.33 69.8172C82.0295 69.8398 81.6977 69.8631 81.3404 69.8857C78.084 70.0937 72.7242 70.2509 70.0928 69.2932C67.7386 68.4355 58.999 61.399 58.999 61.399L56.7675 51.0687C56.7675 51.0687 61.354 50.535 62.1417 51.7329C66.479 58.3398 67.2578 60.1342 72.588 64.6992C76.0399 65.7657 78.6136 66.6492 81.5864 67.4803C82.0543 67.6117 82.5319 67.7406 83.0247 67.8704C83.3876 67.66 85.616 66.5113 87.6785 68.6008C87.6769 68.6016 88.5311 69.606 87.5912 69.876Z"
      fill="#D98789"
    />
    <path
      d="M75.7808 70.4653C75.7808 70.4653 70.0805 69.6882 69.7151 69.4576C69.4667 69.3004 65.8697 66.7289 63.5901 65.0966C63.5893 65.0966 63.5893 65.0965 63.5893 65.0957C62.5148 64.3275 61.7343 63.7681 61.7343 63.7681C61.7343 63.7681 58.9066 55.9988 61.7343 50.6519C61.7343 50.6519 69.0636 61.7036 72.3994 63.997L76.2335 65.8027C76.2335 65.8027 75.7271 68.196 75.7039 69.6438C75.6982 69.9848 75.7191 70.2726 75.7808 70.4653Z"
      fill="white"
    />
    <path
      d="M63.1631 31.3789C63.1631 31.3789 62.3723 30.1858 60.5181 30.767C58.6631 31.3482 59.6367 33.1531 59.6367 33.1531L63.1631 31.3789Z"
      fill="#D35C2B"
    />
    <path
      d="M57.5626 82.6525H63.3495C63.8895 82.6525 64.2926 82.1536 64.1804 81.6223C63.6067 78.8969 62.1916 71.4742 63.0033 68.0515C64.0073 63.8145 62.0089 52.3267 62.0089 52.3267C62.0089 52.3267 61.2653 54.1412 56.7798 52.7821C52.2934 51.423 57.5626 82.6525 57.5626 82.6525Z"
      fill="#F0D170"
    />
    <path
      d="M63.003 42.9776C63.003 42.9776 60.3347 47.3871 62.3844 50.7848C62.3844 50.7848 60.1135 53.557 57.6472 52.5728C52.5198 50.5277 54.9252 45.3637 54.9252 45.3637C54.9252 45.3637 55.5719 45.4838 58.3507 39.2534L63.003 42.9776Z"
      fill="#D98789"
    />
    <path
      d="M63.501 44.7387C63.501 44.7387 56.9321 42.4695 58.4 38.3761C59.868 34.2826 60.0082 31.2331 64.2646 32.5084C68.5202 33.7845 68.771 35.6401 68.6108 37.1266C68.4513 38.6123 65.247 45.2232 63.501 44.7387Z"
      fill="#E7B2B6"
    />
    <path
      d="M61.5978 31.1957C61.5978 31.1957 67.6635 29.6359 68.94 36.1968C69.4849 38.9972 66.9688 36.3346 64.42 36.4265C61.8711 36.5184 58.3502 39.2544 58.3502 39.2544C58.3502 39.2544 55.1676 34.3153 61.5978 31.1957Z"
      fill="#555CA5"
    />
    <path
      d="M60.7106 66.224C60.6657 69.3831 60.5896 72.8163 60.4934 76.2971C60.0968 90.8442 59.3813 106.253 59.3813 106.253H40.7563C39.8444 95.9759 44.0527 64.0531 46.83 55.2705C49.6072 46.488 54.3612 46.334 54.3612 46.334L58.6817 51.319C59.1441 52.6757 60.8172 58.6336 60.7106 66.224Z"
      fill="white"
    />
    <path
      d="M62.7682 51.7349C62.7682 51.7349 62.1224 52.5515 62.1424 53.1415C62.7698 71.4604 64.4709 106.254 64.4709 106.254H68.3564L62.7682 51.7349Z"
      fill="#D0D6EC"
    />
    <path
      d="M81.6801 80.7748C80.584 80.8038 80.987 80.6442 79.4445 80.7168C77.9389 80.7885 76.5231 80.1073 75.9445 79.1755C75.3091 78.9562 74.5134 78.6588 73.6232 78.3081C69.1473 76.5475 62.2578 73.4464 61.0519 72.2461C59.3524 70.5541 51.1465 55.5693 51.8836 53.9507C52.6208 52.3328 57.721 51.3203 57.721 51.3203L64.2578 67.2071L74.7434 75.4004H74.7442L77.2106 77.3271C77.2106 77.3271 80.3171 76.2985 82.128 79.4149C82.128 79.4149 82.7763 80.7458 81.6801 80.7748Z"
      fill="#D98789"
    />
    <path
      d="M60.9927 52.5245C60.9927 52.5245 55.5111 46.082 55.6561 44.5811C55.6561 44.5811 53.5255 44.7262 52.814 46.0135C52.814 46.0135 59.6409 54.6703 60.9927 55.3144V52.5245Z"
      fill="#D0D6EC"
    />
    <path
      d="M61.6472 48.7427C61.6472 48.7427 63.1448 49.6076 63.3202 50.8813C63.3202 50.8813 63.0069 54.3516 61.7553 55.2988L60.9645 52.4846C60.9645 52.4846 61.4957 50.579 61.6472 48.7427Z"
      fill="#D0D6EC"
    />
    <path
      d="M60.7101 66.2253C60.6653 69.3845 60.5892 72.8177 60.493 76.2985C60.4874 76.2977 60.4826 76.2969 60.4778 76.2945C58.513 75.7398 55.2286 69.2579 53.7085 64.7308C52.1877 60.2037 51.2919 54.4102 51.884 53.9507L60.7101 66.2253Z"
      fill="#D0D6EC"
    />
    <path
      d="M55.0756 49.0576C55.0756 49.0576 58.5363 49.21 59.9097 54.5948C61.2831 59.9796 63.9682 66.3398 64.4698 66.7985C64.9714 67.2572 70.9914 72.0745 70.9914 72.0745C70.9914 72.0745 69.687 75.7036 69.6549 76.6653C69.6549 76.6653 61.5083 73.5497 60.5948 72.2615C59.6814 70.9733 50.9706 56.0627 51.2903 54.411C51.61 52.7593 51.7927 50.8617 55.0756 49.0576Z"
      fill="white"
    />
    <path
      d="M48.0209 62.6126C47.0225 75.1202 48.5457 98.0508 49.1547 106.253H40.7572C39.8454 95.9759 44.0537 64.0531 46.831 55.2705C49.6082 46.488 54.3622 46.334 54.3622 46.334C54.3622 46.334 49.2516 47.1981 48.0209 62.6126Z"
      fill="#D0D6EC"
    />
    <path
      d="M81.6805 80.7742C80.5844 80.8033 80.9874 80.6437 79.445 80.7162C77.9394 80.788 76.5235 80.1068 75.945 79.1749C75.3096 78.9557 74.5139 78.6582 73.6237 78.3075C74.2639 77.5401 74.7438 75.3999 74.7438 75.3999H74.7447L77.211 77.3265C77.211 77.3265 80.3176 76.2979 82.1285 79.4143C82.1285 79.4143 82.7767 80.7452 81.6805 80.7742Z"
      fill="#555CA5"
    />
    <path
      d="M87.5911 69.8741C86.6504 70.1449 86.9637 69.916 85.6496 70.3247C84.39 70.7165 83.0463 70.4625 82.3299 69.8152C82.0294 69.8378 81.6977 69.8612 81.3403 69.8838C81.5399 69.0599 81.5831 68.1006 81.5855 67.4791C82.0535 67.6105 82.531 67.7395 83.0238 67.8693C83.3868 67.6589 85.6152 66.5102 87.6777 68.5996C87.6769 68.5996 88.531 69.604 87.5911 69.8741Z"
      fill="#555CA5"
    />
    <path
      d="M87.5512 71.2132C87.5512 71.2986 87.5464 71.3825 87.5368 71.4655C87.4126 72.5779 86.4743 73.4421 85.3357 73.4421C84.2267 73.4421 83.3084 72.6223 83.1458 71.5526C83.1289 71.4413 83.1201 71.3285 83.1201 71.2132C83.1201 70.27 83.7027 69.4647 84.5248 69.1391V66.3145H86.1474V69.1391C86.9695 69.4647 87.5512 70.27 87.5512 71.2132Z"
      fill="#DFE5F4"
    />
    <path
      d="M85.4695 66.314H84.758V69.1007C84.758 69.1007 83.5689 70.4542 83.8069 71.7391H84.9471C84.9471 71.7391 84.9134 69.6746 85.4695 69.1007V66.314Z"
      fill="#D0D6EC"
    />
    <path
      d="M87.5378 71.4604C87.4136 72.5729 86.4753 73.437 85.3366 73.437C84.2277 73.437 83.3094 72.6172 83.1467 71.5475C83.7293 71.5411 84.7188 71.5475 85.6139 71.6418C86.3783 71.7224 87.0722 71.5918 87.5378 71.4604Z"
      fill="#555CA5"
    />
    <path
      d="M92.4338 70.647V80.5637C92.4338 81.0329 92.0564 81.4126 91.5901 81.4126C91.1237 81.4126 90.7463 81.0329 90.7463 80.5637V70.647H92.4338Z"
      fill="#DFE5F4"
    />
    <path
      d="M92.4343 77.6748V80.5647C92.4343 81.0339 92.0569 81.4135 91.5906 81.4135C91.1242 81.4135 90.7468 81.0339 90.7468 80.5647V77.6748H92.4343Z"
      fill="#555CA5"
    />
    <path
      d="M94.8966 70.647V80.5637C94.8966 81.0329 94.5192 81.4126 94.0529 81.4126C93.5865 81.4126 93.2091 81.0329 93.2091 80.5637V70.647H94.8966Z"
      fill="#DFE5F4"
    />
    <path
      d="M94.8967 77.6748V80.5647C94.8967 81.0339 94.5193 81.4135 94.053 81.4135C93.5866 81.4135 93.2092 81.0339 93.2092 80.5647V77.6748H94.8967Z"
      fill="#555CA5"
    />
    <path
      d="M97.3599 70.647V80.5637C97.3599 81.0329 96.9825 81.4126 96.5161 81.4126C96.0498 81.4126 95.6724 81.0329 95.6724 80.5637V70.647H97.3599Z"
      fill="#DFE5F4"
    />
    <path
      d="M97.3596 77.6748V80.5647C97.3596 81.0339 96.9822 81.4135 96.5159 81.4135C96.0495 81.4135 95.6721 81.0339 95.6721 80.5647V77.6748H97.3596Z"
      fill="#555CA5"
    />
    <path
      d="M100.019 70.647V80.5637C100.019 81.0329 99.6419 81.4126 99.1755 81.4126C98.7092 81.4126 98.3318 81.0329 98.3318 80.5637V70.647H100.019Z"
      fill="#DFE5F4"
    />
    <path
      d="M100.02 77.6748V80.5647C100.02 81.0339 99.6424 81.4135 99.176 81.4135C98.7097 81.4135 98.3323 81.0339 98.3323 80.5647V77.6748H100.02Z"
      fill="#555CA5"
    />
    <path
      d="M101.737 80.5557H88.9983V82.0204H101.737V80.5557Z"
      fill="#DFE5F4"
    />
    <path d="M91.2264 70.647H90.9821V77.3401H91.2264V70.647Z" fill="#D0D6EC" />
    <path d="M93.7499 70.647H93.5056V77.3401H93.7499V70.647Z" fill="#D0D6EC" />
    <path d="M96.1632 70.647H95.9188V77.3401H96.1632V70.647Z" fill="#D0D6EC" />
    <path d="M98.8581 70.647H98.6138V77.3401H98.8581V70.647Z" fill="#D0D6EC" />
    <path
      d="M146.777 79.5099C146.777 79.6058 146.771 79.7009 146.761 79.7936C146.62 81.0463 145.564 82.0201 144.281 82.0201C143.032 82.0201 141.999 81.0971 141.815 79.892C141.797 79.767 141.787 79.6396 141.787 79.5099C141.787 78.4482 142.442 77.5405 143.369 77.1746V73.9937H145.196V77.1746C146.122 77.5405 146.777 78.4474 146.777 79.5099Z"
      fill="#DFE5F4"
    />
    <path
      d="M144.432 73.9937H143.63V77.1318C143.63 77.1318 142.291 78.6562 142.558 80.1032H143.842C143.842 80.1032 143.804 77.7783 144.431 77.1318V73.9937H144.432Z"
      fill="#D0D6EC"
    />
    <path
      d="M146.761 79.7939C146.621 81.0466 145.565 82.0204 144.282 82.0204C143.033 82.0204 141.999 81.0974 141.816 79.8923C142.471 79.885 143.587 79.8923 144.594 79.9979C145.454 80.089 146.236 79.9415 146.761 79.7939Z"
      fill="#F0D170"
    />
    <path
      d="M153.344 79.5099C153.344 79.6058 153.338 79.7009 153.328 79.7936C153.187 81.0463 152.131 82.0201 150.848 82.0201C149.6 82.0201 148.566 81.0971 148.382 79.892C148.364 79.767 148.354 79.6396 148.354 79.5099C148.354 78.4482 149.009 77.5405 149.936 77.1746V73.9937H151.763V77.1746C152.688 77.5405 153.344 78.4474 153.344 79.5099Z"
      fill="#DFE5F4"
    />
    <path
      d="M150.999 73.9937H150.197V77.1318C150.197 77.1318 148.858 78.6562 149.126 80.1032H150.409C150.409 80.1032 150.371 77.7783 150.998 77.1318V73.9937H150.999Z"
      fill="#D0D6EC"
    />
    <path
      d="M153.328 79.7939C153.187 81.0466 152.131 82.0204 150.848 82.0204C149.6 82.0204 148.566 81.0974 148.382 79.8923C149.038 79.885 150.153 79.8923 151.16 79.9979C152.021 80.089 152.803 79.9415 153.328 79.7939Z"
      fill="#D35C2B"
    />
  </svg>
);
export default LoginImage;
