import React, { useEffect, useState, useContext } from "react";
import Primitives from "../../components/primitives";
import Reports from "../reports";
import { useHistory } from "react-router-dom";
import ToolTipView from "../../lib/tooltip-view";
import Title from "../../lib/title";
import checkSortingIndex from "../../lib/check-sorting-index/index";
import {
  isDataAvailable,
  isValueZero,
  rupeeMetric
} from "../../lib/demo-user-value-display";
import Hospital from "../../components/glyphs/elements/hospital";
import ServingLocation from "../../components/glyphs/elements/serving-location";
import Location from "../../components/glyphs/elements/location";
import roundOffTwoDecimal from "../../components/utils/round-off-two-decimal";
import ConvertMoneyToLakhsCrores from "../../components/utils/convert-money-to-lakh-crores";
import DropdownComponent from "../mobile-navigation-dropdown";
import { FilterContext } from "../hospital-sales-report/filterContext";

const salesStatistics = hospitalData => [
  {
    glyph: <Hospital />,
    heading: "TOTAL HOSPITALS",
    title: isDataAvailable(hospitalData, "column_sum", "total_hospitals")
  },
  {
    glyph: <ServingLocation />,
    heading: "SERVING STATES",
    title: isDataAvailable(hospitalData, "column_sum", "total_states")
  },
  {
    glyph: <Location />,
    heading: "SERVING CITIES",
    title: isDataAvailable(hospitalData, "column_sum", "total_cities")
  }
];

const column = data => [
  {
    footerData: "Total",
    heading: (
      <Primitives.Flex
        key="1"
        width={1}
        alignItems="space-between"
        justifyContent="space-between"
      >
        <Primitives.Text fontWeight={700}>State Name</Primitives.Text>
      </Primitives.Flex>
    ),
    type: "string",
    width: 3
  },
  {
    footerData: `${roundOffTwoDecimal(data["total_hospitals"])}`,
    heading: "No. Of Hospitals",
    type: "number",
    width: 1,
    align: "flex-end"
  },
  {
    footerData: `${roundOffTwoDecimal(data["purchasing_hospitals"])}`,
    heading: "Purchasing Hospitals",
    type: "string",
    width: 1,
    align: "flex-end"
  },
  {
    heading: "Total SKUS",
    type: "string",
    width: 1,
    align: "flex-end"
  },
  {
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      roundOffTwoDecimal(data["last_month_sales_sum"])
    )}`,
    heading: "Last Month Sales",
    type: "string",
    width: 2,
    align: "flex-end"
  },
  {
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      roundOffTwoDecimal(data["current_month_sales_sum"])
    )}`,
    heading: "This Month Sales",
    type: "string",
    width: 2,
    align: "flex-end"
  },
  {
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      roundOffTwoDecimal(data["total_sales_sum"])
    )}`,
    heading: "Year Till Date Sales",
    type: "string",
    width: 2,
    align: "flex-end"
  }
];

const index = {
  0: "name+",
  1: "total_centres+",
  2: "purchasing_centres+",
  3: "uniq_products+",
  4: "last_month_sales+",
  5: "current_month_sales+",
  6: "total_sales+"
};

const desc =
  "Secondary sales report. Click on any hospital group to view more details";

const toolTip = () => <ToolTipView desc={desc} />;

const modifyReports = data =>
  data &&
  data["items"] &&
  data["items"].map(item => ({
    stateName: item.name,
    noOfHospitals: item.total_centres,
    purchasingHospitals: isValueZero(item.purchasing_centres),
    totalSKUs: isValueZero(item.uniq_products),
    lastMonthSales: rupeeMetric(item.last_month_sales),
    thisMonthSales: rupeeMetric(item.current_month_sales),
    yearTillDateSales: rupeeMetric(item.total_sales)
  }));

const HospitalSalesReport = () => {
  const history = useHistory();
  const [hospitalData, setHospitalData] = useState([]);
  const [columnSum, setColumnSum] = useState({});
  const [sort, updateSort] = useState("current_month_sales+desc");
  const filterContext = useContext(FilterContext);

  const getColumnSum = data => {
    setColumnSum(data);
  };

  const getHeaderId = (id, order) =>
    checkSortingIndex(index, id, order, updateSort);

  const api = `smartchain/hospital_reports/state_wise?`;

  const getHospitalData = data => {
    setHospitalData(data);
  };
  const getHospitalId = index => {
    if (hospitalData && hospitalData["items"]) {
      return hospitalData["items"][index].id;
    }
  };

  // useEffect(() => {
  //   hospitalData &&
  //     hospitalData["total_items"] === 1 &&
  //     hospitalSalesByStatesHandler([], 0);
  // }, [hospitalData]);

  const hospitalSalesByStatesHandler = (row, index) => {
    if (row[0] !== "OTHERS") {
      history.push(`${getHospitalId(index)}/hospital_sales_city_wise`);
    }
  };

  /** For mobile view */
  const initialDropdownId = 0;

  const [request, updateRequest] = useState(api);

  const filterUpdate = ({ stateSearch, months }) => {
    const monthTexts = months?.split(",")[0];
    filterContext.updateFilterState({
      months: String(monthTexts?.split("=")[1] ?? "")
    });
    updateRequest(
      `${api}${monthTexts ? `${monthTexts}` : ""}${
        stateSearch ? `&q[state_name_cont]=${stateSearch}` : ""
      }`
    );
  };

  useEffect(() => {
    filterContext.updateFilterState("");
  }, []);

  return (
    <Reports
      api={request}
      column={column(columnSum)}
      clickHandler={hospitalSalesByStatesHandler}
      cursor="pointer"
      filterConfig={{
        stateSearch: true,
        months: true,
        search: "stateSearch",
        mobileFilters: [{ text: "Months", filterText: "months" }],
        defaultFilter: "Months"
      }}
      filterUpdate={filterUpdate}
      getColumnSum={getColumnSum}
      getHeaderId={getHeaderId}
      getHospitalData={getHospitalData}
      salesStatistics={salesStatistics(hospitalData)}
      modifyReports={modifyReports}
      pageId="hospitalSalesReport"
      responsiveWidth={[900, 1100, 1100, "unset"]}
      responsiveHeight={["30vh", "55vh"]}
      sort={sort}
      selected={1}
      title={
        <>
          <Primitives.Flex
            alignItems={["center", "flex-start"]}
            flexDirection={["row", "column"]}
          >
            <Title>
              <Primitives.Text mb={["unset", 1]}>
                Hospital Sales
              </Primitives.Text>
            </Title>
            {toolTip()}
          </Primitives.Flex>
          <DropdownComponent initialDropdownId={initialDropdownId} />
        </>
      }
      reportTitle="Hospital Sales"
    />
  );
};

export default HospitalSalesReport;
