import { BASE_URL, GET_REQ_OPT, getHeaders } from "../config";

export default {
  isLoggedIn: async () => {
    var requestOptions = {
      headers: getHeaders(),
      ...GET_REQ_OPT
    };
    try {
      const response = await fetch(
        `${BASE_URL}/smartchain/is_logged_in`,
        requestOptions
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  }
};
