import React, { useContext, useEffect, useState } from "react";
import { number, string, oneOfType, array } from "prop-types";
import { useHistory } from "react-router";
import NavigationContainer from "../../../components/navigation";
import logoutUser from "../../logout-user";
import { AppContext } from "../../../utils/context";
import isCompanyAdmin from "../../is-company-admin";
import Caret from "../../../components/glyphs/elements/caret";
import Primitives from "../../../components/primitives";
import Trademark from "../../../pages/onboarding/trademark";
import ClosedLock from "../../../components/glyphs/elements/closed-lock";
import useApiRequest from "../../../hooks/useApiRequest";
import isNewSCApiUser from "../../is-new-sc-api-user";

const propTypes = {
  selected: number,
  subIndex: oneOfType([string, array])
};

const data = (companyAdmin, loggedInUser) => {
  const navigationOptions = [
    {
      label: (
        <>
          <Primitives.Text mr={1}>Product Report</Primitives.Text>
          <Primitives.Flex mt="3px">{<Caret height="8px" />}</Primitives.Flex>
        </>
      ),
      options: [
        // {
        //   label: "MTD Sales Report",
        //   link: "/company/product_report/mtd"
        // },
        {
          label: "Stock & Sales Report",
          link: "/company/product_report/ssr"
        },
        {
          label: "Internal Stock Transfer Report",
          link: "/company"
        }
      ],
      type: "link"
    },
    {
      label: (
        <>
          <Primitives.Text mr={1}>Secondary Sales Report</Primitives.Text>
          <Primitives.Flex mt="3px">{<Caret height="8px" />}</Primitives.Flex>
        </>
      ),
      options: [
        {
          label: "Hospital Sales",
          link: "/company/hospital_sales"
        },
        {
          label: "Sales Invoices",
          link: "/company/orders_report/index"
        },
        {
          label: "Rate Contract",
          glyph: <ClosedLock />,
          cursor: "not-allowed"
        }
      ],
      type: "link"
    },
    {
      label: "Inventory Analysis",
      link: "/company/inventory_analysis",
      type: "link"
    },
    // {
    //   label: "Key Account Manager Report",
    //   link: "/company/company_members",
    //   type: "link"
    // },
    {
      label: "Customer Performance",
      link: "/company/insights",
      type: "link"
    },
    {
      label: "Product Performance",
      link: "/company/product_performance",
      type: "link"
    },
    companyAdmin
      ? {
          label: "Wallet Share Analytics",
          link: "/company/wallet_share",
          type: "link"
        }
      : {}
  ];
  loggedInUser &&
    loggedInUser.user &&
    !loggedInUser.user.istr_report_access &&
    navigationOptions[0].options.pop();

  return navigationOptions;
};

const profile = [
  {
    text: "Logout"
  }
];
const DesktopNavigation = ({ selected, subIndex }) => {
  const { host, loadUser, loggedInUser } = useContext(AppContext);
  const { data: updatedUser } = useApiRequest(
    host,
    `${host}/${isNewSCApiUser() ? "auth" : "smartchain"}/is_logged_in`
  );
  const [comapanyAdmin, setCompanyAdmin] = useState(true);
  const [loading, setLoading] = useState(false);
  const product = "SmartChain";
  const root = "/company/product_report/ssr";
  const history = useHistory();

  const userlogout = async () => {
    setLoading(true);
    const response = await logoutUser(host);
    if (response.success) {
      setLoading(false);
      loadUser({});
      history.push("/");
    }
  };

  const selectHandler = option => {
    if (option.text === "Logout") {
      userlogout();
    }
  };

  useEffect(() => {
    isCompanyAdmin(loggedInUser)
      ? setCompanyAdmin(true)
      : setCompanyAdmin(false);
  }, [loggedInUser]);

  useEffect(() => {
    const userEmail =
      loggedInUser && loggedInUser.user && loggedInUser.user.email;
    const updatedUserEmail =
      updatedUser && updatedUser.user && updatedUser.user.email;
    if (updatedUserEmail && userEmail !== updatedUserEmail) {
      loadUser(updatedUser);
    }
  }, [updatedUser]);

  return (
    <NavigationContainer
      data={data(comapanyAdmin, loggedInUser)}
      id={subIndex}
      loading={loading}
      product={product}
      productLogo={<Trademark />}
      profileOptions={profile}
      root={root}
      selected={selected}
      selectHandler={selectHandler}
      user={loggedInUser}
    />
  );
};
DesktopNavigation.propTypes = propTypes;
export default DesktopNavigation;
