import getMonthName from "../../components/utils/get-month-name";
import { colors } from "../../components/utils/theme";

const MonthRange = (startDate, endDate) => {
  let start = startDate.split("-");
  let end = endDate.split("-");
  let dates = [];
  let startYearMonth = start[1];
  let endYearMonth = end[1];
  const startYear = start[0],
    endYear = end[0];
  if (startYear === endYear) {
    if (endYearMonth > startYearMonth) {
      for (let i = endYearMonth; i >= startYearMonth; i--) {
        let month = getMonthName(i) + "-" + endYear;
        dates.push(month);
      }
    }
  } else {
    for (let i = endYearMonth; i >= 0; i--) {
      let month = getMonthName(i) + "-" + endYear;
      dates.push(month);
    }
    for (let year = endYear - 1; year > startYear; year--) {
      for (let j = 11; j >= 0; j--) {
        let month = getMonthName(j) + "-" + year;
        dates[dates.length] = month;
      }
    }
    for (let j = 11; j >= startYearMonth; j--) {
      let month = getMonthName(j) + "-" + start[0];
      dates[dates.length] = month;
    }
  }
  return dates;
};

const date = new Date();

const Months = (
  startDate = date.getFullYear() - 1 + "-" + date.getMonth(),
  endDate = date.getFullYear() + "-" + date.getMonth()
) =>
  MonthRange(startDate, endDate).map(item => ({
    text: item,
    color: colors.text[0]
  }));

export default Months;
