import React, { useEffect, useState } from "react";
import Primitives from "../../../components/primitives";
import Footer from "../footer";
import BrandImage from "../brand-image";
import LoginHeader from "../login-header";
import PasswordLogin from "./password-login";
/**commented new sc code temporarily */
// import OtpLogin from "./otp-login";
import ForgotPassword from "./forgot-password";
import { useIntercom } from "react-use-intercom";

const screens = {
  password: PasswordLogin,
  /**commented new sc code temporarily */
  // otp: OtpLogin,
  forgot_password: ForgotPassword
};

const Login = () => {
  const [email, setEmail] = useState("");
  const [screen, updateScreen] = useState("password");
  const [alertData, setAlertData] = useState({ details: "" });
  const [isOtpSent, setIsOtpSent] = useState(false);
  const Component = screens[screen];

  const { shutdown } = useIntercom();
  useEffect(() => {
    shutdown();
  }, []);

  return (
    <Primitives.Flex flexDirection="column" mx={4} my={[4, 1]}>
      <BrandImage />
      <Primitives.Flex flexDirection="column" mr="auto" ml="auto">
        <LoginHeader alertData={alertData} screen={screen} />
        <Component
          updateScreen={updateScreen}
          setAlertData={setAlertData}
          setIsOtpSent={setIsOtpSent}
          isOtpSent={isOtpSent}
          email={email}
          setEmail={setEmail}
        />
        <Footer />
      </Primitives.Flex>
    </Primitives.Flex>
  );
};
export default Login;
