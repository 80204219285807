import React, { useState, useContext } from "react";
import { BASE_URL } from "../../../services/config";
import {
  /**commented new sc code temporarily */
  // checkUserAccessToNewApi,
  doPasswordLogin
  /**commented new sc code temporarily */
  // sendOtp
} from "../../../services/login-user";
import { AppContext } from "../../../utils/context";
import isLoggedInObj from "../../../services/user-logged-in";
import sendUsersDataToGoogleAnalytics from "../../../lib/send-userDetails-to-ga";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import Button from "../button";
import { func, string } from "prop-types";

const propTypes = {
  /** for switching between screens */
  updateScreen: func,
  /** for showing alert messages */
  setAlertData: func,
  /** update status of sending otp */
  setIsOtpSent: func,
  /** email */
  email: string,
  /** for changing email */
  setEmail: func
};

const PasswordLogin = ({
  updateScreen,
  setAlertData,
  /**commented new sc code temporarily */
  // setIsOtpSent,
  email,
  setEmail
}) => {
  const [password, setPassword] = useState("");
  const [loading, toggleLoading] = useState(false);
  const { updateHost, loadUser } = useContext(AppContext);

  /** redirect new API users to OTP login page */
  /**commented new sc code temporarily */
  // const redirectToOtpScreen = async () => {
  //   const { success, detail } = await sendOtp(email, BASE_URL_V2);
  //   if (success) {
  //     setIsOtpSent(true);
  //     updateScreen("otp");
  //   }
  //   setAlertData({ success, detail });
  // };

  /** login handler */
  const handleSubmit = async event => {
    event.preventDefault();
    toggleLoading(true);
    try {
      /**commented new sc code temporarily */
      // const { haveNewSCAccess } = await checkUserAccessToNewApi(
      //   email,
      //   BASE_URL_V2
      // );
      // /** user having new API access will login via otp  */
      // if (haveNewSCAccess) return await redirectToOtpScreen();

      /** password login start here */
      const response = await doPasswordLogin(email, password, BASE_URL);
      const result = await response.json();
      result?.success
        ? await postLoginHandler(result.token)
        : setAlertData({
            detail: result?.errors?.[0]?.detail ?? "Something went wrong",
            success: false
          });
    } catch (err) {
      setAlertData({ detail: err.message, success: false });
    }
    toggleLoading(false);
  };

  /** contains logic that runs after successful login */
  const postLoginHandler = async token => {
    /**  stores token in local storage */
    localStorage.setItem("token", token);
    /** update host to use rails apis */
    updateHost(BASE_URL);
    /** send user details to google analytics */
    const isLoggedInResult = await isLoggedInObj.isLoggedIn();
    const user = isLoggedInResult.user;
    sendUsersDataToGoogleAnalytics(user);
    /** load user in context */
    loadUser(isLoggedInResult);
  };

  /** common button props */
  const commonButtonProps = {
    color: colors.primary[3],
    fontSize: 2,
    fontFamily: "lato",
    hover: {
      backgroundColor: "white",
      color: colors.primary[6]
    },
    cursor: "pointer",
    disabled: loading
  };
  /** common input props */
  const commonInputProps = {
    disabled: loading,
    border: 1,
    borderColor: colors.text[2],
    borderRadius: 4,
    borderStyle: "solid",
    height: 33,
    pl: 10
  };
  return (
    <>
      <form style={{ display: "flex", flexDirection: "column" }}>
        <Primitives.Input
          {...commonInputProps}
          my={2}
          onChange={e => setEmail(e.target.value.trim())}
          placeholder="Email"
          value={email}
          type="email"
          autoComplete="email"
          name="email"
          autoFocus
        />
        <Primitives.Input
          {...commonInputProps}
          mb={2}
          onChange={e => setPassword(e.target.value)}
          placeholder="Password"
          type="password"
          name="password"
          autoComplete="current-password"
          value={password}
        />
        <Button handleSubmit={handleSubmit} isLoaded={loading}>
          Login
        </Button>
      </form>
      <Primitives.Flex justifyContent="space-around" mt={2}>
        {/**commented new sc code temporarily */}
        {/* <Primitives.Button
          {...commonButtonProps}
          onClick={() => updateScreen("otp")}
        >
          Login with OTP
        </Primitives.Button>
        {"|"} */}
        <Primitives.Button
          {...commonButtonProps}
          onClick={() => updateScreen("forgot_password")}
        >
          Forgot Password ?
        </Primitives.Button>
      </Primitives.Flex>
    </>
  );
};

PasswordLogin.propTypes = propTypes;
export default PasswordLogin;
