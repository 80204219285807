import React from "react";
import Primitives from "../../../components/primitives";
import CustomersAndTheirCentres from "./cutomers-and-their-centres";
import Reports from "../reports";
import StateWiseHospitals from "./state-wise-hospitals/index";
import ClassificationWiseHospitals from "./classification-wise-hospitals";
import { useParams } from "react-router-dom";

const HospitalsStatistics = () => {
  const filteredMonth = useParams().id;
  return (
    <Reports
      components={
        <Primitives.Flex flexWrap="wrap" flex={1}>
          <StateWiseHospitals filteredMonth={filteredMonth} />
          <ClassificationWiseHospitals filteredMonth={filteredMonth} />
          <CustomersAndTheirCentres filteredMonth={filteredMonth} />
        </Primitives.Flex>
      }
      isbackButtonRequired={true}
      selected={5}
      title="Potential Hospitals"
      reportTitle="Potential Hospitals"
    />
  );
};

export default HospitalsStatistics;
