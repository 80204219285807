import React, { useContext } from "react";
import Primitives from "../../../../components/primitives";
import Card from "../../../../lib/card";
import { string } from "prop-types";
import ForwardAngular from "../../../../components/glyphs/elements/forwardAngular";
import Table from "../../../../components/table-two";
import { AppContext } from "../../../../utils/context";
import useApiRequest from "../../../../hooks/useApiRequest";
import { colors } from "../../../../components/utils/theme";
import Loading from "../../../../components/loading";
import { useHistory } from "react-router-dom";

const propTypes = {
  filteredMonth: string
};

const column = [
  {
    heading: "S.R.N0",
    type: "number",
    width: 1
  },
  {
    heading: "HOSPITAL GROUPS",
    type: "string",
    width: 3
  },
  {
    align: "flex-end",
    heading: "CENTRES NOT PURCHASING",
    type: "number",
    width: 2
  },
  {
    align: "flex-end",
    heading: "TOTAL CENTRES",
    type: "number",
    width: 2
  },
  {
    align: "flex-end",
    heading: " ",
    type: "string",
    width: 1
  }
];

const modifyData = data =>
  data &&
  data.map((item, index) => ({
    srNo: index + 1,
    hospitalGroups: item["name"],
    centresNotPurchasing: item["not_purchasing_centers"].length,
    totalCentres:
      item["purchasing_centers"].length + item["not_purchasing_centers"].length,
    angular: <ForwardAngular fill={colors.accent[3]} />
  }));

const CustomersAndTheirCentres = ({ filteredMonth }) => {
  const history = useHistory();
  const { host, loggedInUser } = useContext(AppContext);
  const request = `${host}/smartchain/wallet_analytics/hospitals_groups_with_their_centers?quick_filter=${filteredMonth}`;
  const { data, isLoaded } = useApiRequest(host, encodeURI(request));

  const IndividualCentersClickHandler = (row, index) => {
    history.push(
      `/company/individual_centers/hospital_group_id=${data[index]["id"]}&quick_filter=${filteredMonth}`
    );
  };

  return (
    <Card
      ctaText="View All Hospitals Groups"
      details={
        <Primitives.Flex mt={2} overflowX="scroll" width={1} minHeight="23vh">
          {isLoaded ? (
            <Primitives.Box minWidth={[500, "unset"]} width={1}>
              <Table
                clickHandler={IndividualCentersClickHandler}
                column={column}
                cursor="pointer"
                data={modifyData(data)}
                fontSize={{ head: 1, row: 1 }}
                isResponsive={true}
                responsiveWidth={["750px", "unset"]}
                displayLockIcon={["block", "none"]}
              />
            </Primitives.Box>
          ) : (
            <Primitives.Flex m="auto">
              <Loading onlySpinner />
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      }
      isCta={true}
      isDownloadable={false}
      link="/company/customers_with_centers"
      title={
        <Primitives.Text fontSize={[2, 3]} fontWeight={[1, 2]} mr={1}>
          {loggedInUser && loggedInUser.user && loggedInUser.user.company_name}{" "}
          customers and their centres
        </Primitives.Text>
      }
    />
  );
};

CustomersAndTheirCentres.propTypes = propTypes;
export default CustomersAndTheirCentres;
