import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Primitives from "../../components/primitives";
import validateEmail from "../../components/utils/validate-email";
import validatePhoneNumber from "../../components/utils/validate-phone-number";
import logoutUser from "../../lib/logout-user";
import { AppContext } from "../../utils/context";
import GenericReport from "./generic-report";

const inputFields = {
  first_name: "First Name",
  last_name: "Last Name",
  phone: "Mobile Number",
  email: "Enter Professional Email ID",
  company_name: "Company Name",
  designation: "Designation",
  location: "Location"
};

const SelfOnboarding = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [alertData, setAlertData] = useState("");
  const { host } = useContext(AppContext);

  const formValidation = e => {
    e.preventDefault();
    setLoading(true);
    if (!validateEmail(userData["email"])) {
      setLoading(false);
      setAlertData("Please enter correct email address");
      return;
    } else if (!validatePhoneNumber(userData["phone"])) {
      setLoading(false);
      setAlertData("Please enter valid Phone number");
      return;
    } else {
      submitHandler(e);
    }
  };

  const submitHandler = event => {
    event.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      user: userData,
      account: "smartchain"
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${host}/api/auth/sign_up`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          logoutUser(host);
        }
        return response.json();
      })
      .then(result => {
        if (result.success) {
          setLoading(false);
          localStorage.setItem("password_token", result.password_token);
          localStorage.setItem("email", result.email);
          history.push({
            pathname: "/create_password"
          });
        } else {
          setLoading(false);
          setAlertData(result.message);
        }
      })
      .catch(error => console.log("error", error));
  };

  return (
    <GenericReport
      alertData={alertData}
      heading={
        <Primitives.Text>
          Improve Healthcare <br /> Outcomes
        </Primitives.Text>
      }
      title="Create account to experience"
      buttonText="Signup"
      inputFields={inputFields}
      loading={loading}
      formValidation={formValidation}
      userData={userData}
      setUserData={setUserData}
    />
  );
};

export default SelfOnboarding;
