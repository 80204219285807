import { BASE_URL, REQ_OPT, getHeaders } from "../config";

export default {
  otpRequest: async () => {
    var requestOptions = {
      headers: getHeaders(),
      ...REQ_OPT
    };
    try {
      const response = await fetch(
        `${BASE_URL}/smartchain/send_email_otp`,
        requestOptions
      );
      const json = await response.json();
      if (json.success) {
        return;
      }
    } catch (error) {
      console.log(error);
    }
  },
  verifyOtp: async otp => {
    var requestOptions = {
      headers: getHeaders(),
      ...REQ_OPT
    };
    try {
      const response = await fetch(
        `${BASE_URL}/smartchain/verify_email_otp?otp=${otp}`,
        requestOptions
      );
      const json = await response.json();
      return json;
    } catch (error) {
      console.log(error);
    }
  }
};
