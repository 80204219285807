import React from "react";
import Checkbox from "../../../components/checkbox";
import Primitives from "../../../components/primitives";
import { bool, func, string } from "prop-types";

/** proptype of component */
const propTypes = {
  /** controls tick-mark */
  isChecked: bool,
  /** handles click event on checkbox */
  clickHandler: func,
  /** title of checkbox */
  title: string
};
/** default props of component */
const defaultProps = {
  title: "Select Checkbox"
};

/** component */
const CheckBoxContainer = ({ isChecked, clickHandler, title }) => (
  <Primitives.Flex alignItems="center">
    <Checkbox checked={isChecked} clickHandler={clickHandler} />
    <Primitives.Text fontSize={1} ml={1}>
      {title}
    </Primitives.Text>
  </Primitives.Flex>
);
CheckBoxContainer.propTypes = propTypes;
CheckBoxContainer.defaultProps = defaultProps;
export default CheckBoxContainer;
