import React from "react";
import { string, object, oneOfType } from "prop-types";
import Cta from "../../components/cta";
import Button from "../../components/button";
import { colors } from "../../components/utils/theme";
const propTypes = {
  link: oneOfType([string, object])
};

const ViewDetailsBtn = ({ link }) => (
  <Cta
    hover={{ textdecorationline: "none" }}
    label={
      <Button
        active={{
          backgroundColor: colors.primary[3],
          color: "white"
        }}
        backgroundColor={colors.primary[3]}
        borderRadius={4}
        color={"white"}
        fontWeight={2}
        fontSize={0}
        hover={{
          backgroundColor: colors.primary[3],
          color: "white"
        }}
        label="View Details"
        lineHeight="12px"
        px={10}
        py="6px"
      />
    }
    textdecorationline={false}
    type="link"
    link={link}
  />
);
ViewDetailsBtn.propTypes = propTypes;
export default ViewDetailsBtn;
