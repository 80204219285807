import React, { createContext, useEffect, useState } from "react";
import { node } from "prop-types";
import useApiRequest from "../hooks/useApiRequest";
import isNewSCApiUser from "../lib/is-new-sc-api-user";
import {
  Appcues_User_Onboarding,
  BASE_URL
  // BASE_URL_V2 /**commented new sc code temporarily */
} from "../services/config";

const propTypes = {
  children: node
};

export const AppContext = createContext("");

const AppContextProvider = ({ children }) => {
  /**commented new sc code temporarily */
  // const [host, updateHost] = useState(
  //   isNewSCApiUser() ? BASE_URL_V2 : BASE_URL
  // );
  const [host, updateHost] = useState(BASE_URL);
  const [branches, updateBranch] = useState([]);
  const [hospitals, updateHospitals] = useState([]);
  const [groups, updateGroups] = useState([]);
  const [divisions, updateDivisions] = useState([]);
  const [cities, updateCities] = useState([]);
  const [masterskus, updateMasterskus] = useState([]);
  const [tenants, updateTenants] = useState([]);
  const [states, updateStates] = useState([]);
  const [category, updateCategory] = useState([]);
  const [currentUser, updateUser] = useState({});

  /* load dropdown data */
  const loadData = () => {
    const loadFn = async (id, updateState) => {
      const response = await fetch(`${host}/smartchain/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const json = await response.json();
      updateState(json);
    };
    loadFn("branches", updateBranch);
    loadFn("hospitals", updateHospitals);
    loadFn("hospital_groups", updateGroups);
    loadFn("divisions", updateDivisions);
    loadFn("cities", updateCities);
    loadFn("masterskus", updateMasterskus);
    loadFn("tenants", updateTenants);
    loadFn("states", updateStates);
    loadFn("hospital_classifications", updateCategory);
  };

  /* handle login and logout */
  const { data: user } = useApiRequest(
    host,
    `${host}/${isNewSCApiUser() ? "auth" : "smartchain"}/is_logged_in`
  );

  const loadUser = data => {
    updateUser(data);
  };

  useEffect(() => {
    updateUser(user);
    const userObj = user?.user;
    if (userObj && Appcues_User_Onboarding) {
      window.Appcues.identify(userObj.id, {
        ...(userObj ?? {})
      });
    }
  }, [user]);

  /** load data when host or user is updated */
  useEffect(() => loadData(), [host, currentUser]);
  return (
    <AppContext.Provider
      value={{
        host: host,
        updateHost,
        loadUser: loadUser,
        loggedInUser: currentUser,
        branches: branches,
        hospitals: hospitals,
        groups: groups,
        divisions: divisions,
        masterskus: masterskus,
        cities: cities,
        tenants: tenants,
        states: states,
        category: category
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
AppContextProvider.propTypes = propTypes;

export default AppContextProvider;
