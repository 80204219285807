import React, { useEffect, useState } from "react";
import { bool, func, number, string } from "prop-types";
import Input from "../../../components/input";
import Primitives from "../../../components/primitives";

const propTypes = {
  clearFilter: bool,
  config: string,
  getClickEvent: func,
  getConfig: func,
  getSearchValue: func,
  margin: bool,
  throttle: bool,
  width: string,
  ml: number,
  heading: string
};

const defaultProps = {
  margin: true,
  ml: 20
};

const GenericSearch = ({
  clearFilter,
  config,
  getClickEvent,
  getConfig,
  getSearchValue,
  ml,
  heading
}) => {
  const [search, setSearch] = useState("");

  useEffect(() => {
    getSearchValue(search);
    getConfig(config);
  }, [search]);

  useEffect(() => {
    !clearFilter && setSearch("");
  }, [clearFilter]);

  const placeHolderObject = {
    skuSearch: "Search by Master SKU",
    productSearch: "Search by Product Name",
    groupSearch: "Search by Group Name",
    hospitalSearch: "Search by Hospital Name",
    stateSearch: "Search by State Name",
    citySearch: "Search by City Name"
  };

  return (
    <Primitives.Flex ml={ml} flexDirection="column">
      {heading && (
        <Primitives.Text color="#333333" fontSize="12px" mb={1}>
          {heading}
        </Primitives.Text>
      )}

      <Input
        active={search.length > 0}
        changeHandler={e => setSearch(e.target.value)}
        clickHandler={() => getClickEvent()}
        glyph="search"
        glyphPaddingX={["5px", "11px"]}
        glyphPaddingY={["6px", "11px"]}
        lineHeight={1}
        placeholder={placeHolderObject[config]}
        type="text"
        value={search}
      />
    </Primitives.Flex>
  );
};

GenericSearch.propTypes = propTypes;
GenericSearch.defaultProps = defaultProps;
export default GenericSearch;
