import "regenerator-runtime/runtime";
import isNewSCApiUser from "../is-new-sc-api-user";

const logoutUser = async host => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
  myHeaders.append(
    "Cookie",
    "_aknamed_admin_session=KNT91jPIl2iY0q52%2BbgDAdmTo9SEP3CP92q3Sh3iM1r7A%2F6VcESDgdk5IFBfSbjuzg%3D%3D--kK9wtn8Cf%2FFV0BVy--RLvNl2gaR7OL9A6dvntJ9g%3D%3D"
  );
  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow"
  };
  try {
    const response = await fetch(
      `${host}/${isNewSCApiUser() ? "auth" : "api"}/logout`,
      requestOptions
    );
    const json = await response.json();
    if (json.success) {
      localStorage.removeItem("token");
      localStorage.removeItem("isNewSCApiUser");
    }
    return json;
  } catch (error) {
    console.log(error);
  }
};

export default logoutUser;
