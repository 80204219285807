import React, { useContext, useEffect, useState } from "react";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import Hamburger from "../../../components/glyphs/elements/hamburger";
import Back from "../../../components/glyphs/elements/back";
import Envelope from "../../../components/glyphs/elements/envelope";
import Modal from "../../../components/modal";
import DownloadBtn from "../../../lib/download";
import {
  array,
  func,
  node,
  number,
  object,
  string,
  bool,
  oneOfType
} from "prop-types";
import Loading from "../../../components/loading";
import { useHistory } from "react-router-dom";
import contentType from "../../../lib/download-options";
import { AppContext } from "../../../utils/context";
import Button from "../../../components/button";
import CircleChecked from "../../../components/glyphs/elements/circle-checked";
import PaymentSummary from "../../../components/payment-summary";
import isDemoAccount from "../../../lib/is-demo-account";
import selfOnboarding from "../../../services/self-onboarding";
import user from "../../../services/user-logged-in";
import downloadHandlerFn from "../../../components/utils/download-handler";
import Filters from "../../../lib/filters";
import { FilterContext } from "../../hospital-sales-report/filterContext";

const propTypes = {
  downloadApi: string,
  dataCountText: oneOfType([string, node]),
  downloadHandler: func,
  details: node,
  email: string,
  filterConfig: object,
  filterUpdate: func,
  filters: array,
  filtersList: object,
  allFilters: object,
  isLoaded: bool,
  isDownloadable: bool,
  isbackButtonRequired: bool,
  navbarDisplayHandler: func,
  salesStatistics: array,
  selected: number,
  showHamburger: bool,
  title: node,
  reportTitle: string
};
const defaultProps = {
  showHamburger: true
};
const Header = ({
  details,
  dataCountText,
  downloadApi,
  email,
  filterConfig,
  filterUpdate,
  isDownloadable,
  isbackButtonRequired,
  navbarDisplayHandler,
  salesStatistics,
  selected,
  showHamburger,
  title,
  reportTitle,
  ...props
}) => {
  const { loadUser, loggedInUser } = useContext(AppContext);
  let history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [emailLoader, updateEmailLoader] = useState(false);
  const [requestAccessLoader, setRequestAccessLoader] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isAccessGranted, setIsAccessGranted] = useState(false);
  const filterContext = useContext(FilterContext);

  /** sending Email and showing Modal with Loader */
  const handleEmailAndModal = async () => {
    setShowModal(!showModal);
    const url = `${downloadApi}&is_email=true`;
    try {
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
        .then(respone => respone.json())
        .then(data => {
          data && updateEmailLoader(true);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const downloadHandler = async option => {
    const downloadRequest = contentType[option.index]; //xlxs pdf csv
    setIsDownload(true);
    downloadHandlerFn(
      `${downloadApi.replaceAll(
        "downloadOptions",
        downloadRequest
      )}&is_download=true`,
      setIsDownload,
      downloadRequest
    );
  };

  /** conditionally rendering button only when the email is sent */
  const buttonProps = {
    ...(emailLoader && {
      buttons: [
        {
          active: {
            backgroundColor: colors.text[2],
            color: colors.text[0]
          },
          backgroundColor: colors.primary[3],
          color: colors.white,
          hover: {
            backgroundColor: colors.text[2],
            color: colors.text[0]
          },
          label: "Done",
          clickHandler: () => {
            setShowModal(!showModal);
          }
        }
      ]
    })
  };

  const displayDownloadButton = () => {
    if (isDemoAccount(loggedInUser) && selected === 1) {
      return;
    } else if (isDownloadable) {
      if (isDownload) {
        return (
          <Primitives.Flex display={["none", "flex"]} mr={5}>
            <Loading onlySpinner />
          </Primitives.Flex>
        );
      } else {
        return (
          <Primitives.Flex display={["none", "flex"]} height={37}>
            <DownloadBtn label="Download" selectHandler={downloadHandler} />
          </Primitives.Flex>
        );
      }
    }
  };

  const displayMailButton = () => {
    if (isDemoAccount(loggedInUser) && selected === 1) {
      return;
    } else if (isDownloadable) {
      return (
        <Primitives.Button
          flexDirection="row"
          cursor="pointer"
          alignItems="center"
          px={0}
          display={["flex", "none"]}
          onClick={handleEmailAndModal}
        >
          <Primitives.Flex mr="4px">
            <Envelope fill={colors.primary[3]} height={12} width={17.07} />
          </Primitives.Flex>
          <Primitives.Text fontSize={1} color={colors.primary[3]}>
            Mail Report
          </Primitives.Text>
        </Primitives.Button>
      );
    }
  };

  const accessRequestHandler = async () => {
    setRequestAccessLoader(true);
    const res = await selfOnboarding.accessRequest();
    if (res && res.success) {
      const isUserLoggedIn = await user.isLoggedIn();
      if (isUserLoggedIn["logged_in"] && isUserLoggedIn["logged_in"]) {
        await loadUser(isUserLoggedIn);
        setIsAccessGranted(true);
        setRequestAccessLoader(false);
      }
    }
  };

  useEffect(() => {
    setIsAccessGranted(
      loggedInUser &&
        loggedInUser["user"] &&
        loggedInUser["user"]["access_requested"] &&
        loggedInUser["user"]["access_requested"]
    );
  }, [loggedInUser]);

  return (
    <>
      <Primitives.Flex
        flexDirection="column"
        backgroundColor={details && "ghost-white"}
        zIndex={3}
      >
        <Primitives.Flex
          pl={["unset", 2]}
          pr={[1, 2]}
          py={[1, "unset"]}
          alignItems={["center", "unset"]}
        >
          <Primitives.Flex m={1} display={["flex", "flex", "none"]}>
            {showHamburger && (
              <Hamburger
                color={colors.text[0]}
                cursor="pointer"
                height={19.65}
                width={20}
                onClick={() => {
                  navbarDisplayHandler();
                }}
              />
            )}
          </Primitives.Flex>
          {(isbackButtonRequired || details) && (
            <Primitives.Flex mx={["unset", 1]} alignItems="flex-end" m="auto">
              <Back
                fill="black"
                onClick={() => {
                  filterContext && filterContext.updateFilterState("");
                  history.goBack();
                }}
                cursor="pointer"
              />
            </Primitives.Flex>
          )}
          <Primitives.Flex flexDirection="column" flex={2} my={[0, 1]} ml="5px">
            {/* display below flex conditionally */}
            <Primitives.Flex
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Primitives.Flex flexDirection="column">
                <Primitives.Flex display={["none", "flex"]}>
                  {isDemoAccount(loggedInUser) &&
                    selected === 1 &&
                    salesStatistics && (
                      <Primitives.Flex my={1}>
                        <PaymentSummary
                          alignItems="center"
                          backgroundColor={"rgba(122, 138, 156, 0.09)"}
                          borderRadius={8}
                          data={salesStatistics}
                          headerFontSize={2}
                          headerFontWeight={1}
                          headerPadding="2px"
                          mr={2}
                          titleFontSize={3}
                          titleFontWeight={2}
                          titleWidth={120}
                          pt={10}
                          pb={10}
                          pl={2}
                        />
                      </Primitives.Flex>
                    )}
                </Primitives.Flex>
                {isDemoAccount(loggedInUser) && selected !== 1 && (
                  <Primitives.Flex
                    width={338}
                    height={28}
                    border={`1px solid ${colors.text[1]}`}
                    borderRadius={8}
                    display={["none", "none", "flex"]}
                    mb={2}
                    fontSize={2}
                    alignItems="center"
                    flexWrap="wrap"
                    justifyContent="center"
                    px={1}
                    color={colors.text[1]}
                    fontWeight={1}
                  >
                    <Primitives.Text mr={1} fontWeight={2}>
                      Disclaimer:{" "}
                    </Primitives.Text>{" "}
                    Data used here is for sample purpose only.
                  </Primitives.Flex>
                )}
                {title}
              </Primitives.Flex>

              <Primitives.Flex>
                {isDemoAccount(loggedInUser) && (
                  <Primitives.Flex
                    backgroundColor="rgb(250, 200, 182)"
                    borderRadius={5}
                    borderLeft={`5px solid ${colors.primary[3]}`}
                    color="black"
                    display={["none", "none", "flex"]}
                    fontSize={2}
                    fontWeight={!isAccessGranted ? 1 : 2}
                    flexDirection="column"
                    lineHeight={3}
                    p="5px"
                    mt={1}
                  >
                    {!isAccessGranted ? (
                      <Primitives.Box width={260} height={80} ml={1}>
                        <Primitives.Flex ml={1}>
                          Want to view your product data here? <br />
                          Reach out to us by clicking on Request
                        </Primitives.Flex>
                        <Primitives.Flex
                          width={114}
                          height={32}
                          ml={1}
                          mt="3px"
                        >
                          {requestAccessLoader ? (
                            <Loading onlySpinner />
                          ) : (
                            <Button
                              active={{
                                backgroundColor: colors.primary[6],
                                color: "white"
                              }}
                              alignItems="center"
                              backgroundColor={colors.primary[3]}
                              color="white"
                              fontWeight={2}
                              hover={{
                                backgroundColor: colors.primary[6],
                                color: "white"
                              }}
                              label="Request Access"
                              lineHeight={4}
                              clickHandler={() => {
                                accessRequestHandler();
                              }}
                              width={430}
                              justifyContent="center"
                            />
                          )}
                        </Primitives.Flex>
                      </Primitives.Box>
                    ) : (
                      <Primitives.Flex
                        alignItems="center"
                        width={354}
                        height={64}
                      >
                        <Primitives.Flex mx={2}>
                          <CircleChecked width={37.5} height={37.5} />
                        </Primitives.Flex>
                        <Primitives.Text>
                          Thank You! We have received your request. You will
                          have access to your company data within 24 hours
                        </Primitives.Text>
                      </Primitives.Flex>
                    )}
                  </Primitives.Flex>
                )}
              </Primitives.Flex>
            </Primitives.Flex>
          </Primitives.Flex>
          {/* display below flex conditionally */}
          {displayMailButton()}
        </Primitives.Flex>
        {details}
        {filterConfig && Object.keys(filterConfig).length !== 0 && (
          <Primitives.Flex
            alignItems="center"
            backgroundColor={
              Object.keys(filterConfig).length > 0 ? "background-blue" : "white"
            }
            height={48}
            justifyContent="space-between"
            px={[1, 3]}
            py={[0, 1]}
          >
            {!Object.keys(filterConfig).length > 0 && dataCountText}
            <Primitives.Flex display={["flex", "none"]} width={1}>
              {/* Filters for Mobile */}
              <Filters
                config={filterConfig}
                filterUpdate={filterUpdate}
                {...props}
              />
            </Primitives.Flex>
            <Primitives.Flex
              display={["none", "flex"]}
              flex={1}
              height={[28, 36]}
              mr={[1, 2]}
              width={1}
            >
              {/* Filtes for Desktop */}
              <Filters
                config={filterConfig}
                filterUpdate={filterUpdate}
                {...props}
              />
              {displayDownloadButton()}
            </Primitives.Flex>
          </Primitives.Flex>
        )}
      </Primitives.Flex>
      <Modal
        {...buttonProps}
        haveCloseButton={false}
        border={`1px solid ${colors.text[3]}`}
        borderRadius={8}
        closeHandler={() => setShowModal(!showModal)}
        content={
          <>
            {emailLoader ? (
              <Primitives.Text fontSize={[1, 2]} lineHeight={3}>
                <Primitives.Text fontWeight={700}>
                  {reportTitle}
                </Primitives.Text>{" "}
                report has been sent to your registered email : {email}
              </Primitives.Text>
            ) : (
              <Primitives.Flex height={80} justifyContent="center" width={1}>
                <Loading onlySpinner={true} />
              </Primitives.Flex>
            )}
          </>
        }
        open={showModal}
        width={276}
        pt={20}
        pb={15}
        px={20}
      />
    </>
  );
};
Header.propTypes = propTypes;
Header.defaultProps = defaultProps;
export default Header;
