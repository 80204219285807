import React, { useContext, useState } from "react";
import { colors } from "../../components/utils/theme";
import LoginImage from "../../components/glyphs/elements/login-image";
import Primitives from "../../components/primitives";
import Swipe from "../../components/glyphs/elements/swipe";
import DoubleTap from "../../components/glyphs/elements/double-tap";
import { func } from "prop-types";
import { AppContext } from "../../utils/context";

const propTypes = {
  backDropHandler: func
};

const Onboarding = ({ backDropHandler }) => {
  const [onboard, setOnboard] = useState(1);
  const { host } = useContext(AppContext);

  const closeOnboarding = async () => {
    const url = `${host}/smartchain/onboard?onboard=true`;
    setOnboard(4);
    backDropHandler(false);
    try {
      await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onBoarding = (title, glyph, button, bottomSize) => (
    <Primitives.Flex
      backgroundColor="white"
      borderRadius="8px 8px 0px 0px"
      flexDirection="column"
      key={1}
      onClick={e => e.stopPropagation()}
      pb={bottomSize}
      position="relative"
      pt={3}
      px={0}
      width="100vw"
    >
      <Primitives.Flex width={1} justifyContent="center">
        <Primitives.Flex flexDirection="column">
          <Primitives.Flex pl={3} pr={3} mb={3}>
            {glyph}
            <Primitives.Text
              fontSize={3}
              fontFamily="lato"
              fontWeight={2}
              color="#354062"
            >
              {title}
            </Primitives.Text>
          </Primitives.Flex>
          <Primitives.Flex position="fixed" bottom={[20]} right={[3, "45%"]}>
            {button}
          </Primitives.Flex>
        </Primitives.Flex>
      </Primitives.Flex>
    </Primitives.Flex>
  );

  const onboardMap = {
    1: [
      onBoarding(
        "",
        <Primitives.Flex flexDirection="column">
          <Primitives.Flex
            flexDirection="column"
            ml={4}
            mr={4}
            height="100vh"
            justifyContent={["center", "flex-start"]}
          >
            <Primitives.Flex marginLeft="auto" marginRight="auto" mt={5}>
              <Primitives.Flex display={["flex", "none"]}>
                <LoginImage></LoginImage>
              </Primitives.Flex>
              <Primitives.Flex display={["none", "flex"]}>
                <LoginImage width={400} height={275}></LoginImage>
              </Primitives.Flex>
            </Primitives.Flex>

            <Primitives.Flex mr="auto" ml="auto">
              <Primitives.Text
                fontSize={22}
                fontFamily="lato"
                mt={5}
                fontWeight={2}
                color="black"
              >
                Welcome to Smartchain!
              </Primitives.Text>
            </Primitives.Flex>

            <Primitives.Flex mr="auto" ml="auto">
              <Primitives.Text
                fontSize={3}
                fontFamily="lato"
                mt={2}
                color={colors.text[0]}
                textAlign="center"
              >
                We will walk you through the interaction features.
              </Primitives.Text>
            </Primitives.Flex>
          </Primitives.Flex>
        </Primitives.Flex>,

        <Primitives.Flex>
          <Primitives.Button
            paddingRight={4}
            paddingLeft={4}
            pt={10}
            pb={10}
            borderRadius={4}
            color={colors.primary[3]}
            fontFamily="lato"
            fontSize="13px"
            fontWeight={3}
            onClick={closeOnboarding}
          >
            Skip
          </Primitives.Button>
          <Primitives.Button
            onClick={() => {
              setOnboard(onboard + 1);
            }}
            background={colors.primary[3]}
            paddingRight={4}
            paddingLeft={4}
            borderRadius={4}
            color={colors.white}
            fontFamily="lato"
            fontSize="13px"
            fontWeight={3}
          >
            Next
          </Primitives.Button>
        </Primitives.Flex>,
        ""
      )
    ],
    2: [
      onBoarding(
        "Swipe from right to left to slide through the list",
        <Primitives.Flex mr={28}>
          <Swipe width={50} fill="inherit"></Swipe>
        </Primitives.Flex>,

        <Primitives.Button
          onClick={() => {
            setOnboard(onboard + 1);
          }}
          paddingRight={4}
          paddingLeft={4}
          pt={0}
          pb={10}
          borderRadius={4}
          color={colors.primary[3]}
          fontFamily="lato"
          fontSize="13px"
          fontWeight={3}
        >
          Next
        </Primitives.Button>,
        4
      )
    ],
    3: [
      onBoarding(
        "Double tap on name to get full detail",
        <Primitives.Flex mr={28}>
          <DoubleTap width={50} fill="inherit"></DoubleTap>
        </Primitives.Flex>,
        <Primitives.Button
          background={`${colors.primary[3]} !important`}
          onClick={closeOnboarding}
          paddingRight={4}
          paddingLeft={4}
          pt={10}
          pb={10}
          borderRadius={4}
          fontFamily="lato"
          fontSize="13px"
          fontWeight={3}
          color={`${colors.white} !important`}
        >
          Finish
        </Primitives.Button>,
        5
      )
    ],
    4: [onBoarding("", "", "", "")]
  };
  return onboardMap[onboard];
};
Onboarding.propTypes = propTypes;
export default Onboarding;
