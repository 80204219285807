import React, { useContext, useEffect, useState } from "react";
import Primitives from "../../../components/primitives";
import ConvertMoneyToLakhsCrores from "../../../components/utils/convert-money-to-lakh-crores";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Reports from "../../reports";
import DrillDownPageHeader from "../../../lib/drilldown-page-header";
import NewItems from "../../../components/new-items";
import ProgressIcon from "../../../components/progress-icon";
import { breakPoint } from "../../../components/utils/theme";
import DropdownView from "../../../lib/dropdown-view";
import Title from "../../../lib/title";
import toolTipData from "../../../lib/tooltip-text";
import convertToObj from "../../../components/utils/convert-to-object";
import headerValue from "../../../lib/header-value";
import checkSortingIndex from "../../../lib/check-sorting-index";
import roundOffTwoDecimal from "../../../components/utils/round-off-two-decimal";
import { FilterContext } from "../../hospital-sales-report/filterContext";

const dropdownData = [
  {
    text: "Centre Report",
    id: "centre",
    col: [1, 4, 1, 1, 1, 1, 1],
    filtersConfig: {
      hospitalSearch: true,
      Date: true,
      City: true,
      search: "hospitalSearch",
      mobileFilters: [
        { text: "Date", filterText: "Date" },
        { text: "City", filterText: "City" }
      ],
      defaultFilter: "Date"
    },
    toolTipText: convertToObj([3, 4, 5, 6], toolTipData)
  },
  {
    text: "Product Report",
    id: "product",
    col: [3, 1, 1, 1, 1, 1, 1],
    filtersConfig: {
      productSearch: true,
      Date: true,
      search: "productSearch",
      mobileFilters: [{ text: "Date", filterText: "Date" }],
      defaultFilter: "Date"
    },
    toolTipText: convertToObj([2, 3, 4, 5], toolTipData)
  }
];
const metricMapper = data => (data["metrics"] === "qty" ? "" : "₹ ");

const modifyReports = (data, api) => {
  if (data && data["items"]) {
    if (
      api.includes(
        "customer_performance" && "customer_performance_breakdown?type=product"
      )
    ) {
      return data["items"].map(item => ({
        name: item.name,
        centresPurchasing: item.centres_purchasing,
        last90DaysAvg:
          metricMapper(data) + `${roundOffTwoDecimal(item.avg_sales_90_days)}`,
        last30DaysAvg:
          metricMapper(data) + `${roundOffTwoDecimal(item.avg_sales_30_days)}`,
        last7DaysAvg:
          metricMapper(data) + `${roundOffTwoDecimal(item.avg_sales_7_days)}`,
        performance: (
          <Primitives.Flex>
            <ProgressIcon input1={item.performance} input2={0} />
            <Primitives.Text ml={1}>{item.performance + "%"}</Primitives.Text>
          </Primitives.Flex>
        )
      }));
    } else {
      return data["items"].map(item => ({
        city: item.city,
        centreName: (
          <Primitives.Flex>
            <Primitives.Text mr={1}>{item.name}</Primitives.Text>
            {item.new_hospital && <NewItems />}
          </Primitives.Flex>
        ),
        totalSKUs: item.uniq_products,
        last90DaysAvg:
          metricMapper(data) + `${Math.round(item.avg_sales_90_days)}`,
        last30DaysAvg:
          metricMapper(data) + `${Math.round(item.avg_sales_30_days)}`,
        last7DaysAvg:
          metricMapper(data) + `${Math.round(item.avg_sales_7_days)}`,
        performance: (
          <Primitives.Flex>
            <ProgressIcon input1={item.performance} input2={0} />
            <Primitives.Text ml={1}>{item.performance + "%"}</Primitives.Text>
          </Primitives.Flex>
        )
      }));
    }
  }
};

const headerData = value => ({
  "No. of Centres": headerValue(value, "total_centres"),
  "Purchasing Centres": headerValue(value, "purchasing_centres"),
  "Unique SKUs": headerValue(value, "uniq_products"),
  "90 Day Avg.Sales": headerValue(value, "avg_sales_90_days"),
  "30 Day Avg.Sales": headerValue(value, "avg_sales_30_days"),
  "7 Day Avg.Sales": headerValue(value, "avg_sales_7_days")
});

const headerMetric = (customerData, data) =>
  customerData && customerData["metrics"] === "qty"
    ? `Qty (last ${data} days)(`
    : `Sales (last ${data} days)`;

const centerColumn = (columnSum, customerData) => [
  {
    footerData: "Total",
    heading: "City",
    type: "string",
    width: 1.5,
    sort: false
  },
  {
    heading: "Centre Name",
    type: "string",
    width: 1.5
  },
  {
    align: "flex-end",
    heading: "Total SKUs",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      columnSum["avg_sales_90_days_sum"]
    )}`,
    heading: `Daily avg. ${headerMetric(customerData, "90")}`,
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      columnSum["avg_sales_30_days_sum"]
    )}`,
    heading: `Daily avg. ${headerMetric(customerData, "30")}`,
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      columnSum["avg_sales_7_days_sum"]
    )}`,
    heading: `Daily avg. ${headerMetric(customerData, "7")}`,
    type: "string",
    width: 1
  },
  {
    heading: "Performance trend",
    type: "string",
    width: 1
  }
];

const productColumn = (columnSum, customerData) => [
  {
    heading: "Product Name",
    type: "string",
    width: 3,
    sort: false
  },
  {
    heading: "Purchasing Centres",
    type: "number",
    width: 1
  },
  {
    heading: `Avg. ${headerMetric(customerData, "90") +
      Math.round(columnSum["avg_sales_90_days_sum"]).toLocaleString()})`,
    type: "string",
    width: 1
  },
  {
    heading: `Avg. ${headerMetric(customerData, "30") +
      Math.round(columnSum["avg_sales_30_days_sum"]).toLocaleString()})`,
    type: "string",
    width: 1
  },
  {
    heading: `Avg. ${headerMetric(customerData, "7") +
      Math.round(columnSum["avg_sales_7_days_sum"]).toLocaleString()})`,
    type: "string",
    width: 1
  },
  {
    heading: "Performance trend",
    type: "string",
    width: 1
  }
];

const CustomerPerformanceDetails = () => {
  let history = useHistory();
  const [hospitalData, setHospitalData] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownId, setdropdownId] = useState(dropdownData[0]);
  const [columnSum, setColumnSum] = useState({});
  const [sort, updateSort] = useState(null);
  const [customerData, setCustomerData] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const filterContext = useContext(FilterContext);
  const dateQuery =
    filterContext?.filterState?.singleDate ??
    `&q[sales_invoices_invoice_date_in]=${filterContext?.filterState?.singleDate}`;
  const api = `smartchain/insights/${
    useParams().id
  }/customer_performance_breakdown?type=${dropdownId.id}&${dateQuery}`;
  const [request, updateRequest] = useState(api);

  const getColumnSum = data => {
    setColumnSum(data);
  };

  const getCustomerData = data => {
    setCustomerData(data);
  };

  const tabSelectHandler = index => {
    setdropdownId(dropdownData[index]);
  };

  const centreIndex = {
    2: "uniq_products+",
    3: "avg_sales_90_days+",
    4: "avg_sales_30_days+",
    5: "avg_sales_7_days+",
    6: "performance+"
  };

  const productIndex = {
    1: "centres_purchasing+",
    2: "avg_sales_90_days+",
    3: "avg_sales_30_days+",
    4: "avg_sales_7_days+",
    5: "performance+"
  };
  const getHeaderId = {
    centre: (id, order) =>
      checkSortingIndex(centreIndex, id, order, updateSort),

    product: (id, order) =>
      checkSortingIndex(productIndex, id, order, updateSort)
  };

  const dropDownSelectHandler = item => {
    setdropdownId(item);
    setShowDropdown(!showDropdown);
  };

  const getHospitalData = data => {
    setHospitalData(data);
  };

  const getHospitalId = index => {
    if (hospitalData && hospitalData["items"]) {
      return hospitalData["items"][index].id;
    }
  };

  const customerCentreProductsClickHandler = (row, index) => {
    history.push(
      `/company/insights/${getHospitalId(
        index
      )}/customer_centre_products_breakdown`
    );
  };

  const dropdown = () => (
    <Primitives.Box mt={1}>
      <DropdownView
        dropdownData={dropdownData}
        dropdownId={dropdownId}
        dropDownSelectHandler={dropDownSelectHandler}
        px={4}
        setShowDropdown={setShowDropdown}
        showDropdown={showDropdown}
        tabSelectHandler={tabSelectHandler}
      />
    </Primitives.Box>
  );

  useEffect(() => {
    dropdownId.id === "centre"
      ? setColumnData(centerColumn(columnSum, customerData))
      : setColumnData(productColumn(columnSum, customerData));
  }, [dropdownId, columnSum, customerData]);

  useEffect(() => {
    window.onpopstate = function() {
      filterContext.updateFilterState("");
    };
  }, []);

  const filterUpdate = ({
    singleDate,
    City,
    hospitalSearch,
    filterCleared
  }) => {
    !filterCleared &&
      filterContext.updateFilterState({
        ...filterContext.filterState,
        singleDate: singleDate
      });

    updateRequest(
      `${api}${
        singleDate
          ? `&q[sales_invoices_invoice_date_in]=${singleDate}`
          : "&q[sales_invoices_invoice_date_in]="
      }${hospitalSearch ? `&q[display_name_cont]=${hospitalSearch}` : ""}${
        City ? `&q[city_id_in][]=${City}` : ""
      }`
    );
  };

  useEffect(() => {
    updateRequest(api);
  }, [dropdownId]);

  return (
    <Reports
      api={request}
      column={columnData}
      clickHandler={
        dropdownId.id === "centre"
          ? customerCentreProductsClickHandler
          : () => {}
      }
      cursor={dropdownId.id === "centre" && "pointer"}
      filterConfig={
        dropdownData.find(item => item.id === dropdownId.id).filtersConfig
      }
      filterUpdate={filterUpdate}
      getColumnSum={getColumnSum}
      getCustomerData={getCustomerData}
      getHeaderId={getHeaderId[dropdownId.id]}
      getHospitalData={getHospitalData}
      modifyReports={modifyReports}
      placeholder="Search by Hospital Name"
      pageId="customerPerformanceDetails"
      responsiveWidth={[900, 1000, 1000, "unset"]}
      responsiveHeight={["30vh", "55vh"]}
      sort={sort}
      selected={3}
      title={
        <>
          <Title>
            {customerData &&
              customerData["hospital_hash"] &&
              customerData["hospital_hash"]["name"]}
          </Title>
          {window.innerWidth < breakPoint && dropdown()}
        </>
      }
      reportTitle={customerData?.hospital_hash?.name}
      details={
        <>
          <DrillDownPageHeader
            headerData={headerData(
              customerData && customerData["hospital_hash"]
            )}
          />
          {window.innerWidth > breakPoint && (
            <Primitives.Box>{dropdown()}</Primitives.Box>
          )}
        </>
      }
      toolTipText={dropdownId.toolTipText}
    />
  );
};

export default CustomerPerformanceDetails;
