import React from "react";
import Loading from "../../components/loading";
import Primitives from "../../components/primitives";
import ConvertMoneyToLakhsCrores from "../../components/utils/convert-money-to-lakh-crores";

const currency = [
  "avg_sales_90_days",
  "avg_sales_30_days",
  "avg_sales_7_days",
  "total_sales",
  "total_value"
];

const headerValue = (value, keys) =>
  value ? (
    currency.includes(keys) ? (
      `₹ ${ConvertMoneyToLakhsCrores(value[keys])}`
    ) : (
      value[keys] && value[keys]
    )
  ) : (
    <Primitives.Flex display={["none", "flex"]} height={18} mt="3px" width={18}>
      <Loading onlySpinner />
    </Primitives.Flex>
  );

export default headerValue;
