import { useContext, useEffect, useState } from "react";
import logoutUser from "../lib/logout-user";
import { useHistory } from "react-router-dom";
import { AppContext } from "../utils/context";

const allowPaths = ["/onboarding", "/create_password", "/reset_password"];

const useApiRequest = (host, url) => {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();
  const context = useContext(AppContext);

  /** it redirects user to login page */
  const redirectUserToLoginPage = () =>
    context.loadUser
      ? context.loadUser({
          is_logged_in: false
        })
      : history.push("/");

  const userlogout = async () => {
    const response = await logoutUser(host);
    console.log(response, window.location.pathname);
    if (
      response.success &&
      !window.location.pathname.includes("/reset_password")
    ) {
      redirectUserToLoginPage();
    }
  };

  const fetchData = async url => {
    setIsLoaded(false);
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      const json = await response.json();
      if (response.status === 401) {
        if (!allowPaths.some(path => window.location.pathname.includes(path))) {
          localStorage.getItem("token")
            ? userlogout()
            : redirectUserToLoginPage();
        }
      }
      setIsLoaded(true);
      setData(json);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };
  useEffect(() => {
    if (url) fetchData(url);
  }, [url]);

  return { error, isLoaded, data };
};

export default useApiRequest;
