import React, { useEffect, useState } from "react";
import Primitives from "../primitives";
import { colors } from "../utils/theme";
import { func, number } from "prop-types";

const propTypes = {
  counterFinalValue: func,
  value: number
};

const defaultProps = {
  value: 60 * 10
};

const Counter = ({ counterFinalValue, value }) => {
  const [counterValue, setCounterValue] = useState(value);

  useEffect(() => {
    counterFinalValue(counterValue);
  }, [counterValue]);

  useEffect(() => {
    const interval = setInterval(() => {
      value -= 1;
      setCounterValue(value);
      value === 0 && clearInterval(interval);
    }, 1000);
  }, []);

  return (
    <Primitives.Flex
      border={`1px solid ${colors.primary[3]}`}
      width={14}
      height={14}
      borderRadius="50%"
      fontSize={["6.41px", "8px"]}
      color={colors.primary[3]}
      alignItems="center"
      justifyContent="center"
      ml="2px"
      p={["unset", "2px"]}
    >
      {counterValue}
    </Primitives.Flex>
  );
};

Counter.propTypes = propTypes;
Counter.defaultProps = defaultProps;
export default Counter;
