import React, { useContext } from "react";
import Primitives from "../../../../components/primitives";
import Card from "../../../../lib/card";
import { string } from "prop-types";
import DoughnutChart from "../../../../components/charts/doughnut";
import { colors } from "../../../../components/utils/theme";
import CtaColored from "../../../../components/cta-colored";
import { AppContext } from "../../../../utils/context";
import useApiRequest from "../../../../hooks/useApiRequest";
import Loading from "../../../../components/loading";

const propTypes = {
  filteredMonth: string
};

const color = [
  colors["light-green"],
  colors["dark-purple"],
  colors.primary[3],
  colors.text[1],
  colors["dark-yellow"],
  colors["sky-blue"],
  "pink"
];

const ClassificationWiseHospitals = ({ filteredMonth }) => {
  const { host } = useContext(AppContext);
  const request = `${host}/smartchain/wallet_analytics/hospitals_with_classification?quick_filter=${filteredMonth}`;
  const { data, isLoaded } = useApiRequest(host, encodeURI(request));

  const isRequiredData = () =>
    data &&
    data["response"] &&
    data["response"]["results"] &&
    Object.keys(data["response"]["results"]);

  return (
    <Card
      details={
        <Primitives.Flex
          alignItems="center"
          flexDirection={["column", "row"]}
          justifyContent={["unset", "space-around", "space-between"]}
          mt={3}
          minHeight="25vh"
        >
          {isLoaded ? (
            <>
              <Primitives.Flex height={[150, 250]}>
                <DoughnutChart
                  backgroundColor={[
                    colors["light-green"],
                    colors["dark-purple"],
                    colors.primary[3],
                    colors.text[1],
                    colors["dark-yellow"],
                    colors["sky-blue"],
                    "pink"
                  ]}
                  chartData={
                    isRequiredData() &&
                    Object.keys(data["response"]["results"]).map(
                      item =>
                        isRequiredData() &&
                        data["response"]["results"][item].length
                    )
                  }
                  labels={
                    isRequiredData() && Object.keys(data["response"]["results"])
                  }
                  tooltip={true}
                />
              </Primitives.Flex>
              <Primitives.Flex
                flexWrap={["wrap", "unset"]}
                flexDirection={["unset", "column"]}
                justifyContent={["space-between", "flex-end"]}
                mt={2}
              >
                {isRequiredData() &&
                  Object.keys(data["response"]["results"]).map(
                    (item, index) => (
                      <Primitives.Flex key={index}>
                        <CtaColored
                          label={item}
                          link={`/company/potential_hospital/states=&categories=${item}&quick_filter=${filteredMonth}`}
                          value={data["response"]["results"][item].length}
                          color={color[index]}
                        />
                      </Primitives.Flex>
                    )
                  )}
              </Primitives.Flex>
            </>
          ) : (
            <Primitives.Flex m="auto">
              <Loading onlySpinner />
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      }
      isCta={true}
      isDownloadable={false}
      link={`/company/potential_hospital/states=&categories=&quickfilter=${filteredMonth}`}
      title={
        <Primitives.Flex flexDirection="column">
          <Primitives.Text fontSize={[2, 3]} fontWeight={[1, 2]} mr={1}>
            Hospitals by Classification
          </Primitives.Text>
          <Primitives.Text
            color={colors.text[2]}
            fontWeight={[1, 2]}
            fontSize={["10px", "12px"]}
          >
            Total Hospitals{" "}
            {isRequiredData() &&
              data["response"]["total_count"] &&
              data["response"]["total_count"]}
          </Primitives.Text>
        </Primitives.Flex>
      }
    />
  );
};

ClassificationWiseHospitals.propTypes = propTypes;
export default ClassificationWiseHospitals;
