import React from "react";
import Primitives from "../../components/primitives";
import Tooltip from "../../components/tool-tip";
import { breakPoint, colors } from "../../components/utils/theme";
import { number, oneOfType, string } from "prop-types";

const propTypes = {
  desc: string,
  height: oneOfType([number, string]),
  width: oneOfType([number, string])
};
const ToolTipView = ({ desc, height, width }) => {
  const view = {
    desktop: (
      <Primitives.Text
        color={colors.text[1]}
        display={["none", "flex"]}
        fontSize={2}
        fontWeight={1}
      >
        {desc}
      </Primitives.Text>
    ),
    mobile: (
      <Primitives.Flex display={["flex", "none"]}>
        <Tooltip content={desc} height={height} width={width} />
      </Primitives.Flex>
    )
  };
  return window.innerWidth < breakPoint ? view["mobile"] : view["desktop"];
};
ToolTipView.propTypes = propTypes;
export default ToolTipView;
