import React from "react";
import Loading from "../../components/loading";
import Primitives from "../../components/primitives";
import { node } from "prop-types";

const propTypes = {
  children: node
};

const Title = ({ children }) => (
  <Primitives.Flex color="black" fontSize={[2, 5]} fontWeight={2} mr={1}>
    {children ? (
      children
    ) : (
      <Primitives.Flex height={25} width={25}>
        <Loading onlySpinner={true} />
      </Primitives.Flex>
    )}
  </Primitives.Flex>
);

Title.propTypes = propTypes;
export default Title;
