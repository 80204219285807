import React, { useContext, useEffect, useState } from "react";
import Primitives from "../../../components/primitives";
import { AppContext } from "../../../utils/context";
import useApiRequest from "../../../hooks/useApiRequest";
import Table from "../../../components/table-two";
import { number, object, oneOfType, string } from "prop-types";
import Loading from "../../../components/loading";
import InventoryDropdown from "../dropdown";
import ViewDetailsBtn from "../../../lib/view-details";
import NoResults from "../../../components/no-results";
import DownloadBtn from "../../../lib/download";
import contentType from "../../../lib/download-options";
import downloadHandlerFn from "../../../components/utils/download-handler";
import { breakPoint } from "../../../components/utils/theme";
import { indianNumberFormat } from "../../../components/utils";
import roundOffTwoDecimal from "../../../components/utils/round-off-two-decimal";

const propTypes = {
  tabKey: oneOfType([string, number]),
  branch: object
};

const tabs = ["near_expiry", "expired", "non_moving"];

const columnHeaderValue = ["Near Expiry", "Expired", "Non Moving"];

/** renders download btn on desktop */
const displayDownloadBtn = (isDownload, downloadHandler) => (
  <Primitives.Flex
    flex={1}
    alignSelf="flex-end"
    justifyContent="end"
    display={["none", "flex"]}
  >
    {isDownload ? (
      <Loading onlySpinner />
    ) : (
      <Primitives.Flex>
        <DownloadBtn label="Download" selectHandler={downloadHandler} />
      </Primitives.Flex>
    )}
  </Primitives.Flex>
);

const InventoryBreakup = ({ tabKey, branch }) => {
  const [query, setQuery] = useState({});
  const { branches, host } = useContext(AppContext);
  const [isDownload, setIsDownload] = useState(false);
  const [request, updateRequest] = useState("");
  const { data } = useApiRequest(host, request);

  const column = [
    {
      heading: "Product Name",
      type: "string",
      width: 3
    },
    {
      heading: "Division",
      type: "string",
      width: 1.5
    },
    {
      heading: `${columnHeaderValue[tabKey]} Qty`,
      type: "number",
      width: 1
    },
    {
      heading: "No of Batches",
      sort: true,
      type: "number",
      width: 1
    },
    {
      heading: `${columnHeaderValue[tabKey]} Value`,
      type: "number",
      width: 1
    },
    {
      heading: "Action",
      sort: false,
      type: "object",
      width: window.innerWidth < breakPoint ? 1.2 : 0.7
    }
  ];

  useEffect(() => {
    if (branch && branch["branch_id"]) {
      updateRequest(
        `${host}/smartchain/inventory_analytics/product_wise_expiry_report?tab=${tabs[
          tabKey
        ] || "near_expiry"}&branch_id=${branch["branch_id"]}${
          query.expiry
            ? `&near_expiry_filter=${query.expiry}`
            : `${
                tabKey === 2
                  ? "&non_moving_filter=0_60_days"
                  : "&near_expiry_filter=0_180_days"
              }`
        }${query.product ? `&q[universal_sku_id_in]=${query.product}` : ""}`
      );
      setQuery(prevState => ({
        ...prevState,
        branch: branch["branch_id"]
      }));
    }
  }, [branch]);

  useEffect(() => {
    branch &&
      branch["branch_id"] &&
      updateRequest(
        `${host}/smartchain/inventory_analytics/product_wise_expiry_report?tab=${tabs[
          tabKey
        ] || "near_expiry"}&branch_id=${branch["branch_id"]}${
          tabKey === 2
            ? "&non_moving_filter=0_60_days"
            : "&near_expiry_filter=0_180_days"
        }`
      );
    setQuery({});
  }, [tabKey]);

  const modifyData = data =>
    data.map(item => ({
      "Product Name": item.sku_name,
      Division: item.company_divisions,
      "Near Expiry Qty": indianNumberFormat(item.quantity),
      ...(tabKey !== 2 && { "No of Batches": item.batch_number_count }),
      "Total Value": indianNumberFormat(roundOffTwoDecimal(item.value)),
      ...(tabKey !== 2 && {
        Action: (
          <ViewDetailsBtn
            link={`/company/inventory_analysis/${query.branch ||
              branch["branch_id"]}/${item.sku_id}/${query.tab ||
              tabs[tabKey]}/productwise_breakup/${query.expiry ||
              (tabKey === 2 ? "0_60_days" : "0_180_days")}`}
          />
        )
      })
    }));

  const dropdownQueryHandler = query => {
    setQuery(prevState => ({
      ...prevState,
      branch: query.location,
      expiry: query.expiry,
      tab: tabs[tabKey],
      product: query.product
    }));
    updateRequest(
      `${host}/smartchain/inventory_analytics/product_wise_expiry_report?branch_id=${query.location ||
        branches["Branches"][0].id}${
        tabKey === 2
          ? `&non_moving_filter=${query.expiry || "0_60_days"}`
          : `&near_expiry_filter=${query.expiry || "0_180_days"}`
      }&q[universal_sku_id_in]=${query.product || ""}&tab=${tabs[tabKey]}&`
    );
  };

  const downloadHandler = async option => {
    const downloadRequest = contentType[option.index];
    let downloadApi = `${host}/smartchain/inventory_analytics/product_wise_expiry_report?branch_id=${query.branch ||
      branches["Branches"][0].id}&near_expiry_filter=${query.expiry ||
      (tabKey === 2
        ? "0_60_days"
        : "0_180_days")}&q[universal_sku_id_in]=${query.product || ""}&tab=${
      tabs[tabKey]
    }`;
    downloadHandlerFn(
      `${downloadApi.split("?")[0]}.${downloadRequest}?${
        downloadApi.split("?")[1]
      }&is_download=true`,
      setIsDownload,
      downloadRequest
    );
  };

  return (
    <Primitives.Box>
      <Primitives.Flex flexDirection="row" mb={3} mt={4} flexWrap="wrap">
        <InventoryDropdown
          branch={branch}
          query={query}
          dropdownQueryHandler={dropdownQueryHandler}
          defaultQuery={query}
          tabkey={tabKey}
        />
        {displayDownloadBtn(isDownload, downloadHandler)}
      </Primitives.Flex>
      {data && data.product_details ? (
        <Primitives.Flex position="relative" zIndex={0}>
          {data.product_details.length > 0 ? (
            <Table
              column={
                tabKey === 2
                  ? column
                      .slice(0, column.length - 3)
                      .concat(
                        column.slice(column.length - 2, column.length - 1)
                      )
                  : column
              }
              data={modifyData(data.product_details)}
              displayLockIcon={["block", "block", "block", "none"]}
              isResponsive={true}
              responsiveWidth={[800, 1000, 1100, "unset"]}
              sorting={true}
            />
          ) : (
            <Primitives.Flex mx="auto" mt={100}>
              <NoResults />
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      ) : (
        <Primitives.Flex
          height="30vh"
          justifyContent="center"
          alignItems="center"
        >
          <Loading onlySpinner={true} />
        </Primitives.Flex>
      )}
      <Primitives.Box pt={150} />
    </Primitives.Box>
  );
};
InventoryBreakup.propTypes = propTypes;
export default InventoryBreakup;
