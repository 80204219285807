import React from "react";
import Primitives from "../../components/primitives";
import Title from "../../lib/title";
import Reports from "../reports";

const column = [
  {
    heading: (
      <Primitives.Flex
        key="1"
        width={1}
        alignItems="space-between"
        justifyContent="space-between"
      >
        <Primitives.Text fontWeight={700}>Medical Rep</Primitives.Text>
      </Primitives.Flex>
    ),
    type: "string",
    width: 1,
    sort: false
  },
  {
    heading: "Month Till Date",
    type: "string",
    width: 1,
    sort: false
  },
  {
    heading: "Quarter Till Date",
    type: "string",
    width: 1,
    sort: false
  },
  {
    heading: "Year Till Date",
    type: "string",
    width: 1,
    sort: false
  }
];

const modifyReports = data => {
  let reports = [];
  data &&
    data["items"] &&
    data["items"].forEach(item => {
      reports.push({
        name: item.name,
        mtd: (
          <Primitives.Flex
            justifyContent="space-between"
            width={1}
            px={["5px", 1]}
          >
            <Primitives.Text>
              ₹{Math.round(item["mtd"]["amount"]).toLocaleString()}
            </Primitives.Text>
            <Primitives.Text>{item["mtd"]["quantity"]}</Primitives.Text>
          </Primitives.Flex>
        ),
        qtd: (
          <Primitives.Flex
            justifyContent="space-between"
            width={1}
            px={["5px", 1]}
          >
            <Primitives.Text>
              ₹{Math.round(item["qtd"]["amount"]).toLocaleString()}
            </Primitives.Text>
            <Primitives.Text>{item["qtd"]["quantity"]}</Primitives.Text>
          </Primitives.Flex>
        ),
        ytd: (
          <Primitives.Flex
            justifyContent="space-between"
            width={1}
            px={["5px", 1]}
          >
            <Primitives.Text>
              ₹{Math.round(item["ytd"]["amount"]).toLocaleString()}
            </Primitives.Text>
            <Primitives.Text>{item["ytd"]["quantity"]}</Primitives.Text>
          </Primitives.Flex>
        )
      });
    });

  const hash = {
    "sub-header": (
      <Primitives.Flex justifyContent="space-between" width={1} px={["5px", 1]}>
        <Primitives.Text>AMT</Primitives.Text>
        <Primitives.Text>QTY</Primitives.Text>
      </Primitives.Flex>
    )
  };

  reports.unshift({
    0: "",
    1: hash["sub-header"],
    2: hash["sub-header"],
    3: hash["sub-header"]
  });

  return reports;
};

const KeyAccountManagerReport = () => {
  const api = `smartchain/key_account_managers?`;
  return (
    <Reports
      api={api}
      column={column}
      clickHandler={() => {}}
      filters={["Kam", "Master Sku", "Month"]}
      isDownloadable={false}
      isSortingRequire={false}
      modifyReports={modifyReports}
      pageId="keyAccountManagerReport"
      selected={4}
      title={
        <>
          <Title>
            <Primitives.Text mb={["unset", 1]}>
              Key Account Manager Report
            </Primitives.Text>
          </Title>
        </>
      }
      reportTitle="Key Account Manager Report"
    />
  );
};

export default KeyAccountManagerReport;
