export const BASE_URL_V2 = process.env.REACT_APP_BASE_URL_V2;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const Appcues_User_Onboarding =
  BASE_URL === "https://staging.aknamed.com";

export const getHeaders = () => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
  return myHeaders;
};

export const REQ_OPT = {
  method: "POST",
  redirect: "follow"
};
export const GET_REQ_OPT = {
  method: "GET",
  redirect: "follow"
};
