/** does otp based login */
export const doOtpLogin = async (email, otp, host) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    user: {
      email: email,
      otp: otp
    }
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };
  return fetch(`${host}/auth/login`, requestOptions);
};

/** does password based login */
export const doPasswordLogin = async (email, password, host) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "_aknamed_admin_session=KNT91jPIl2iY0q52%2BbgDAdmTo9SEP3CP92q3Sh3iM1r7A%2F6VcESDgdk5IFBfSbjuzg%3D%3D--kK9wtn8Cf%2FFV0BVy--RLvNl2gaR7OL9A6dvntJ9g%3D%3D"
  );

  const raw = JSON.stringify({
    user: {
      email: email,
      password: password,
      account: "smartchain"
    }
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };
  return await fetch(`${host}/api/login`, requestOptions);
};

/** send otp to user email */
export const sendOtp = async (email, host) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      email: email
    }),
    redirect: "follow"
  };
  try {
    const { status } = await fetch(`${host}/auth/send_otp`, requestOptions);
    if (status === 200)
      return {
        success: true,
        detail: "OTP is successfully sent"
      };
  } catch (error) {
    console.error("API request failed:", error);
  }
  return {
    success: false,
    detail: "OTP is not being sent, please try again after page refresh"
  };
};

/** determing whether a user of provided email have access to new APIs or not */
export const checkUserAccessToNewApi = async (email, host) => {
  try {
    const response = await fetch(
      `${host}/auth/have_new_sc_access?email=${email}`
    );
    return await response.json();
  } catch (err) {
    console.log("Error ", err);
    return {
      haveNewSCAccess: false
    };
  }
};

/** send reset-password link */
export const resetPassword = async (email, host) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Cookie",
    "_aknamed_admin_session=KNT91jPIl2iY0q52%2BbgDAdmTo9SEP3CP92q3Sh3iM1r7A%2F6VcESDgdk5IFBfSbjuzg%3D%3D--kK9wtn8Cf%2FFV0BVy--RLvNl2gaR7OL9A6dvntJ9g%3D%3D"
  );
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };
  return await fetch(
    `${host}/smartchain/send_token?email=${email}`,
    requestOptions
  );
};
