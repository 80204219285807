import React, { useState } from "react";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import { BASE_URL } from "../../../services/config";
import Button from "../button";
import { string, func } from "prop-types";
import { resetPassword } from "../../../services/login-user";

const propTypes = {
  /** email */
  email: string,
  /** For changing email */
  setEmail: func,
  /** For switching between different login screens */
  updateScreen: func,
  /** For showing alert message */
  setAlertData: func
};

/** store different alert messages */
const alertData = [
  {
    detail:
      "This email ID does not exist in our database. Please try with a different email.",
    success: false
  },
  {
    detail:
      "Your reset password Instructions have been sent to registered email.Please Check",
    success: true
  },
  {
    detail: "Too many requests, please try after sometime",
    success: false
  }
];
/** Component */
const ForgotPassword = ({ email, setEmail, updateScreen, setAlertData }) => {
  const [loading, updateLoading] = useState(false);

  /** handler for sending password-reset link */
  const handleSubmit = async event => {
    event.preventDefault();
    updateLoading(true);
    try {
      const response = await resetPassword(email, BASE_URL);
      /** handling too many requests to forgot password */
      if (response.status === 429) {
        throw new Error(alertData[2]);
      }
      const jsonResponse = await response.json();
      setAlertData(jsonResponse.valid ? alertData[1] : alertData[0]);
    } catch (err) {
      console.log("error", err);
      setAlertData({
        detail: err.message,
        success: false
      });
    }
    updateLoading(false);
  };
  return (
    <Primitives.Flex flexDirection="column" width={1}>
      <form style={{ display: "flex", flexDirection: "column" }}>
        <Primitives.Input
          border={1}
          borderColor={colors.text[2]}
          borderRadius={4}
          borderStyle="solid"
          height={33}
          my={3}
          onChange={e => setEmail(e.target.value)}
          value={email}
          placeholder="Email"
          autoComplete="email"
          pl={10}
          disabled={loading}
        ></Primitives.Input>
        <Button isLoaded={loading} handleSubmit={handleSubmit}>
          Send Reset Link
        </Button>
      </form>
      <Primitives.Flex justifyContent="center" mt={2} pr={1}>
        <Primitives.Button
          color={colors.primary[3]}
          fontFamily="lato"
          fontSize={2}
          hover={{
            backgroundColor: "white",
            color: colors.primary[6]
          }}
          onClick={() => updateScreen("password")}
          disabled={loading}
        >
          Go Back
        </Primitives.Button>
      </Primitives.Flex>
    </Primitives.Flex>
  );
};

ForgotPassword.propTypes = propTypes;
export default ForgotPassword;
