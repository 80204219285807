import React from "react";
import { colors } from "../utils/theme";
import CalendarIcon from "../glyphs/elements/calendar";
import { DateRange as Calendar } from "react-date-range";
import {
  array,
  bool,
  func,
  number,
  object,
  oneOfType,
  string,
  instanceOf
} from "prop-types";
import Primitives from "../primitives";
import { StyledSelect } from "../dropdown";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./index.css";

const propTypes = {
  /** Border around the button */
  border: string,
  /** Border radius for select */
  borderRadius: oneOfType([number, string]),
  cancelHandler: func,
  /** Handler function for date change */
  changeHandler: func,
  /** Handler function for collapsing calendar */
  collapseHandler: func,
  /** State of calendar if it is collapsed or not */
  collapsed: bool,
  /** Text color of select */
  color: string,
  /** Calendar is disabled or not */
  disabled: bool,
  /** disabled dates */
  disabledDates: oneOfType([string, instanceOf(Date)]),
  /** Header for select to be displayed befor collapsing */
  defaultValue: oneOfType([object, string]),
  /** What Font family should button text use*/
  fontFamily: string,
  /** What font size should be used on Select */
  fontSize: number,
  /** Font styling for select text */
  fontStyle: string,
  /** What fontweight should be applied to select text */
  fontWeight: string,
  /** line height for texts in select */
  lineHeight: number,
  /** Starting and opening date range */
  ranges: array,
  showInitialDate: bool
};

const defaultProps = {
  defaultValue: "Date Range",
  disabled: false,
  fontFamily: "Lato",
  fontSize: 1,
  fontStyle: "normal",
  fontWeight: "normal",
  lineHeight: 1.2,
  showInitialDate: false
};
const dateDisplay = ranges => (
  <Primitives.Text color={colors.text[0]} fontSize="0.85em">
    {ranges[0].startDate.getDate()}/{ranges[0].startDate.getMonth() + 1}/
    {ranges[0].startDate.getFullYear()} -{ranges[0].endDate.getDate()}/
    {ranges[0].endDate.getMonth() + 1}/{ranges[0].endDate.getFullYear()}
  </Primitives.Text>
);

const showDate = (collapsed, ranges, defaultValue, showInitialDate) => {
  if (collapsed && ranges[0].startDate && ranges[0].endDate) {
    if (!ranges[0].new) {
      return dateDisplay(ranges);
    }
    if (ranges[0].new) {
      if (showInitialDate) {
        return dateDisplay(ranges);
      } else {
        return (
          <Primitives.Text fontSize="0.85em">{defaultValue}</Primitives.Text>
        );
      }
    }
  }
};

const DateRange = ({
  border,
  borderRadius,
  cancelHandler,
  changeHandler,
  collapseHandler,
  collapsed,
  color,
  disabled,
  disabledDates,
  defaultValue,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  lineHeight,
  ranges,
  showInitialDate
}) => (
  <Primitives.Flex flexDirection="column" position="relative" width={1}>
    <StyledSelect
      border={border}
      borderColor={colors.text[0]}
      borderRadius={borderRadius}
      color={color}
      disabled={disabled}
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontStyle={fontStyle}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      onClick={collapseHandler}
      paddingBottom={12}
      paddingLeft="0.2em"
      paddingRight="0.2em"
      paddingTop={12}
      width={1}
    >
      {showDate(collapsed, ranges, defaultValue, showInitialDate)}
      <Primitives.Flex ml="auto">
        <CalendarIcon fill={colors.text[1]} />
      </Primitives.Flex>
    </StyledSelect>
    <Primitives.Flex
      display={collapsed && "none"}
      position="absolute"
      top={50}
      zIndex={1}
    >
      <Primitives.Flex
        position={["fixed", "unset"]}
        width={["100%", "unset"]}
        height={["100%", "unset"]}
        left={[0, "unset"]}
        top={[0, "unset"]}
        justifyContent={["center", "unset"]}
        zIndex={2}
      >
        <Calendar
          editableDateInputs={false}
          moveRangeOnFirstSelection={false}
          onChange={changeHandler}
          ranges={ranges}
          rangeColors={[colors.primary[0]]}
          showMonthAndYearPickers={false}
          twoStepChange={false}
          weekdayDisplayFormat="EEEEE"
          minDate={disabledDates}
        />
        <Primitives.Flex
          display={["flex", "none"]}
          position="fixed"
          top={400}
          left={130}
        >
          <Primitives.Button
            width="90px"
            mt={20}
            py={10}
            borderRadius={4}
            color={colors.text[1]}
            fontFamily="lato"
            fontSize="13px"
            fontWeight={3}
            onClick={cancelHandler}
          >
            Cancel
          </Primitives.Button>
          <Primitives.Button
            active={{ backgroundColor: colors.primary[6], color: "white" }}
            width="90px"
            mt={20}
            background={colors.primary[3]}
            py={10}
            borderRadius={4}
            color={colors.white}
            hover={{ backgroundColor: colors.primary[6], color: "white" }}
            fontFamily="lato"
            fontSize="13px"
            fontWeight={3}
            onClick={collapseHandler}
          >
            Done
          </Primitives.Button>
        </Primitives.Flex>
      </Primitives.Flex>
    </Primitives.Flex>
  </Primitives.Flex>
);

DateRange.propTypes = propTypes;
DateRange.defaultProps = defaultProps;
export default DateRange;
