import React, { useEffect, useState } from "react";
import Alert from "../../../components/alert";
import Error from "../../../components/glyphs/elements/error";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import Trademark from "../trademark";
import { object, string, oneOfType } from "prop-types";
import Checked from "../../../components/glyphs/elements/checked";

const propTypes = {
  alertData: oneOfType([object, string]),
  screen: string
};
/** stores title and description text for each screen */
const labelKey = {
  default: {
    title: "Improve Healthcare Outcomes",
    description:
      "Managing Orders, Tracking Delivery, Tracking Profit Margins became easier"
  },
  forgot_password: {
    title: "Forgot your Password ?",
    description:
      "Please enter your email and get password reset instructions in your inbox"
  }
};
/** color related props of alert component */
const alertColorsProps = {
  true: {
    backgroundColor: colors["background-hint-green"],
    borderColor: colors.success[0],
    color: colors.success[1],
    glyph: <Checked fill={colors.success[0]} />
  },
  false: {
    backgroundColor: colors["background-dull-red"],
    borderColor: colors.error[1],
    color: colors.error[1],
    glyph: <Error fill={colors.error[1]} />
  }
};
const LoginHeader = ({ alertData, screen }) => {
  const [showAlert, updateAlertVisibility] = useState(false);
  const { title, description } = labelKey[screen] || labelKey.default;
  useEffect(() => {
    let timeOutId;
    if (alertData && alertData.detail && !showAlert) {
      updateAlertVisibility(true);
      timeOutId = setTimeout(() => updateAlertVisibility(false), 3000);
    }
    return () => timeOutId && clearTimeout(timeOutId);
  }, [alertData]);
  return (
    <>
      {showAlert && (
        <Primitives.Flex position="absolute" mt={-36}>
          <Alert
            {...alertColorsProps[alertData.success]}
            fontSize={1}
            label={
              <Primitives.Text alignItems="center" display="inline-flex">
                {alertData.detail}
              </Primitives.Text>
            }
            lineHeight={3}
            mx={1}
            pl={1}
            pr={2}
            py="7px"
          />
        </Primitives.Flex>
      )}
      <Primitives.Flex>
        <Trademark />
      </Primitives.Flex>

      <Primitives.Flex>
        <Primitives.Text
          color={colors.text[0]}
          fontFamily="lato"
          fontSize={7}
          fontWeight={1}
          mt={11}
        >
          {title}
        </Primitives.Text>
      </Primitives.Flex>

      <Primitives.Flex>
        <Primitives.Text
          color={colors.text[2]}
          fontFamily="lato"
          fontSize={15}
          mt={11}
        >
          {description}
        </Primitives.Text>
      </Primitives.Flex>
    </>
  );
};

LoginHeader.propTypes = propTypes;
export default LoginHeader;
