/**  Mobile Navigation Dropdown component for Hospital Sales Report & Rate Contract */

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import DropdownView from "../../lib/dropdown-view";
import Primitives from "../../components/primitives";
import { number } from "prop-types";
import ClosedLock from "../../components/glyphs/elements/closed-lock";

const propTypes = {
  initialDropdownId: number
};

const DropdownComponent = ({ initialDropdownId }) => {
  const dropdownData = [
    {
      text: "Hospital Sales",
      id: "hospital",
      link: "/company/hospital_sales"
    },
    {
      text: "Sales Invoices",
      id: "invoices",
      link: "/company/orders_report/index"
    },
    {
      text: "Rate Contract",
      id: "rate",
      glyph: <ClosedLock />,
      cursor: "not-allowed"
    }
  ];
  const [showDropdown, setShowDropdown] = useState(false);
  const history = useHistory();

  const dropDownSelectHandler = item => {
    setShowDropdown(!showDropdown);
    history.push(item.link);
  };

  return (
    <Primitives.Box mt={1}>
      <DropdownView
        dropdownData={dropdownData}
        dropdownId={dropdownData[initialDropdownId]}
        dropDownSelectHandler={dropDownSelectHandler}
        px={4}
        setShowDropdown={setShowDropdown}
        showDropdown={showDropdown}
        tabSelectHandler={dropDownSelectHandler}
        showTabsOnMediumScreen={true}
      />
    </Primitives.Box>
  );
};
DropdownComponent.propTypes = propTypes;
export default DropdownComponent;
