import React from "react";

const Caution = ({ ...props }) => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.37008 0.871493C7.16685 0.516831 6.84493 0.245431 6.46096 0.105132C6.077 -0.0350439 5.65597 -0.0350439 5.27203 0.105132C4.88807 0.245438 4.56615 0.516831 4.36292 0.871493L0.259677 8.05347C-0.0887317 8.66277 -0.0864071 9.41152 0.265816 10.0188C0.617925 10.6259 1.26661 10.9997 1.96854 11H9.76476C10.467 11.0004 11.1163 10.6268 11.4689 10.0195C11.8215 9.41227 11.8241 8.66316 11.4755 8.05347L7.37008 0.871493ZM9.76461 9.8998H1.96839C1.65849 9.90005 1.37199 9.73532 1.2162 9.46737C1.06055 9.19955 1.05927 8.86895 1.21288 8.59984L5.31645 1.41786C5.39037 1.28766 5.50817 1.1879 5.64871 1.13635C5.7894 1.08481 5.94365 1.08481 6.08435 1.13635C6.22491 1.1879 6.3427 1.28766 6.41661 1.41786L10.5202 8.59984C10.6738 8.86894 10.6725 9.19954 10.5169 9.46737C10.3611 9.73532 10.0746 9.90006 9.76467 9.8998H9.76461Z"
      fill="inherit"
    />
    <path d="M5.3157 3.66406H6.4159V6.4144H5.3157V3.66406Z" fill="#B2A000" />
    <path
      d="M6.4159 8.06462C6.4159 8.3685 6.16957 8.61472 5.8658 8.61472C5.56204 8.61472 5.3157 8.36851 5.3157 8.06462C5.3157 7.76086 5.56204 7.51465 5.8658 7.51465C6.16957 7.51465 6.4159 7.76085 6.4159 8.06462Z"
      fill="#B2A000"
    />
  </svg>
);

export default Caution;
