import React, { useContext } from "react";
import { Redirect, Route } from "react-router";
import { AppContext } from "../../utils/context";

const allowPaths = [
  "/onboarding",
  "/create_password",
  "/reset_password",
  "otp_verification"
];

const ProtectedRoute = () => {
  const { loggedInUser } = useContext(AppContext);
  return (
    <>
      {loggedInUser["logged_in"] !== undefined && (
        <Route
          render={() =>
            loggedInUser["logged_in"] ||
            allowPaths.some(path => window.location.pathname.includes(path)) ? (
              <Redirect
                to={{
                  pathname: `${
                    window.location.pathname === "/" ||
                    allowPaths.some(path =>
                      window.location.pathname.includes(path)
                    )
                      ? "/company/product_report/"
                      : window.location.pathname
                  }`
                }}
              />
            ) : (
              <Redirect to={{ pathname: "/" }} />
            )
          }
        />
      )}
    </>
  );
};
export default ProtectedRoute;
