import React from "react";
import Cta from "../cta";
import Circle from "../glyphs/elements/circle";
import ForwardAngular from "../glyphs/elements/forwardAngular";
import Primitives from "../primitives";
import { colors } from "../utils/theme";
import { array, number, oneOfType, string } from "prop-types";

const propTypes = {
  /** background color of whole div */
  backgroundColor: string,
  /** color of circle svg */
  color: string,
  /** font-color of anchor text */
  fontColor: string,
  /** font-size of anchor text*/
  fontSize: oneOfType([array, number, string]),
  /** font-weight of anchor text*/
  fontWeight: oneOfType([array, number, string]),
  /** redirection link */
  link: string,
  /** text inside colored cta */
  label: string,
  /** value inside colored cta */
  value: number
};

const defaultProps = {
  backgroundColor: colors.text[3],
  fontColor: colors.text[0],
  fontSize: [0, 1],
  fontWeight: [1, 2]
};

const CtaColored = ({
  backgroundColor,
  color,
  fontColor,
  fontSize,
  fontWeight,
  label,
  link,
  value
}) => (
  <Primitives.Flex mx="6px" my="4px" overflow="hidden" alignItems="center">
    <Primitives.Flex
      height={[7, 12]}
      width={[7, 12]}
      mr={1}
      alignItems="center"
    >
      <Circle fill={color} />
    </Primitives.Flex>
    <Cta
      fontSize={fontSize}
      fontWeight={fontWeight}
      label={
        <Primitives.Flex
          alignItems="center"
          justifyContent="space-between"
          backgroundColor={backgroundColor}
          p={1}
          width={[120, 140]}
          borderRadius="3px"
          color={fontColor}
        >
          <Primitives.Text mr={1}>{label}</Primitives.Text>
          <Primitives.Flex>
            <Primitives.Text mr={1}>{value}</Primitives.Text>
            <ForwardAngular fill={colors.accent[3]} />
          </Primitives.Flex>
        </Primitives.Flex>
      }
      // type="link"
      link={link}
      hover={{ color: colors.primary[3], textDecorationLine: true }}
      textdecorationline={false}
    />
  </Primitives.Flex>
);

CtaColored.propTypes = propTypes;
CtaColored.defaultProps = defaultProps;
export default CtaColored;
