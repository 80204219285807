import React from "react";
import Primitives from "../../../components/primitives";
import AknamedLogo from "../../../components/glyphs/elements/aknamed-logo";
import { colors } from "../../../components/utils/theme";

const Footer = () => (
  <>
    <Primitives.Flex
      border={1}
      borderColor={colors["background-light-blue"]}
      borderStyle="solid"
      mt={3}
    />
    <Primitives.Flex mt={2}>
      <AknamedLogo height={35} width={60} />
      <Primitives.Text
        color={colors.text[1]}
        fontFamily="lato"
        fontSize={1}
        margin="auto"
      >
        A Proprietary Product of Akna Medical Pvt. Ltd
      </Primitives.Text>
    </Primitives.Flex>
  </>
);
export default Footer;
