import React from "react";
import { bool } from "prop-types";

const propTypes = {
  onlyArrow: bool
};

const defaultProps = {
  onlyArrow: false
};
const DecreasingZigZagArrow = ({ onlyArrow, ...props }) => (
  <svg
    width="26"
    height="20"
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {!onlyArrow && <rect width="26" height="20" rx="4" fill="#FFDCDD" />}
    <g clipPath="url(#clip0_1018:140)">
      <path
        d="M6.68759 4.0625C6.79842 4.06236 6.90818 4.08412 7.01057 4.12652C7.11296 4.16893 7.20596 4.23115 7.28423 4.30961L12.0313 9.05676L13.966 7.12211C14.0443 7.04376 14.1373 6.9816 14.2397 6.9392C14.3421 6.89679 14.4518 6.87497 14.5626 6.87497C14.6734 6.87497 14.7831 6.89679 14.8855 6.9392C14.9879 6.9816 15.0809 7.04376 15.1592 7.12211L20.4685 12.4314L20.4682 10.5312C20.4682 10.3075 20.5571 10.0929 20.7153 9.93463C20.8736 9.77639 21.0882 9.6875 21.312 9.6875C21.5357 9.6875 21.7503 9.77639 21.9086 9.93463C22.0668 10.0929 22.1557 10.3075 22.1557 10.5312L22.1563 14.4687C22.1563 14.6925 22.0674 14.9071 21.9092 15.0654C21.751 15.2236 21.5364 15.3125 21.3126 15.3125L17.3751 15.3125C17.1513 15.3125 16.9367 15.2236 16.7785 15.0654C16.6202 14.9071 16.5313 14.6925 16.5313 14.4687C16.5313 14.245 16.6202 14.0304 16.7785 13.8721C16.9367 13.7139 17.1513 13.625 17.3751 13.625L19.2756 13.625L14.5626 8.91199L12.628 10.8466C12.5496 10.925 12.4566 10.9871 12.3542 11.0295C12.2519 11.072 12.1421 11.0938 12.0313 11.0938C11.9205 11.0938 11.8108 11.072 11.7084 11.0295C11.6061 10.9871 11.5131 10.925 11.4347 10.8466L6.09095 5.50289C5.97295 5.38489 5.89258 5.23454 5.86002 5.07087C5.82747 4.90719 5.84417 4.73754 5.90804 4.58336C5.9719 4.42918 6.08005 4.2974 6.21881 4.20469C6.35757 4.11198 6.52071 4.06249 6.68759 4.0625V4.0625Z"
        fill="inherit"
      />
    </g>
    <defs>
      <clipPath id="clip0_1018:140">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 5 18)"
        />
      </clipPath>
    </defs>
  </svg>
);

DecreasingZigZagArrow.defaultProps = defaultProps;
DecreasingZigZagArrow.propTypes = propTypes;
export default DecreasingZigZagArrow;
