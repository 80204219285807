import React, { useContext, useEffect, useState, useRef } from "react";
import Dropdown from "../../../components/dropdown";
import Primitives from "../../../components/primitives";
import { number, string, func, object, oneOfType } from "prop-types";
import { breakPoint, colors } from "../../../components/utils/theme";
import { AppContext } from "../../../utils/context";
import styled from "styled-components";

const propTypes = {
  branch: object,
  dropdownQueryHandler: func,
  defaultQuery: object,
  tabkey: oneOfType([number, string])
};

const StyledText = styled(Primitives.Text)`
  text-decoration: underline;
`;

const InventoryDropdown = ({
  branch,
  dropdownQueryHandler,
  defaultQuery,
  tabkey
}) => {
  const { branches, masterskus } = useContext(AppContext);
  const [dropDowns, setDropDowns] = useState(3);
  const [searchText, setSearchText] = useState({});

  const [dropdownCollapse, setDropdownCollapse] = useState([
    false,
    false,
    false
  ]);
  const [selectedDropdown, setSelectedDropdown] = useState([]);

  useEffect(() => {
    const branchDefaultvalue = branch && branch.branch_name;
    const arr = [
      { text: branchDefaultvalue },
      selectedDropdown[1] || { text: "ALL" },
      selectedDropdown[2] || { text: tabkey === 0 ? "ALL" : "Last 60 days" }
    ];
    branchDefaultvalue && setSelectedDropdown(arr);
  }, [branch]);

  useEffect(() => {
    const branchDefaultvalue = branch && branch.branch_name;
    branchDefaultvalue &&
      setSelectedDropdown([
        { text: branchDefaultvalue },
        { text: "ALL" },
        { text: tabkey === 0 ? "ALL" : "Last 60 days" }
      ]);
  }, [tabkey]);

  const filterHeader = {
    0: {
      true: "Near Expiry",
      false: "Near Expiry Range"
    },
    2: {
      true: "Non Moving",
      false: "Non Moving Range"
    }
  };
  const dropdownArr = [
    {
      id: "branch",
      data: branches && branches["Branches"],
      header: "Locations",
      name: "name"
    },
    {
      id: "products",
      data: masterskus && masterskus["Masterskus"],
      header: "Product Name",
      name: "sku_name"
    },
    {
      id: "expiry",
      data:
        tabkey === 0
          ? [
              {
                name: "ALL",
                id: "0_180_days"
              },
              {
                name: "Expiring in 0 - 30 days",
                id: "0_30_days"
              },
              {
                name: "Expiring in 30 - 90 days",
                id: "30_90_days"
              },
              {
                name: "Expiring in 90 - 180 days",
                id: "90_180_days"
              }
            ]
          : [
              {
                name: "Last 30 days",
                id: "0_30_days"
              },
              {
                name: "Last 60 days",
                id: "0_60_days"
              }
            ],
      header: filterHeader[tabkey]?.[window.innerWidth < breakPoint],
      name: "name"
    }
  ];

  useEffect(() => {
    if (tabkey === 1) {
      setDropDowns(2);
    } else setDropDowns(3);
  }, [tabkey]);

  const dropdownSelectHandler = (option, index) => {
    selectedDropdown[index] = option;
    setSelectedDropdown(selectedDropdown);
    setDropdownCollapse([false, false, false]);
    dropdownQueryHandler({
      location: selectedDropdown[0].id || defaultQuery["branch"],
      product: selectedDropdown[1].id,
      expiry: selectedDropdown[2].id || defaultQuery["expiry"],
      tab: selectedDropdown[2].id || defaultQuery["expiry"]
    });
  };
  const node = useRef();

  const handleClick = e => {
    if (node && node.current && node.current.contains(e.target)) {
      /** inside click */
    }
    /** outside click */
    if (!e.target.closest(".dropdown-filter")) {
      setDropdownCollapse(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <>
      {dropdownArr.splice(0, dropDowns).map((item, index) => (
        <Primitives.Flex
          flexDirection="column"
          key={index}
          mr={[1, 3]}
          width={150}
        >
          <Primitives.Flex color={colors.text[0]} fontSize="12px" pb={1}>
            {item.header}
          </Primitives.Flex>
          <Dropdown
            className="dropdown-filter"
            collapsed={dropdownCollapse[index]}
            placeholder={
              selectedDropdown[index] && selectedDropdown[index].text
            }
            focusHandler={() => {
              setDropdownCollapse([index === 0, index === 1, index === 2]);
            }}
            id={item.id}
            listMaxHeight={195}
            name={item.id}
            options={
              item.data &&
              item.data.map(val => ({
                id: val.id,
                text: val[item.name]
              }))
            }
            searchable={true}
            selectHandler={option => {
              dropdownSelectHandler(option, index);
              setSearchText({});
            }}
            selectType="multiSelect"
            changeHandler={({ target }) =>
              setSearchText(searchText => ({
                ...searchText,
                [index]: target?.value
              }))
            }
            search={searchText?.[index]}
          />
        </Primitives.Flex>
      ))}

      <StyledText
        alignSelf="flex-end"
        color={colors.text[1]}
        cursor="pointer"
        fontSize={1}
        height="24px"
        ml={1}
        lineHeight={4}
        onClick={() => {
          setDropdownCollapse([false, false, false]);
          setSelectedDropdown([
            { text: branches["Branches"]?.[0].name },
            { text: "ALL" },
            { text: tabkey === 0 ? "ALL" : "Last 60 days" }
          ]);
          dropdownQueryHandler({
            location: "",
            product: "",
            expiry: "",
            tab: selectedDropdown[2].id || defaultQuery["expiry"]
          });
          setSearchText({});
        }}
      >
        Clear Filter
      </StyledText>
    </>
  );
};

InventoryDropdown.propTypes = propTypes;
export default InventoryDropdown;
