import React from "react";
import Primitives from "../../../components/primitives";
import { bool, func, string } from "prop-types";
import Input from "../../../components/input";

const propTypes = {
  changeHandler: func,
  placeholder: string,
  value: string,
  required: bool,
  type: string
};
const defaultProps = {
  type: "text"
};

const InputSection = ({
  changeHandler,
  placeholder,
  value,
  required,
  type
}) => (
  <>
    <Primitives.Flex flexDirection="column" mr={4} my={1}>
      <Primitives.Box width={430}>
        <Input
          changeHandler={changeHandler}
          type={type}
          value={value}
          placeholder={placeholder}
          required={required}
        />
      </Primitives.Box>
    </Primitives.Flex>
  </>
);

InputSection.propTypes = propTypes;
InputSection.defaultProps = defaultProps;
export default InputSection;
