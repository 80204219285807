import React from "react";
import styled from "styled-components";
import Button from "../../../components/button";
import Filter from "../../../components/glyphs/elements/filter";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import { bool, func } from "prop-types";

const propTypes = {
  applyFilter: func,
  clearFilter: func,
  filterActive: bool
};

const StyledText = styled(Primitives.Text)`
  text-decoration: underline;
`;

const ApplyFilter = ({ applyFilter, filterActive, clearFilter }) => {
  return (
    <>
      <Primitives.Flex
        alignItems="center"
        backgroundColor={filterActive ? colors.primary[3] : "white"}
        border={`1px solid ${colors.text[2]}`}
        borderRadius={4}
        py="2px"
        cursor="pointer"
        onClick={() => {
          applyFilter();
        }}
        justifyContent="center"
        width={128}
        mx={1}
      >
        <Button
          backgroundColor={filterActive > 0 ? colors.primary[3] : "white"}
          border="opx"
          borderColor={colors.text[3]}
          glyph={
            <Filter
              height="19.65px"
              width="20px"
              fill={filterActive ? "white" : colors.primary[3]}
            />
          }
          opacity="0.69"
          py={1}
        />
        <Primitives.Text
          color={filterActive ? "white" : colors.primary[3]}
          fontSize={1}
          fontWeight={1}
          ml={1}
        >
          Apply Filter
        </Primitives.Text>
      </Primitives.Flex>
      <Primitives.Flex width={70}>
        {filterActive && (
          <StyledText
            color={colors.text[1]}
            cursor="pointer"
            fontSize={1}
            lineHeight={4}
            onClick={() => clearFilter()}
          >
            Clear Filter
          </StyledText>
        )}
      </Primitives.Flex>
    </>
  );
};

export default ApplyFilter;
ApplyFilter.propTypes = propTypes;
