import React, { useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import AppContextProvider from "./utils/context";
import ThemeProvider from "./components/utils/theme/index";
import Bugsnag from "@bugsnag/browser";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import ProductReportSku from "./pages/product-report/sku-wise-report";
import ProductReportBranch from "./pages/product-report";
import KeyAccountManagerReport from "./pages/key-account-manager-report";
import Login from "./pages/onboarding/login";
import OrderReport from "./pages/order-report";
import CustomerPerformance from "./pages/customer-performance";
import CustomerPerformanceDetails from "./pages/customer-performance/customer-performance-details";
import ProductPerformance from "./pages/product-performance";
import CustomerCentreProductsBreakDown from "./pages/customer-performance/customer-centre-products-breakdown";
import ProtectedRoute from "./lib/protect-route";
import ResetPassword from "./pages/onboarding/reset-password";
import ReactGA from "react-ga4";
import WalletShareAnalytics from "./pages/wallet-share-analytics";
import HospitalsStatistics from "./pages/wallet-share-analytics/hospitals-statistics";
import PotentialHospital from "./pages/wallet-share-analytics/potential-hospital";
import HospitalGroupWithCenters from "./pages/wallet-share-analytics/hospital-group-with-centers";
import IndividualCenters from "./pages/wallet-share-analytics/hospital-group-with-centers/individual-centers";
import SelfOnboarding from "./pages/self-onboarding";
import CreatePassword from "./pages/self-onboarding/create-password";
import { IntercomProvider } from "react-use-intercom";
import Intercom from "./lib/intercom";
import OtpVerification from "./pages/onboarding/otp-verification";
import HospitalSalesReport from "./pages/hospital-sales-report";
import HospitalSalesCityWise from "./pages/hospital-sales-report/hospital-sales-city-wise";
import HospitalSalesGroupWise from "./pages/hospital-sales-report/hospital-sales-group-wise";
import HospitalSalesHospitalWise from "./pages/hospital-sales-report/hospital-sales-hospital-wise";
import InventoryAnalysis from "./pages/inventory-analysis";
import HospitalSalesPoductWise from "./pages/hospital-sales-report/hospital-sales-product-wise";
// import RateContract from "./pages/rate-contract";
// import RateContractDetails from "./pages/rate-contract/rc-details";
import FilterContextProvider from "./pages/hospital-sales-report/filterContext";
import ProductWiseBreakUp from "./pages/inventory-analysis/inventory-breakup/productwise-breakup";
import { Appcues_User_Onboarding } from "./services/config";

/** BugSnag */
Bugsnag.start({
  apiKey: "16ead7805e5734e368f4bea49c4c8f97",
  plugins: [new BugsnagPluginReact()]
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
const INTERCOM_APP_ID = "fqcsu1do";

const newRelicInit = () => {
  if (process.env.NODE_ENV !== "development") {
    const script = document.createElement("script");
    script.src = "/new-relic-production.js";
    script.async = true;
    document.body.appendChild(script);
  }
};

const App = () => {
  useEffect(() => {
    newRelicInit();
    if (Appcues_User_Onboarding) {
      window.AppcuesSettings = { enableURLDetection: true };
    }
  }, []);
  ReactGA.initialize("G-NQZ9KCVN74");
  ReactGA.send("pageview");
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <ThemeProvider>
          <AppContextProvider>
            <IntercomProvider appId={INTERCOM_APP_ID}>
              <Route exact path="/" component={Login} />
              <Route exact path="/onboarding" component={SelfOnboarding} />
              <Route exact path="/create_password" component={CreatePassword} />
              <Route
                exact
                path="/reset_password/:token/:email"
                component={ResetPassword}
              />
              <Route
                exact
                path="/otp_verification"
                component={OtpVerification}
              />
              <FilterContextProvider>
                <Route
                  exact
                  path="/company/:id/sku_wise_breakdown/:type"
                  component={ProductReportSku}
                />
                <Route exact path="/company" component={ProductReportSku} />
                <Route
                  exact
                  path="/company/product_report/:type?"
                  component={ProductReportBranch}
                />
              </FilterContextProvider>
              <Route
                exact
                path="/company/company_members"
                component={KeyAccountManagerReport}
              />
              <FilterContextProvider>
                <Route
                  exact
                  path="/company/hospital_sales"
                  component={HospitalSalesReport}
                />
                <Route
                  exact
                  path="/company/:id/hospital_sales_city_wise"
                  component={HospitalSalesCityWise}
                />
                <Route
                  exact
                  path="/company/:id/hospital_sales_group_wise"
                  component={HospitalSalesGroupWise}
                />
                <Route
                  exact
                  path="/company/:id/:city/hospital_sales_hospital_wise"
                  component={HospitalSalesHospitalWise}
                />
                <Route
                  exact
                  path="/company/:id/hospital_sales_product_wise"
                  component={HospitalSalesPoductWise}
                />
              </FilterContextProvider>
              {/* <Route
                exact
                path="/company/rate_contract"
                component={RateContract}
              />
              <Route
                exact
                path="/company/rate_contract/rc_details/:rate_contract_id"
                component={RateContractDetails}
              /> */}
              <Route
                exact
                path="/company/orders_report/index"
                component={OrderReport}
              />
              <FilterContextProvider>
                <Route
                  exact
                  path="/company/insights"
                  component={CustomerPerformance}
                />
                <Route
                  exact
                  path="/company/insights/:id/customer_performance_breakdown"
                  component={CustomerPerformanceDetails}
                />
                <Route
                  exact
                  path="/company/insights/:id/customer_centre_products_breakdown"
                  component={CustomerCentreProductsBreakDown}
                />
              </FilterContextProvider>
              <FilterContextProvider>
                <Route
                  exact
                  path="/company/potential_hospital"
                  component={PotentialHospital}
                />
                <Route
                  exact
                  path="/company/potential_hospital/:id"
                  component={PotentialHospital}
                />
              </FilterContextProvider>
              <Route
                exact
                path="/company/product_performance"
                component={ProductPerformance}
              />
              <Route
                exact
                path="/company/wallet_share"
                component={WalletShareAnalytics}
              />
              <Route
                exact
                path="/company/hospitals_statistics/:id"
                component={HospitalsStatistics}
              />

              <Route
                exact
                path="/company/customers_with_centers"
                component={HospitalGroupWithCenters}
              />
              <Route
                exact
                path="/company/individual_centers/:id"
                component={IndividualCenters}
              />
              <Route
                exact
                path="/company/inventory_analysis"
                component={InventoryAnalysis}
              />
              <Route
                path="/company/inventory_analysis/:branchId/:skuId/:tab/productwise_breakup/:expiryRange"
                component={ProductWiseBreakUp}
              />
              <ProtectedRoute />
              <Intercom />
            </IntercomProvider>
          </AppContextProvider>
        </ThemeProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
