import React, { useContext } from "react";
import Primitives from "../../../../components/primitives";
import Card from "../../../../lib/card";
import Table from "../../../../components/table-two";
import NoResults from "../../../../components/no-results";
import ProgressTag from "../../../../components/progress-tag";
import { AppContext } from "../../../../utils/context";
import useApiRequest from "../../../../hooks/useApiRequest";
import Loading from "../../../../components/loading";

const status = {
  regular: <ProgressTag statusCode="success">Regular</ProgressTag>,
  not_regular: <ProgressTag statusCode="warning">Not Regular</ProgressTag>,
  not_a_customer: <ProgressTag statusCode="error">Not a Customer</ProgressTag>
};

const column = [
  {
    heading: "RANK",
    type: "string",
    width: 0.5,
    sort: false
  },
  {
    heading: "HOSPITAL NAME",
    type: "string",
    width: 3,
    sort: false
  },
  {
    heading: " ",
    type: "string",
    width: 1,
    sort: false
  }
];

const tableProps = {
  column: column,
  fontSize: { head: 1, row: 1 },
  justify: "flex-start",
  pl: 1
};

const modifyData = data =>
  data &&
  data["top_hospitals"] &&
  data["top_hospitals"].map((item, index) => ({
    rank: index + 1,
    hospitalName: item["hospital_name"],
    "":
      item["hospital_status"] in status ? (
        <Primitives.Flex width={1} justifyContent="flex-end">
          {status[item["hospital_status"]]}
        </Primitives.Flex>
      ) : (
        item["hospital_status"]
      )
  }));

const SalesStatus = () => {
  const { host } = useContext(AppContext);
  const request = `${host}/smartchain/wallet_analytics/top_aknamed_customer?`;
  const { data, isLoaded } = useApiRequest(host, encodeURI(request));
  return (
    <Card
      details={
        <Primitives.Flex minHeight="20vh" maxHeight="40vh">
          {isLoaded ? (
            data &&
            data["top_hospitals"] &&
            data["top_hospitals"].length > 0 ? (
              <Primitives.Flex width={1} overflow="scroll">
                <Primitives.Box width={1}>
                  <Table
                    data={modifyData(data)}
                    {...tableProps}
                    isResponsive={true}
                    responsiveWidth={[700, "unset", "unset"]}
                    responsiveHeights={["20vh", "40vh"]}
                    displayLockIcon={["display", "none", "none"]}
                  />
                </Primitives.Box>
              </Primitives.Flex>
            ) : (
              <Primitives.Flex m="auto">
                <NoResults />
              </Primitives.Flex>
            )
          ) : (
            <Primitives.Flex m="auto">
              <Loading onlySpinner />
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      }
      downloadApi={request}
      title="Sales Status At Top 10 Aknamed Customers"
    />
  );
};

export default SalesStatus;
