import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../utils/context";
import DesktopNavigation from "../../lib/responsive-navigation/desktop-navigation";
import useApiRequest from "../../hooks/useApiRequest";
import Primitives from "../../components/primitives";
import Pagination from "../../components/pagination";
import Header from "./header";
import TableSection from "./table";
import { colors } from "../../components/utils/theme";
import NavBar from "../../lib/responsive-navigation/mobile-navigation";
import {
  array,
  bool,
  func,
  node,
  number,
  string,
  object,
  oneOfType
} from "prop-types";

const propTypes = {
  api: string,
  column: array,
  clickHandler: func,
  columnIndex: number,
  cursor: string,
  details: node,
  dataCountText: oneOfType([string, node]),
  displayLockIcon: array,
  filterConfig: oneOfType([array, object]),
  filterUpdate: func,
  getHeaderId: func,
  getPageNumber: func,
  getHospitalData: func,
  getRateContractData: func,
  getHospitalCentersData: func,
  getCustomerData: func,
  getColumnSum: func,
  getFilterMonth: func,
  headClick: bool,
  isDownloadable: bool,
  isbackButtonRequired: bool,
  isSortingRequire: bool,
  isBorderRequire: bool,
  isBackgroundRequire: bool,
  modifyReports: func,
  responsiveWidth: oneOfType([string, array]),
  responsiveHeight: oneOfType([string, array]),
  reportTitle: string,
  salesStatistics: array,
  sort: string,
  subNavIndex: oneOfType([object, array]),
  selected: number,
  showHamburger: bool,
  striped: bool,
  title: node,
  toolTipText: object
};
const defaultProps = {
  isDownloadable: true,
  filterPreApllied: false
};

const orderPerPage = 20;

const Reports = ({
  api,
  column,
  clickHandler,
  columnIndex,
  cursor,
  dataCountText,
  details,
  displayLockIcon,
  filterConfig,
  filterUpdate,
  getHeaderId,
  getHospitalData,
  getRateContractData,
  getHospitalCentersData,
  getCustomerData,
  getColumnSum,
  headClick,
  salesStatistics,
  isDownloadable,
  isbackButtonRequired,
  isSortingRequire,
  isBorderRequire,
  isBackgroundRequire,
  modifyReports,
  responsiveWidth,
  responsiveHeight,
  reportTitle,
  sort,
  subNavIndex,
  selected,
  showHamburger,
  striped,
  title,
  toolTipText
}) => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { host, loggedInUser } = useContext(AppContext);
  document.title =
    loggedInUser && loggedInUser.user
      ? loggedInUser.user.first_name + " - SmartChain by Aknamed "
      : "SmartChain by Aknamed";
  const [pageNumber, setpageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [request, updateRequest] = useState("");
  const { data, isLoaded } = useApiRequest(host, encodeURI(request));
  /** whenever dropdown changes, setting page number and current page values to default value 1  */
  useEffect(() => {
    setpageNumber(1);
    setCurrentPage(1);
  }, [api]);

  useEffect(() => {
    getHospitalData && getHospitalData(data);
    getRateContractData && getRateContractData(data);
    getHospitalCentersData && getHospitalCentersData(data);
    getCustomerData && getCustomerData(data);
    getColumnSum && data["column_sum"] && getColumnSum(data["column_sum"]);
  }, [
    getHospitalData,
    getRateContractData,
    getCustomerData,
    getColumnSum,
    getHospitalCentersData,
    data
  ]);

  /** setting pageNumber for pagination */
  const getPageNumber = page => {
    setpageNumber(page);
  };

  /** getting search keyword from input component */
  const getSearch = text => {
    setSearchText(text);
  };

  /** download function for the reports */
  const downloadHandler = () => {
    const csvHost =
      request?.split("?")[0] + ".downloadOptions?" + request?.split("?")[1];
    return csvHost;
  };

  /** after Search,re-assigning pageNumber to default values */
  const searchHandler = text => {
    getPageNumber(1);
    setCurrentPage(1);
    getSearch(text);
  };

  /** conditionally rendering navbar */
  const navbarDisplayHandler = () => {
    setShowNavbar(!showNavbar);
  };

  useEffect(() => {
    updateRequest(
      `${host}/${api}&page=${pageNumber}&name_cont=${searchText}&q[s]=${sort}`
    );
  }, [api, searchText, pageNumber, sort]);

  /** pagination */
  const indexOfLastOrder = currentPage * orderPerPage;
  const indexOfFirstOrder = indexOfLastOrder - orderPerPage;

  const paginationChangeHandler = pageNumber => {
    if (pageNumber.selected > 0) {
      setCurrentPage(pageNumber.selected + 1);
      getPageNumber(pageNumber.selected + 1);
    } else if (pageNumber.selected === 0) {
      getPageNumber(1);
      setCurrentPage(1);
    }
  };

  return (
    <>
      <Primitives.Box display={["none", "none", "block"]}>
        <DesktopNavigation
          selected={selected}
          subIndex={subNavIndex}
          loggedInUser={loggedInUser}
        />
      </Primitives.Box>
      {showNavbar ? (
        <NavBar
          navbarDisplayHandler={navbarDisplayHandler}
          title={title}
          selected={selected}
        />
      ) : (
        <Primitives.Flex
          backgroundColor="white"
          flexDirection="column"
          fontFamily="Lato"
          fontStyle="normal"
          width={1}
        >
          <Header
            api={api}
            details={details && details}
            dataCountText={dataCountText}
            downloadApi={downloadHandler()}
            email={loggedInUser && loggedInUser.user && loggedInUser.user.email}
            filterConfig={{
              ...filterConfig,
              ...(data &&
                data["current_warehoues_list"] && {
                  currentBranchesList: data["current_warehoues_list"]
                }),
              ...(data &&
                data["original_warehouse_list"] && {
                  originBranchesList: data["original_warehouse_list"]
                })
            }}
            filterUpdate={filterUpdate}
            salesStatistics={salesStatistics}
            isLoaded={isLoaded}
            isDownloadable={isDownloadable}
            isbackButtonRequired={isbackButtonRequired}
            navbarDisplayHandler={navbarDisplayHandler}
            selected={selected}
            searchHandler={searchHandler}
            showHamburger={showHamburger}
            title={title}
            reportTitle={reportTitle}
          />
          {data && data["total_items"] > 0 && (
            <Primitives.Text
              color={colors.text[1]}
              fontSize={0}
              px={[1, 3]}
              py="8px"
            >
              Showing {indexOfFirstOrder + 1}-
              {indexOfLastOrder < data["total_items"]
                ? indexOfLastOrder
                : data["total_items"]}{" "}
              of {data["total_items"]} orders
            </Primitives.Text>
          )}
          <Primitives.Flex px={[0, 3]} mt={1}>
            <TableSection
              column={column}
              cursor={cursor}
              displayLockIcon={displayLockIcon}
              headClick={headClick}
              isLoaded={isLoaded}
              isSortingRequire={isSortingRequire}
              isBorderRequire={isBorderRequire}
              isBackgroundRequire={isBackgroundRequire}
              pageNumber={currentPage}
              data={
                typeof data == "object"
                  ? modifyReports(data, api, orderPerPage)
                  : {}
              }
              responsiveWidth={responsiveWidth && responsiveWidth}
              responsiveHeight={responsiveHeight && responsiveHeight}
              toolTipText={toolTipText}
              getHeaderId={getHeaderId}
              clickHandler={clickHandler}
              columnIndex={columnIndex}
              striped={striped}
            />
          </Primitives.Flex>
          {data["total_items"] > 20 && (
            <>
              <Primitives.Flex
                backgroundColor={colors.accent[0]}
                position="fixed"
                bottom={0}
                zIndex={1}
                display={["flex", "none"]}
                width="100vw"
              >
                <Pagination
                  changeHandler={paginationChangeHandler}
                  forcePage={currentPage - 1}
                  marginPagesDisplayed={-1}
                  pageCount={data["total_items"] / 20}
                  pageRangeDisplayed={2}
                  backgroundColor={colors.accent[0]}
                />
              </Primitives.Flex>
              <Primitives.Flex
                backgroundColor={colors.accent[0]}
                position="fixed"
                bottom={0}
                display={["none", "flex"]}
                justifyContent="flex-end"
                width="100vw"
                zIndex={1}
              >
                <Pagination
                  changeHandler={paginationChangeHandler}
                  forcePage={currentPage - 1}
                  marginPagesDisplayed={1}
                  pageCount={data["total_items"] / 20}
                  pageRangeDisplayed={5}
                  backgroundColor={colors.accent[0]}
                />
              </Primitives.Flex>
            </>
          )}
        </Primitives.Flex>
      )}
    </>
  );
};

Reports.propTypes = propTypes;
Reports.defaultProps = defaultProps;
export default Reports;
