import React from "react";
import AknamedLogo from "../../../components/glyphs/elements/aknamed-logo";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";

const Footer = () => (
  <Primitives.Flex width={430} mt={3} borderTop="1px solid rgb(238, 240, 246)">
    <Primitives.Flex
      width={430}
      alignItems="center"
      justifyContent="space-between"
      my={1}
    >
      <Primitives.Flex ml={15}>
        <AknamedLogo height={35} width={60} />
      </Primitives.Flex>
      <Primitives.Text fontSize={2} fontWeight={1} color={colors.text[1]}>
        A Proprietary Product of Akna Medical Pvt. Ltd
      </Primitives.Text>
    </Primitives.Flex>
  </Primitives.Flex>
);

export default Footer;
