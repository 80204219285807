import React, { useEffect } from "react";
import {
  number,
  object,
  oneOfType,
  string,
  node,
  bool,
  func
} from "prop-types";
import ToolTipIcon from "../../components/glyphs/elements/tool-tip";
import Primitives from "../../components/primitives";
import ToolTipComponent from "../../components/tool-tip/ToolTipComponent";

const propTypes = {
  backgroundColor: string,
  boxShadow: oneOfType([number, string]),
  content: oneOfType([string, node]),
  contentTextAlign: string,
  contentWidth: oneOfType([number, string]),
  contentHeight: oneOfType([number, string]),
  delay: number,
  fontSize: oneOfType([number, string]),
  getPosition: func,
  height: oneOfType([number, string]),
  left: oneOfType([number, string]),
  padding: oneOfType([number, string]),
  toolTipTriangle: object,
  top: oneOfType([number, string]),
  value: object,
  width: oneOfType([number, string]),
  active: bool,
  setActive: func
};
const defaultProps = {
  delay: 400,
  top: 30,
  fontSize: "10px",
  height: 12,
  width: 12
};

const TooltipOnClick = ({
  backgroundColor,
  boxShadow,
  content,
  contentTextAlign,
  contentWidth,
  contentHeight,
  fontSize,
  getPosition,
  height,
  left,
  padding,
  toolTipTriangle,
  top,
  value,
  width,
  active,
  setActive
}) => {
  const handleClick = e => {
    if (!e.target.closest(".sc-dIsUp")) {
      setActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <Primitives.Flex
      display="inline-block"
      onClick={() => setActive(true)}
      position="relative"
    >
      {value || <ToolTipIcon height={height} width={width} />}
      {active && (
        <ToolTipComponent
          backgroundColor={backgroundColor}
          borderRadius="10px"
          boxShadow={boxShadow}
          left={left}
          top={top}
          toolTipTriangle={toolTipTriangle}
          width={contentWidth}
          height={contentHeight}
          padding={padding}
          translate={"0%"}
        >
          <Primitives.Flex
            fontSize={fontSize}
            textAlign={contentTextAlign}
            width={1}
            ref={el => {
              if (!el) return;
              getPosition(el.getBoundingClientRect().bottom);
            }}
          >
            {content}
          </Primitives.Flex>
        </ToolTipComponent>
      )}
    </Primitives.Flex>
  );
};
TooltipOnClick.propTypes = propTypes;
TooltipOnClick.defaultProps = defaultProps;
export default TooltipOnClick;
