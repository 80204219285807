import React, { useContext } from "react";
import Primitives from "../../../components/primitives";
import ConvertMoneyToLakhsCrores from "../../../components/utils/convert-money-to-lakh-crores";
import useApiRequest from "../../../hooks/useApiRequest";
import Card from "../../../lib/card";
import { AppContext } from "../../../utils/context";

const dataObj = {
  uniq_products: "Products",
  total_quantity: "Qty",
  total_value: "Value"
};
const arr = [
  {
    heading: "INVENTORY OPENING",
    key: "current_month_inventory_details",
    data: dataObj
  },
  {
    heading: "INVENTORY TILL DATE",
    key: "till_date_inventory_details",
    data: dataObj
  }
];

const InventoryHeader = () => {
  const { host } = useContext(AppContext);
  const { data } = useApiRequest(
    host,
    `${host}/smartchain/inventory_analytics/inventory_soh_details`
  );
  return (
    <Primitives.Flex minHeight="10vh">
      {data && data.current_month_inventory_details && (
        <Card
          borderRadius={8}
          boxShadow="0px 0px 15px rgba(0, 0, 0, 0.1);"
          details={
            <Primitives.Flex>
              {arr.map((item, index) => (
                <Primitives.Flex
                  borderLeft={index === 1 && "1px solid rgba(0, 0, 0, 0.08);"}
                  flex={1}
                  flexDirection="column"
                  key={item.key}
                >
                  <Primitives.Box
                    color="#768698"
                    fontSize={["10px", 1, "13px"]}
                    height={[32, 32, "auto"]}
                    mb={[0, 0, 1]}
                    mr={2}
                    pl={index === 1 && 3}
                  >
                    {data[item.key].current_month &&
                      data[item.key].current_month.toUpperCase()}{" "}
                    {item.heading}
                  </Primitives.Box>
                  <Primitives.Flex
                    flexWrap="wrap"
                    flexDirection={["row", "row", "row"]}
                    pl={index === 1 && 3}
                  >
                    {Object.keys(arr[0].data).map((x, index) => (
                      <Primitives.Flex
                        key={x}
                        flexDirection="column"
                        minWidth={["17vw", "17vw", "auto"]}
                        mr={index < 2 && [0, 0, 28]}
                        mb={index < 2 && [2, 2, 0]}
                      >
                        <Primitives.Text
                          color="#525983"
                          fontSize={["14px", 3, "22px"]}
                          fontWeight={2}
                        >
                          {index === 2 && "₹"}
                          {ConvertMoneyToLakhsCrores(data[item.key][x]) || 0}
                        </Primitives.Text>
                        <Primitives.Text
                          color="#768698;"
                          fontSize={["10px", 1, "13px"]}
                        >
                          {arr[0].data[x]}
                        </Primitives.Text>
                      </Primitives.Flex>
                    ))}
                  </Primitives.Flex>
                </Primitives.Flex>
              ))}
            </Primitives.Flex>
          }
          isDownloadable={false}
          innerMargin={2}
          mx={0}
          my={0}
          width={[1, 1, "60vw"]}
        />
      )}
    </Primitives.Flex>
  );
};

export default InventoryHeader;
