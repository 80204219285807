import React, { useContext, useEffect, useState } from "react";
import Primitives from "../../../../components/primitives";
import Card from "../../../../lib/card";
import DoughnutChart from "../../../../components/charts/doughnut";
import Cta from "../../../../components/cta";
import { colors } from "../../../../components/utils/theme";
import Back from "../../../../components/glyphs/elements/back";
import Circle from "../../../../components/glyphs/elements/circle";
import useApiRequest from "../../../../hooks/useApiRequest";
import { AppContext } from "../../../../utils/context";
import Loading from "../../../../components/loading";
import NoResults from "../../../../components/no-results";

const filters = {
  "This month": "this_month",
  "Last Month": "last_month",
  "Last 6 months": "last_six_month",
  "Year Till date": "ytd"
};

const findFilterMonth = userStartDate => {
  const userMonth = userStartDate && userStartDate.getMonth();
  const currentMonth = new Date().getMonth();
  const userYear = userStartDate && userStartDate.getFullYear();
  const currentYear = new Date().getFullYear();
  if (currentYear === userYear) {
    if (currentMonth === userMonth) {
      return 0;
    } else if (Math.abs(currentMonth - userMonth) < 6) {
      return 1;
    } else if (Math.abs(currentMonth - userMonth) > 6) {
      return 2;
    } else {
      return 3;
    }
  } else {
    /** if user year is previous to current year */
    const totalMonth = Math.abs(12 - userMonth);
    return totalMonth + currentMonth;
  }
};

const labels = ["Hospitals buying", "Hospitals Not Buying"];

const content = loggedInUser => [
  {
    text: `Hospitals buying ${loggedInUser &&
      loggedInUser["user"] &&
      loggedInUser["user"]["company_name"]} products`,
    color: colors.accent[3]
  },
  {
    text: `Hospitals not buying ${loggedInUser &&
      loggedInUser["user"] &&
      loggedInUser["user"]["company_name"]} products`,
    color: colors.accent[1]
  }
];

const Hospitals = () => {
  const { host, loggedInUser } = useContext(AppContext);
  const userStartDate =
    loggedInUser &&
    loggedInUser["user"] &&
    loggedInUser["user"]["data_started_at"] &&
    new Date(loggedInUser["user"]["data_started_at"]);

  const [request, updateRequest] = useState(
    `${host}/smartchain/wallet_analytics/hospitals_contribution?`
  );
  const [values, setValues] = useState([]);
  const [insideText, setInsideText] = useState([]);
  const [filterValue, setFiterValue] = useState("This month");

  const { data, isLoaded } = useApiRequest(host, encodeURI(request));

  const getFilterValues = text => {
    updateRequest(
      `${host}/smartchain/wallet_analytics/hospitals_contribution?quick_filter=${filters[text]}`
    );
    setFiterValue(text);
  };

  useEffect(() => {
    setValues([data["purchasing_hospitals"], data["remaining_hospitals"]]);
    data &&
      data["purchasing_hospitals"] !== undefined &&
      setInsideText([
        data["purchasing_hospitals"] + "/" + data["total_hospitals"],
        parseInt(
          (data["purchasing_hospitals"] / data["total_hospitals"]) * 100
        ) + "% Coverage"
      ]);
  }, [data]);

  return (
    <Card
      details={
        <Primitives.Flex
          flexDirection="column"
          minHeight={["25vh", "20vh", "37vh"]}
          m="20px"
        >
          {isLoaded ? (
            <>
              <Primitives.Flex
                alignItems="center"
                flexDirection={["column", "row"]}
                justifyContent={["unset", "space-around"]}
                mt={3}
              >
                <Primitives.Flex height={[150, 230]}>
                  {insideText.length > 0 ? (
                    <DoughnutChart
                      backgroundColor={[colors.accent[3], colors.accent[1]]}
                      chartData={
                        values && values.map(value => value && value.toFixed(2))
                      }
                      insideTextNeeded={true}
                      labels={labels}
                      text={insideText}
                      tooltip={true}
                    />
                  ) : (
                    <NoResults />
                  )}
                </Primitives.Flex>
                <Primitives.Flex flexDirection="column" mt={[2, "unset"]}>
                  {insideText.length > 0 &&
                    content(loggedInUser).map((item, index) => (
                      <Primitives.Flex mb={2} key={index}>
                        <Primitives.Flex
                          alignItems="center"
                          justifyContent="center"
                          height={["6px", "15px"]}
                          mr={1}
                          width={["7px", "14px"]}
                        >
                          <Circle fill={item.color} />
                        </Primitives.Flex>
                        <Primitives.Flex
                          fontSize={["10px", 1]}
                          letterSpacing="0.5px"
                        >
                          {item.text}
                        </Primitives.Flex>
                      </Primitives.Flex>
                    ))}
                </Primitives.Flex>
              </Primitives.Flex>
              {insideText.length > 0 && (
                <Primitives.Flex
                  alignItems="center"
                  flex={1}
                  justifyContent="center"
                  mt={5}
                >
                  <Cta
                    color={colors.primary[3]}
                    fontSize={1}
                    fontWeight={2}
                    hover={{
                      color: colors.primary[3],
                      textDecorationLine: true
                    }}
                    label={
                      <Primitives.Flex alignItems="flex-end">
                        <Primitives.Text mr={1} fontSize={1} fontWeight={2}>
                          View remaining{" "}
                          {Math.abs(
                            data &&
                              data["total_hospitals"] &&
                              data["purchasing_hospitals"] &&
                              data["total_hospitals"] -
                                data["purchasing_hospitals"]
                          )}{" "}
                          Potential Hospitals
                        </Primitives.Text>
                        <Primitives.Box transform="rotateZ(180deg)">
                          <Back
                            height={12}
                            width={12}
                            fill={colors.primary[3]}
                          />
                        </Primitives.Box>
                      </Primitives.Flex>
                    }
                    link={`/company/hospitals_statistics/${filters[filterValue]}`}
                    // type="hyperlink"
                    textdecorationline={false}
                  />
                </Primitives.Flex>
              )}
            </>
          ) : (
            <Primitives.Flex
              alignItems="center"
              height={1}
              justifyContent="center"
              width={1}
            >
              <Loading onlySpinner />
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      }
      downloadApi={request}
      filters={Object.keys(filters).slice(
        0,
        findFilterMonth(userStartDate) + 1
      )}
      getFilterValues={getFilterValues}
      isDownloadable={false}
      title={
        <Primitives.Text>
          Purchasing Hospitals{" "}
          <Primitives.Text fontWeight={1}>vs</Primitives.Text> Total Aknamed
          Hospitals
        </Primitives.Text>
      }
    />
  );
};

export default Hospitals;
