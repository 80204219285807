import React, { useContext, useState } from "react";
import Primitives from "../../components/primitives";
import { colors } from "../../components/utils/theme";
import ToolTipView from "../../lib/tooltip-view";
import Reports from "../reports";
import Title from "../../lib/title";
import checkSortingIndex from "../../lib/check-sorting-index";
import Download from "../../components/glyphs/elements/download";
import { AppContext } from "../../utils/context";
import Cta from "../../components/cta";
import DropdownComponent from "../mobile-navigation-dropdown";

const checkNetAmount = (data, setNetAmount) =>
  data &&
  data["items"] &&
  data["items"].length &&
  data["items"].reduce((prev, curr) => prev || curr["net_amount"], false) &&
  setNetAmount(true);

const column = (columnSum, data, netAmount, setNetAmount) => [
  {
    footerData: "Total",
    heading: "Invoice Date",
    type: "string",
    width: 2,
    sort: false
  },
  {
    heading: "Invoice No.",
    type: "string",
    width: 2,
    sort: false
  },
  {
    heading: "Product Name",
    type: "string",
    width: 3,
    sort: false
  },
  {
    heading: "Batch Number",
    type: "string",
    width: 2
  },
  {
    heading: "Batch Expiry",
    type: "string",
    width: 2
  },
  {
    heading: "Hospital Name/Doctor Name",
    type: "string",
    width: 3,
    sort: false
  },
  {
    align: "flex-end",
    footerData: `${Math.round(columnSum["quantity_sum"])}`,
    heading: "Sales Quantity",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    footerData: `${Math.round(columnSum["return_quantity_sum"])}`,
    heading: "Return Quantity",
    type: "number",
    width: 1
  },
  {
    footerData:
      netAmount || checkNetAmount(data, setNetAmount) ? netAmount : null,
    heading:
      netAmount || checkNetAmount(data, setNetAmount) ? "Net Amount" : "",
    type: "number",
    width: netAmount ? 1 : 0,
    align: "flex-end"
  }
];
const podLinkColor = "#555CA5";
const desc = " Sales report with invoice, batch and customer details";
const toolTip = () => <ToolTipView desc={desc} />;

const OrderReport = () => {
  const [columnSum, setColumnSum] = useState({});
  const [sort, updateSort] = useState(null);
  const [data, updateData] = useState();
  const [netAmount, setNetAmount] = useState(false);
  const { loggedInUser } = useContext(AppContext);
  const api = `smartchain/orders_report`;
  const [request, updateRequest] = useState(`${api}?include_retail_sales=true`);

  const getColumnSum = data => {
    setColumnSum(data);
  };

  const index = {
    3: "batch_no+",
    4: "batch_expiry+",
    6: "quantity+",
    7: "return_quantity+",
    8: "net_amount+"
  };
  const getHeaderId = (id, order) =>
    checkSortingIndex(index, id, order, updateSort);

  /** renders a link to POD */
  const renderPODLink = podUrl =>
    !!(podUrl && loggedInUser?.user?.pod_access) && (
      <Cta
        color={podLinkColor}
        hover={{ color: colors.primary[6] }}
        label={
          <Primitives.Flex alignItems="center" mt={1}>
            <Download stroke={podLinkColor} width="12px" height="12px" />
            <Primitives.Text ml="5px" color={podLinkColor}>
              POD
            </Primitives.Text>
          </Primitives.Flex>
        }
        link={podUrl}
        textdecorationline={false}
        target="_blank"
        type="hyperLink"
        fontWeight="400"
      />
    );
  const modifyReports = data => {
    return (
      data &&
      data["items"] &&
      data["items"].map(item => ({
        invoiceDate: item.invoice_date,
        invoiceNumber: (
          <Primitives.Flex
            justifyContent="space-between"
            flexDirection="column"
          >
            <Primitives.Text> {item.invoice_no} </Primitives.Text>
            {renderPODLink(item.pod)}
          </Primitives.Flex>
        ),
        productName: item.product_name,
        batchNumber: item.batch_no,
        batchExpiry: item.batch_expiry,
        hospital: item.hospital,
        quantity: item.quantity,
        returnQuantity: item.return_quantity,
        ...(netAmount && {
          netamount: item.net_amount
        })
      }))
    );
  };

  /** For mobile view */
  const initialDropdownId = 1;

  /** update request according to selected filter */
  const filterUpdate = queryObj => {
    const {
      hospitals,
      masterSku,
      branches,
      Tenant,
      date,
      retailSales,
      istrTransfer
    } = queryObj;
    const dateQuery = date
      ? date
          .replace("date_start", "&q[sales_invoice_invoice_date_gteq]")
          .replace("date_end", "q[sales_invoice_invoice_date_lteq]")
          .replaceAll("/", "-")
      : "";
    updateRequest(
      `${api}?${hospitals ?? ""}${masterSku ?? ""}${branches ?? ""}${Tenant ??
        ""}${dateQuery}${
        retailSales ? "&include_retail_sales=" + retailSales : ""
      }${istrTransfer ? "&include_ist_stock=" + istrTransfer : ""}`
    );
  };

  return (
    <Reports
      api={request}
      column={column(columnSum, data, netAmount, setNetAmount)}
      filterConfig={{
        hospitals: true,
        masterSku: true,
        branches: true,
        "Date Range": true,
        Tenant: true,
        istrTransfer: {
          defaultValue: false
        },
        retailSales: {
          defaultValue: true
        },
        mobileFilters: [
          { text: "Hospital", filterText: "hospitals" },
          { text: "Master SKU", filterText: "masterSku" },
          { text: "Branch", filterText: "branches" },
          { text: "Date Range", filterText: "Date Range" },
          { text: "Tenant", filterText: "Tenant" },
          {
            text: "Include Internal Stock Transfer",
            filterText: "istrTransfer"
          },
          { text: "Include Patient Sales", filterText: "retailSales" }
        ],
        defaultFilter: "Date Range",
        defaultDate: {
          startDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
          ),
          endDate: new Date()
        }
      }}
      filterUpdate={filterUpdate}
      // filterStateId={{ selectedRetailSales: true }}
      filterPreApllied={true}
      getColumnSum={getColumnSum}
      getHeaderId={getHeaderId}
      getCustomerData={updateData}
      modifyReports={modifyReports}
      pageId="orderReport"
      responsiveHeight={["30vh", "55vh"]}
      sort={sort}
      selected={1}
      title={
        <>
          <Primitives.Flex
            alignItems={["center", "flex-start"]}
            flexDirection={["row", "column"]}
          >
            <Title>
              <Primitives.Text mb={["unset", 1]}>
                Sales Invoices
              </Primitives.Text>
            </Title>
            {toolTip()}
          </Primitives.Flex>
          <DropdownComponent initialDropdownId={initialDropdownId} />
        </>
      }
      reportTitle="Sales Invoices"
    />
  );
};

export default OrderReport;
