import React, { useEffect, useRef, useState } from "react";
import Download from "../../components/glyphs/elements/download";
import { func, string } from "prop-types";
import Button from "../../components/button";
import { colors } from "../../components/utils/theme";
import Primitives from "../../components/primitives";
import ListDropdown from "../../components/list-dropdown";

const propTypes = {
  selectHandler: func,
  label: string
};

const defalutProps = {
  label: "Download"
};

const downloadOptions = [
  {
    index: 0,
    text: "Download XLSX"
  },
  {
    index: 1,
    text: "Download CSV"
  },
  {
    index: 2,
    text: "Download PDF"
  }
];

const DownloadBtn = ({ selectHandler, label }) => {
  const [downloadCollapsed, setDownloadCollapsed] = useState(false);
  const node = useRef();
  const handleClick = e => {
    if (node && node.current && node.current.contains(e.target)) {
      // inside
      return;
    }
    // outside click
    setDownloadCollapsed(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <Primitives.Flex position="relative" ref={node}>
      <Button
        active={{
          backgroundColor: colors.text[2],
          color: colors.text[0],
          borderColor: "inherit"
        }}
        backgroundColor="background-light"
        clickHandler={() => setDownloadCollapsed(!downloadCollapsed)}
        color={colors.text[1]}
        glyph={<Download height={20} width={20} />}
        hover={{
          backgroundColor: colors.text[2],
          color: colors.text[0]
        }}
        label={label}
        lineHeight={3}
        px={["10px", 2]}
        py={["6px", 10]}
      />
      {downloadCollapsed && (
        <ListDropdown
          options={downloadOptions}
          right={0}
          selectHandler={option => selectHandler(option)}
          width="auto"
        />
      )}
    </Primitives.Flex>
  );
};

DownloadBtn.propTypes = propTypes;
DownloadBtn.defalutProps = defalutProps;
export default DownloadBtn;
