import React, { useContext, useEffect, useState } from "react";
import Primitives from "../../../components/primitives";
import BarChart from "../../../components/charts/bar";
import Tags from "../../../components/tags";
import Checkbox from "../../../components/checkbox";
import ConvertMoneyToLakhsCrores from "../../../components/utils/convert-money-to-lakh-crores";
import Loading from "../../../components/loading";
import { indianNumberFormat } from "../../../components/utils";
import useApiRequest from "../../../hooks/useApiRequest";
import { AppContext } from "../../../utils/context";
import Dropdown from "../../../components/dropdown";
import TabButtons from "../../../lib/tab-buttons";
import { breakPoint, colors } from "../../../components/utils/theme";
import { func } from "prop-types";

const propTypes = {
  getKey: func,
  setBranchFilter: func
};

const filters = [
  { label: "By Value", key: "total_value" },
  { label: "By Quantity", key: "total_quantity" }
];

const findBranchWithMaxValue = (data, key) => {
  const branchTotalValue = [];
  const branchFilter = [];
  dataArr[key].forEach(item =>
    data.forEach((branch, index) => {
      branchFilter[index] = {
        branch_id: branch.branch_id,
        branch_name: branch.branch_name
      };
      branchTotalValue[index] =
        (branchTotalValue[index] || 0) + branch[item.response].total_value;
    })
  );
  return branchFilter[branchTotalValue.indexOf(Math.max(...branchTotalValue))];
};

const inventory = [
  { label: "Near Expiry", key: 0 },
  {
    label: window.innerWidth < breakPoint ? "Expired" : "Expired Inventory",
    key: 1
  },
  {
    label:
      window.innerWidth < breakPoint ? "Non Moving" : "Non Moving Inventory",
    key: 2
  }
];

const dataArr = [
  [
    {
      backgroundColor: "#FFD670",
      response: "near_expiry_0_to_30_hash",
      label: "0-30 days Exp"
    },
    {
      backgroundColor: "#FDB67A",
      response: "near_expiry_30_to_90_hash",
      label: "30-90 days Exp"
    },
    {
      backgroundColor: "#AAC2FF",
      response: "near_expiry_90_to_180_hash",
      label: "90-180 days Exp"
    }
  ],
  [
    {
      backgroundColor: "#AAC2FF",
      response: "expired_inventory_hash",
      label: "Expired"
    }
  ],
  [
    {
      backgroundColor: "#AAC2FF",
      response: "non_moving_0_to_30_hash",
      label: "Since 30 days"
    },
    {
      backgroundColor: "#FFD670",
      response: "non_moving_0_to_60_hash",
      label: "Since 60 days"
    }
  ]
];

const NearExpiry = ({ getKey, setBranchFilter }) => {
  const { host } = useContext(AppContext);
  const { data: nearExpiry } = useApiRequest(
    host,
    `${host}/smartchain/inventory_analytics/near_expiry_report`
  );
  const { data: expired } = useApiRequest(
    host,
    `${host}/smartchain/inventory_analytics/expired_report`
  );
  const { data: nonMoving } = useApiRequest(
    host,
    `${host}/smartchain/inventory_analytics/non_moving_report`
  );
  const [filterValue, setFiterValue] = useState(0);
  const [key, setKey] = useState(0);
  const [graphCheckbox, updateGraphCheckbox] = useState([1, 1, 1]);
  const [dropdownCollapse, setDropdownCollapse] = useState(false);
  const [tabEvent, setTabEvent] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);

  useEffect(() => {
    const tabData = tabEvent?.[key] && Object.values(tabEvent[key])?.[0];
    if (selectedBranch) {
      tabData &&
        tabData.map(item => {
          if (item?.branch_name === selectedBranch?.branch_name) {
            setBranchFilter({
              branch_name: item.branch_name,
              branch_id: item.branch_id
            });
          }
        });
    } else {
      const result = findBranchWithMaxValue(tabData || [], key);
      if (result) {
        setBranchFilter({
          branch_name: result.branch_name,
          branch_id: result.branch_id
        });
      }
    }
  }, [tabEvent, selectedBranch, key]);

  useEffect(() => {
    setTabEvent([nearExpiry, expired, nonMoving]);
  }, [nearExpiry, expired, nonMoving]);

  const clickHandler = index => {
    setFiterValue(index);
  };

  const keyClickHandler = index => {
    if (tabEvent[index][inventoryObj[index].response]) {
      setKey(index);
      updateGraphCheckbox([1, 1, 1]);
      setDropdownCollapse(false);
    }
  };

  const checkBoxClickHandler = checkIndex => {
    updateGraphCheckbox(
      graphCheckbox.map((item, index) => (index === checkIndex ? !item : item))
    );
  };
  const removeZeroValues = (obj, key, labels) => {
    const filteredArray =
      obj[key] &&
      obj[key].filter(branch => {
        let bool = false;
        labels.map(item => {
          const inventory = branch[item];
          bool =
            bool ||
            inventory.uniq_products !== 0 ||
            inventory.total_quantity !== 0 ||
            inventory.total_value !== 0;
        });
        return bool;
      });

    obj[key] = filteredArray;
    return obj;
  };
  const dataArrFn = (data, dataArr) =>
    dataArr.map(obj => ({
      backgroundColor: obj.backgroundColor,
      data: data.map(item => item[obj.response][filters[filterValue].key]),
      label: obj.label,
      tooltipData: data
    }));
  const inventoryObj = [
    {
      checkboxData:
        nearExpiry &&
        nearExpiry["branch_near_expiry_details"] &&
        dataArrFn(nearExpiry["branch_near_expiry_details"], dataArr[0]),
      chartData:
        nearExpiry &&
        nearExpiry["branch_near_expiry_details"] &&
        dataArrFn(nearExpiry["branch_near_expiry_details"], dataArr[0]),
      result: removeZeroValues(nearExpiry, "branch_near_expiry_details", [
        "near_expiry_0_to_30_hash",
        "near_expiry_30_to_90_hash",
        "near_expiry_90_to_180_hash"
      ]),
      response: "branch_near_expiry_details",
      toolTiplabelArr: [
        "Expiring in 0-30 days :",
        "Expiring in 30-90 days :",
        "Expiring in 90-180 days :"
      ]
    },
    {
      checkboxData:
        expired &&
        expired["branch_expired_details"] &&
        dataArrFn(expired["branch_expired_details"], dataArr[1]),
      chartData:
        expired &&
        expired["branch_expired_details"] &&
        dataArrFn(expired["branch_expired_details"], dataArr[1]),
      result: removeZeroValues(expired, "branch_expired_details", [
        "expired_inventory_hash"
      ]),
      response: "branch_expired_details",
      toolTiplabelArr: ["Expired :"]
    },
    {
      checkboxData:
        nonMoving &&
        nonMoving["branch_non_moving_details"] &&
        dataArrFn(nonMoving["branch_non_moving_details"], dataArr[2]),
      chartData:
        nonMoving &&
        nonMoving["branch_non_moving_details"] &&
        dataArrFn(nonMoving["branch_non_moving_details"], dataArr[2]),
      result: removeZeroValues(nonMoving, "branch_non_moving_details", [
        "non_moving_0_to_30_hash",
        "non_moving_0_to_60_hash"
      ]),
      response: "branch_non_moving_details",
      toolTiplabelArr: ["Since 30 days :", "Since 60 days :"]
    }
  ];

  useEffect(() => {
    getKey(key);
  }, [key]);

  return (
    <>
      {inventoryObj[key].result &&
      inventoryObj[key].result[inventoryObj[key].response] ? (
        <Primitives.Box mt={["20px", "20px", 0]}>
          {inventory.map((item, index) => (
            <TabButtons
              booleanValue={key === item.key}
              clickHandler={() => {
                keyClickHandler(index);
                setSelectedBranch(null);
              }}
              cursor={
                tabEvent[index][inventoryObj[index].response]
                  ? "pointer"
                  : "progress"
              }
              key={item.key}
              label={item.label}
              width={["30vw", "auto"]}
            />
          ))}

          <Primitives.Flex
            justifyContent="space-between"
            flexDirection="column"
            borderTop="1px solid"
            borderColor={colors["pattens-blue"]}
            pl={[1, 0, 0]}
          >
            <Primitives.Flex
              alignSelf={["inherit", "flex-end"]}
              mb={2}
              mr={4}
              pt={1}
            >
              <Primitives.Flex flex={1}>
                <Primitives.Text
                  alignSelf="center"
                  color="#7A8A9C"
                  display={["none", "inherit", "inherit"]}
                  fontSize={0}
                  mr={1}
                >
                  View Data:
                </Primitives.Text>
                <Tags
                  clickHandler={clickHandler}
                  highlightColor="#FDE6DE"
                  highlightTextColor="#F58158"
                  selected={filterValue}
                  items={filters}
                  mr={1}
                />
              </Primitives.Flex>
              <Primitives.Flex
                display={["inherit", "none", "none"]}
                flex={0.7}
                justifyContent="flex-end"
                mr={3}
              >
                <Dropdown
                  className="dropdown-filter"
                  collapsed={dropdownCollapse}
                  defaultValue={inventory[key].label}
                  focusHandler={() => {
                    setDropdownCollapse(true);
                  }}
                  id="inventory"
                  listMaxHeight={195}
                  name="inventory"
                  options={inventory.map(item => {
                    return {
                      text: item.label,
                      id: item.key
                    };
                  })}
                  searchable={true}
                  selectHandler={item => keyClickHandler(item.id)}
                  selectType="singleSelect"
                />
              </Primitives.Flex>
            </Primitives.Flex>
            <Primitives.Flex
              flexWrap="wrap"
              justifyContent={["inherit", "inherit", "flex-end"]}
            >
              {inventoryObj[key].checkboxData.map((item, index) => (
                <Primitives.Flex
                  key={item.label}
                  minWidth={["46%", "auto", "auto"]}
                  mr={[8, 8, 38]}
                >
                  {item.label !== "Expired" && (
                    <Checkbox
                      checked={graphCheckbox[index]}
                      clickHandler={() => checkBoxClickHandler(index)}
                    ></Checkbox>
                  )}
                  <Primitives.Box
                    width={19}
                    height={19}
                    backgroundColor={item.backgroundColor}
                    borderRadius={100}
                    ml={[1, 1, 14]}
                    mr={[1, 1, 8]}
                  ></Primitives.Box>
                  <Primitives.Text fontSize={[1, 1, 2]} mb={[2, 0, 0]}>
                    {item.label}
                  </Primitives.Text>
                </Primitives.Flex>
              ))}
            </Primitives.Flex>
          </Primitives.Flex>
          <Primitives.Box height={400} overflow="scroll" position="relative">
            <Primitives.Flex
              height={1}
              mt={0}
              position="absolute"
              width={["150vw", 1, 1]}
            >
              <BarChart
                setXAxis={setSelectedBranch}
                callback={{
                  title: function(tooltipItems, data) {
                    const dataIndex = tooltipItems[0].index;
                    const branch = data.labels[dataIndex];
                    return `Branch: ${branch}`;
                  },
                  label: tooltipItem =>
                    inventoryObj[key].toolTiplabelArr[
                      tooltipItem["datasetIndex"]
                    ],
                  afterLabel: tooltipItem => {
                    const dataIndex = tooltipItem.index;
                    const afterLabelData =
                      inventoryObj[key].checkboxData[
                        inventoryObj[key].checkboxData.length - 1
                      ].tooltipData[dataIndex];

                    const afterLabelFn = afterLabelData =>
                      dataArr[key].map(item => [
                        `Products - ${
                          afterLabelData[item["response"]].uniq_products
                        }`,
                        `Qty. - ${indianNumberFormat(
                          afterLabelData[item["response"]].total_quantity
                        )}`,
                        `Value - ₹${ConvertMoneyToLakhsCrores(
                          afterLabelData[item["response"]].total_value
                        )}`,
                        ""
                      ]);
                    const afterLabelHash = afterLabelFn(afterLabelData);
                    return afterLabelHash[tooltipItem.datasetIndex];
                  }
                }}
                labels={inventoryObj[key].result[
                  inventoryObj[key].response
                ].map(item => item.branch_name)}
                layout={{
                  padding: {
                    top: 60,
                    bottom: 0
                  }
                }}
                stacked={true}
                tooltip={{
                  enabled: true,
                  mode: "index",
                  position: "nearest",
                  backgroundColor: "#1E2B51",
                  bodyFontColor: "white",
                  bodyFontSize: 10.5,
                  titleAlign: "center",
                  titleFontColor: "white",
                  titleFontSize: 11,
                  titleMarginBottom: 8,
                  borderColor: "#EAEBF0",
                  borderWidth: 0
                }}
                gridLines={false}
                id="inventorychart"
                tickColor="rgba(0,0,0,0)"
                values={inventoryObj[key].chartData.map((item, index) =>
                  graphCheckbox[index] ? item : {}
                )}
                xAxes={{
                  barPercentage: 0.5,
                  fontColor: "#525983",
                  gridLines: false,
                  labels: true
                }}
                yAxes={{
                  borderDash: [4, 6],
                  gridLines: true,
                  fontColor: "#525983",
                  labels: true,
                  padding: 20,
                  yLabel: value => ConvertMoneyToLakhsCrores(value)
                }}
              />
            </Primitives.Flex>
          </Primitives.Box>
        </Primitives.Box>
      ) : (
        <Primitives.Flex
          alignItems="center"
          justifyContent="center"
          minHeight="69vh"
        >
          <Loading onlySpinner />
        </Primitives.Flex>
      )}
    </>
  );
};

NearExpiry.propTypes = propTypes;
export default NearExpiry;
