import React, { useState, useContext } from "react";
import Primitives from "../../../components/primitives";
import { AppContext } from "../../../utils/context";
import DesktopNavigation from "../../../lib/responsive-navigation/desktop-navigation";
import NavBar from "../../../lib/responsive-navigation/mobile-navigation";
import Header from "../../reports/header";
import Title from "../../../lib/title";
import { bool, node, number, string, oneOfType, array } from "prop-types";

const propTypes = {
  backgroundColor: oneOfType([string, array]),
  components: node,
  headerAlign: string,
  isbackButtonRequired: bool,
  showHamburger: bool,
  selected: number,
  title: string
};
const defaultProps = {
  backgroundColor: ["white", "whitesmoke"],
  showHamburger: true
};

const Reports = ({
  backgroundColor,
  components,
  headerAlign,
  isbackButtonRequired,
  showHamburger,
  selected,
  title
}) => {
  const { loggedInUser } = useContext(AppContext);
  const [showNavbar, setShowNavbar] = useState(false);
  const navbarDisplayHandler = () => {
    setShowNavbar(!showNavbar);
  };
  return (
    <>
      <Primitives.Box display={["none", "none", "block"]}>
        <DesktopNavigation selected={selected} loggedInUser={loggedInUser} />
      </Primitives.Box>
      {showNavbar ? (
        <NavBar
          navbarDisplayHandler={navbarDisplayHandler}
          selected={selected}
          title="Wallet Share Analytics"
        />
      ) : (
        <>
          <Primitives.Flex backgroundColor="whitesmoke" flexDirection="column">
            <Primitives.Flex
              backgroundColor={backgroundColor}
              flexDirection="column"
              fontFamily="Lato"
              fontStyle="normal"
            >
              {/* Wallet Share Analytics */}
              <Header
                email={
                  loggedInUser && loggedInUser.user && loggedInUser.user.email
                }
                isbackButtonRequired={isbackButtonRequired}
                navbarDisplayHandler={navbarDisplayHandler}
                showHamburger={showHamburger}
                title={
                  <Primitives.Flex justifyContent={headerAlign}>
                    <Title>
                      <Primitives.Text>{title}</Primitives.Text>
                    </Title>
                  </Primitives.Flex>
                }
              />
            </Primitives.Flex>
          </Primitives.Flex>
          {components && components}
        </>
      )}
    </>
  );
};

Reports.defaultProps = defaultProps;
Reports.propTypes = propTypes;
export default Reports;
