import React, { useState } from "react";
import Primitives from "../../../components/primitives";
import Table from "../../../components/table-two";
import {
  array,
  bool,
  func,
  number,
  object,
  string,
  oneOfType
} from "prop-types";
import { colors } from "../../../components/utils/theme";
import Loading from "../../../components/loading";
import NoResults from "../../../components/no-results";

const propTypes = {
  column: array,
  clickHandler: func,
  columnIndex: number,
  cursor: string,
  data: array,
  displayLockIcon: array,
  getHeaderId: func,
  headClick: bool,
  isLoaded: bool,
  isSortingRequire: bool,
  isBorderRequire: bool,
  isBackgroundRequire: bool,
  responsiveWidth: oneOfType([array, string]),
  responsiveHeight: oneOfType([array, string]),
  striped: bool,
  toolTipText: object
};
const defaultProps = {
  displayLockIcon: ["block", "block", "block", "none"],
  headClick: true,
  isSortingRequire: true,
  isBorderRequire: true,
  isBackgroundRequire: true,
  striped: true
};
const TableSection = ({
  column,
  clickHandler,
  columnIndex,
  cursor,
  data,
  displayLockIcon,
  getHeaderId,
  headClick,
  isLoaded,
  isSortingRequire,
  isBorderRequire,
  isBackgroundRequire,
  responsiveWidth,
  responsiveHeight,
  striped,
  toolTipText
}) => {
  const [order, toggleOrder] = useState("asc");
  const [sortingIndex, updateSortingIndex] = useState(null);

  /*sorting */
  const headClickHandler = id => {
    updateSortingIndex(id);
    toggleOrder(order === "asc" ? "desc" : "asc");
    getHeaderId && getHeaderId(id, order);
  };
  return (
    <>
      {isLoaded ? (
        <>
          {data && data.length > 0 ? (
            <Primitives.Flex overflow="auto" width={1}>
              <Primitives.Flex pb={55} width={1}>
                <Table
                  alternateRowColor="whitesmoke"
                  {...(isBackgroundRequire
                    ? {
                        backgroundColor: {
                          head: "background-light-blue",
                          footer: "background-light-blue"
                        }
                      }
                    : {})}
                  {...(isBorderRequire
                    ? { border: `1px solid ${colors.text[3]}` }
                    : {})}
                  clickHandler={clickHandler}
                  columnIndex={columnIndex}
                  color={{
                    head: `${colors.text[0]}`,
                    row: `${colors.text[0]}`
                  }}
                  column={column}
                  cursor={cursor}
                  data={data}
                  firstColumnFixed={true}
                  fontSize={{ footer: [0, 1], head: [0, 1], row: ["11px", 1] }}
                  fontWeight={{ footer: 2, head: 1, row: 1 }}
                  footer={true}
                  footShadow="0px"
                  headClick={headClick}
                  headClickHandler={headClick && headClickHandler}
                  pt={{ headContainer: [1, 10], row: [9, 10] }}
                  pb={{ headContainer: [1, 10], row: [9, 10] }}
                  pl={10}
                  sorting={isSortingRequire && true}
                  sortingType={order === "asc" ? "desc" : "asc"}
                  sortingIndex={sortingIndex}
                  striped={striped}
                  toolTipText={toolTipText}
                  isResponsive={true}
                  responsiveWidth={
                    responsiveWidth
                      ? responsiveWidth
                      : [1350, 1450, 1450, "unset"]
                  }
                  responsiveHeight={
                    responsiveHeight ? responsiveHeight : ["60vh", "60vh"]
                  }
                  displayLockIcon={displayLockIcon}
                />
              </Primitives.Flex>
            </Primitives.Flex>
          ) : (
            <Primitives.Flex
              alignItems="center"
              display="flex"
              justifyContent="center"
              mt={6}
              mx="auto"
              textAlign="center"
            >
              <NoResults />
            </Primitives.Flex>
          )}
        </>
      ) : (
        <Primitives.Flex
          alignItems="center"
          display="flex"
          justifyContent="center"
          mt={5}
          mx="auto"
        >
          <Loading />
        </Primitives.Flex>
      )}
    </>
  );
};
TableSection.propTypes = propTypes;
TableSection.defaultProps = defaultProps;
export default TableSection;
