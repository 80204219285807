import React from "react";
import { bool } from "prop-types";

const propTypes = {
  onlyArrow: bool
};

const defaultProps = {
  onlyArrow: false
};
const IncreasingZigZagArrow = ({ onlyArrow, ...props }) => (
  <svg
    width="26"
    height="20"
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {!onlyArrow && (
      <rect
        width="26"
        height="20"
        rx="4"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 0 20)"
        fill="#CAFFE1"
      />
    )}

    <path
      d="M6.68759 15.9375C6.79842 15.9376 6.90818 15.9159 7.01057 15.8735C7.11296 15.8311 7.20596 15.7688 7.28423 15.6904L12.0313 10.9432L13.966 12.8779C14.0443 12.9562 14.1373 13.0184 14.2397 13.0608C14.3421 13.1032 14.4518 13.125 14.5626 13.125C14.6734 13.125 14.7831 13.1032 14.8855 13.0608C14.9879 13.0184 15.0809 12.9562 15.1592 12.8779L20.4685 7.56859L20.4682 9.46875C20.4682 9.69253 20.5571 9.90714 20.7153 10.0654C20.8736 10.2236 21.0882 10.3125 21.312 10.3125C21.5357 10.3125 21.7503 10.2236 21.9086 10.0654C22.0668 9.90714 22.1557 9.69253 22.1557 9.46875L22.1563 5.53125C22.1563 5.30747 22.0674 5.09286 21.9092 4.93463C21.751 4.77639 21.5364 4.6875 21.3126 4.6875H17.3751C17.1513 4.6875 16.9367 4.77639 16.7785 4.93463C16.6202 5.09286 16.5313 5.30747 16.5313 5.53125C16.5313 5.75503 16.6202 5.96964 16.7785 6.12787C16.9367 6.28611 17.1513 6.375 17.3751 6.375H19.2756L14.5626 11.088L12.628 9.15336C12.5496 9.07501 12.4566 9.01286 12.3542 8.97045C12.2519 8.92805 12.1421 8.90622 12.0313 8.90622C11.9205 8.90622 11.8108 8.92805 11.7084 8.97045C11.6061 9.01286 11.5131 9.07501 11.4347 9.15336L6.09096 14.4971C5.97295 14.6151 5.89258 14.7655 5.86003 14.9291C5.82747 15.0928 5.84418 15.2625 5.90804 15.4166C5.9719 15.5708 6.08005 15.7026 6.21881 15.7953C6.35757 15.888 6.52071 15.9375 6.68759 15.9375Z"
      fill="inherit"
    />
  </svg>
);

IncreasingZigZagArrow.defaultProps = defaultProps;
IncreasingZigZagArrow.propTypes = propTypes;
export default IncreasingZigZagArrow;
