import React from "react";
import { colors } from "../../components/utils/theme";
import CalendarIcon from "../../components/glyphs/elements/calendar";
import { Calendar } from "react-date-range";
import PropTypes, {
  array,
  bool,
  func,
  number,
  string,
  oneOfType
} from "prop-types";
import Primitives from "../../components/primitives";
import { StyledSelect } from "../../components/dropdown";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./index.css";

const propTypes = {
  /** Border around the button */
  border: string,
  /** Border radius for select */
  borderRadius: number,
  /** Handler function for date change */
  cancelHandler: func,
  changeHandler: func,
  /** Handler function for collapsing calendar */
  collapseHandler: func,
  /** State of calendar if it is collapsed or not */
  collapsed: bool,
  /** Text color of select */
  color: string,
  date: PropTypes.instanceOf(Date),
  /** Calendar is disabled or not */
  disabled: bool,
  /** disabled dates */
  disabledDates: oneOfType([string, PropTypes.instanceOf(Date)]),
  /** Header for select to be displayed befor collapsing */
  defaultValue: string,
  /** What Font family should button text use*/
  fontFamily: string,
  /** What font size should be used on Select */
  fontSize: number,
  /** Font styling for select text */
  fontStyle: string,
  /** What fontweight should be applied to select text */
  fontWeight: string,
  /** line height for texts in select */
  lineHeight: number,
  /** Starting and opening date range */
  ranges: array
};
const defaultProps = {
  defaultValue: "Select Date",
  disabled: false,
  fontFamily: "Lato",
  fontSize: 1,
  fontStyle: "normal",
  fontWeight: "normal",
  lineHeight: 1.2
};

const SingleDatePick = ({
  border,
  borderRadius,
  cancelHandler,
  changeHandler,
  collapseHandler,
  collapsed,
  color,
  date,
  disabled,
  disabledDates,
  defaultValue,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  lineHeight
}) => (
  <Primitives.Flex flexDirection="column" position="relative" width={1}>
    <StyledSelect
      border={border}
      borderColor={colors.text[2]}
      borderRadius={borderRadius}
      color={color}
      disabled={disabled}
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontStyle={fontStyle}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      onClick={collapseHandler}
      padding={12}
      width={1}
    >
      {collapsed && date ? (
        <Primitives.Text color={colors.text[0]}>
          {date.toLocaleDateString()}
        </Primitives.Text>
      ) : (
        <Primitives.Text>{defaultValue}</Primitives.Text>
      )}
      <Primitives.Flex ml="auto">
        <CalendarIcon fill={colors.text[1]} />
      </Primitives.Flex>
    </StyledSelect>
    <Primitives.Flex
      display={collapsed && "none"}
      position="absolute"
      top={50}
      zIndex={1}
    >
      <Primitives.Flex
        position={["fixed", "unset"]}
        width={["100%", "unset"]}
        height={["100%", "unset"]}
        left={[0, "unset"]}
        top={[0, "unset"]}
        justifyContent={["center", "unset"]}
        zIndex={2}
      >
        <Calendar
          editableDateInputs={false}
          moveRangeOnFirstSelection={false}
          onChange={changeHandler}
          date={date}
          rangeColors={[colors.primary[0]]}
          showMonthAndYearPickers={false}
          twoStepChange={false}
          weekdayDisplayFormat="EEEEE"
          minDate={disabledDates}
        />
        <Primitives.Flex
          display={["flex", "none"]}
          position="fixed"
          top={400}
          left={130}
        >
          <Primitives.Button
            width="90px"
            mt={20}
            py={10}
            borderRadius={4}
            color={colors.text[1]}
            fontFamily="lato"
            fontSize="13px"
            fontWeight={3}
            onClick={cancelHandler}
          >
            Cancel
          </Primitives.Button>
          <Primitives.Button
            active={{ backgroundColor: colors.primary[6], color: "white" }}
            width="90px"
            mt={20}
            background={colors.primary[3]}
            py={10}
            borderRadius={4}
            color={colors.white}
            hover={{ backgroundColor: colors.primary[6], color: "white" }}
            fontFamily="lato"
            fontSize="13px"
            fontWeight={3}
            onClick={collapseHandler}
          >
            Done
          </Primitives.Button>
        </Primitives.Flex>
      </Primitives.Flex>
    </Primitives.Flex>
  </Primitives.Flex>
);

SingleDatePick.propTypes = propTypes;
SingleDatePick.defaultProps = defaultProps;
export default SingleDatePick;
