import React, { useContext, useEffect, useState } from "react";
import Primitives from "../../../components/primitives";
import ConvertMoneyToLakhsCrores from "../../../components/utils/convert-money-to-lakh-crores";
import Reports from "../../reports";
import { useParams } from "react-router-dom";
import DrillDownPageHeader from "../../../lib/drilldown-page-header";
import ProgressIcon from "../../../components/progress-icon";
import Title from "../../../lib/title";
import toolTipData from "../../../lib/tooltip-text";
import convertToObj from "../../../components/utils/convert-to-object";
import headerValue from "../../../lib/header-value";
import checkSortingIndex from "../../../lib/check-sorting-index";
import roundOffTwoDecimal from "../../../components/utils/round-off-two-decimal";
import { FilterContext } from "../../hospital-sales-report/filterContext";

const toolTipIndex = [1, 2, 3, 4];

const metricMapper = data => (data["metrics"] === "qty" ? "" : "₹ ");

const modifyReports = data =>
  data &&
  data["items"] &&
  data["items"].map(item => ({
    productName: item.name,
    last90Days:
      metricMapper(data) + `${roundOffTwoDecimal(item.avg_sales_90_days)}`,
    last30Days:
      metricMapper(data) + `${roundOffTwoDecimal(item.avg_sales_30_days)}`,
    last7Days:
      metricMapper(data) + `${roundOffTwoDecimal(item.avg_sales_7_days)}`,
    performance: (
      <Primitives.Flex>
        <ProgressIcon input1={item.performance} input2={0} />
        <Primitives.Text ml={1}>{item.performance + "%"}</Primitives.Text>
      </Primitives.Flex>
    )
  }));

const headerData = value => ({
  "Unique SKUs": headerValue(value, "uniq_products"),
  "90 Day Avg.Sales": headerValue(value, "avg_sales_90_days"),
  "30 Day Avg.Sales": headerValue(value, "avg_sales_30_days"),
  "7 Day Avg.Sales": headerValue(value, "avg_sales_7_days")
});

const headerMetric = (customerData, data) =>
  customerData && customerData["metrics"] === "qty"
    ? `Qty (last ${data} days)(`
    : `Sales (last ${data} days)`;

const column = (columnSum, customerData) => [
  {
    footerData: "Total",
    heading: "Product Name",
    type: "string",
    width: 1,
    sort: false
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      columnSum["avg_sales_90_days_sum"]
    )}`,
    heading: `Daily Avg. ${headerMetric(customerData, "90")}`,
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      columnSum["avg_sales_30_days_sum"]
    )}`,
    heading: `Daily Avg. ${headerMetric(customerData, "30")}`,
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      columnSum["avg_sales_7_days_sum"]
    )}`,
    heading: `Daily Avg. ${headerMetric(customerData, "7")}`,
    type: "string",
    width: 1
  },
  {
    heading: "Performance trend",
    type: "string",
    width: 1
  }
];

const CustomerCentreProductsBreakDown = () => {
  const [columnSum, setColumnSum] = useState({});
  const [customerData, setCustomerData] = useState([]);
  const [sort, updateSort] = useState(null);
  const filterContext = useContext(FilterContext);
  const dateQuery = filterContext?.filterState?.singleDate
    ? `&q[sales_invoices_invoice_date_in]=${filterContext?.filterState?.singleDate}`
    : "";
  const api = `smartchain/insights/${
    useParams().id
  }/customer_centre_products_breakdown?${dateQuery}`;
  const [request, updateRequest] = useState(api);

  const index = {
    1: "avg_sales_90_days+",
    2: "avg_sales_30_days+",
    3: "avg_sales_7_days+",
    4: "performance+"
  };

  const getHeaderId = (id, order) =>
    checkSortingIndex(index, id, order, updateSort);

  const getColumnSum = data => {
    setColumnSum(data);
  };
  const getCustomerData = data => {
    setCustomerData(data);
  };

  const filterUpdate = ({ singleDate, productSearch }) => {
    updateRequest(
      `${api}${
        singleDate ? `&q[sales_invoices_invoice_date_in]=${singleDate}` : ""
      }${
        productSearch
          ? `&q[sales_rate_contracts_master_sku_universal_sku_name_cont]=${productSearch}`
          : ""
      }`
    );
  };

  useEffect(() => {
    window.onpopstate = function() {
      filterContext.updateFilterState("");
    };
  }, []);

  return (
    <Reports
      api={request}
      column={column(columnSum, customerData)}
      filterConfig={{
        productSearch: true,
        Date: true,
        search: "productSearch",
        mobileFilters: [{ text: "Date", filterText: "Date" }],
        defaultFilter: "Date"
      }}
      filterUpdate={filterUpdate}
      getColumnSum={getColumnSum}
      getHeaderId={getHeaderId}
      getCustomerData={getCustomerData}
      modifyReports={modifyReports}
      placeholder="Search by Product Name"
      pageId="customerCentreProductsBreakdown"
      responsiveWidth={[800, 1000, 1100, "unset"]}
      responsiveHeight={["30vh", "55vh"]}
      sort={sort}
      selected={3}
      title={
        <>
          <Title>
            {customerData &&
              customerData["product_hash"] &&
              customerData["product_hash"]["name"]}
          </Title>
        </>
      }
      reportTitle={customerData?.product_hash?.name}
      details={
        <DrillDownPageHeader
          headerData={headerData(customerData && customerData["product_hash"])}
        />
      }
      toolTipText={convertToObj(toolTipIndex, toolTipData)}
    />
  );
};

export default CustomerCentreProductsBreakDown;
