import React, { useContext, useEffect, useState } from "react";
import Checkbox from "../../../components/checkbox";
import Dropdown from "../../../components/dropdown";
import {
  array,
  bool,
  func,
  object,
  oneOfType,
  string,
  number
} from "prop-types";
import Primitives from "../../../components/primitives";
import FilterDateRange from "../date-range";
import { breakPoint, colors } from "../../../components/utils/theme";
import SingleDate from "../single-date-pick";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ParamsToHash from "../../params-to-hash";
import { FilterContext } from "../../../pages/hospital-sales-report/filterContext";

const propTypes = {
  borderColor: string,
  color: string,
  clearFilter: bool,
  clearMobileFilters: bool,
  config: string,
  defaultDate: bool,
  dropdownData: oneOfType([array, object, string]),
  filterContextState: oneOfType([array, object, string]),
  getConfig: func,
  getMobileDateRange: func,
  getMobileDate: func,
  getSelectedItems: func,
  heading: string,
  listWidth: oneOfType([string, number]),
  listMaxHeight: oneOfType([number, array]),
  mobileConfig: string,
  mobileFilterContextState: oneOfType([array, object, string]),
  selectType: string,
  shadowRequired: bool
};

const defaultProps = {
  listMaxHeight: 195,
  listWidth: 320
};

const filterObject = {
  Division: {
    id: "division_name",
    name: "division_name",
    placeholder: "Select Division",
    idType: String
  },
  Tenant: {
    id: "id",
    name: "name",
    placeholder: "All Distributors",
    idType: Number
  },
  originBranch: {
    id: "id",
    name: "name",
    placeholder: "Origin Location",
    idType: Number
  },
  currentBranch: {
    id: "id",
    name: "name",
    placeholder: "Current Location",
    idType: Number
  },
  masterSku: {
    id: "id",
    name: "sku_name",
    placeholder: "Product",
    idType: Number
  },
  City: {
    id: "id",
    name: "city_name",
    placeholder: "Select City",
    idType: Number
  },
  months: {
    id: "id",
    name: "text",
    placeholder: "Select Month",
    preSelected: true,
    idType: Number
  },
  hospitals: {
    id: "id",
    name: "name",
    placeholder: "Hospital",
    idType: Number
  },
  branches: {
    id: "id",
    name: "name",
    placeholder: "Branches",
    idType: Number
  },
  states: {
    id: "id",
    name: "state_name",
    placeholder: "Select State",
    idType: Number
  },
  categories: {
    id: "classification",
    name: "classification",
    placeholder: "Select category",
    idType: String
  }
};

const GenericDropdown = ({
  borderColor,
  color,
  clearFilter,
  clearMobileFilters,
  config,
  defaultDate,
  dropdownData,
  filterContextState,
  getConfig,
  getSelectedItems,
  getMobileDate,
  getMobileDateRange,
  heading,
  listWidth,
  listMaxHeight,
  mobileConfig,
  mobileFilterContextState,
  shadowRequired,
  selectType
}) => {
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [collapse, setCollpase] = useState(false);
  const params = useParams();
  const initialDateRange = {
    startDate: defaultDate?.startDate ?? new Date(),
    endDate: defaultDate?.endDate ?? new Date(),
    key: "selection",
    color: colors.primary[0],
    /** new prop = false, indicates it's not a new date range */
    new: defaultDate?.startDate ? false : true
  };
  const filterContext = useContext(FilterContext);
  const [dateRanges, setDateRanges] = useState([
    filterContext?.filterState?.date
      ? filterContext?.filterState?.date
      : initialDateRange
  ]);
  const [searchText, updateSearchText] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const multiSelectHandler = option => {
    const id =
      dropdownData &&
      dropdownData.filter(
        item => item[filterObject[config].name] === option.text
      )[0][filterObject[config].id];
    !selectedEntities.includes(id)
      ? setSelectedEntities([...selectedEntities, id])
      : setSelectedEntities(selectedEntities.filter(data => data !== id));
    updateSearchText("");
  };

  const singleSelectHandler = option => {
    !dropdownData.includes(option.text)
      ? setSelectedEntities([option.text, option.id])
      : setSelectedEntities(
          selectedEntities.filter(data => data !== option.text),
          option.id
        );
    setCollpase(false);
    updateSearchText("");
  };

  const handleClick = e => {
    if (
      !e.target.closest(".dropdown-filter") &&
      !e.target.closest(".rdrDateRangeWrapper")
    ) {
      setCollpase(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    getSelectedItems(selectedEntities);
    getConfig(config);
  }, [selectedEntities, dateRanges]);

  useEffect(() => {
    if (!clearFilter) {
      setSelectedEntities([]);
    }
  }, [clearFilter]);

  useEffect(() => {
    if (clearMobileFilters) {
      setSelectedEntities([]);
      setSelectedDate(new Date());
      setDateRanges([initialDateRange]);
    }
  }, [clearMobileFilters]);

  useEffect(() => {
    if (
      params.id &&
      ParamsToHash(params.id)[config] &&
      ParamsToHash(params.id)[config] != ""
    ) {
      selectType === "singleSelect"
        ? setSelectedEntities(["1 selected"])
        : setSelectedEntities([ParamsToHash(params.id)[config]]);
    }
  }, []);

  useEffect(() => {
    if (filterContextState) {
      if (config === "months") {
        setSelectedEntities([filterContextState]);
      } else {
        const entityArray = filterObject[config].preSelected
          ? filterContextState
          : filterContextState.split(",").map(filterObject[config].idType);
        setSelectedEntities(entityArray);
      }
    }
  }, []);

  useEffect(() => {
    if (mobileFilterContextState && mobileConfig !== "Date Range") {
      const entityArray = filterObject[mobileConfig].preSelected
        ? mobileFilterContextState
        : mobileFilterContextState
            .split(",")
            .map(filterObject[mobileConfig].idType);
      setSelectedEntities(entityArray);
    }
  }, [mobileConfig]);

  const dateChangeHandler = (item, collapseTheDateOptions = false) => {
    item.selection.new = false;
    setDateRanges([item.selection]);
    collapseTheDateOptions &&
      item.selection.startDate.getTime() !== item.selection.endDate.getTime() &&
      setCollpase(!collapse);
    getMobileDateRange([item.selection]);
  };

  const singleDateChangeHandler = item => {
    setSelectedDate(item);
    getMobileDate(item);
  };

  const renderFilter = key => {
    switch (key) {
      case "Date Range":
        return (
          <Primitives.Flex display={["flex", "flex", "none"]}>
            <FilterDateRange
              cancelHandler={() => setCollpase(!collapse)}
              dateRangeCollapse={collapse}
              dateChangeHandler={dateChangeHandler}
              dateRanges={dateRanges}
              defaultValue="Date Range"
            />
          </Primitives.Flex>
        );
      case "Date":
        return (
          <Primitives.Flex display={["flex", "flex", "none"]}>
            <SingleDate
              color={colors.text[1]}
              collapsed={collapse}
              changeHandler={singleDateChangeHandler}
              date={selectedDate}
            />
          </Primitives.Flex>
        );
      default:
        return (
          <Dropdown
            borderColor={borderColor}
            color={color}
            className="dropdown-filter"
            changeHandler={e => updateSearchText(e.target.value)}
            collapsed={collapse || window.innerWidth < breakPoint}
            defaultValue={
              config === "months"
                ? selectedEntities.length > 0
                  ? selectedEntities[0]
                  : dropdownData[0].text
                : null
            }
            placeholder={
              selectedEntities.length > 0
                ? selectType === "singleSelect"
                  ? selectedEntities[0]
                  : `${selectedEntities.length} selected`
                : filterObject[config]?.placeholder
            }
            flex={1}
            focusHandler={() => setCollpase(true)}
            id={config}
            name={config}
            listMaxWidth={30}
            listWidth={listWidth}
            listMaxHeight={listMaxHeight}
            options={
              selectType === "singleSelect"
                ? dropdownData &&
                  dropdownData.map(month => {
                    return {
                      text: month[filterObject[config].name],
                      id: month[filterObject[config].id]
                    };
                  })
                : dropdownData &&
                  dropdownData.map(hospital => {
                    return {
                      glyph: (
                        <Checkbox
                          checked={selectedEntities.includes(
                            hospital[filterObject[config].id]
                          )}
                        />
                      ),
                      text: hospital[filterObject[config].name]
                    };
                  })
            }
            search={searchText}
            searchable={true}
            shadowRequired={shadowRequired}
            selectHandler={
              selectType === "singleSelect"
                ? singleSelectHandler
                : multiSelectHandler
            }
            selectType={selectType}
          />
        );
    }
  };

  return (
    <Primitives.Flex flexDirection="column">
      {heading && (
        <Primitives.Text color="#333333" fontSize="12px" mb={1}>
          {heading}
        </Primitives.Text>
      )}
      {renderFilter(config)}
    </Primitives.Flex>
  );
};

GenericDropdown.propTypes = propTypes;
GenericDropdown.defaultProps = defaultProps;
export default GenericDropdown;
