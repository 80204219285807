import ReactGA from "react-ga4";
/** send user details to Google Analytics */
const sendUsersDataToGoogleAnalytics = ({ id, name, company_name, email }) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      async ({ coords: { latitude, longitude } }) => {
        const loc = await getCity(latitude, longitude);
        ReactGA.gtag("set", "user_properties", {
          userLocation: loc
        });
      }
    );
  }
  ReactGA.gtag("set", "user_properties", {
    userId: id,
    userName: name,
    userCompanyName: company_name,
    userEmail: email
  });
};

const getCity = async (lat, lng) => {
  const response = await fetch(
    `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lng}&localityLanguage=en`
  );
  const responseJson = await response.json();
  return (
    responseJson.city +
    ", " +
    responseJson.principalSubdivision
  ).toString();
};
export default sendUsersDataToGoogleAnalytics;
