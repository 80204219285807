import React, { useEffect, useRef, useState } from "react";
import Primitives from "../../components/primitives";
import { colors } from "../../components/utils/theme";
import { array, bool, func, node, number, oneOfType, string } from "prop-types";
import Button from "../../components/button";
import Cta from "../../components/cta";
import ForwardAngular from "../../components/glyphs/elements/forwardAngular";
import DownloadBtn from "../download";
import Dropdown from "../../components/dropdown";
import Loading from "../../components/loading";
import contentType from "../download-options";
import downloadHandlerFn from "../../components/utils/download-handler";

const propTypes = {
  borderRadius: oneOfType([string, number]),
  boxShadow: oneOfType([string, number]),
  ctaText: string,
  details: node,
  downloadApi: string,
  dropdownDefaultValue: string,
  filters: array,
  getFilterValues: func,
  isCta: bool,
  isDownloadable: bool,
  innerMargin: oneOfType([string, number]),
  link: string,
  mx: oneOfType([string, number, array]),
  my: oneOfType([string, number]),
  title: oneOfType([string, node]),
  outerMargin: oneOfType([string, array]),
  width: oneOfType([string, number, array])
};
const defaultProps = {
  borderRadius: "3px",
  boxShadow: `${colors.text[2]} 0px 5px 15px`,
  ctaText: "View All Hospitals",
  isCta: false,
  isDownloadable: true,
  innerMargin: "20px",
  mx: ["8px", "13px"],
  my: 1,
  dropdownDefaultValue: "Select Month",
  width: [1, 1, 12 / 25]
};

const Card = ({
  borderRadius,
  boxShadow,
  ctaText,
  details,
  downloadApi,
  dropdownDefaultValue,
  filters,
  getFilterValues,
  innerMargin,
  isCta,
  isDownloadable,
  link,
  mx,
  my,
  title,
  width,
  ...props
}) => {
  const [dropdownCollapse, setDropdownCollapse] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [selectedDropdown, setSelectedDropdown] = useState("");

  const dropdownSelectHandler = option => {
    setSelectedDropdown(option.text);
    getFilterValues(option.text);
    setDropdownCollapse(false);
  };

  const filterSelectHandler = item => {
    getFilterValues(item);
  };

  const downloadHandler = async option => {
    const downloadRequest = contentType[option.index];
    downloadHandlerFn(
      `${
        downloadApi.replaceAll("downloadOptions", downloadRequest).split("?")[0]
      }.${downloadRequest}?${downloadApi.split("?")[1]}&is_download=true`,
      setIsDownload,
      downloadRequest
    );
  };

  const displayDownload = () =>
    isDownload ? (
      <Loading onlySpinner />
    ) : (
      <Primitives.Flex zIndex={3}>
        <DownloadBtn label="Download" selectHandler={downloadHandler} />
      </Primitives.Flex>
    );
  const node = useRef();

  const handleClick = e => {
    if (node && node.current && node.current.contains(e.target)) {
      // inside click
    }
    // outside click
    if (!e.target.closest(".dropdown-filter")) {
      setDropdownCollapse(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <Primitives.Flex
      backgroundColor="white"
      borderRadius={borderRadius}
      boxShadow={boxShadow}
      flexDirection="column"
      my={my}
      mx={mx}
      width={width}
      {...props}
    >
      <Primitives.Flex flexDirection="column" m={innerMargin}>
        <Primitives.Flex alignItems="center" justifyContent="space-between">
          <Primitives.Flex
            fontSize={[15, 3]}
            fontWeight={2}
            flex={2}
            mr={1}
            minWidth={150}
          >
            {title}
          </Primitives.Flex>
          {/* display filter, download button and cta conditionally */}
          {isCta && (
            <Primitives.Flex flex={1} justifyContent="flex-end">
              <Cta
                color={colors.primary[3]}
                fontSize={["10px", 1]}
                fontWeight={2}
                hover={{ color: colors.primary[3], textDecorationLine: true }}
                label={
                  <Primitives.Flex alignItems="flex-end">
                    <Primitives.Text mr={1}>{ctaText}</Primitives.Text>
                    <ForwardAngular fill={colors.primary[3]} />
                  </Primitives.Flex>
                }
                link={link}
                textdecorationline={false}
              />
            </Primitives.Flex>
          )}
          {filters && (
            <>
              <Primitives.Flex
                display={["none", "flex"]}
                mr={2}
                ref={node}
                width={205}
                zIndex={3}
              >
                <Dropdown
                  className="dropdown-filter"
                  collapsed={dropdownCollapse}
                  defaultValue={
                    selectedDropdown ? selectedDropdown : dropdownDefaultValue
                  }
                  focusHandler={() => {
                    setDropdownCollapse(true);
                  }}
                  id="months"
                  listMaxHeight={195}
                  listWidth="100%"
                  name="months"
                  options={
                    filters &&
                    filters.map(item => ({
                      text: item
                    }))
                  }
                  searchable={true}
                  selectHandler={dropdownSelectHandler}
                  selectType="multiSelect"
                />
              </Primitives.Flex>
            </>
          )}
          {isDownloadable && displayDownload()}
        </Primitives.Flex>
        {/* filter Ui for mobile version */}
        {filters && (
          <Primitives.Flex display={["flex", "none"]} mt={1} width={1}>
            {filters.map((item, index) => (
              <Primitives.Flex key={index} mr={1}>
                <Button
                  active={{
                    backgroundColor: colors.primary[3],
                    color: "white"
                  }}
                  alignItems="center"
                  backgroundColor="whitesmoke"
                  color={colors.text[1]}
                  clickHandler={() => {
                    filterSelectHandler(item);
                  }}
                  fontSize={0}
                  fontWeight={1}
                  hover={{ backgroundColor: colors.primary[3], color: "white" }}
                  label={item}
                  py="6px"
                  px="6px"
                />
              </Primitives.Flex>
            ))}
          </Primitives.Flex>
        )}
        {details}
      </Primitives.Flex>
    </Primitives.Flex>
  );
};

Card.defaultProps = defaultProps;
Card.propTypes = propTypes;

export default Card;
