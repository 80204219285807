import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import ForwardAngular from "../../../components/glyphs/elements/forwardAngular";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import Title from "../../../lib/title";
import { AppContext } from "../../../utils/context";
import Reports from "../../reports";

const modifyReports = data =>
  data &&
  data.map((item, index) => ({
    srNo: index + 1,
    hospitalGroups: item["name"],
    centresNotPurchasing: item["not_purchasing_centers"].length,
    totalCentres:
      item["purchasing_centers"].length + item["not_purchasing_centers"].length,
    angular: <ForwardAngular fill={colors.accent[3]} />
  }));

const column = [
  {
    heading: "SR. NO.",
    type: "number",
    width: 0.5
  },
  {
    heading: (
      <Primitives.Flex
        key="1"
        width={1}
        alignItems="space-between"
        justifyContent="space-between"
      >
        <Primitives.Text fontWeight={700}>HOSPITAL GROUP</Primitives.Text>
      </Primitives.Flex>
    ),
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    heading: "CENTRES NOT PURCHASING",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    heading: "TOTAL CENTRES",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    heading: " ",
    type: "string",
    width: 0.2
  }
];

const HospitalGroupWithCenters = () => {
  const history = useHistory();
  const { loggedInUser } = useContext(AppContext);
  const [hospitalData, setHospitalData] = useState();
  const api =
    "smartchain/wallet_analytics/hospitals_groups_with_their_centers?";
  const [request, updateRequest] = useState(api);

  const IndividualCentersClickHandler = (row, index) => {
    hospitalData &&
      history.push(
        `/company/individual_centers/hospital_group_id=${hospitalData[index]["id"]}`
      );
  };

  const getHospitalCentersData = data => {
    data && setHospitalData(data);
  };

  const filterUpdate = queryObj => {
    const { City, states, hospitalSearch } = queryObj;
    updateRequest(
      `${api}${City ? `&q[universal_city_id_in]=${City}` : ""}${
        states ? `&q[universal_state_id_in]=${states}` : ""
      }${hospitalSearch ? `&q[group_name_cont]=${hospitalSearch}` : ""}`
    );
  };

  return (
    <Reports
      api={request}
      column={column}
      clickHandler={IndividualCentersClickHandler}
      cursor="pointer"
      getHospitalCentersData={getHospitalCentersData}
      filterConfig={{
        hospitalSearch: true,
        states: true,
        City: true,
        search: "hospitalSearch",
        mobileFilters: [
          { text: "State", filterText: "states" },
          { text: "City", filterText: "City" }
        ]
      }}
      filterUpdate={filterUpdate}
      isDownloadable={false}
      isSortingRequire={false}
      isbackButtonRequired={true}
      isBorderRequire={false}
      isBackgroundRequire={false}
      modifyReports={modifyReports}
      placeholder="Search by Group Name"
      pageId="hospitalSalesReport"
      selected={5}
      striped={false}
      title={
        <>
          <Title>
            <Primitives.Text mb={["unset", 1]}>
              {loggedInUser &&
                loggedInUser.user &&
                loggedInUser.user.company_name}{" "}
              Customers and their centres
            </Primitives.Text>
          </Title>
        </>
      }
      reportTitle={`${loggedInUser?.user?.company_name} Customers and their centres`}
    />
  );
};

export default HospitalGroupWithCenters;
