import React from "react";
import { array, number, oneOfType, string } from "prop-types";
import { colors } from "../utils/theme";
import Primitives from "../primitives";

const propTypes = {
  alignItems: string,
  /** background color*/
  backgroundColor: string,
  /** border Radius */
  borderRadius: oneOfType([string, number]),
  /** Array of data objects */
  data: array,
  headerPadding: oneOfType([string, number]),
  headerFontSize: oneOfType([string, number]),
  headerFontWeight: oneOfType([string, number]),
  /** Header for the payment summary */
  header: string,
  mr: oneOfType([string, number]),
  pb: oneOfType([string, number]),
  pl: oneOfType([string, number]),
  pt: oneOfType([string, number]),
  pr: oneOfType([string, number]),
  /** Summary for the payment summary */
  summary: string,
  titleFontSize: oneOfType([string, number]),
  titleFontWeight: oneOfType([string, number]),
  titleWidth: oneOfType([string, number])
};

const defaultProps = {
  backgroundColor: colors["background-blue"],
  headerPadding: 1,
  headerFontSize: 5,
  headerFontWeight: 2,
  mr: 5,
  pb: 55,
  pl: 58,
  pt: 5,
  pr: 5,
  titleFontSize: 2,
  titleFontWeight: 1,
  titleWidth: 146
};

const PaymentSummary = ({
  alignItems,
  backgroundColor,
  borderRadius,
  data,
  header,
  headerPadding,
  headerFontSize,
  headerFontWeight,
  mr,
  pb,
  pl,
  pt,
  pr,
  summary,
  titleFontSize,
  titleFontWeight,
  titleWidth
}) => (
  <Primitives.Flex
    backgroundColor={backgroundColor}
    borderRadius={borderRadius}
    flexDirection="column"
    fontFamily="Lato"
    fontStyle="normal"
    pb={pb}
    pl={pl}
    pt={pt}
  >
    {header && summary && (
      <Primitives.Flex flexDirection="column" mb={69}>
        <Primitives.Flex
          color={colors.text[0]}
          fontSize={headerFontSize}
          fontWeight={headerFontWeight}
          letterSpacing={-0.02}
          lineHeight={1.2}
        >
          {header}
        </Primitives.Flex>
        <Primitives.Flex
          color={colors.text[1]}
          fontWeight={titleFontWeight}
          fontSize={titleFontSize}
          lineHeight={1.8}
        >
          {summary}
        </Primitives.Flex>
      </Primitives.Flex>
    )}

    <Primitives.Flex>
      {data.map((item, index, arr) => (
        <Primitives.Flex
          alignItems={alignItems}
          borderRight={
            index !== arr.length - 1 && `1px solid ${colors["pattens-blue"]}`
          }
          flex={1}
          flexDirection="row"
          key={index}
          mr={index !== arr.length - 1 && mr}
          pr={index !== arr.length - 1 && pr}
        >
          <Primitives.Flex mr={20}>{item.glyph}</Primitives.Flex>
          <Primitives.Flex flexDirection="column">
            <Primitives.Flex
              color={colors.text[0]}
              fontWeight={headerFontWeight}
              fontSize={headerFontSize}
              lineHeight={0}
              pb={headerPadding}
              width={titleWidth}
            >
              {item.heading}
            </Primitives.Flex>
            <Primitives.Flex
              fontWeight={titleFontWeight}
              fontSize={titleFontSize}
              lineHeight={1.45}
            >
              {item.title}
            </Primitives.Flex>
          </Primitives.Flex>
        </Primitives.Flex>
      ))}
    </Primitives.Flex>
  </Primitives.Flex>
);
PaymentSummary.propTypes = propTypes;
PaymentSummary.defaultProps = defaultProps;
export default PaymentSummary;
