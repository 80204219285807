import React from "react";
import Caret from "../../components/glyphs/elements/caret";
import ListDropdown from "../../components/list-dropdown";
import Primitives from "../../components/primitives";
import { colors } from "../../components/utils/theme";
import { breakPoint } from "../../components/utils/theme";
import TabButtons from "../tab-buttons";
import {
  array,
  bool,
  func,
  number,
  object,
  oneOfType,
  string
} from "prop-types";

const defaultProps = {
  showTabsOnMediumScreen: false
};
const propTypes = {
  data: oneOfType([array, object]),
  dropdownData: array,
  dropdownId: object,
  dropDownSelectHandler: func,
  id: string,
  px: number,
  setShowDropdown: func,
  showDropdown: bool,
  showTabsOnMediumScreen: bool,
  tabSelectHandler: func
};

const DropdownView = ({
  data,
  dropdownData,
  dropdownId,
  dropDownSelectHandler,
  id,
  px,
  setShowDropdown,
  showDropdown,
  showTabsOnMediumScreen,
  tabSelectHandler
}) => {
  const view = {
    tablet: (
      <Primitives.Box
        backgroundColor="white"
        borderBottom="1px solid"
        borderTop="1px solid"
        borderColor="athens-gray"
        display={["none", "flex", "none"]}
        mt={1}
        px={px}
      >
        {dropdownData.map(item => (
          <TabButtons
            booleanValue={dropdownId?.id === item.id}
            clickHandler={() => {
              tabSelectHandler(item);
            }}
            key={item.id}
            label={item.text.toUpperCase()}
            cursor={item.cursor}
          />
        ))}
      </Primitives.Box>
    ),
    mobile: (
      <Primitives.Flex display={["flex", "none"]} position="relative">
        <Primitives.Flex
          alignItems="center"
          cursor="pointer"
          color={colors.text[0]}
          fontSize={[0, 1]}
          fontWeight={1}
          maxWidth={200}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          {dropdownId?.text}
          {showDropdown ? (
            <Primitives.Flex ml="5px" transform="rotate(180deg)">
              <Caret fill={colors.text[0]} height={6.91} width={11.61} />
            </Primitives.Flex>
          ) : (
            <Primitives.Flex ml="5px">
              <Caret fill={colors["dusty-grey"]} height={6.91} width={11.61} />
            </Primitives.Flex>
          )}
        </Primitives.Flex>
        {showDropdown && (
          <Primitives.Box position="absolute" top={[18, 20]}>
            <ListDropdown
              activeIndex={dropdownId?.text}
              activeColor={colors["background-light-blue"]}
              color={colors.text[1]}
              fontWeight={1}
              fontSize={[0, 1]}
              options={
                id === "productReport" && data.is_company_member
                  ? dropdownData.filter(item => item.id !== "istr")
                  : dropdownData
              }
              selectHandler={dropDownSelectHandler}
              top={0}
              width={[189, 192]}
            />
          </Primitives.Box>
        )}
      </Primitives.Flex>
    )
  };
  return window.innerWidth < breakPoint
    ? view["mobile"]
    : showTabsOnMediumScreen && view["tablet"];
};
DropdownView.propTypes = propTypes;
DropdownView.defaultProps = defaultProps;
export default DropdownView;
