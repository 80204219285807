import React, { useContext, useEffect, useState } from "react";
import Primitives from "../../../components/primitives";
import NavbarCloseButton from "../../../components/glyphs/elements/navbar-close-button";
import Cta from "../../../components/cta";
import { colors } from "../../../components/utils/theme";
import AknamedLogo from "../../../components/glyphs/elements/aknamed-logo";
import { func, node, number } from "prop-types";
import { useHistory } from "react-router-dom";
import Loading from "../../../components/loading";
import { AppContext } from "../../../utils/context";
import logoutUser from "../../logout-user";
import isCompanyAdmin from "../../is-company-admin";
import { useIntercom } from "react-use-intercom";

let linksArray = [
  {
    linkName: "Product Report",
    link: "/company/product_report",
    selected: 0
  },
  {
    linkName: "Secondary Sales Report",
    link: "/company/hospital_sales",
    selected: 1
  },
  {
    linkName: "Inventory Analysis",
    link: "/company/inventory_analysis",
    selected: 2
  }
  // ,
  // {
  //   linkName: "Key Account Manager Report",
  //   link: "/company/company_members",
  //   selected: 3
  // }
];
let insightsArray = comapanyAdmin => [
  {
    linkName: "Customer Performance",
    link: "/company/insights",
    selected: 3
  },
  {
    linkName: "Product Performance",
    link: "/company/product_performance",
    selected: 4
  },
  comapanyAdmin
    ? {
        linkName: "Wallet Share Analytics",
        link: "/company/wallet_share",
        selected: 5
      }
    : {}
];
const propTypes = {
  navbarDisplayHandler: func,
  title: node,
  selected: number
};
const NavBar = ({ navbarDisplayHandler, title, selected }) => {
  const { host, loadUser, loggedInUser } = useContext(AppContext);
  const [comapanyAdmin, setCompanyAdmin] = useState(true);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const { shutdown } = useIntercom();

  const userlogout = async () => {
    setLoader(true);
    const response = await logoutUser(host);
    if (response.success) {
      setLoader(false);
      shutdown();
      loadUser({});
      history.push("/");
    }
  };

  useEffect(() => {
    isCompanyAdmin(loggedInUser)
      ? setCompanyAdmin(true)
      : setCompanyAdmin(false);
  }, [loggedInUser]);

  return (
    <>
      {!loader ? (
        <Primitives.Flex>
          <Primitives.Flex flexDirection="column" fontFamily="Lato" width={1}>
            <Primitives.Flex
              px={[1, 2]}
              py={[1, "12px"]}
              cursor="pointer"
              alignItems="center"
              mb={2}
            >
              <Primitives.Flex mr={1}>
                <NavbarCloseButton
                  width={34}
                  height={35}
                  fill={colors.text[0]}
                  onClick={() => {
                    navbarDisplayHandler();
                  }}
                />
              </Primitives.Flex>
              <Primitives.Flex flexDirection="column" flex={2} m={[0, 1]}>
                {title && title}
              </Primitives.Flex>
            </Primitives.Flex>
            {linksArray.map((item, index) => {
              return (
                <Primitives.Text
                  cursor="pointer"
                  mb={2}
                  p={1}
                  pl={2}
                  key={index}
                  backgroundColor={
                    item.selected === selected &&
                    colors["background-light-blue"]
                  }
                >
                  <Cta
                    fontSize={[1, 3]}
                    fontWeight={item.selected === selected && 2}
                    hover={{
                      textDecorationLine: false
                    }}
                    color="black"
                    label={item.linkName}
                    link={item.link}
                    textdecorationline={false}
                  />
                </Primitives.Text>
              );
            })}
            <Primitives.Text
              pl={2}
              fontSize={[0, 2]}
              fontWeight={700}
              mb={2}
              color="gray"
            >
              Insights
            </Primitives.Text>
            {insightsArray(comapanyAdmin).map((item, index) => {
              return (
                <Primitives.Text
                  cursor="pointer"
                  mb={2}
                  p={1}
                  pl={2}
                  key={index}
                  backgroundColor={
                    item.selected === selected &&
                    colors["background-light-blue"]
                  }
                >
                  <Cta
                    fontSize={[1, 3]}
                    fontWeight={item.selected === selected && 2}
                    hover={{
                      textDecorationLine: false
                    }}
                    color="black"
                    label={item.linkName}
                    link={item.link}
                    textdecorationline={false}
                  />
                </Primitives.Text>
              );
            })}
            <Primitives.Flex
              borderBottom={`1px solid ${colors["pattens-blue"]}`}
            />
            <Primitives.Flex cursor="pointer" my={3} ml={2} p={1}>
              <Primitives.Button
                fontSize={[1, 3]}
                fontWeight={1}
                color={colors.text[1]}
                onClick={userlogout}
              >
                Logout
              </Primitives.Button>
            </Primitives.Flex>
            {loggedInUser && loggedInUser.user && (
              <Primitives.Flex
                color={colors.text[1]}
                fontSize={[1, 3]}
                fontWeight={1}
                ml={2}
                p={1}
              >
                {loggedInUser.user.first_name} | {loggedInUser.user.email}
              </Primitives.Flex>
            )}
          </Primitives.Flex>
          <Primitives.Box position="fixed" left={20} bottom={25}>
            <AknamedLogo width={67} height={48} />
          </Primitives.Box>
        </Primitives.Flex>
      ) : (
        <Primitives.Flex margin="auto" mt="50vh">
          <Loading onlySpinner={true} />
        </Primitives.Flex>
      )}
    </>
  );
};
NavBar.propTypes = propTypes;
export default NavBar;
