import React from "react";
import LoginImage from "../../../components/glyphs/elements/login-image";
import Primitives from "../../../components/primitives";

const BrandImage = () => (
  <Primitives.Flex marginLeft="auto" marginRight="auto">
    <Primitives.Flex display={["flex", "none"]}>
      <LoginImage />
    </Primitives.Flex>
    <Primitives.Flex display={["none", "flex"]}>
      <LoginImage width={400} height={275} />
    </Primitives.Flex>
  </Primitives.Flex>
);

export default BrandImage;
