import React, { useEffect, useState } from "react";
import Reports from "../../../reports";
import DrillDownPageHeader from "../../../../lib/drilldown-page-header";
import Title from "../../../../lib/title";
import headerValue from "../../../../lib/header-value";
import { useParams } from "react-router-dom";

const modifyReports = data =>
  data &&
  data["batch_details"] &&
  data["batch_details"].map(item => ({
    batch_number: item["batch_number"],
    days_to_expiry: Math.abs(item["days_to_expiry"]),
    quantity: item["quantity"],
    value: `₹ ${item["value"]}`
  }));

const columnSumFn = data => ({
  branch: data["branch"],
  division: data["company_division"],
  expiry_range: data["expiry_range"],
  total_quantity: data["total_quantity"],
  total_batches: data["total_batches"],
  total_value: data["total_value"]
});

const headerData = (value, tab) => {
  const isExpiredTab = tab === "expired";
  const headerDataObj = {
    Branch: headerValue(value, "branch"),
    Division: headerValue(value, "division"),
    "No of Batches": headerValue(value, "total_batches")
  };
  if (isExpiredTab) {
    headerDataObj["Expired Qty"] = headerValue(value, "total_quantity");
  } else {
    headerDataObj["Expiry Range"] = headerValue(value, "expiry_range");
    headerDataObj["Near Exp Qty"] = headerValue(value, "total_quantity");
  }
  headerDataObj["Total Value"] = headerValue(value, "total_value");
  return headerDataObj;
};

const productColumn = tab => {
  const headingPrefix = tab === "expired" ? "Expired" : "Near Expiry";
  return [
    {
      heading: "Batch No",
      type: "string",
      width: 1
    },
    {
      heading: `Days ${headingPrefix === "Expired" ? "since" : "to"} Expiry`,
      type: "number",
      width: 1
    },
    {
      heading: `${headingPrefix} Qty`,
      type: "number",
      width: 1
    },
    {
      heading: `${headingPrefix} Value`,
      type: "number",
      width: 1
    }
  ];
};

const ProductWiseBreakUp = () => {
  const [productData, setProductData] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const { branchId, tab, skuId, expiryRange } = useParams();

  const getCustomerData = data => {
    setProductData(data);
  };

  const api = `smartchain/inventory_analytics/batch_details?branch_id=${branchId}&tab=${tab}&q[universal_sku_id_in]=${skuId}&near_expiry_filter=${expiryRange}`;

  useEffect(() => {
    setColumnData(productColumn(tab));
  }, [productData]);

  return (
    <Reports
      api={api}
      column={columnData}
      filters={["Search"]}
      getCustomerData={getCustomerData}
      headClick={false}
      modifyReports={modifyReports}
      placeholder="Batch No."
      pageId="customerPerformanceDetails"
      responsiveWidth={[900, 1000, 1000, "unset"]}
      selected={2}
      title={
        <>
          <Title>{productData && productData["sku_name"]}</Title>
        </>
      }
      reportTitle={productData?.sku_name}
      details={
        <>
          <DrillDownPageHeader
            headerData={headerData(
              productData &&
                productData["sku_name"] &&
                columnSumFn(productData),
              tab
            )}
          />
        </>
      }
      isSortingRequire={true}
    />
  );
};

export default ProductWiseBreakUp;
