import React from "react";

const NavbarCloseButton = ({ ...props }) => (
  <svg
    width="34"
    height="35"
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    title="navbar-close-button"
    {...props}
  >
    <ellipse cx="17" cy="17.5" rx="17" ry="17.5" fill="#F2F4FF" />
    <path
      d="M18.4658 18.0001L24.6959 11.7697C25.1014 11.3645 25.1014 10.7092 24.6959 10.3039C24.2907 9.89868 23.6354 9.89868 23.2302 10.3039L16.9999 16.5343L10.7698 10.3039C10.3644 9.89868 9.70934 9.89868 9.30408 10.3039C8.89864 10.7092 8.89864 11.3645 9.30408 11.7697L15.5342 18.0001L9.30408 24.2305C8.89864 24.6358 8.89864 25.291 9.30408 25.6963C9.50604 25.8984 9.77159 26 10.037 26C10.3023 26 10.5677 25.8984 10.7698 25.6963L16.9999 19.4659L23.2302 25.6963C23.4323 25.8984 23.6977 26 23.963 26C24.2284 26 24.4938 25.8984 24.6959 25.6963C25.1014 25.291 25.1014 24.6358 24.6959 24.2305L18.4658 18.0001Z"
      fill="inherit"
    />
  </svg>
);
export default NavbarCloseButton;
