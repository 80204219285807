import React, { useState, useContext } from "react";
import Primitives from "../../../components/primitives";
import Reports from "../../reports";
import { useParams, useHistory } from "react-router-dom";
import Title from "../../../lib/title";
import checkSortingIndex from "../../../lib/check-sorting-index/index";
import {
  isDataAvailable,
  isValueZero,
  rupeeMetric
} from "../../../lib/demo-user-value-display";
import Hospital from "../../../components/glyphs/elements/hospital";
import BackButton from "../../../lib/back-button";
import DropdownComponent from "../../mobile-navigation-dropdown";
import { FilterContext } from "../../hospital-sales-report/filterContext";

const salesStatistics = hospitalData => [
  {
    glyph: <Hospital />,
    heading: "TOTAL HOSPITALS",
    title: isDataAvailable(hospitalData, "hospital_hash", "purchasing_centres")
  }
];
const column = () => [
  {
    heading: "PRODUCT NAME",
    type: "string",
    width: 1.5
  },
  {
    heading: "PRODUCT DIVISION SKUS",
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    heading: "QUANTITY PURCHASED",
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    heading: "VALUE",
    type: "string",
    width: 1
  }
];

const index = {
  0: "division+",
  1: "division+",
  2: "total_quantity+",
  3: "total_value+"
};

const modifyReports = data =>
  data &&
  data["sku_details"] &&
  data["sku_details"].map(item => ({
    hospitalName: item.name,
    division: item.division,
    total_quantity: isValueZero(item.total_quantity),
    total_value: rupeeMetric(item.total_value)
  }));

const HospitalSalesProductWise = () => {
  const [hospitalData, setHospitalData] = useState([]);
  const [columnSum, setColumnSum] = useState({});
  const [sort, updateSort] = useState("current_month_sales+desc");
  const filterContext = useContext(FilterContext);
  const monthFilterQueryString = filterContext?.filterState?.months
    ? `&sales_invoices_invoice_date_in=${filterContext?.filterState?.months}`
    : "";
  const api = `smartchain/hospital_reports/hospitals/${
    useParams().id
  }/sku_wise?sort=total_quantity asc&sort=division asc`;

  const getHeaderId = (id, order) =>
    checkSortingIndex(index, id, order, updateSort);

  const getColumnSum = data => {
    setColumnSum(data);
  };

  const getHospitalData = data => {
    setHospitalData(data);
  };

  /** For mobile view */
  const initialDropdownId = 0;

  const [request, updateRequest] = useState(api + monthFilterQueryString);

  const filterUpdate = ({ skuSearch, months }) => {
    const monthTexts = months?.split(",")[0];
    updateRequest(
      `${api}${monthTexts ? `&${monthTexts}` : ""}${
        skuSearch ? `&sku_name=${skuSearch}` : ""
      }`
    );
  };
  const history = useHistory();

  const handleClick = () => {
    filterContext.updateFilterState("");
    history.goBack();
  };

  return (
    <Reports
      api={request}
      column={column(columnSum)}
      cursor="pointer"
      filterConfig={{
        skuSearch: true,
        months: true,
        search: "skuSearch",
        mobileFilters: [{ text: "Months", filterText: "months" }],
        defaultFilter: "Months"
      }}
      filterUpdate={filterUpdate}
      getColumnSum={getColumnSum}
      getHeaderId={getHeaderId}
      getHospitalData={getHospitalData}
      modifyReports={modifyReports}
      pageId="hospitalSalesProductWise"
      responsiveWidth={[600, 850, 1100, "unset"]}
      responsiveHeight={["30vh", "55vh"]}
      salesStatistics={salesStatistics(hospitalData)}
      sort={sort}
      selected={1}
      title={
        <>
          <Title>
            <BackButton clickHandler={handleClick} />
            <Primitives.Text mr={1}>
              {isDataAvailable(hospitalData, "column_sum", "group")}
            </Primitives.Text>{" "}
            Products
          </Title>
          <DropdownComponent initialDropdownId={initialDropdownId} />
        </>
      }
      reportTitle="Products"
    />
  );
};

export default HospitalSalesProductWise;
