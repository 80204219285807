import React, { useState, useContext } from "react";
import Primitives from "../../../components/primitives";
import Reports from "../../reports";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Title from "../../../lib/title";
import checkSortingIndex from "../../../lib/check-sorting-index/index";
import {
  isDataAvailable,
  isValueZero,
  rupeeMetric
} from "../../../lib/demo-user-value-display";
import roundOffTwoDecimal from "../../../components/utils/round-off-two-decimal";
import BackButton from "../../../lib/back-button";
import ConvertMoneyToLakhsCrores from "../../../components/utils/convert-money-to-lakh-crores";
import DropdownComponent from "../../mobile-navigation-dropdown";
import { FilterContext } from "../../hospital-sales-report/filterContext";

const salesStatistics = hospitalData => [
  {
    glyph: <Primitives.Flex />,
    heading: "TOTAL GROUPS",
    title: isDataAvailable(hospitalData, "column_sum", "total_centres_sum")
  }
];

const column = data => [
  {
    footerData: "Total",
    heading: "GROUP NAME",
    type: "string",
    width: 2
  },
  {
    align: "flex-end",
    footerData: `${roundOffTwoDecimal(data["total_centres_sum"])}`,
    heading: "NO. OF CENTRES",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    footerData: `${roundOffTwoDecimal(data["purchasing_centres_sum"])}`,
    heading: "PURCHASING CENTRES",
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    heading: "TOTAL SKUS",
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      roundOffTwoDecimal(data["last_month_sales_sum"])
    )}`,
    heading: "LAST MONTHS SALES",
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      roundOffTwoDecimal(data["current_month_sales_sum"])
    )}`,
    heading: "THIS MONTH SALES",
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      roundOffTwoDecimal(data["total_sales_sum"])
    )}`,
    heading: "YEAR TILL DATE SALES",
    type: "string",
    width: 1
  }
];

const index = {
  0: "name+",
  1: "total_centres+",
  2: "purchasing_centres+",
  3: "uniq_products+",
  4: "last_month_sales+",
  5: "current_month_sales+",
  6: "total_sales+"
};

const modifyReports = data =>
  data &&
  data["items"] &&
  data["items"].map(item => ({
    groupName: item.name,
    noOfCentres: item.total_centres,
    purchasingCentres: isValueZero(item.purchasing_centres),
    totalSkus: isValueZero(item.uniq_products),
    lastMonthSales: rupeeMetric(item.last_month_sales),
    thisMonthSales: rupeeMetric(item.current_month_sales),
    yearTillDateSales: rupeeMetric(item.total_sales)
  }));

const HospitalSalesGroupWise = () => {
  const history = useHistory();
  const [hospitalData, setHospitalData] = useState([]);
  const [columnSum, setColumnSum] = useState({});
  const [sort, updateSort] = useState("current_month_sales+desc");
  const filterContext = useContext(FilterContext);
  const monthFilterQueryString = filterContext?.filterState?.months
    ? `&sales_invoices_invoice_date_in=${filterContext?.filterState?.months}`
    : "";
  const city_id = useParams().id;
  const api = `smartchain/hospital_reports/cities/${city_id}/group_wise?`;

  const getHeaderId = (id, order) =>
    checkSortingIndex(index, id, order, updateSort);

  const getColumnSum = data => {
    setColumnSum(data);
  };

  const getHospitalData = data => {
    setHospitalData(data);
  };
  const getHospitalId = index => {
    if (hospitalData && hospitalData["items"]) {
      return hospitalData["items"][index].id;
    }
  };
  const hospitalSalesByGroupHandler = (row, index) => {
    if (getHospitalId(index)) {
      history.push(
        `/company/${getHospitalId(
          index
        )}/${city_id}/hospital_sales_hospital_wise`
      );
    }
  };

  /** For mobile view */
  const initialDropdownId = 0;

  const [request, updateRequest] = useState(api + monthFilterQueryString);

  const filterUpdate = ({ groupSearch, months }) => {
    const monthTexts = months?.split(",")[0];
    filterContext.updateFilterState({
      ...filterContext.filterState,
      months: String(monthTexts?.split("=")[1] ?? "")
    });
    updateRequest(
      `${api}${monthTexts ? `${monthTexts}` : ""}${
        groupSearch ? `&q[group_name_cont]=${groupSearch}` : ""
      }`
    );
  };
  const handleClick = () => {
    history.goBack();
    filterContext.updateFilterState("");
  };

  return (
    <Reports
      api={request}
      column={column(columnSum)}
      cursor="pointer"
      clickHandler={hospitalSalesByGroupHandler}
      filterConfig={{
        groupSearch: true,
        months: true,
        search: "groupSearch",
        mobileFilters: [{ text: "Months", filterText: "months" }],
        defaultFilter: "Months"
      }}
      filterUpdate={filterUpdate}
      getColumnSum={getColumnSum}
      getHeaderId={getHeaderId}
      getHospitalData={getHospitalData}
      modifyReports={modifyReports}
      pageId="hospitalSalesGroupWise"
      responsiveWidth={[800, 1100, 1100, "unset"]}
      responsiveHeight={["30vh", "55vh"]}
      salesStatistics={salesStatistics(hospitalData)}
      sort={sort}
      selected={1}
      title={
        <>
          <Title>
            <BackButton clickHandler={handleClick} />
            <Primitives.Text mr={1}>
              {isDataAvailable(hospitalData, "column_sum", "city")}
            </Primitives.Text>{" "}
            HOSPITALS
          </Title>
          <DropdownComponent initialDropdownId={initialDropdownId} />
        </>
      }
      reportTitle={`${isDataAvailable(
        hospitalData,
        "column_sum",
        "city"
      )} HOSPITALS`}
    />
  );
};

export default HospitalSalesGroupWise;
