import Loading from "../../components/loading";
import Primitives from "../../components/primitives";
import React from "react";

export const isDataAvailable = (hospitalData, value1, value2) =>
  hospitalData && hospitalData[value1] ? (
    hospitalData[value1][value2]
  ) : (
    <Primitives.Flex width={25} height={25}>
      <Loading onlySpinner />
    </Primitives.Flex>
  );

export const isValueZero = value => (value === 0 ? null : value);
export const rupeeMetric = value => (value === 0 ? "--" : `₹ ${value}`);
