import React from "react";
import NavItem from "../../components/nav-item";
import Primitives from "../../components/primitives";
import { array, bool, oneOfType, func, string } from "prop-types";
import { colors } from "../../components/utils/theme";

const propTypes = {
  booleanValue: bool,
  clickHandler: func,
  label: string,
  width: oneOfType([array, string]),
  cursor: string
};

const defaultProps = {
  width: "auto"
};

const TabButtons = ({ booleanValue, clickHandler, label, width, cursor }) => (
  <Primitives.Button onClick={clickHandler} width={width}>
    <NavItem
      borderColor={`${colors.primary[3]} !important`}
      color={colors.text[0]}
      cursor={cursor}
      hoverColor={colors.text[0]}
      label={label}
      pb={2}
      px={3}
      pt={2}
      selected={booleanValue}
    />
  </Primitives.Button>
);

TabButtons.propTypes = propTypes;
TabButtons.defaultProps = defaultProps;
export default TabButtons;
