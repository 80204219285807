import React, { useContext, useEffect, useState } from "react";
import { array, bool, func, object, oneOfType, string } from "prop-types";
import { convertDate } from "../../components/utils";
import { AppContext } from "../../utils/context";
import { colors } from "../../components/utils/theme";
import Primitives from "../../components/primitives";
import FilterDateRange from "./date-range";
import ApplyFilter from "./apply-filter";
import GenericDropdown from "./generic-dropdown";
import Filter from "../../components/glyphs/elements/filter";
import GenericSearch from "./generic-search";
import ListDropdown from "../../components/list-dropdown";
import styled from "styled-components";
import Month from "../months-list";
import CheckBoxContainer from "./checkbox-container";
import SingleDate from "./single-date-pick";
import { FilterContext } from "../../pages/hospital-sales-report/filterContext";

const propTypes = {
  button: bool,
  config: object,
  endDate: string,
  category: string,
  headers: oneOfType([array, object]),
  hospital: string,
  flexWrap: string,
  filterUpdate: func,
  tagSearchHeading: string,
  startDate: string,
  tags: bool,
  tagProps: object,
  clearAllFilters: bool,
  setClearAllFilters: func
};

// const defaultFilter = {
//   ProductReportSku: "Date Range",
//   ProductReportBranch: "Date Range",
//   hospitalSalesReport: "Month",
//   hospitalSalesBreakdownGroup: "Month",
//   hospitalSalesBreakdownParts: "Month",
//   orderReport: "Hospitals",
//   keyAccountManagerReport: "Kam",
//   customerPerformance: "Date",
//   customerPerformanceDetails: "Date",
//   customerCentreProductsBreakdown: "Date",
//   productPerformance: "Date"
// };

const mobileDropdown = {
  borderColor: colors.text[3],
  color: colors.text[2],
  listWidth: "100%",
  listMaxHeight: ["unset", "400px"],
  shadowRequired: false
};

const StyledText = styled(Primitives.Text)`
  text-decoration: underline;
`;

const Filters = ({
  filterUpdate,
  flexWrap,
  config,
  endDate,
  startDate,
  headers,
  clearAllFilters,
  setClearAllFilters
}) => {
  const {
    category,
    cities,
    divisions,
    states,
    tenants,
    masterskus,
    hospitals,
    branches
  } = useContext(AppContext);

  const initialDateRange = {
    startDate: config?.defaultDate?.startDate ?? new Date(),
    endDate: config?.defaultDate?.endDate ?? new Date(),
    key: "selection",
    color: colors.primary[0],
    /** new prop = false, indicates it's not a new date range */
    new: config?.defaultDate?.startDate ? false : true
  };
  const [filterActive, setFilterActive] = useState(
    startDate || endDate ? true : false
  );
  const [collapse, setCollpase] = useState(false);
  const [filterState, setFilterState] = useState({});
  const filterContext = useContext(FilterContext);
  const [dateRanges, setDateRanges] = useState([
    filterContext?.filterState?.date
      ? filterContext?.filterState?.date
      : initialDateRange
  ]);
  const [showMobileFilter, setShowMobileFilter] = useState("none");
  const [clearMobileFilters, setClearMobileFilters] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(config["defaultFilter"]);
  const [mobileConfig, setMobileConfig] = useState(currentFilter);
  const [selectedItems, setSelectedItems] = useState("");
  const [getConfig, setGetConfig] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    filterContext?.filterState?.singleDate
      ? new Date(
          filterContext?.filterState?.singleDate
            .split("-")
            .reverse()
            .join("-")
        )
      : new Date()
  );
  const marginBottom = flexWrap ? 4 : 0;
  const filterObj = {
    Division: {
      data: divisions["Divisions"],
      queryString: "&q[company_division_eq]="
    },
    Tenant: {
      data: tenants && tenants["tenants"],
      queryString: "&[tenant_id][]="
    },
    "Date Range": {},
    Date: {},
    originBranch: {
      data: config?.originBranchesList,
      queryString: "&original_warehouse_id[]="
    },
    currentBranch: {
      data: config?.currentBranchesList,
      queryString: "&current_warehouse_id[]="
    },
    masterSku: {
      data: masterskus && masterskus["Masterskus"],
      queryString: "&q[master_sku_id_in][]="
    },
    City: {
      data: cities && cities["Cities"],
      singleSelect: true
    },
    months: {
      data: Month(),
      singleSelect: true,
      queryString: "sales_invoices_invoice_date_in="
    },
    hospitals: {
      data: hospitals?.Hospitals ?? [],
      queryString: "&q[sales_invoice_buyer_party_id_in][]="
    },
    branches: {
      data: branches?.Branches ?? [],
      queryString: "&q[branch_id_in][]="
    },
    states: {
      data: states && states["states"],
      singleSelect: true
    },
    categories: {
      data: category && category["classifications"],
      queryString: "&q[classification_in][]="
    }
  };

  const searchObj = {
    skuSearch: { margin: false },
    productSearch: { margin: false },
    stateSearch: { margin: false },
    citySearch: { margin: false },
    groupSearch: { margin: false },
    hospitalSearch: { margin: false }
  };

  const checkBoxObj = {
    retailSales: {
      title: "Include Patient Sales",
      isChecked:
        filterState?.retailSales ?? config?.retailSales?.defaultValue ?? false,
      key: "retailSales"
    },
    istrTransfer: {
      title: "Include Internal Stock Transfer",
      isChecked:
        filterState?.istrTransfer ??
        config?.istrTransfer?.defaultValue ??
        false,
      key: "istr"
    }
  };
  useEffect(() => {
    Object.keys(filterObj).map(item => {
      let updatedValue = {};
      updatedValue[item] = [];
      setFilterState(prevState => ({
        ...prevState,
        ...updatedValue
      }));
    });
  }, []);

  useEffect(() => {
    Object.keys(searchObj).map(item => {
      let updatedValue = {};
      updatedValue[item] = "";
      setFilterState(prevState => ({
        ...prevState,
        ...updatedValue
      }));
    });
  }, []);

  const generateStrFn = (selectedData, queryString) =>
    selectedData.length > 0 ? `${queryString}${selectedData.join()}` : "";

  const multiSelectKeys = [
    "entity",
    "hospitalEntity",
    "hospitalCenter",
    "invoiceStatus",
    "subcategory",
    "distributor",
    "status"
  ];
  const mapMultiSingleSelect = key => {
    const mapperFn = (data, filter) => (filter || "") + data.join(filter);
    if (filterObj[key].singleSelect || multiSelectKeys.includes(key)) {
      if (filterObj[key].queryString) {
        return generateStrFn(filterState[key], filterObj[key].queryString);
      } else {
        return filterState[key][1];
      }
    } else {
      return filterState[key] && filterState[key].length > 0
        ? mapperFn(filterState[key], filterObj[key].queryString)
        : "";
    }
  };

  const generateStr = () => {
    const filterRes = {};
    const searchRes = {};
    const checkBoxRes = {};
    const dateStr = !dateRanges[0].new
      ? `date_start=${convertDate(
          dateRanges[0].startDate,
          "-"
        )}&date_end=${convertDate(dateRanges[0].endDate, "-")}`
      : "";
    const singleDateStr = selectedDate ? convertDate(selectedDate, "-") : "";
    Object.keys(config).forEach(filter => {
      if (filterObj[filter]) {
        filterRes[filter] = mapMultiSingleSelect(filter);
      } else if (searchObj[filter]) {
        searchRes[filter] = filterState[filter];
      } else if (checkBoxObj[filter]) {
        checkBoxRes[filter] =
          filterState[filter] ?? config[filter]?.defaultValue ?? false;
      }
    });

    const queryObj = {
      ...filterRes,
      ...searchRes,
      ...checkBoxRes,
      date: dateStr,
      singleDate: singleDateStr
    };

    return queryObj;
  };

  useEffect(() => {
    if (getConfig) {
      let updatedValue = {};
      updatedValue[getConfig] = selectedItems[getConfig];
      setFilterState(prevState => ({
        ...prevState,
        ...updatedValue
      }));
    }
  }, [selectedItems]);

  useEffect(() => {
    if (getConfig) {
      let updatedValue = {};
      updatedValue[getConfig] = searchValue;
      setFilterState(prevState => ({
        ...prevState,
        ...updatedValue
      }));
    }
  }, [searchValue]);

  const commonFilterUpdate = (timeStr, quickSearchStr) => {
    const queryObj = generateStr();
    filterUpdate({ ...queryObj, time: timeStr, quickSearch: quickSearchStr });
  };

  const dateChangeHandler = (item, collapseTheDateOptions = false) => {
    item.selection.new = false;
    setDateRanges([item.selection]);
    collapseTheDateOptions &&
      item.selection.startDate.getTime() !== item.selection.endDate.getTime() &&
      setCollpase(!collapse);
  };

  const singleDateChangeHandler = item => {
    filterContext && filterContext.updateFilterState("");
    setSelectedDate(item);
  };

  const applyFilter = () => {
    commonFilterUpdate(null, "");
    setFilterActive(true);
  };

  const clearFilterLink = isClearFilter => (
    <StyledText
      color={colors.text[1]}
      cursor={isClearFilter ? "pointer" : "default"}
      fontSize={1}
      ml={1}
      lineHeight={4}
      onClick={() => {
        if (isClearFilter) {
          clearFilter();
        }
        setFilterActive(false);
      }}
      width="60px"
    >
      {isClearFilter ? "Clear Filter" : ""}
    </StyledText>
  );

  const clearFilter = () => {
    setDateRanges([initialDateRange]);
    setSelectedDate(new Date());
    setFilterActive(false);
    const clearObj = { ...searchObj, ...filterObj };
    let updatedValue = {};
    Object.keys(clearObj).forEach(item => (updatedValue[item] = []));
    Object.keys(checkBoxObj).forEach(item => (updatedValue[item] = false));
    setFilterState(updatedValue);
    filterContext && filterContext.updateFilterState("");
    filterUpdate({
      quickSearch: "quick_filter=monthly",
      time: "time_period=30",
      filterCleared: true
    });
  };
  useEffect(() => {
    if (clearAllFilters) {
      clearFilter();
      setClearAllFilters(false);
    }
  }, [clearAllFilters]);

  useEffect(() => {
    setMobileConfig(currentFilter);
  }, [currentFilter]);

  useEffect(() => {
    if (filterContext?.filterState) {
      setFilterActive(true);
    } else {
      setFilterActive(false);
      setDateRanges([initialDateRange]);
    }
  }, [filterContext]);

  return (
    <Primitives.Flex flexDirection="column" flex={1} width={0}>
      {/* Desktop Filters Section */}
      <Primitives.Flex
        flex={1}
        flexWrap={flexWrap}
        display={["none", "none", "flex"]}
      >
        {Object.keys(config).map((item, index) => (
          <React.Fragment key={index}>
            {searchObj[item] && (
              <GenericSearch
                clearFilter={filterActive}
                config={item}
                getClickEvent={() => applyFilter()}
                getConfig={item => setGetConfig(item)}
                getSearchValue={item => setSearchValue(item)}
                margin={searchObj[item].margin}
                ml={index === 0 ? 0 : 20}
                throttle={searchObj[item].throttle}
                heading={headers && headers[index]}
              />
            )}
            {filterObj[item] && (
              <Primitives.Box
                width={item !== "Date Range" && item !== "Date" && 200}
              >
                <Primitives.Flex
                  flex={1}
                  mb={marginBottom}
                  ml={index === 0 || filterObj[item].ml === 0 ? 0 : 20}
                  flexDirection="column"
                >
                  <GenericDropdown
                    clearFilter={filterActive}
                    config={item}
                    dropdownData={filterObj[item].data}
                    filterContextState={filterContext?.filterState?.[item]}
                    getConfig={item => setGetConfig(item)}
                    getSelectedItems={items =>
                      setSelectedItems({ [item]: items })
                    }
                    selectType={
                      filterObj[item].singleSelect
                        ? "singleSelect"
                        : "multiSelect"
                    }
                    singleDateChangeHandler={singleDateChangeHandler}
                    selectedDate={selectedDate}
                    heading={headers && headers[index]}
                  />
                </Primitives.Flex>
              </Primitives.Box>
            )}
            {checkBoxObj[item] && (
              <Primitives.Flex
                ml={index === 0 ? 0 : 20}
                flexShrink={0}
                key={checkBoxObj?.[item]?.key}
              >
                <CheckBoxContainer
                  isChecked={checkBoxObj[item]?.isChecked}
                  title={checkBoxObj[item]?.title}
                  clickHandler={() => {
                    setGetConfig(item);
                    setSelectedItems({ [item]: !checkBoxObj[item]?.isChecked });
                  }}
                />
              </Primitives.Flex>
            )}
            {item === "Date Range" && dateRanges && (
              <Primitives.Box width={200}>
                <Primitives.Flex flexDirection="column">
                  <FilterDateRange
                    dateRangeCollapse={collapse}
                    dateChangeHandler={dateChangeHandler}
                    dateRanges={dateRanges}
                    heading={headers && headers[index]}
                    defaultValue="Date Range"
                  />
                </Primitives.Flex>
              </Primitives.Box>
            )}
            {item === "Date" && (
              <Primitives.Flex width={200}>
                <SingleDate
                  color={colors.text[1]}
                  changeHandler={singleDateChangeHandler}
                  collapsed={collapse}
                  date={selectedDate}
                />
              </Primitives.Flex>
            )}
          </React.Fragment>
        ))}

        {!config.noFilter && (
          <Primitives.Flex
            flex={0.5}
            ml={20}
            alignItems={flexWrap ? "start" : "end"}
          >
            <ApplyFilter
              applyFilter={applyFilter}
              clearFilter={clearFilter}
              filterActive={filterActive}
            />
          </Primitives.Flex>
        )}
      </Primitives.Flex>

      <Primitives.Flex
        flexDirection="column"
        position="fixed"
        top={0}
        right={0}
        width={1}
        zIndex={2}
        display={[showMobileFilter, showMobileFilter, "none"]}
      >
        <Primitives.Flex
          width={1}
          justifyContent="space-between"
          backgroundColor="purple"
          borderBottom="1px solid lightGrey"
        >
          <Primitives.Flex color="black" fontSize={2} fontWeight={700} p={3}>
            Filters
          </Primitives.Flex>
          <Primitives.Flex
            color={colors.primary[3]}
            fontSize={1}
            fontWeight={2}
            p={3}
            cursor="pointer"
            onClick={() => {
              filterContext.updateFilterState("");
              setClearMobileFilters(!clearMobileFilters);
            }}
          >
            Clear All
          </Primitives.Flex>
        </Primitives.Flex>
        <Primitives.Flex width={1}>
          <Primitives.Box width="35%" height="100vh" backgroundColor="purple">
            <Primitives.Flex width={1}>
              <ListDropdown
                activeIndex={currentFilter}
                activeColor="white"
                backgroundColor="purple"
                top={66}
                width={"32%"}
                color={colors.text[1]}
                hoverweight={1}
                hoverColor="green"
                options={config["mobileFilters"]}
                listMaxHeight="auto"
                selectHandler={e => {
                  setCurrentFilter(e.filterText);
                }}
                shadowRequired={false}
              />
            </Primitives.Flex>
          </Primitives.Box>
          <Primitives.Flex width="65%" p={2} backgroundColor="white">
            {config["mobileFilters"]?.map((filter, index) => (
              <Primitives.Flex
                width={1}
                key={index}
                maxWidth="350px"
                overflowY="auto"
                display={filter.filterText === currentFilter ? "flex" : "none"}
              >
                <Primitives.Box width={218}>
                  {filterObj[currentFilter] && (
                    <Primitives.Flex
                      flex={1}
                      mb={marginBottom}
                      flexDirection="column"
                    >
                      <GenericDropdown
                        clearFilter={filterActive}
                        clearMobileFilters={clearMobileFilters}
                        config={currentFilter}
                        defaultDate={config?.defaultDate}
                        dropdownData={filterObj[currentFilter].data}
                        getConfig={item => setGetConfig(item)}
                        getMobileDate={item => setSelectedDate(item)}
                        getMobileDateRange={item => setDateRanges(item)}
                        getSelectedItems={items => {
                          items.length > 0 &&
                            setSelectedItems({
                              ...selectedItems,
                              [filter.filterText]: items
                            });
                        }}
                        mobileConfig={mobileConfig}
                        mobileFilterContextState={
                          filterContext?.filterState?.[filter.filterText]
                        }
                        selectType={
                          filterObj[currentFilter].singleSelect
                            ? "singleSelect"
                            : "multiSelect"
                        }
                        heading={headers && headers[currentFilter]}
                        {...mobileDropdown}
                      />
                    </Primitives.Flex>
                  )}
                  {checkBoxObj[currentFilter] && (
                    <Primitives.Flex
                      ml={index === 0 ? 0 : 20}
                      flexShrink={0}
                      key={checkBoxObj?.[currentFilter]?.key}
                    >
                      <CheckBoxContainer
                        isChecked={checkBoxObj[currentFilter]?.isChecked}
                        title={checkBoxObj[currentFilter]?.title}
                        clickHandler={() => {
                          setGetConfig(currentFilter);
                          setSelectedItems({
                            [currentFilter]: !checkBoxObj[currentFilter]
                              ?.isChecked
                          });
                        }}
                      />
                    </Primitives.Flex>
                  )}
                </Primitives.Box>
              </Primitives.Flex>
            ))}
          </Primitives.Flex>
        </Primitives.Flex>

        {/* Mobile Filters Page */}
        <Primitives.Flex
          width={1}
          justifyContent="space-around"
          backgroundColor="white"
          position="fixed"
          bottom="0px"
          py={3}
          zIndex={3}
        >
          <Primitives.Button
            color="black"
            fontSize={2}
            fontWeight={700}
            onClick={() => {
              setShowMobileFilter("none");
            }}
          >
            Cancel
          </Primitives.Button>
          <Primitives.Flex border="1px solid grey"></Primitives.Flex>
          <Primitives.Button
            color={colors.primary[3]}
            fontSize={1}
            fontWeight={2}
            onClick={() => {
              applyFilter();
              setShowMobileFilter("none");
            }}
          >
            Apply Filters
          </Primitives.Button>
        </Primitives.Flex>
      </Primitives.Flex>

      {/* Mobile Search with Apply Filters Section */}
      <Primitives.Flex
        height={"30px"}
        display={["flex", "flex", "none"]}
        width={1}
        alignItems="center"
      >
        <Primitives.Flex>
          {config && config["search"] && (
            <GenericSearch
              clearFilter={filterActive}
              config={config["search"]}
              getClickEvent={() => applyFilter()}
              getConfig={item => setGetConfig(item)}
              getSearchValue={item => setSearchValue(item)}
              margin={searchObj[config["search"]].margin}
              ml={0}
              //   throttle={searchObj[item].throttle}
              heading={headers && headers[config["search"]]}
            />
          )}
        </Primitives.Flex>

        <Primitives.Flex
          opacity={!filterActive ? 0.8 : 1}
          //   flex={3}
          alignItems="center"
          backgroundColor={filterActive ? colors.primary[3] : "white"}
          border={`1px solid ${colors.text[2]}`}
          borderRadius={4}
          height={28}
          ml={1}
          cursor="pointer"
          onClick={() => setShowMobileFilter("flex")}
          justifyContent="center"
          width={filterActive ? 70 : 128}
        >
          <Filter
            height="14.51px"
            width="15.2px"
            fill={filterActive ? "white" : colors.primary[3]}
          />
          {filterActive ? (
            <Primitives.Text ml={1} color="white" fontSize={0}>
              {/* ({getFiltersCount()}) */}
            </Primitives.Text>
          ) : (
            <Primitives.Text
              color={filterActive ? "white" : colors.primary[3]}
              fontSize={1}
              fontWeight={1}
              ml={1}
            >
              Apply Filters
            </Primitives.Text>
          )}
        </Primitives.Flex>
        {clearFilterLink(filterActive)}
      </Primitives.Flex>
    </Primitives.Flex>
  );
};
Filters.propTypes = propTypes;
export default Filters;
