import React, { useEffect, useState, useContext } from "react";
import Primitives from "../../../components/primitives";
import Reports from "../../reports";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Title from "../../../lib/title";
import checkSortingIndex from "../../../lib/check-sorting-index/index";
import {
  isDataAvailable,
  isValueZero,
  rupeeMetric
} from "../../../lib/demo-user-value-display";
import Hospital from "../../../components/glyphs/elements/hospital";
import Location from "../../../components/glyphs/elements/location";
import roundOffTwoDecimal from "../../../components/utils/round-off-two-decimal";
import BackButton from "../../../lib/back-button";
import ConvertMoneyToLakhsCrores from "../../../components/utils/convert-money-to-lakh-crores";
import DropdownComponent from "../../mobile-navigation-dropdown";
import { FilterContext } from "../../hospital-sales-report/filterContext";

const salesStatistics = hospitalData => [
  {
    glyph: <Hospital />,
    heading: "TOTAL HOSPITALS",
    title: isDataAvailable(hospitalData, "column_sum", "total_hospitals")
  },
  {
    glyph: <Location />,
    heading: "SERVING CITIES",
    title: isDataAvailable(hospitalData, "column_sum", "total_cities")
  }
];

const column = data => [
  {
    footerData: "Total",
    heading: "CITY NAME",
    type: "string",
    width: 2
  },
  {
    footerData: `${roundOffTwoDecimal(data["total_hospitals"])}`,
    heading: "NO. OF HOSPITALS",
    type: "number",
    width: 2,
    align: "flex-end"
  },
  {
    footerData: `${roundOffTwoDecimal(data["purchasing_hospitals"])}`,
    heading: "PURCHASING HOSPITALS",
    type: "string",
    width: 1,
    align: "flex-end"
  },
  {
    heading: "TOTAL SKUS",
    type: "string",
    width: 1,
    align: "flex-end"
  },
  {
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      roundOffTwoDecimal(data["last_month_sales_sum"])
    )}`,
    heading: "LAST MONTHS SALES",
    type: "string",
    width: 1,
    align: "flex-end"
  },
  {
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      roundOffTwoDecimal(data["current_month_sales_sum"])
    )}`,
    heading: "THIS MONTH SALES",
    type: "string",
    width: 1,
    align: "flex-end"
  },
  {
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      roundOffTwoDecimal(data["total_sales_sum"])
    )}`,
    heading: "YEAR TILL DATE SALES",
    type: "string",
    width: 1,
    align: "flex-end"
  }
];

const index = {
  0: "name+",
  1: "total_centres+",
  2: "purchasing_centres+",
  3: "uniq_products+",
  4: "last_month_sales+",
  5: "current_month_sales+",
  6: "total_sales+"
};

const modifyReports = data =>
  data &&
  data["items"] &&
  data["items"].map(item => ({
    cityName: item.name,
    noOfHospitals: item.total_centres,
    purchasingHospitals: isValueZero(item.purchasing_centres),
    totalSkus: isValueZero(item.uniq_products),
    lastMonthSales: rupeeMetric(item.last_month_sales),
    thisMonthSales: rupeeMetric(item.current_month_sales),
    yearTillDateSales: rupeeMetric(item.total_sales)
  }));

const HospitalSalesCityWise = () => {
  const history = useHistory();
  const [hospitalData, setHospitalData] = useState([]);
  const [columnSum, setColumnSum] = useState({});
  const [sort, updateSort] = useState("current_month_sales+desc");
  const filterContext = useContext(FilterContext);
  const monthFilterQueryString = filterContext?.filterState?.months
    ? `&sales_invoices_invoice_date_in=${filterContext?.filterState?.months}`
    : "";
  const api = `smartchain/hospital_reports/states/${useParams().id}/city_wise?`;

  const getHeaderId = (id, order) =>
    checkSortingIndex(index, id, order, updateSort);

  const getColumnSum = data => {
    setColumnSum(data);
  };

  const getHospitalData = data => {
    setHospitalData(data);
  };
  const getHospitalId = index => {
    if (hospitalData && hospitalData["items"]) {
      return hospitalData["items"][index].id;
    }
  };
  const hospitalSalesByCityHandler = (row, index) => {
    history.push(`/company/${getHospitalId(index)}/hospital_sales_group_wise`);
  };

  // useEffect(() => {
  //   hospitalData &&
  //     hospitalData["total_items"] === 1 &&
  //     hospitalSalesByCityHandler([], 0);
  // }, [hospitalData]);

  /** For mobile view */
  const initialDropdownId = 0;

  const [request, updateRequest] = useState(api + monthFilterQueryString);

  const filterUpdate = ({ citySearch, months }) => {
    const monthTexts = months?.split(",")[0];
    filterContext.updateFilterState({
      ...filterContext.filterState,
      months: String(monthTexts?.split("=")[1] ?? "")
    });
    updateRequest(
      `${api}${monthTexts ? `${monthTexts}` : ""}${
        citySearch ? `&q[city_name_cont]=${citySearch}` : ""
      }`
    );
  };

  const handleClick = () => {
    filterContext.updateFilterState("");
    history.goBack();
  };

  return (
    <Reports
      api={request}
      column={column(columnSum)}
      cursor="pointer"
      clickHandler={hospitalSalesByCityHandler}
      filterConfig={{
        citySearch: true,
        months: true,
        search: "citySearch",
        mobileFilters: [{ text: "Months", filterText: "months" }],
        defaultFilter: "Months"
      }}
      filterUpdate={filterUpdate}
      getColumnSum={getColumnSum}
      getHeaderId={getHeaderId}
      getHospitalData={getHospitalData}
      modifyReports={modifyReports}
      pageId="hospitalSalesCityWise"
      responsiveWidth={[900, 1100, 1100, "unset"]}
      responsiveHeight={["30vh", "55vh"]}
      salesStatistics={salesStatistics(hospitalData)}
      sort={sort}
      selected={1}
      title={
        <>
          <Title>
            <BackButton clickHandler={handleClick} />
            <Primitives.Text mr={1}>
              {isDataAvailable(hospitalData, "column_sum", "state")}
            </Primitives.Text>{" "}
            HOSPITALS
          </Title>
          <DropdownComponent initialDropdownId={initialDropdownId} />
        </>
      }
      reportTitle={`${isDataAvailable(
        hospitalData,
        "column_sum",
        "state"
      )} HOSPITALS`}
    />
  );
};

export default HospitalSalesCityWise;
