import { useContext } from "react";
import { useIntercom } from "react-use-intercom";
import { AppContext } from "../../utils/context";
const Intercom = () => {
  const { loggedInUser } = useContext(AppContext);

  const { boot } = useIntercom();

  window.intercomSettings = {
    horizontal_padding: 20,
    vertical_padding: 5
  };
  loggedInUser?.user?.name &&
    boot({
      name: loggedInUser.user.name,
      customAttributes: { Product: "SmartChain" },
      bottom: 30,
      email: loggedInUser.user.email,
      user_id: loggedInUser.user.id
    });
  return null;
};
export default Intercom;
