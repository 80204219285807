import React, { useContext, useEffect, useState } from "react";
import Primitives from "../../components/primitives";
// import OpenLock from "../../components/glyphs/elements/open-lock";
import ConvertMoneyToLakhsCrores from "../../components/utils/convert-money-to-lakh-crores";
import Reports from "../reports";
import { useHistory } from "react-router-dom";
import ProgressIcon from "../../components/progress-icon";
import ToolTipView from "../../lib/tooltip-view";
import Title from "../../lib/title";
import { breakPoint } from "../../components/utils/theme";
import toolTipData from "../../lib/tooltip-text";
import convertToObj from "../../components/utils/convert-to-object";
import checkSortingIndex from "../../lib/check-sorting-index";
import roundOffTwoDecimal from "../../components/utils/round-off-two-decimal";
import { FilterContext } from "../hospital-sales-report/filterContext";

const toolTipIndex = [4, 5, 6, 7];

const headerMetric = (customerData, data) =>
  customerData && customerData["metrics"] === "qty"
    ? `Qty (last ${data} days)`
    : `Sales (last ${data} days)`;

const column = (columnSum, customerData) => [
  {
    footerData: "Total",
    heading: (
      <Primitives.Flex
        key="1"
        width={1}
        alignItems="space-between"
        justifyContent="space-between"
      >
        <Primitives.Text fontWeight={700}>Group Name</Primitives.Text>
        {/* <OpenLock fill={colors.text[1]} /> */}
      </Primitives.Flex>
    ),
    type: "string",
    width: 2,
    sort: false
  },
  {
    align: "flex-end",
    footerData: `${ConvertMoneyToLakhsCrores(columnSum["total_centres_sum"])}`,
    heading: "Total Centres",
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    footerData: `${ConvertMoneyToLakhsCrores(
      columnSum["purchasing_centres_sum"]
    )}`,
    heading: "Purchasing Centres",
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    heading: "SKU's Purchased",
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      columnSum["avg_sales_90_days_sum"]
    )}`,
    heading: `Daily avg. ${headerMetric(customerData, "90")}`,
    type: "string",
    width: 1.5
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      columnSum["avg_sales_30_days_sum"]
    )}`,
    heading: `Daily avg. ${headerMetric(customerData, "30")}`,
    type: "string",
    width: 1.5
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      columnSum["avg_sales_7_days_sum"]
    )}`,
    heading: `Daily avg. ${headerMetric(customerData, "7")}`,
    type: "string",
    width: 1.5
  },
  {
    heading: "Performance trend",
    type: "string",
    width: 1
  }
];

const desc = "Insights report showing customer specific sales trend";
const toolTip = () => <ToolTipView desc={desc} />;

const metricMapper = data => (data["metrics"] === "qty" ? "" : "₹ ");

const modifyReports = data =>
  data &&
  data["items"] &&
  data["items"].map(item => ({
    groupName: item.name,
    noOfCentres: item.total_centres,
    purchasingCentres: item.purchasing_centres,
    totalSkus: item.uniq_products,
    last90DaysAvg:
      metricMapper(data) + `${roundOffTwoDecimal(item.avg_sales_90_days)}`,
    last30DaysAvg:
      metricMapper(data) + `${roundOffTwoDecimal(item.avg_sales_30_days)}`,
    last7DaysAvg:
      metricMapper(data) + `${roundOffTwoDecimal(item.avg_sales_7_days)}`,
    performance: (
      <Primitives.Flex>
        <ProgressIcon input1={item.performance} input2={0} />
        <Primitives.Text ml={1}>{item.performance + "%"}</Primitives.Text>
      </Primitives.Flex>
    )
  }));

const CustomerPerformance = () => {
  let history = useHistory();
  const [customerData, setCustomerData] = useState([]);
  const [columnSum, setColumnSum] = useState({});
  const [sort, updateSort] = useState(null);
  const api = "smartchain/insights/customer_performance?";
  const [request, updateRequest] = useState(api);
  const filterContext = useContext(FilterContext);

  const index = {
    1: "total_centres+",
    2: "purchasing_centres+",
    3: "uniq_products+",
    4: "avg_sales_90_days+",
    5: "avg_sales_30_days+",
    6: "avg_sales_7_days+",
    7: "performance+"
  };

  const getHeaderId = (id, order) =>
    checkSortingIndex(index, id, order, updateSort);

  const getColumnSum = data => {
    setColumnSum(data);
  };

  const getCustomerData = data => {
    setCustomerData(data);
  };

  const getCustomerId = index => {
    if (customerData && customerData["items"]) {
      return customerData["items"][index].id;
    }
  };

  const customerDetailsClickHandler = (row, index) => {
    let customerId = getCustomerId(index);
    history.push(
      `/company/insights/${customerId}/customer_performance_breakdown`
    );
  };

  const filterUpdate = ({ singleDate, groupSearch, filterCleared }) => {
    !filterCleared &&
      filterContext.updateFilterState({
        ...filterContext.filterState,
        singleDate: singleDate
      });
    updateRequest(
      `${api}${
        singleDate ? `&q[sales_invoices_invoice_date_in]=${singleDate}` : ""
      }${groupSearch ? `&q[group_name_cont]=${groupSearch}` : ""}`
    );
  };

  useEffect(() => {
    filterContext.updateFilterState("");
  }, []);

  return (
    <Reports
      api={request}
      column={column(columnSum, customerData)}
      clickHandler={customerDetailsClickHandler}
      cursor="pointer"
      filterConfig={{
        groupSearch: true,
        Date: true,
        search: "groupSearch",
        mobileFilters: [{ text: "Date", filterText: "Date" }],
        defaultFilter: "Date"
      }}
      filterUpdate={filterUpdate}
      getHeaderId={getHeaderId}
      getColumnSum={getColumnSum}
      getCustomerData={getCustomerData}
      modifyReports={modifyReports}
      placeholder="Search by Group Name"
      pageId="customerPerformance"
      responsiveWidth={[1000, 1100, 1100, "unset"]}
      responsiveHeight={["30vh", "55vh"]}
      sort={sort}
      selected={3}
      title={
        <>
          <Primitives.Flex alignItems="center">
            <Title>
              <Primitives.Text mb={["unset", 1]}>
                Customer Performance
              </Primitives.Text>
            </Title>
            {window.innerWidth < breakPoint && toolTip()}
          </Primitives.Flex>
          {window.innerWidth > breakPoint && toolTip()}
        </>
      }
      reportTitle="Customer Performance"
      toolTipText={convertToObj(toolTipIndex, toolTipData)}
    />
  );
};

export default CustomerPerformance;
