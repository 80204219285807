import React from "react";
import BubbleChart from "@weknow/react-bubble-chart-d3";
import Primitives from "../../primitives";
import { array, bool, func, number, oneOfType, string } from "prop-types";

const propTypes = {
  /** bubbles click function */
  bubbleClickFunc: func,
  /** array of object containing lable, color and value for each bubble */
  data: array,
  /** height of bubble chart */
  height: oneOfType([string, number, array]),
  /** legend click function */
  legendClickFun: func,
  /** boolean value to show legends */
  showLegend: bool,
  /** width of bubble chart */
  width: oneOfType([string, number, array])
};

const defaultProps = {
  bubbleClickFunc: () => {},
  legendClickFun: () => {},
  height: 300,
  showLegend: false,
  width: 300
};

const Bubble = ({
  bubbleClickFunc,
  data,
  height,
  legendClickFun,
  showLegend,
  width
}) => (
  <Primitives.Flex textAlign="center">
    <BubbleChart
      /** Custom bubble click functions such as searching using the label, redirecting to other page */
      data={data}
      bubbleClickFunc={bubbleClickFunc}
      graph={{
        zoom: 1,
        offsetX: 0,
        offsetY: 0
      }}
      height={height}
      /** Custom legend click functions such as searching using the label, redirecting to other page */
      legendClickFun={legendClickFun}
      /** optional value, number that set the padding between bubbles */
      padding={5}
      /** optional value, pass false to disable the legend. */
      showLegend={showLegend}
      width={width}
    />
  </Primitives.Flex>
);

Bubble.defaultProps = defaultProps;
Bubble.propTypes = propTypes;
export default Bubble;
