import React from "react";
import { useHistory } from "react-router-dom";
import Back from "../../components/glyphs/elements/back";
import Primitives from "../../components/primitives";
import { func } from "prop-types";

const propTypes = {
  clickHandler: func
};

const BackButton = ({ clickHandler }) => {
  let history = useHistory();

  return (
    <Primitives.Flex mr={1}>
      <Back
        fill="black"
        onClick={() => (clickHandler ? clickHandler() : history.goBack())}
        cursor="pointer"
      />
    </Primitives.Flex>
  );
};

BackButton.propTypes = propTypes;
export default BackButton;
