import React, { useContext, useState } from "react";
import Primitives from "../../../../components/primitives";
import { useParams } from "react-router-dom";
import DropdownView from "../../../../lib/dropdown-view";
import Reports from "../../../reports";
import { AppContext } from "../../../../utils/context";
import { breakPoint, colors } from "../../../../components/utils/theme";
import Title from "../../../../lib/title";

const dropdownData = loggedInUser => [
  {
    text: `CENTRES NOT PURCHASING ${
      loggedInUser && loggedInUser.user ? loggedInUser.user.company_name : ""
    }`,
    id: "notPurchasing"
  },
  {
    text: `CENTRES PURCHASING ${
      loggedInUser && loggedInUser.user ? loggedInUser.user.company_name : ""
    }`,
    id: "purchasing"
  }
];

const column = [
  {
    heading: "SR. NO.",
    type: "number",
    width: 1
  },
  {
    heading: "HOSPITAL BRANCH NAME",
    type: "string",
    width: 3
  },
  {
    heading: "CITY",
    type: "number",
    width: 1
  },
  {
    heading: "STATE",
    type: "number",
    width: 1
  }
];

const displayDataCount = (data, hospitalName) => (
  <Primitives.Text color={colors.text[1]} fontSize={1} px={[1, 3]} py="8px">
    Showing {data && data.length} {hospitalName} Hospital Branches
  </Primitives.Text>
);

const IndividualCenters = () => {
  const hospitalId = useParams().id;
  const values = hospitalId && hospitalId.split("&");
  const { loggedInUser } = useContext(AppContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownId, setdropdownId] = useState(dropdownData(loggedInUser)[0]);
  const [hospitalName, setHospitalName] = useState("");
  const [centersPurchasing, setCentersPurchasing] = useState();
  const [centersNotPurchasing, setCentersNotPurchasing] = useState();

  const tabSelectHandler = index => {
    setdropdownId(dropdownData(loggedInUser)[index]);
  };

  const dropDownSelectHandler = item => {
    setdropdownId(item);
    setShowDropdown(!showDropdown);
  };

  const api = `smartchain/wallet_analytics/hospitals_groups_with_their_centers?&${values[0]}&${values[1]}`;

  const modifyReports1 = () =>
    centersNotPurchasing &&
    centersNotPurchasing.map((item, index) => ({
      srNo: index + 1,
      name: item["name"],
      city: item["city"],
      state: item["state"]
    }));

  const modifyReports2 = () =>
    centersPurchasing &&
    centersPurchasing.map((item, index) => ({
      srNo: index + 1,
      name: item["name"],
      city: item["city"],
      state: item["state"]
    }));

  const getHospitalCentersData = data => {
    setHospitalName(data && data[0] && data[0] && data[0]["name"]);
    data &&
      data[0] &&
      setCentersNotPurchasing(data[0]["not_purchasing_centers"]);
    data && data[0] && setCentersPurchasing(data[0]["purchasing_centers"]);
  };

  const dropdown = () => (
    <Primitives.Box mt={1}>
      <DropdownView
        dropdownData={dropdownData(loggedInUser)}
        dropdownId={dropdownId}
        dropDownSelectHandler={dropDownSelectHandler}
        px={4}
        setShowDropdown={setShowDropdown}
        showDropdown={showDropdown}
        tabSelectHandler={tabSelectHandler}
      />
    </Primitives.Box>
  );

  return (
    <Reports
      api={api}
      column={column}
      dataCountText={displayDataCount(
        dropdownId.id === "notPurchasing"
          ? centersNotPurchasing
          : centersPurchasing,
        hospitalName
      )}
      filters={[]}
      isSortingRequire={false}
      isbackButtonRequired={true}
      isBorderRequire={false}
      isBackgroundRequire={false}
      getHospitalCentersData={getHospitalCentersData}
      modifyReports={
        dropdownId.id === "notPurchasing" ? modifyReports1 : modifyReports2
      }
      responsiveWidth={[900, 1000, 1000, "unset"]}
      selected={5}
      striped={false}
      title={
        <>
          <Title>
            {hospitalName} Purchasing centres & Not Purchasing centres
          </Title>
          {window.innerWidth < breakPoint && dropdown()}
        </>
      }
      reportTitle={`${hospitalName} Purchasing centres & Not Purchasing centres`}
      details={
        <>
          {window.innerWidth > breakPoint && (
            <Primitives.Box>{dropdown()}</Primitives.Box>
          )}
        </>
      }
    />
  );
};

export default IndividualCenters;
