import React from "react";
import Primitives from "../../../components/primitives";
// import SalesContribution from "./sales-contribution";
import Hospitals from "./hospitals";
import TopTenProducts from "./top-ten-products";
import SalesStatus from "./sales-status";
import Reports from "../reports";

const Home = () => (
  <Reports
    components={
      <>
        <Primitives.Flex flexWrap="wrap" flex={1}>
          {/* <SalesContribution /> */}
          <Hospitals />
          <SalesStatus />
        </Primitives.Flex>
        <TopTenProducts />
      </>
    }
    selected={5}
    title="Wallet Share Analytics"
    reportTitle="Wallet Share Analytics"
  />
);

export default Home;
