import React from "react";
import Primitives from "../../components/primitives";
import { colors } from "../../components/utils/theme";
import { object } from "prop-types";

const propTypes = {
  headerData: object
};

const DrillDownPageHeader = ({ headerData }) => (
  <Primitives.Flex
    backgroundColor="ghost-white"
    color={colors.text[0]}
    display="flex"
    fontSize={[0, 1]}
    fontWeight={400}
    flexWrap="wrap"
    justifyContent={["space-between", "unset"]}
    pl={[2, 56]}
    py={1}
  >
    {Object.keys(headerData).map((item, index) => (
      <Primitives.Flex
        key={index}
        mr={5}
        flexDirection={["row", "column"]}
        mb={1}
        minWidth={[120, "unset"]}
        style={{ gap: "5px" }}
      >
        <Primitives.Text>{item}</Primitives.Text>
        <Primitives.Text
          color={colors.text[0]}
          fontWeight={700}
          ml={["auto", 0]}
        >
          {headerData && headerData[item]}
        </Primitives.Text>
      </Primitives.Flex>
    ))}
  </Primitives.Flex>
);

DrillDownPageHeader.propTypes = propTypes;
export default DrillDownPageHeader;
