import React, { useContext } from "react";
import Primitives from "../../../../components/primitives";
import Card from "../../../../lib/card";
import { string } from "prop-types";
import Bubble from "../../../../components/charts/bubble";
import { colors } from "../../../../components/utils/theme";
import CtaColored from "../../../../components/cta-colored";
import { AppContext } from "../../../../utils/context";
import useApiRequest from "../../../../hooks/useApiRequest";
import Loading from "../../../../components/loading";

const propTypes = {
  filteredMonth: string
};

const bubbleColors = [
  colors["light-green"],
  colors["sky-blue"],
  colors["light-pink"],
  colors.primary[3],
  colors["dark-purple"],
  colors["dark-yellow"]
];

const modifyData = data =>
  data &&
  data["hospitals_count_state_wise"] &&
  data["hospitals_count_state_wise"].map((item, index) => ({
    label: item.state,
    value: item.hospitals_count,
    color: bubbleColors[index]
  }));

const StateWiseHospitals = ({ filteredMonth }) => {
  const { host } = useContext(AppContext);
  const request = `${host}/smartchain/wallet_analytics/hospitals_contribution_by_state?quick_filter=${filteredMonth}`;
  const { data, isLoaded } = useApiRequest(host, encodeURI(request));

  return (
    <Card
      details={
        <Primitives.Flex
          alignItems="center"
          flexDirection={["column", "row"]}
          justifyContent={["unset", "space-around", "space-between"]}
          mt={3}
          minHeight="25vh"
        >
          {isLoaded ? (
            <>
              <Bubble data={modifyData(data)} />
              <Primitives.Flex
                flexWrap={["wrap", "unset"]}
                flexDirection={["unset", "column"]}
                justifyContent={["space-between", "flex-end"]}
                mt={2}
              >
                {data &&
                  data["hospitals_count_state_wise"] &&
                  data["hospitals_count_state_wise"].map((item, index) => (
                    <Primitives.Flex key={index}>
                      <CtaColored
                        color={bubbleColors[index]}
                        label={item.state}
                        link={`/company/potential_hospital/states=${
                          item.id === 0 ? item.hospital_ids : item.id
                        }&categories=&quickfilter=${filteredMonth}`}
                        value={item.hospitals_count}
                      />
                    </Primitives.Flex>
                  ))}
              </Primitives.Flex>
            </>
          ) : (
            <Primitives.Flex
              alignItems="center"
              height={1}
              justifyContent="center"
              width={1}
            >
              <Loading onlySpinner />
            </Primitives.Flex>
          )}
        </Primitives.Flex>
      }
      isCta={true}
      isDownloadable={false}
      link={`/company/potential_hospital/states=&categories=&quickfilter=${filteredMonth}`}
      title={
        <Primitives.Flex flexDirection="column">
          <Primitives.Text fontSize={[2, 3]} fontWeight={[1, 2]} mr={1}>
            Hospitals by States
          </Primitives.Text>
          <Primitives.Text
            color={colors.text[2]}
            fontWeight={[1, 2]}
            fontSize={[1, "12px"]}
          >
            Total Hospitals {data && data["total_items"] && data["total_items"]}
          </Primitives.Text>
        </Primitives.Flex>
      }
    />
  );
};

StateWiseHospitals.propTypes = propTypes;
export default StateWiseHospitals;
