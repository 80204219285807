import React from "react";

const Hospital = ({ ...props }) => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.9179 5.6197H14.6998V6.4016C14.6998 6.73497 14.9725 7.00769 15.3058 7.00769C15.6392 7.00769 15.9119 6.73497 15.9119 6.4016V5.6197H16.6938C17.0272 5.6197 17.2999 5.34697 17.2999 5.01361C17.2999 4.68025 17.0272 4.40752 16.6938 4.40752H15.9119V3.62562C15.9119 3.29226 15.6392 3.01953 15.3058 3.01953C14.9725 3.01953 14.6998 3.29226 14.6998 3.62562V4.40752H13.9179C13.5845 4.40752 13.3118 4.68025 13.3118 5.01361C13.3118 5.34697 13.5845 5.6197 13.9179 5.6197Z"
      fill="#7A8A9C"
    />
    <path
      d="M10.6024 15.5305H13.6453C13.9787 15.5305 14.2514 15.2578 14.2514 14.9244L14.2517 11.8815C14.2517 11.5481 13.9789 11.2754 13.6456 11.2754H10.6027C10.2693 11.2754 9.99658 11.5481 9.99658 11.8815V14.9244C9.99633 15.2578 10.263 15.5305 10.6024 15.5305ZM11.2088 12.4876H13.0395V14.3183H11.2088V12.4876Z"
      fill="#7A8A9C"
    />
    <path
      d="M16.8092 15.5305H19.8521C20.1855 15.5305 20.4582 15.2578 20.4582 14.9244L20.4585 11.8815C20.4585 11.5481 20.1857 11.2754 19.8524 11.2754H16.8095C16.4761 11.2754 16.2034 11.5481 16.2034 11.8815V14.9244C16.2031 15.2578 16.4758 15.5305 16.8092 15.5305H16.8092ZM17.4153 12.4876H19.246V14.3183H17.4153V12.4876Z"
      fill="#7A8A9C"
    />
    <path
      d="M13.6451 21.7258C13.9785 21.7258 14.2512 21.4531 14.2512 21.1197V18.0768C14.2512 17.7434 13.9785 17.4707 13.6451 17.4707H10.6022C10.2688 17.4707 9.99609 17.7434 9.99609 18.0768V21.1197C9.99609 21.4531 10.2688 21.7258 10.6022 21.7258H13.6451ZM11.2083 18.6829H13.039V20.5136H11.2083V18.6829Z"
      fill="#7A8A9C"
    />
    <path
      d="M16.8092 21.7258H19.8521C20.1855 21.7258 20.4582 21.4531 20.4582 21.1197V18.0768C20.4582 17.7434 20.1855 17.4707 19.8521 17.4707H16.8092C16.4759 17.4707 16.2031 17.7434 16.2031 18.0768V21.1197C16.2031 21.4592 16.4759 21.7258 16.8092 21.7258ZM17.4153 18.6829H19.246V20.5136H17.4153V18.6829Z"
      fill="#7A8A9C"
    />
    <path
      d="M29.8481 11.9356H24.2896V5.0129C24.2896 4.67954 24.0168 4.40681 23.6835 4.40681H20.2829C19.9795 1.92764 17.8641 0 15.306 0C12.7479 0 10.6323 1.92764 10.3353 4.40681H6.77103C6.43767 4.40681 6.16494 4.67954 6.16494 5.0129V11.9356L0.606089 11.9359C0.272727 11.9359 0 12.2086 0 12.542V29.703C0 30.0364 0.272727 30.3091 0.606089 30.3091H29.848C30.1814 30.3091 30.4541 30.0364 30.4541 29.703V12.542C30.4541 12.2086 30.1814 11.9359 29.848 11.9359L29.8481 11.9356ZM15.3058 1.21218C17.4031 1.21218 19.1065 2.91555 19.1065 5.0129C19.1065 7.11025 17.4031 8.81362 15.3058 8.81362C13.2084 8.81362 11.505 7.11025 11.505 5.0129C11.5048 2.91555 13.2084 1.21218 15.3058 1.21218ZM1.21218 13.1476H6.16468L6.16443 29.0961H1.21219L1.21218 13.1476ZM7.37686 12.5415V5.61873H10.335C10.6382 8.0979 12.7477 10.0255 15.3058 10.0255C17.8639 10.0255 19.9794 8.0979 20.2765 5.61873H23.071V12.5415L23.0708 29.0959H18.3245V24.7072C18.3245 24.3738 18.0518 24.1011 17.7184 24.1011H12.7236C12.3902 24.1011 12.1175 24.3738 12.1175 24.7072L12.1173 29.0959H7.37102L7.37077 12.5415H7.37686ZM13.3355 29.0959V25.3135H17.1179L17.1182 29.0959H13.3355ZM29.2418 29.0959H24.2893L24.2895 13.1474H29.242L29.2418 29.0959Z"
      fill="#7A8A9C"
    />
  </svg>
);

export default Hospital;
