import React, { useEffect, useRef, useState } from "react";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import {
  array,
  bool,
  func,
  number,
  object,
  oneOfType,
  string
} from "prop-types";
import DateRange from "../../../components/date-range";

const propTypes = {
  buffer: number,
  cancelHandler: func,
  dateRangeCollapse: bool,
  dateChangeHandler: func,
  dateRanges: oneOfType([array, string]),
  defaultValue: oneOfType([object, string]),
  marginLeft: oneOfType([bool, number]),
  heading: string,
  maxSpan: number
};

const FilterDateRange = ({
  cancelHandler,
  dateRangeCollapse,
  dateChangeHandler,
  dateRanges,
  defaultValue,
  heading,
  marginLeft
}) => {
  const node = useRef();
  const [collapse, setCollpase] = useState(false);

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    if (
      !e.target.closest(".dropdown-filter") &&
      !e.target.closest(".rdrDateRangeWrapper") &&
      !e.target.closest(".rdrDefinedRangesWrapper")
    ) {
      setCollpase(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    setCollpase(!collapse);
  }, [dateRangeCollapse]);

  const dateFilterProps = {
    backgroundColor: "white",
    border: "1px solid",
    borderRadius: "4px",
    cancelHandler: cancelHandler,
    color: colors.text[1],
    changeHandler: dateChangeHandler,
    collapsed: collapse,
    collapseHandler: () => setCollpase(!collapse),
    defaultValue: defaultValue,
    ranges: dateRanges
  };

  return (
    <Primitives.Flex flex={1} ml={marginLeft} ref={node} flexDirection="column">
      {heading && (
        <Primitives.Text color="#333333" fontSize="12px" mb={1}>
          {heading}
        </Primitives.Text>
      )}
      <Primitives.Flex backgroundColor="white">
        <DateRange {...dateFilterProps} />
      </Primitives.Flex>
    </Primitives.Flex>
  );
};

FilterDateRange.propTypes = propTypes;
export default FilterDateRange;
