import React, { useContext, useEffect, useState } from "react";
import Primitives from "../../components/primitives";
import { AppContext } from "../../utils/context";
import { useHistory, useParams } from "react-router-dom";
import Reports from "../reports";
import { breakPoint, colors } from "../../components/utils/theme";
import Onboarding from "../onboarding";
import ConvertMoneyToLakhsCrores from "../../components/utils/convert-money-to-lakh-crores";
import { useLocation } from "react-router-dom";
import ParamsToHash from "../../lib/params-to-hash/index";
import DropdownView from "../../lib/dropdown-view";
import ToolTipView from "../../lib/tooltip-view";
import Title from "../../lib/title";
import checkSortingIndex from "../../lib/check-sorting-index/index";
import roundOffTwoDecimal from "../../components/utils/round-off-two-decimal";
import Button from "../../components/button";
import { FilterContext } from "../hospital-sales-report/filterContext";
import isNewSCApiUser from "../../lib/is-new-sc-api-user";

/** dropdown index */
const dropdownIndex = {
  //mtd: 0,
  ssr: 0,
  istr: 1
};

/** no sorting columns */
const noSortingColumns = [0];

const mtdSsrFilters = {
  "Date Range": true,
  Division: true,
  skuSearch: true,
  Tenant: true,
  search: "skuSearch",
  mobileFilters: [
    { text: "Date Range", filterText: "Date Range" },
    { text: "Tenant", filterText: "Tenant" },
    { text: "Division", filterText: "Division" }
  ],
  defaultFilter: "Date Range",
  defaultDate: {
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date()
  }
};

const filterDateFormat = date =>
  date
    .split("=")[1]
    .split("-")
    .reverse()
    .join("-");

const dropdownData = () => [
  // {
  //   col: [3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  //   desc:
  //     "Live stock and sales statement for products which have been sold this month",
  //   filters: mtdSsrFilters,
  //   id: "mtd",
  //   text: "MTD Sales Report",
  //   toolTipHeight: 50,
  //   toolTipText: {
  //     4: `Quantity purchased this month`,
  //     5: "Secondary sales this month"
  //   }
  // },
  {
    col: [3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    desc: "Live stock and sales statement displaying all products",
    filters: mtdSsrFilters,
    id: "ssr",
    text: "Stock & Sales Report",
    toolTipHeight: 40,
    toolTipText: {
      4: "Quantity purchased",
      5: "Scheme Qty"
    }
  },
  {
    col: [1, 1, 1, 1, 1],
    desc:
      "Report showing products transferred internally between two warehouses",
    filters: ["Date Range", "Branch", "Tenant"],
    id: "istr",
    text: "Internal Stock Transfer Report",
    toolTipHeight: 50
  }
];

const glyphHeader = (title, key) => [
  {
    footerData: "Total",
    heading: (
      <Primitives.Flex
        key={key}
        width={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Primitives.Text fontWeight={700}>{title}</Primitives.Text>
      </Primitives.Flex>
    ),
    type: "string",
    width: 2.5,
    sort: false
  }
];

const commonColumn = (columnMonth, columnSum) => [
  {
    align: "flex-end",
    footerData: columnSum["skus_count"],
    heading: "No. of SKUs",
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    footerData: ConvertMoneyToLakhsCrores(columnSum["last_month_sales"]),
    heading: ` Last Month Sales Qty`,
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    footerData: ConvertMoneyToLakhsCrores(columnSum["opening_stock"]),
    heading: `Opening Qty`,
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    background: "#E2E7FE !important",
    footerData: ConvertMoneyToLakhsCrores(columnSum["stock_in_grn_quantity"]),
    heading: "GRN Qty.",
    mainHeading: " ",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    background: "#E2E7FE !important",
    footerData: ConvertMoneyToLakhsCrores(
      columnSum["stock_in_grn_free_quantity"]
    ),
    heading: "Free Qty.",
    mainHeading: (
      <Primitives.Flex fontWeight={3} position="absolute" ml={3}>
        Stock
      </Primitives.Flex>
    ),
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    background: "#E2E7FE !important",
    footerData: ConvertMoneyToLakhsCrores(columnSum["stock_in_ist_quantity"]),
    heading: "Int Trns",
    mainHeading: (
      <Primitives.Flex fontWeight={3} position="absolute" ml={-55}>
        In
        <Primitives.Text fontSize="12px" ml="4px">
          ⬇️
        </Primitives.Text>
      </Primitives.Flex>
    ),
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    background: "#E2E7FE !important",
    footerData: `${ConvertMoneyToLakhsCrores(
      columnSum["stock_in_manual_credit_quantity"]
    )}`,
    heading: "Manual Cr",
    mainHeading: " ",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    background: "#CDD5FF !important",
    footerData: ConvertMoneyToLakhsCrores(
      columnSum["stock_out_sales_quantity"]
    ),
    heading: "MTD Sales",
    mainHeading: " ",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    background: "#CDD5FF !important",
    footerData: ConvertMoneyToLakhsCrores(
      columnSum["stock_out_sales_free_quantity"]
    ),
    heading: "Free Qty",
    type: "number",
    mainHeading: (
      <Primitives.Flex fontWeight={3} position="absolute" ml={3}>
        Stock
      </Primitives.Flex>
    ),
    width: 1
  },
  {
    align: "flex-end",
    background: "#CDD5FF !important",
    footerData: `${ConvertMoneyToLakhsCrores(
      columnSum["stock_out_ist_quantity"]
    )}`,
    heading: "Int Trns",
    mainHeading: (
      <Primitives.Flex fontWeight={3} position="absolute" ml={-55}>
        Out
        <Primitives.Text fontSize="12px" ml="4px">
          ⬆️
        </Primitives.Text>
      </Primitives.Flex>
    ),
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    background: "#CDD5FF !important",
    footerData: ConvertMoneyToLakhsCrores(
      columnSum["stock_out_manual_debit_quantity"]
    ),
    heading: "Manual Db",
    mainHeading: " ",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(columnSum["sales_value"])}`,
    heading: "Sales Value",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    footerData: ConvertMoneyToLakhsCrores(columnSum["sales_return_quantity"]),
    heading: "Sales Return",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    footerData: ConvertMoneyToLakhsCrores(
      columnSum["purchase_return_quantity"]
    ),
    heading: "Purchase Return",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    footerData: ConvertMoneyToLakhsCrores(columnSum["closing_stock"]),
    heading: "Closing Qty.",
    type: "number",
    width: 1
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      columnSum["closing_stock_value"]
    )}`,
    heading: "Closing Value",
    type: "number",
    width: 1.5
  }
];

const getProductName = name => (
  <Primitives.Flex justifyContent="space-between" flex={1}>
    <Primitives.Flex alignSelf="flex-start">{name}</Primitives.Flex>
    <Primitives.Flex alignSelf="flex-start" whiteSpace="nowrap">
      <Button
        active={{
          backgroundColor: colors.primary[3],
          color: "white"
        }}
        backgroundColor={colors.primary[3]}
        borderRadius={4}
        color={"white"}
        fontSize={0}
        fontWeight={2}
        hover={{
          backgroundColor: colors.primary[3],
          color: "white"
        }}
        label="View Details"
        lineHeight="12px"
        px="6px"
        py="4px"
      />
    </Primitives.Flex>
  </Primitives.Flex>
);

const ProductReportBranch = () => {
  const history = useHistory();
  const type = useParams().type || "ssr";
  const { branches, loggedInUser } = useContext(AppContext);
  const api = `smartchain${
    isNewSCApiUser() ? "/" : "/v4/"
  }product_reports?type=${type}`;
  const [request, updateRequest] = useState(api);

  const filterContext = useContext(FilterContext);
  const [columnMonth, setColumnMonth] = useState("");

  const companyName = loggedInUser?.user?.first_name;
  const [reportData, setReportData] = useState([]);
  const [columnSum, setColumnSum] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownId, setdropdownId] = useState(
    dropdownData()[dropdownIndex[type]]
  );
  const [backdrop, setBackdrop] = useState(true);
  const [sort, updateSort] = useState("current_month_sales+desc");
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [dropdownValue] = useState(dropdownIndex[type]);
  const [column, setColumn] = useState([]);

  const location = useLocation();

  const toolTip = () => <ToolTipView desc={dropdownId?.desc} />;

  const getFilterMonth = str => {
    const data = ParamsToHash(str);
    /** two distinguish between two same months of different years we are appending 
    corresponding year as well */
    setColumnMonth(
      data["sales_invoices_invoice_date_in"] &&
        data["sales_invoices_invoice_date_in"]
    );
  };

  const modifyReports = (data, api) => {
    if (data && data["items"]) {
      if (api.includes("product_reports?type=ssr")) {
        return data["items"].map(item => ({
          productName: getProductName(item.branch_name),
          skuCount: item.sku_count,
          lastMonthSales: roundOffTwoDecimal(item.last_month_sales),
          openingStock: roundOffTwoDecimal(item.opening_stock),
          quantityOrdered: roundOffTwoDecimal(item.stock_in_grn_quantity),
          stock_in_grn_free_quantity: roundOffTwoDecimal(
            item.stock_in_grn_free_quantity
          ),
          stock_in_ist_quantity: roundOffTwoDecimal(item.stock_in_ist_quantity),
          stock_in_manual_credit_quantity: roundOffTwoDecimal(
            item.stock_in_manual_credit_quantity
          ),
          stock_out_sales_quantity: roundOffTwoDecimal(
            item.stock_out_sales_quantity
          ),
          stock_out_sales_free_quantity: item.stock_out_sales_free_quantity,
          stock_out_ist_quantity: roundOffTwoDecimal(
            item.stock_out_ist_quantity
          ),
          stock_out_manual_debit_quantity: roundOffTwoDecimal(
            item.stock_out_manual_debit_quantity
          ),
          sales_value: `₹ ${roundOffTwoDecimal(item.sales_value)}`,
          sales_return_quantity: roundOffTwoDecimal(item.sales_return_quantity),
          purchase_return_quantity: roundOffTwoDecimal(
            item.purchase_return_quantity
          ),
          closing_stock: roundOffTwoDecimal(item.closing_stock),
          closing_stock_value: `₹ ${roundOffTwoDecimal(
            item.closing_stock_value
          )}`
        }));
      } else {
        return data["items"].map(item => ({
          productName: getProductName(item.branch_name),
          skuCount: item.sku_count,
          lastMonthSales: roundOffTwoDecimal(item.last_month_sales),
          openingStock: roundOffTwoDecimal(item.opening_stock),
          quantityOrdered: roundOffTwoDecimal(item.stock_in_grn_quantity),
          stock_in_grn_free_quantity: roundOffTwoDecimal(
            item.stock_in_grn_free_quantity
          ),
          stock_in_ist_quantity: roundOffTwoDecimal(item.stock_in_ist_quantity),
          stock_in_manual_credit_quantity: roundOffTwoDecimal(
            item.stock_in_manual_credit_quantity
          ),
          stock_out_sales_quantity: roundOffTwoDecimal(
            item.stock_out_sales_quantity
          ),
          stock_out_sales_free_quantity: item.stock_out_sales_free_quantity,
          stock_out_ist_quantity: roundOffTwoDecimal(
            item.stock_out_ist_quantity
          ),
          stock_out_manual_debit_quantity: roundOffTwoDecimal(
            item.stock_out_manual_debit_quantity
          ),
          sales_value: `₹ ${roundOffTwoDecimal(item.sales_value)}`,
          sales_return_quantity: roundOffTwoDecimal(item.sales_return_quantity),
          purchase_return_quantity: roundOffTwoDecimal(
            item.purchase_return_quantity
          ),
          closing_stock: roundOffTwoDecimal(item.closing_stock),
          closing_stock_value: `₹ ${roundOffTwoDecimal(
            item.closing_stock_value
          )}`
        }));
      }
    }
  };

  /** dropDownSelectHandler for mobile view, here we wont get index directly,hence using dropdownIndex we get its index and set current dropdown */
  const dropDownSelectHandler = item => {
    setdropdownId(dropdownData()[dropdownIndex[item.id]]);
    if (loggedInUser && loggedInUser.user) {
      item.id === "istr"
        ? history.push("/company")
        : history.push(
            `/company/product_report/${
              dropdownData()[dropdownIndex[item.id]].id
            }`
          );
    }
    setShowDropdown(!showDropdown);
  };

  const getColumnSum = data => {
    setColumnSum(data);
  };

  const backDropHandler = data => {
    setBackdrop(data);
  };

  const commonIndex = {
    1: "sku_count+",
    2: "last_month_sales+",
    3: "opening_stock+",
    4: "stock_in_grn_quantity+",
    5: "stock_in_grn_free_quantity+",
    6: "stock_in_ist_quantity+",
    7: "stock_in_manual_credit_quantity+",
    8: "stock_out_sales_quantity+",
    9: "stock_out_sales_free_quantity+",
    10: "stock_out_ist_quantity+",
    11: "stock_out_manual_debit_quantity+",
    12: "sales_value+",
    13: "sales_return_quantity+",
    14: "purchase_return_quantity+",
    15: "closing_stock+",
    16: "closing_stock_value+"
  };

  const istrIndex = {
    2: "uniq_skus+",
    3: "total_quantity+",
    4: "total_sales+"
  };

  const getHeaderId = {
    mtd: (id, order) => checkSortingIndex(commonIndex, id, order, updateSort),
    ssr: (id, order) => checkSortingIndex(commonIndex, id, order, updateSort),
    istr: (id, order) => checkSortingIndex(istrIndex, id, order, updateSort)
  };

  useEffect(() => {
    location.state &&
      /** checking whether the onboarding is done or not, false indicates onboarding is not done */
      !location.state.detail &&
      setShowOnboarding(true);
  }, [location]);

  /** whenever page reloads or change to different tabs, we set the dynamic value of tooltip text */
  useEffect(() => {
    setdropdownId(dropdownData()[dropdownValue]);
  }, [companyName, dropdownValue]);

  useEffect(() => {
    setColumn([
      ...glyphHeader("Branch", 1),
      ...commonColumn(columnMonth, columnSum)
    ]);
    if (loggedInUser && loggedInUser.user) {
      window.innerWidth < breakPoint && type === "istr"
        ? history.push("/company")
        : history.push(
            `/company/product_report/${dropdownData()[dropdownIndex[type]]?.id}`
          );
    }
  }, [dropdownId, columnSum, type, companyName]);

  useEffect(() => {
    if (branches && branches["Branches"] && branches["Branches"].length == 1) {
      history.push(
        `/company/${branches["Branches"][0].id}/sku_wise_breakdown/${type}`
      );
    }
  }, [branches]);

  const getBranchId = index =>
    reportData &&
    reportData.items &&
    reportData.items[index] &&
    reportData.items[index].branch_id;

  const branchDetailsClickHandler = (row, index) => {
    let branchId = getBranchId(index);
    history.push(`/company/${branchId}/sku_wise_breakdown/${type}`);
  };

  useEffect(() => {
    updateRequest(api);
  }, [type]);

  useEffect(() => {
    filterContext.updateFilterState("");
  }, []);

  const filterUpdate = ({
    date,
    skuSearch,
    Tenant,
    Division,
    filterCleared
  }) => {
    !filterCleared &&
      filterContext.updateFilterState({
        ...filterContext.filterState,
        date: {
          startDate: new Date(filterDateFormat(date?.split("&")[0])),
          endDate: new Date(filterDateFormat(date?.split("&")[1]))
        },
        tenant: Tenant?.split("&[tenant_id][]=")
          ?.filter(n => n)
          .join(","),
        division: Division?.split("&q[company_division_eq]=")
          ?.filter(n => n)
          .join(","),
        datePayload: date,
        divisionPayload: Division,
        tenantPayload: Tenant,
        skuSearch: skuSearch
      });
    updateRequest(
      `${api}&${date || ""}${
        skuSearch ? `&q[name_cont]=${skuSearch}` : ""
      }${Division || ""}${Tenant !== "&[tenant_id][]=" ? Tenant : ""}`
    );
  };

  return (
    <>
      <Reports
        api={request}
        column={column}
        displayLockIcon={["block"]}
        cursor="pointer"
        clickHandler={branchDetailsClickHandler}
        filterConfig={
          dropdownData().find(item => item.id === dropdownId?.id)?.filters
        }
        filterUpdate={filterUpdate}
        getColumnSum={getColumnSum}
        getFilterMonth={getFilterMonth}
        getHeaderId={getHeaderId[dropdownId?.id]}
        getHospitalData={data => setReportData(data)}
        modifyReports={modifyReports}
        noPagination={true}
        noSortingColumns={noSortingColumns}
        placeholder="Search by Master SKU"
        pageId="ProductReportBranch"
        responsiveWidth={[2023, 2200, 2120, 2180]}
        responsiveHeight={["40vh", "55vh"]}
        sort={sort}
        subNavIndex={[0, dropdownIndex[type]]}
        selected={0}
        title={
          <>
            <Primitives.Flex flexDirection="column">
              <Primitives.Flex>
                <Title>
                  <Primitives.Text mb={1}>
                    {window.innerWidth > breakPoint
                      ? dropdownData()[dropdownIndex[type]]?.text
                      : "Product Report"}
                  </Primitives.Text>
                </Title>
                {window.innerWidth < breakPoint && toolTip()}
              </Primitives.Flex>
              {window.innerWidth > breakPoint && (
                <ToolTipView desc={dropdownData()[dropdownIndex[type]]?.desc} />
              )}

              <DropdownView
                data={reportData}
                dropdownData={dropdownData()}
                dropdownId={dropdownId}
                dropDownSelectHandler={dropDownSelectHandler}
                id="ProductReportBranch"
                setShowDropdown={setShowDropdown}
                showDropdown={showDropdown}
                showTabsOnMediumScreen={true}
                tabSelectHandler={dropDownSelectHandler}
              />
            </Primitives.Flex>
          </>
        }
        reportTitle={dropdownData()[dropdownIndex[type]]?.text?.replace(
          "Report",
          ""
        )}
        toolTipText={dropdownId?.toolTipText}
      />
      {showOnboarding && (
        <Primitives.Flex
          alignItems="flex-end"
          backgroundColor={backdrop ? colors["shadow-grey"] : "none"}
          display={["flex", "none"]}
          height={backdrop ? 1 : 0}
          justifyContent="center"
          position="fixed"
          top={0}
          width={1}
          zIndex={1}
        >
          <Primitives.Box>
            <Onboarding backDropHandler={backDropHandler} />
          </Primitives.Box>
        </Primitives.Flex>
      )}
    </>
  );
};

export default ProductReportBranch;
