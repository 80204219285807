import React, { useState, useContext } from "react";
import Primitives from "../../../components/primitives";
import Reports from "../../reports";
import { useParams, useHistory } from "react-router-dom";
import Title from "../../../lib/title";
import checkSortingIndex from "../../../lib/check-sorting-index/index";
import {
  isDataAvailable,
  isValueZero,
  rupeeMetric
} from "../../../lib/demo-user-value-display";
import Hospital from "../../../components/glyphs/elements/hospital";
import roundOffTwoDecimal from "../../../components/utils/round-off-two-decimal";
import BackButton from "../../../lib/back-button";
import ConvertMoneyToLakhsCrores from "../../../components/utils/convert-money-to-lakh-crores";
import DropdownComponent from "../../mobile-navigation-dropdown";
import { FilterContext } from "../../hospital-sales-report/filterContext";

const salesStatistics = hospitalData => [
  {
    glyph: <Hospital />,
    heading: "TOTAL HOSPITALS",
    title: isDataAvailable(hospitalData, "hospital_hash", "purchasing_centres")
  }
];
const column = data => [
  {
    footerData: "Total",
    heading: "HOSPITAL NAME",
    type: "string",
    width: 2
  },
  {
    align: "flex-end",
    heading: "TOTAL SKUS",
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    heading: "AVERAGE SALES / MONTH",
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      roundOffTwoDecimal(data["last_month_sales_sum"])
    )}`,
    heading: "LAST MONTHS SALES",
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      roundOffTwoDecimal(data["current_month_sales_sum"])
    )}`,
    heading: "THIS MONTH SALES",
    type: "string",
    width: 1
  },
  {
    align: "flex-end",
    footerData: `₹ ${ConvertMoneyToLakhsCrores(
      roundOffTwoDecimal(data["total_sales_sum"])
    )}`,
    heading: "YEAR TILL DATE SALES",
    type: "string",
    width: 1
  }
];

const index = {
  0: "hospital_name+",
  1: "uniq_products+",
  2: "avg_sales+",
  3: "last_month_sales+",
  4: "current_month_sales+",
  5: "total_sales+"
};

const modifyReports = data =>
  data &&
  data["items"] &&
  data["items"].map(item => ({
    hospitalName: item.hospital_name,
    totalSkus: isValueZero(item.uniq_products),
    averageSalesPerMonth: rupeeMetric(item.avg_sales),
    lastMonthSales: rupeeMetric(item.last_month_sales),
    thisMonthSales: rupeeMetric(item.current_month_sales),
    yearTillDateSales: rupeeMetric(item.total_sales)
  }));

const HospitalSalesHospitalWise = () => {
  const [hospitalData, setHospitalData] = useState([]);
  const [columnSum, setColumnSum] = useState({});
  const [sort, updateSort] = useState("current_month_sales+desc");
  const filterContext = useContext(FilterContext);
  const monthFilterQueryString = filterContext?.filterState?.months
    ? `&sales_invoices_invoice_date_in=${filterContext?.filterState?.months}`
    : "";
  const api = `smartchain/hospital_reports/groups/${
    useParams().id
  }/hospital_wise?q[universal_city_id_eq]=${useParams().city}`;

  const getHeaderId = (id, order) =>
    checkSortingIndex(index, id, order, updateSort);

  const getColumnSum = data => {
    setColumnSum(data);
  };

  const getHospitalData = data => {
    setHospitalData(data);
  };

  const history = useHistory();

  const getHospitalId = index => {
    if (hospitalData && hospitalData["items"]) {
      return hospitalData["items"][index].id;
    }
  };
  const hospitalSalesByGroupHandler = (row, index) => {
    if (getHospitalId(index)) {
      history.push(
        `/company/${getHospitalId(index)}/hospital_sales_product_wise`
      );
    }
  };

  /** For mobile view */
  const initialDropdownId = 0;

  const [request, updateRequest] = useState(api + monthFilterQueryString);

  const filterUpdate = ({ hospitalSearch, months }) => {
    const monthTexts = months?.split(",")[0];
    filterContext.updateFilterState({
      ...filterContext.filterState,
      months: String(monthTexts?.split("=")[1] ?? "")
    });
    updateRequest(
      `${api}${monthTexts ? `&${monthTexts}` : ""}${
        hospitalSearch ? `&q[legal_name_cont]=${hospitalSearch}` : ""
      }`
    );
  };

  const handleClick = () => {
    filterContext.updateFilterState("");
    history.goBack();
  };

  return (
    <Reports
      api={request}
      column={column(columnSum)}
      cursor="pointer"
      clickHandler={hospitalSalesByGroupHandler}
      filterConfig={{
        hospitalSearch: true,
        months: true,
        search: "hospitalSearch",
        mobileFilters: [{ text: "Months", filterText: "months" }],
        defaultFilter: "Months"
      }}
      filterUpdate={filterUpdate}
      getColumnSum={getColumnSum}
      getHeaderId={getHeaderId}
      getHospitalData={getHospitalData}
      modifyReports={modifyReports}
      pageId="hospitalSalesHospitalWise"
      responsiveWidth={[750, 1100, 1100, "unset"]}
      responsiveHeight={["30vh", "55vh"]}
      salesStatistics={salesStatistics(hospitalData)}
      sort={sort}
      selected={1}
      title={
        <>
          <Title>
            <BackButton clickHandler={handleClick} />
            <Primitives.Text mr={1}>
              {isDataAvailable(hospitalData, "column_sum", "group")}
            </Primitives.Text>{" "}
            HOSPITALS
          </Title>
          <DropdownComponent initialDropdownId={initialDropdownId} />
        </>
      }
      reportTitle={`${isDataAvailable(
        hospitalData,
        "column_sum",
        "group"
      )} HOSPITALS`}
    />
  );
};

export default HospitalSalesHospitalWise;
