import React from "react";
import Caution from "../glyphs/elements/caution";
import { array, node, number, oneOfType, string } from "prop-types";
import CircleChecked from "../glyphs/elements/circle-checked";
import CircleCrossed from "../glyphs/elements/circle-crossed";
import Primitives from "../primitives";
import { colors } from "../utils/theme";

const propTypes = {
  /** children wrapped inside Progress Tag */
  children: node,
  /** font Size of text */
  fontSize: oneOfType([array, number, string]),
  /** font Weight of text */
  fontWeight: oneOfType([array, number, string]),
  /** indicates status */
  statusCode: string
};

const defaultProps = {
  fontSize: 1,
  fontWeight: 2
};

const status = {
  success: {
    color: "background-green",
    glyph: (
      <CircleChecked fill={colors["background-green"]} width={14} height={14} />
    )
  },
  error: {
    backgroundColor: "background-light-red",
    color: "background-error",
    glyph: (
      <CircleCrossed fill={colors["background-error"]} width={14} height={14} />
    )
  },
  warning: {
    backgroundColor: "background-caution",
    color: "dark-gold",
    glyph: <Caution fill={colors["dark-gold"]} />
  }
};

const ProgressTag = ({ children, fontSize, fontWeight, statusCode }) => (
  <Primitives.Flex
    alignItems="center"
    {...(statusCode && status[statusCode]["backgroundColor"]
      ? { backgroundColor: status[statusCode]["backgroundColor"] }
      : {})}
    {...(statusCode && status[statusCode]["backgroundColor"]
      ? { borderRadius: "17px" }
      : {})}
    display="inline-flex"
    overflow="auto"
    maxHeight={[19, 13]}
    py="5px"
    px="9px"
  >
    <Primitives.Text
      color={statusCode && status[statusCode]["color"]}
      fontSize={fontSize}
      fontWeight={fontWeight}
      mr={1}
    >
      {children}
    </Primitives.Text>
    {statusCode && status[statusCode]["glyph"]}
  </Primitives.Flex>
);

ProgressTag.defaultProps = defaultProps;
ProgressTag.propTypes = propTypes;
export default ProgressTag;
