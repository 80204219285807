import React, { useState } from "react";
import Primitives from "../../../components/primitives";
import Title from "../../../lib/title";
import Reports from "../../reports";
import { useParams } from "react-router-dom";

const column = [
  {
    heading: "SR. NO.",
    type: "number",
    width: 0.5
  },
  {
    heading: (
      <Primitives.Flex
        key="1"
        width={1}
        alignItems="space-between"
        justifyContent="space-between"
      >
        <Primitives.Text fontWeight={700}>HOSPITAL NAME</Primitives.Text>
      </Primitives.Flex>
    ),
    type: "string",
    width: 1
  },
  {
    heading: "CATEGORY",
    type: "number",
    width: 1
  },
  {
    heading: "STATE",
    type: "number",
    width: 1
  },
  {
    heading: "CITY",
    type: "number",
    width: 1
  }
];

const modifyReports = data =>
  data &&
  data["hospitals"] &&
  data["hospitals"].map((item, index) => ({
    srno: index + 1,
    hospitalName: item.name,
    category: item.classification,
    state: item.state,
    city: item.city
  }));

const PotentialHospital = () => {
  const filterValue = useParams().id;
  const values = filterValue && filterValue.split("&");
  let hospitals =
    values &&
    values[0].split("=")[1] &&
    Array.from(values[0].split("=")[1].split(",")).map(
      item => `&q[state_id_in]=${item}`
    );
  const api = `smartchain/wallet_analytics/hospitals_contribution_by_state?quick_filter=${values &&
    values[2].split("=")[1]}${
    values && values[0].split("=")[1] ? hospitals.join("") : ""
  }${
    values && values[1].split("=")[1]
      ? `&q[classification_in][]=${values[1].split("=")[1]}`
      : ""
  }`;
  const [request, updateRequest] = useState(`${api}`);

  const filterUpdate = queryObj => {
    const { City, states, categories, hospitalSearch } = queryObj;
    updateRequest(
      `smartchain/wallet_analytics/hospitals_contribution_by_state?quick_filter=${values &&
        values[2].split("=")[1]}${
        values && values[1].split("=")[1]
          ? `&q[classification_in][]=${values[1].split("=")[1]}`
          : ""
      }${City ? `&q[city_id_in]=${City}` : ""}${
        states ? `&q[state_id_in]=${states}` : hospitals
      }${categories ? categories : ""}${
        hospitalSearch ? `&q[display_name_cont]=${hospitalSearch}` : ""
      }`
    );
  };

  return (
    <Reports
      api={request}
      column={column}
      filterConfig={{
        hospitalSearch: true,
        City: true,
        states: true,
        categories: true,
        search: "hospitalSearch",
        mobileFilters: [
          { text: "City", filterText: "City" },
          { text: "State", filterText: "states" },
          { text: "Category", filterText: "categories" }
        ]
      }}
      filterUpdate={filterUpdate}
      isDownloadable={false}
      isSortingRequire={false}
      isbackButtonRequired={true}
      isBorderRequire={false}
      isBackgroundRequire={false}
      modifyReports={modifyReports}
      placeholder="Search by Group Name"
      pageId="hospitalByStates"
      responsiveWidth={[1000, 1100, 1100, "unset"]}
      selected={5}
      striped={false}
      title={<Title>Potential Hospitals</Title>}
      reportTitle="Potential Hospitals"
    />
  );
};

export default PotentialHospital;
