import React from "react";
import Button from "../../../components/button";
import LoginImage from "../../../components/glyphs/elements/login-image";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import Trademark from "../../onboarding/trademark";
import InputSection from "../input-section";
import Error from "../../../components/glyphs/elements/error";
import Loading from "../../../components/loading";
import Footer from "../footer";
import Alert from "../../../components/alert";
import { bool, func, object, string, oneOfType } from "prop-types";

const propTypes = {
  alertData: string,
  buttonText: string,
  formValidation: func,
  heading: oneOfType([object, string]),
  inputFields: object,
  loading: bool,
  setUserData: func,
  type: string,
  title: string,
  userData: object
};

const GenericReport = ({
  alertData,
  buttonText,
  formValidation,
  heading,
  inputFields,
  loading,
  setUserData,
  type,
  title,
  userData
}) => {
  const handleKeyDown = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      formValidation();
    }
  };

  return (
    <Primitives.Flex alignItems="center" height="95vh" p={20}>
      <Primitives.Flex flexDirection="column" ml={100}>
        <Trademark />
        {alertData && (
          <Primitives.Flex mt={2}>
            <Alert
              backgroundColor={colors["background-dull-red"]}
              borderColor={colors.error[1]}
              color={colors.error[1]}
              fontSize={1}
              glyph={<Error fill={colors.error[1]} />}
              label={
                <Primitives.Text alignItems="center" display="inline-flex">
                  {alertData}
                </Primitives.Text>
              }
              lineHeight={3}
              mx={1}
              pl={1}
              pr={2}
              py="7px"
            />
          </Primitives.Flex>
        )}
        <Primitives.Flex flexDirection="column" onKeyPress={handleKeyDown}>
          <Primitives.Flex>
            <Primitives.Text
              color={colors.text[0]}
              fontSize={11}
              fontWeight={0}
              fontFamily="lato"
              mt={11}
            >
              {/* Improve Healthcare <br /> Outcomes */}
              {heading}
            </Primitives.Text>
          </Primitives.Flex>

          <Primitives.Flex>
            <Primitives.Text
              color={colors.text[0]}
              fontSize={15}
              fontFamily="lato"
              mt={11}
            >
              {/* Create account to experience */}
              {title}
            </Primitives.Text>
          </Primitives.Flex>

          <Primitives.Flex>
            <form onSubmit={formValidation}>
              <Primitives.Flex flexWrap="wrap">
                {Object.keys(inputFields).map((item, index) => (
                  <InputSection
                    changeHandler={e =>
                      setUserData({
                        ...userData,
                        [item]: e.target.value
                      })
                    }
                    key={index}
                    placeholder={inputFields[item]}
                    required={true}
                    type={type}
                    value={userData[item]}
                  />
                ))}
              </Primitives.Flex>
              <Primitives.Flex mt={2}>
                <Button
                  active={{
                    backgroundColor: colors.primary[6],
                    color: "white"
                  }}
                  backgroundColor={colors.primary[3]}
                  color="white"
                  fontWeight={2}
                  hover={{
                    backgroundColor: colors.primary[6],
                    color: "white"
                  }}
                  label={
                    loading ? (
                      <Primitives.Flex width={20} height={25} m="5px">
                        <Loading onlySpinner />
                      </Primitives.Flex>
                    ) : (
                      <Primitives.Text>{buttonText}</Primitives.Text>
                      //   button text
                    )
                  }
                  lineHeight={4}
                  width={430}
                  justifyContent="center"
                />
              </Primitives.Flex>
            </form>
          </Primitives.Flex>

          <Footer />
        </Primitives.Flex>
      </Primitives.Flex>
      <Primitives.Flex mr={5}>
        <LoginImage height={325} width={665} />
      </Primitives.Flex>
    </Primitives.Flex>
  );
};

GenericReport.propTypes = propTypes;
export default GenericReport;
