import { BASE_URL, REQ_OPT, getHeaders } from "../config";

export default {
  accessRequest: async () => {
    var requestOptions = {
      headers: getHeaders(),
      ...REQ_OPT
    };
    try {
      const response = await fetch(
        `${BASE_URL}/smartchain/request_access`,
        requestOptions
      );
      const json = await response.json();
      if (json.success) {
        return json;
      }
    } catch (error) {
      console.log(error);
    }
  }
};
