import React, { useState, useEffect, useContext } from "react";
import Primitives from "../../../components/primitives";
import { colors } from "../../../components/utils/theme";
import { useHistory } from "react-router-dom";
import Footer from "../footer";
import Button from "../button";
import { useIntercom } from "react-use-intercom";
import OtpInput from "react-otp-input";
import { breakPoint } from "../../../components/utils/theme";
import { useLocation } from "react-router-dom";
import Tooltip from "../../../components/tool-tip";
import Counter from "../../../components/counter";
import BrandImage from "../brand-image";
import LoginHeader from "../login-header";
import otpVerifcationServices from "../../../services/otp-verification";
import user from "../../../services/user-logged-in";
import { AppContext } from "../../../utils/context";

const otpButtonStyle = {
  width: window.innerWidth < breakPoint ? "28px" : "40px",
  height: window.innerWidth < breakPoint ? "32px" : "42px",
  border: `1px solid ${colors.text[2]}`,
  borderRadius: "4px"
};

const OtpVerification = () => {
  const { loadUser } = useContext(AppContext);
  const location = useLocation();
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [alertData, setAlertData] = useState({});
  const [otp, setOtp] = useState();
  const [displayCounter, setDisplayCounter] = useState(true);
  const [counterStopValue, setCounterStopValue] = useState();
  const handleChange = otpValue => setOtp(otpValue);

  const newOtpRequestHandler = () => {
    setDisplayCounter(true);
    otpVerifcationServices.otpRequest();
  };

  const counterFinalValue = value => {
    value === 0 && setDisplayCounter(false);
    setCounterStopValue(value);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const res = await otpVerifcationServices.verifyOtp(otp);
    if (res && res.success) {
      const isUserLoggedIn = await user.isLoggedIn();
      if (isUserLoggedIn["logged_in"] && isUserLoggedIn["logged_in"]) {
        await loadUser(isUserLoggedIn);
        history.push("/company/product_report");
      }
    } else {
      setAlertData({ detail: "Invalid OTP" });
    }
  };

  const { shutdown } = useIntercom();

  useEffect(() => {
    shutdown();
  }, []);

  useEffect(() => {
    location.state && location.state.email && setEmail(location.state.email);
  }, [location]);

  useEffect(() => {
    otpVerifcationServices.otpRequest();
  }, []);

  return (
    <Primitives.Flex flexDirection="column" mx={4} my={[4, 1]}>
      <BrandImage />

      <Primitives.Flex flexDirection="column" mr="auto" ml="auto">
        <LoginHeader alertData={alertData} />
        <Primitives.Flex
          borderTop={`1px solid ${colors["athens-gray"]}`}
          my={2}
        />

        <Primitives.Text
          color={colors.text[0]}
          fontSize={["10.42px", "13px"]}
          fontWeight={1}
          lineHeight="20px"
          mb={17}
        >
          We have sent a 6 digit OTP on your registered email <br />({email}).
          Please enter the OTP below.
        </Primitives.Text>

        <OtpInput
          inputStyle={otpButtonStyle}
          numInputs={6}
          onChange={handleChange}
          separator={
            <Primitives.Flex mx="2px" width={[10, 20]} height={[35, 48]} />
          }
          shouldAutoFocus={true}
          value={otp}
        />
        <Button handleSubmit={handleSubmit}>Submit OTP</Button>
        <Primitives.Flex
          color={colors.primary[3]}
          fontSize="12px"
          justifyContent="space-between"
          mt={2}
        >
          <Primitives.Flex>
            <Primitives.Flex alignItems="center" cursor="pointer">
              <Primitives.Text
                fontSize="12px"
                fontWeight={1}
                onClick={counterStopValue === 0 && newOtpRequestHandler}
                opacity={counterStopValue !== 0 ? "50%" : 1}
              >
                Resend OTP
              </Primitives.Text>
              {displayCounter && (
                <Counter counterFinalValue={counterFinalValue} value={60} />
              )}
            </Primitives.Flex>
          </Primitives.Flex>
          <Primitives.Flex>
            <Tooltip
              contentWidth={[150, 219]}
              contentHeight={["unset", 79]}
              content="Email OTP verification is required for ensure your account and company data are secured."
              padding={[10, 15]}
              value={
                <Primitives.Text
                  textDecoration="underline"
                  fontSize="12px"
                  color={colors.primary[3]}
                  lineHeight="14.4px"
                >
                  Why OTP verification ?
                </Primitives.Text>
              }
            />
          </Primitives.Flex>
        </Primitives.Flex>
        <Footer />
      </Primitives.Flex>
    </Primitives.Flex>
  );
};
export default OtpVerification;
